import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../../app/common/tables/Table";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Cell, FilterProps} from "react-table";
import {ClientDetailedFilter} from "../../../../app/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientDetailedCell from "../ClientDetailedCell";
import {IClientEmployee} from "../../../../app/models/clients";
import moment from "moment";
import {DateFilter} from "../../../../app/common/tables/filters/date/DateFilter";
import {Message} from "semantic-ui-react";

interface IProps {
    trackingId?: string
}

const ClientEmployeesList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {clientEmployeesArray: clientEmployees} = rootStore.clientEmployeesStore
    const { loadClientCategoriesIfNull, categories } = rootStore.clientsStore
    const { loadRegionsIfNull, regionsArray: regions } = rootStore.regionsStore
    
    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull
    ])
    
    const columns = useMemo(() => [
            {
                Header: 'Ime',
                accessor: 'name'
            },
            {
                id: 'client',
                Header: 'Klijent',
                Cell: ({row: {original: employee}}: Cell<IClientEmployee>) => (<ClientDetailedCell client={employee.client!}/>),
                accessor: 'client.name',
                Filter: (d: FilterProps<any>) => ClientDetailedFilter(d, regions!, categories!),
                filter: 'clientDetailedFilter'
            },
            {
                Header: 'Pozicija',
                accessor: 'job'
            },
            {
                id: 'thisYearBirthday',
                Header: 'Rodjendan',
                accessor: 'thisYearBirthday',
                Cell: ({row: {original: employee}}: Cell<IClientEmployee>) => moment(employee.thisYearBirthday).format('l'),
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                Header: 'Broj telefona',
                accessor: 'telNumber',
            },
            {
                Header: 'Komentar',
                accessor: 'note'
            },
        ],
        [
            categories,
            regions
        ])

    if (clientEmployees.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Nismo pornašli zaposlene po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>


    return (<Table
        trackingId={trackingId}
        data={clientEmployees}
        columns={columns}
    />);
};

export default observer(ClientEmployeesList)
