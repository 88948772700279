import React, {FC} from 'react'
import {
    VictoryAxis,
    VictoryChart, VictoryGroup,
    VictoryLine, VictoryScatter, VictoryTheme,
    VictoryTooltip, VictoryVoronoiContainer
} from "victory";
import {formatRsd} from "../../../../../app/common/util/format";
import moment from "moment";

interface IData {
    key: Date,
    b: number
}

interface IEntry {
    title: string,
    color: string,
    hide?: boolean,
    data: IData[]
}

interface IProps {
    entries: IEntry[]
}

const tickFormatter = (tick: any) => {
    const {length} = tick.toFixed(0)

    if (length < 4) {
        return tick
    } else if (length < 7) {
        return tick / 1000 + "K"
    } else if (length < 10) {
        return tick / 1000000 + "M"
    }
}

export const ProfitabilityPeriodTimeDiagram: FC<IProps> = ({entries}) => <div>
    <VictoryChart
        theme={VictoryTheme.material}
        height={400}
        width={1200}
        containerComponent={<VictoryVoronoiContainer
            style={{marginLeft: 20, marginRight: 20}}/>}
    >
        <VictoryAxis
            dependentAxis
            tickFormat={tickFormatter}
        />
        {
            console.log({entries})
        }
        <VictoryAxis
            tickCount={entries?.[0].data.length}
            tickFormat={(datum) => moment(datum).format('MMMYY').toUpperCase()}
        />

        {entries
            .filter(entry => !entry.hide)
            .map(entry => <VictoryGroup
                color={entry.color}
                labels={({datum}) => formatRsd(datum.y)}
                labelComponent={
                    <VictoryTooltip
                        style={{fontSize: 10}}
                    />
                }
                data={entry.data.map(el => ({x: el.key, y: el.b}))}
            >
                <VictoryLine/>
                <VictoryScatter
                    size={({active}) => active ? 8 : 3}
                />
            </VictoryGroup>)}

    </VictoryChart>
</div>

