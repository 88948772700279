import React, {FunctionComponent, useContext} from 'react';
import {Link, NavLink} from 'react-router-dom'
import {Button, Form, Icon, List, Menu, Placeholder, Select} from "semantic-ui-react";
import {formatRsd} from "../../../app/common/util/format";
import ClientProgress from "../commom/ClientProgress";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {generateYearsOptions} from "../../../app/common/util/options";

interface IProps {
    // setCurrentTab: any
    setYear: any
    year: string
    currentTab: string
}

const ClientDetailedMenu: FunctionComponent<IProps> = ({currentTab, setYear, year}) => {
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore
    const {client, updatingFinancialState, updateFinancialState} = rootStore.clientsStore


    if (!client)
        return <>'NOC'</>

    return (<Menu fluid vertical size='small'>
        <Menu.Item
            as={Link}
            active={currentTab === 'bills'}
            to={`/${currentTraffic?.id}/clients/${client.id}/bills`}
        >
            Računi
        </Menu.Item>

        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/payments`}
        >
            Uplate
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/goals`}
            >
            Ciljevi
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/goals-payments`}
        >
            Naplate
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/activities`}
        >
            Aktivnosti
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/brands`}
        >
            Brendovi
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/onHoldProducts`}
        >
            Proizvodi na čekanju
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/orders`}
        >
            Narudžbine
        </Menu.Item>
        <Menu.Item
            as={NavLink}
            to={`/${currentTraffic?.id}/clients/${client.id}/employees`}
        >
            Zaposleni
        </Menu.Item>
        
        <Menu.Item>

            <List divided verticalAlign='middle'>
                <List.Item>
                    <Form>
                        <Form.Group>
                            <Form.Field width={13}>
                                <Select
                                    disabled={updatingFinancialState}
                                    fluid
                                    text={'Godina: ' + year}
                                    search
                                    options={generateYearsOptions(2010, 2050)}
                                    value={year}
                                    onChange={(event, data) => setYear(data.value)}
                                />
                            </Form.Field>
                            <Form.Field width={2}>
                                <Button
                                    disabled={updatingFinancialState}
                                    icon
                                    onClick={() => updateFinancialState()}
                                >
                                    <Icon name='sync' loading={updatingFinancialState}/>
                                </Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </List.Item>

                {updatingFinancialState ?
                    <List.Item>
                        <Placeholder>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                            <Placeholder.Line length='full'/>
                        </Placeholder>
                    </List.Item> :
                    <>
                        <List.Item>
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.balanceWithInitialState!)}
                            </List.Content>
                            <List.Content>Saldo</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.toPaidWithInitialState!)}
                            </List.Content>
                            <List.Content>Dospelo</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.clientInitialStateValue!)}
                            </List.Content>
                            <List.Content>Početno stanje</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.placedBaseValue!)}
                            </List.Content>
                            <List.Content>Plasirano</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.paid!)}
                            </List.Content>
                            <List.Content>Plaćeno</List.Content>
                        </List.Item>
                        <List.Item
                            as={NavLink}
                            to={`/${currentTraffic?.id}/clients/${client.id}/gratis`}
                        >
                            <List.Content floated='right'>
                                {formatRsd(client.financialState?.gratisAmount!)}
                            </List.Content>
                            <List.Content>Gratis</List.Content>
                        </List.Item>
                        {(!!(client.financialState?.scheduleValue)) && 
                        <List.Item>
                            <List.Header>
                                Ostvarenje
                            </List.Header>
                            
                            <Link to={`/${currentTraffic?.id}/clientSchedules/${client.id}/${year}`}>
                                <ClientProgress total={client.financialState?.scheduleValue}
                                                value={client.financialState?.placedBaseValue}/>    
                            </Link>
                        </List.Item>}
                    </>}
            </List>
        </Menu.Item>
    </Menu>);
};

export default observer(ClientDetailedMenu);
