import React, {FunctionComponent, useContext, useState} from 'react';
import {Form as FinalForm, Field} from 'react-final-form'
import {Button, Checkbox, Form, Grid, Header, Input, Segment} from "semantic-ui-react";
import {FORM_ERROR} from "final-form";
import {combineValidators, isRequired} from "revalidate";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IUserResetPasswordFormValues} from "../../../app/models/user";
import TextInput from "../../../app/common/form/TextInput";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {RouteComponentProps} from "react-router";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";

interface ResetParams {

}


const validate = combineValidators({
    newPassword: isRequired({message: 'Lozinka je obavezna'}),
    token: isRequired({message: 'Token je obavezan'}),
    userId: isRequired({message: 'Id korisnika je obavezan'}),

})

const UserResetPasswordForm: FunctionComponent<RouteComponentProps<ResetParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {resetPassword} = rootStore.userStore
    const {homePageWithCurrentLinkTrafficOrDefault} = rootStore.navbarStore

    const [showPasswords, setShowPasswords] = useState(false)
    const [retypedPassword, setRetypedPassword] = useState('')

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    const userId = query.get('userId')
    const token = query.get('token')

    return (
        <Grid>
            <Grid.Row centered>
                <Grid.Column computer={8} tablet={10} mobile={16}>
                    <Header
                        content='Resetovanje lozinke'
                        as='h2'
                        color='blue'
                    />
                    <Segment clearing>
                        <FinalForm
                            onSubmit={(values: IUserResetPasswordFormValues) => resetPassword(values)
                                .then(() => {
                                    toast.success('Lozinka je uspešno resetovana')
                                    history.push(homePageWithCurrentLinkTrafficOrDefault)
                                })
                                .catch(error => ({
                                    [FORM_ERROR]: error
                                }))}
                            initialValues={{
                                userId,
                                token,
                                newPassword: ''
                            }}
                            validate={validate}
                            render={({
                                         handleSubmit,
                                         submitting,
                                         form: {
                                             change,
                                         },
                                         values,
                                         submitError,
                                         invalid,
                                         pristine,
                                         dirtySinceLastSubmit,
                                     }) => (
                                <Form onSubmit={handleSubmit} error>

                                    <Form.Field>
                                        <Checkbox
                                            slider
                                            label='Pirkaži lozinke'
                                            onChange={((event, data) => setShowPasswords(data.checked!))}
                                            checked={showPasswords}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Nova lozinka</label>
                                        <Field
                                            name='newPassword'
                                            component={TextInput}
                                            placeholder='Nova lozinka'
                                            type={showPasswords ? 'text' : 'password'}/>
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Ponovi novu lozinku</label>
                                        <Input
                                            onChange={((event, data) => setRetypedPassword(data.value))}
                                            value={retypedPassword}
                                            type={showPasswords ? 'text' : 'password'}
                                        />
                                    </Form.Field>

                                    <Field
                                        name='token'
                                        component='input'
                                        type='hidden'
                                    />
                                    <Field
                                        name='userId'
                                        component='input'
                                        type='hidden'
                                    />

                                    {submitError && !dirtySinceLastSubmit &&
                                    <ErrorMessage error={submitError}/>}
                                    <Button
                                        disabled={(invalid && !dirtySinceLastSubmit) || pristine || values.newPassword !== retypedPassword}
                                        color='blue'
                                        content='Promeni'
                                        loading={submitting}
                                        floated='right'/>

                                </Form>
                            )}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default UserResetPasswordForm
