import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Cell, FilterProps} from 'react-table'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {IClient, IClientFilter} from "../../../app/models/clients";
import {Link} from "react-router-dom";
import ClientTableLocationCell from "./ClientTableLocationCell";
import ClientTableContactCell from "./ClientTableContactCell";
import ClientTableNotesCell from "./ClientTableNotesCell";
import {formatRsd} from "../../../app/common/util/format";
import ClientProgress from "../commom/ClientProgress";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";
import {ClientDetailedFilter} from "../../../app/common/tables/filters/clients/ui/ClientDetailedFilter";
import {clientDetailedFilter} from "../../../app/common/tables/filters/clients/logic/clientDetailedFilter";
import ClientDetailedCell from "./ClientDetailedCell";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import {numberToStringToFixedOrInt} from "../../../app/common/util/string";
import ClientDetailsGenerateCardModal from "../details/header/ClientDetailsGenerateCardModal";
import UserBasicCell from "../../user/table/UserBasicCell";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";

interface IProps {
    hideRegion?: boolean,
    hideBalance?: boolean,
    hideProgress?: boolean,
    hideRank?: boolean,
    trackingId?: string,
}

const ClientsList: FunctionComponent<IProps> = ({hideRegion, hideBalance, hideProgress, trackingId, hideRank}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientsArray: clients,
        deleting,
        deleteClient,
        deletingId,
        loadClientCategoriesIfNull,
        categories
    } = rootStore.clientsStore
    const { currentTraffic } = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const { loadRegionsIfNull, regionsArray: regions } = rootStore.regionsStore
    const { loadUsers, employeesArray: employees } = rootStore.usersStore
    
    useEffect(() => {
        loadUsers(true)
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadUsers
    ])
    

    const columns = React.useMemo(
        () => [
            {
                Header: 'Naziv',
                Cell: ({row: {original: client}}: Cell<IClient>) => (<ClientDetailedCell client={client}/>),
                id: 'client',
                accessor: (d: IClient) => d.name,
                Filter: (d: any) =>  ClientDetailedFilter(d, regions!, categories!),
                filter: (rows: any, id: any, filterValue: IClientFilter) => clientDetailedFilter(rows, id, filterValue, true),
            },
            {
                Header: 'Lokacija',
                accessor: 'city',
                Cell: (cell: Cell<IClient>) => (
                    <ClientTableLocationCell hideRegion={hideRegion} client={cell.row.original}/>)
            },
            {
                Header: 'Kontakt',
                Cell: (cell: Cell<IClient>) => (<ClientTableContactCell client={cell.row.original}/>),
                accessor: (d: IClient) => d.contactInfo 
            },
            {
                Header: 'Napomena',
                Cell: (cell: Cell<IClient>) => (<ClientTableNotesCell hidePublic client={cell.row.original}/>)
            },
            {
                Header: 'Račun',
                accessor: 'currentAccount'
            },
            {
                accessor: (d: any) => d.agent?.id,
                Header: 'Agent',
                Cell: ({row: {original: client}}: Cell<IClient>) => client.agent ? 
                    <UserBasicCell 
                        avatar
                        imageSize='mini'
                        textSize='tiny'
                        user={client.agent}/> : 
                    'N/A',
                Filter: (d: FilterProps<any>) => 
                    MultiselectFilter(d, employees.map(user => ({key: user.id, value: user.id, text: `${user.displayName} [${user.email}]` })), 
                        'Agent'),
                filter: 'multiselectFilter'
            },
            {
                id: 'financialState.balanceWithInitialState',
                Header: (row: any) =>                    
                    <SumTableHeader row={row} type={'money'} name={'Saldo'}
                                                      accessor={'financialState.balanceWithInitialState'}/>,
                accessor: (d: IClient) => d.financialState?.balanceWithInitialState,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (financialState?.balanceWithInitialState !== null)
                    
                    ? (
                    <div>
                        {formatRsd(financialState?.balanceWithInitialState ?? NaN)}
                    </div>
                ) : (<Icon name='cog' loading />)
                ,
                hide: hideBalance,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {

                accessor: (d: IClient) => {

                    const placed = d.financialState?.placedBaseValue
                    const scheduled = d.financialState?.scheduleValue

                    if (!placed || !scheduled) {
                        return 0
                    }

                    return placed / scheduled
                },
                id: 'financialState.scheduleValue',
                Header: (row: any) => {

                    const filteredRows: IClient[] = row.filteredRows.map((row: any) => row.original)

                    const sum = filteredRows.reduce((previousValue, currentValue) => {
                            const placed = currentValue.financialState?.placedBaseValue ?? 0
                            const scheduled = currentValue.financialState?.scheduleValue ?? 0

                            let percentage = 0

                            if (scheduled !== 0) {
                                percentage = placed / scheduled * 100
                                if (percentage > 100) {
                                    percentage = 100
                                }
                            }

                            return {
                                placed: previousValue.placed + (scheduled === 0 ? 0 : placed),
                                scheduled: previousValue.scheduled + (scheduled === 0 ? 0 : scheduled),
                                percentageSum: previousValue.percentageSum + (scheduled === 0 ? 0 : percentage),
                                withScheduleCount: previousValue.withScheduleCount + (scheduled === 0 ? 0 : 1)
                            }
                        },
                        {
                            placed: 0,
                            scheduled: 0,
                            percentageSum: 0,
                            withScheduleCount: 0
                        })

                    return <>
                        <Header sub>
                            Ostvarenje
                        </Header>
                        <strong>
                            {formatRsd(sum.placed)} / {formatRsd(sum.scheduled)} [{numberToStringToFixedOrInt(sum.percentageSum / sum.withScheduleCount, 2)}%]
                        </strong>
                    </>
                },                
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) =><ClientProgress value={financialState?.placedBaseValue} total={financialState?.scheduleValue} />,
                hide: hideProgress,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            // {
            //     id: 'rank',
            //     accessor: 'id',
            //     hide: hideRank,
            //     Header: 'Rang',
            //     Cell: ({row: {original: client }}: Cell<IClient>) => <Header 
            //         color={client.type === 'Aktivan' ? clientStatusToColorName(client.status!) : 'grey'} 
            //         as='h4' 
            //         content={`${alphabet[getClientRankByStatusAndType(client)]
            //             .toUpperCase()}${client.financialState?.placedBaseValue}`} />,
            //     Filter: <></>,
            //     sortType: 'clientRank',
            // },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: client}}: Cell<IClient>) => (
                    <Button.Group>
                        <ClientDetailsGenerateCardModal client={client} />
                        <Button as={Link} to={`/${currentTraffic?.id}/clients/manage/${client.id}/edit`} icon='edit' color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje klijenta
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš klijenta <strong>{client.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any, data) => {
                                    event.target.className += ' loading'
                                    deleteClient(client.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={(deletingId === client.id) && deleting}/>
                    </Button.Group>)
            },

        ],
        [
            closeModal, 
            deleteClient, 
            deleting, 
            openModal, 
            deletingId, 
            hideRegion, 
            currentTraffic, 
            hideBalance, 
            hideProgress,
            categories,
            regions,
            // hideRank,
            employees
        ]
    )
    
    if (clients.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje klijenti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    
    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={clients}
        />
    );
};

export default observer(ClientsList)
