import React, {FunctionComponent, useContext, useEffect} from 'react'
import {Button, Divider, Grid, Icon, Segment} from "semantic-ui-react";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import RequiredProductsList from "../list/RequiredProductsList";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import ShortcutButton from "../../commom/buttons/ShortcutButton";
import {useResponsive} from "../../../app/common/responsive/Responsive";

interface OwnProps {
}

type Props = OwnProps

const RequiredProductsDashboard: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {loading, loadRequiredProducts} = rootStore.requiredProductsStore

    const {isMobile} = useResponsive()

    useNavbarSelectedItem('requiredProductsDashboard')

    useEffect(() => {
        loadRequiredProducts()
    }, [
        loadRequiredProducts,
    ])

    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Traženi proizvodi',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <ShortcutButton
                    fluid={isMobile}
                    text='Proizvodi na čekanju'
                    link={`/${currentTraffic?.id}/onHoldProducts`}
                />
                {isMobile
                && <Divider fitted hidden />}
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/requiredProducts/add`}
                    floated='right'
                    color='blue'
                    fluid={isMobile}
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj traženi proizvod
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment.Group>
                    <Segment clearing color='blue' loading={loading}>
                        <Button
                            disabled={loading}
                            icon
                            labelPosition='left'
                            floated='right'
                            color='teal'
                            // onClick={() => exportPdf()}
                        >
                            <Icon name='file pdf'/>
                            PDF
                        </Button>
                    </Segment>
                    <Segment>
                        <RequiredProductsList/>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(RequiredProductsDashboard)
