import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Form as FinalForm, Field} from "react-final-form";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import {v4 as uuid} from 'uuid';
import {RootStoreContext} from "../../../app/stores/rootStore";
import {
    DocumentationFormValues,
    IDocumentationFormValues
} from "../../../app/models/documentations";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import TextInput from "../../../app/common/form/TextInput";
import DateInput from "../../../app/common/form/DateInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {convertStateToString, convertStringToState} from "../../../app/common/util/draft-js";
import {EditorState} from "draft-js";
import WysiwygEditor from "../../wysiwygEditor/WysiwygEditor";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {useLocation} from "react-router-dom";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    folderId: isRequired({message: 'Folder je obavezan'}),
    category: isRequired({message: 'Kategorija je obavezna'})

});

interface DetailParams {
    id: string;
}


const DocumentationForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({
                                                                                     match,
                                                                                     history,
                                                                                 }) => {

    const rootStore = useContext(RootStoreContext);
    const {
        createDocumentation,
        loadDocumentation,
        editDocumentation,
        submittingDocumentation: submitting,
        loadDocumentationCategories,
        documentationCategories,
        loadingDocumentationCategories,
        documentationFoldersWithOriginalOrVirtualPrivateArray: documentationFolders
    } = rootStore.documentationsStore
    const {currentTraffic} = rootStore.trafficsStore
    const { homePageWithCurrentLinkTrafficOrDefault } = rootStore.navbarStore

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    const initialFolderId = query.get('initialFolderId')

    const [documentation, setDocumentation] = useState<IDocumentationFormValues>(new DocumentationFormValues())
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    )

    useNavbarSelectedItem(match.params.id ? '' : 'documentationsCreate')


    useEffect(() => {
        loadDocumentationCategories('', true)

        if (match.params.id) {
            setLoading(true)
            loadDocumentation(match.params.id)
                .then(documentation => {
                        setEditorState(convertStringToState(documentation!.description))
                        setDocumentation(new DocumentationFormValues(documentation))
                    }
                )
                .finally(() => setLoading(false))
        } else {
            if (initialFolderId) {
                setDocumentation(prevState => ({...prevState, folderId: initialFolderId}))
            }
        }


    }, [
        match.params.id,
        loadDocumentation,
        loadDocumentationCategories,
        initialFolderId
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const documentation = {...values}

        if (!documentation.id) {
            let newDocumentation = {
                ...documentation,
                id: uuid()
            }

            return createDocumentation(newDocumentation)
        } else {

            return editDocumentation(documentation)
        }
    }

    return (
        <Grid centered>
            <Grid.Row>
                <Grid.Column>
                    <BreadcrumbNavigation
                        items={[
                            {
                                text: 'Dokumentacija'
                            },
                            {
                                text: match.params.id ? 'Izmena dokumentacije' : 'Dodavanje dokumentacije',
                                active: true
                            }
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IDocumentationFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        validate={validate}
                        initialValues={documentation}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form,
                                     values
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingDocumentationCategories}>

                                <Form.Field>
                                    <label>Naziv</label>
                                    <Field
                                        name='name'
                                        placeholder='Naziv'
                                        value={documentation.name}
                                        component={TextInput}/>
                                </Form.Field>

                                <Form.Field>
                                    <label>Folder</label>
                                    <Field
                                        name='folderId'
                                        component={SelectStringInput}
                                        placeholder='Folder'
                                        onChangeCallback={(value: string) => {
                                            const isPrivate = documentationFolders.filter(folder => folder.id === value)[0]?.private ?? false

                                            form.change('private', isPrivate)
                                        }}
                                        options={documentationFolders.map(folder => ({
                                            key: folder.id,
                                            value: folder.id,
                                            text: folder.name
                                        }))}
                                    />

                                    <Field
                                        name='private'
                                        component='input'
                                        hidden
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>
                                        Kategorija
                                    </label>
                                    <Field
                                        name='category'
                                        placeholder='Kategorija'
                                        value={documentation.category}
                                        component={TextInput}
                                        suggestions={documentationCategories}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Datum</label>
                                    <Field
                                        component={DateInput}
                                        name='date'
                                        date={true}
                                        placeholder='Date'
                                        value={documentation.date}/>
                                </Form.Field>

                                <Form.Field>
                                    <label>Opis</label>
                                    <Field
                                        name='description'
                                        render={() =>
                                            <WysiwygEditor
                                                placeholder='Opis...'
                                                editorState={editorState}
                                                setEditorState={setEditorState}
                                                onChangeCallback={() => form.change('description', convertStateToString(editorState))}/>}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Napomena</label>
                                    <Field
                                        name='note'
                                        placeholder='Napomena'
                                        value={documentation.note}
                                        component={TextAreaInput}/>
                                </Form.Field>

                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!documentation.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={documentation.id ?
                                        () => history.push(`/${currentTraffic?.id}/documentations/${documentation.id}`) :
                                        () => history.push(values.folderId ? `/${currentTraffic?.id}/documentationFolder/${values.folderId}` : homePageWithCurrentLinkTrafficOrDefault)}
                                    disabled={loading}
                                    floated='right'
                                    type='button'
                                    content='Odustani'
                                />
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(DocumentationForm)
