import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import RegionsList from "../lists/RegionsList";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const RegionsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadRegions, loading} = rootStore.regionsStore
    const {currentTraffic} = rootStore.trafficsStore

    useNavbarSelectedItem('regions')

    useEffect(() => {
        loadRegions()
    }, [
        loadRegions,
    ])

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Regioni',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/regions/create`}
                    floated='right'
                    color='blue'
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj region
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment color='blue' loading={loading}>
                    <RegionsList/>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>)
}

export default observer(RegionsDashboard)
