import React, {FunctionComponent, useState} from 'react'
import {Comment, Grid} from "semantic-ui-react";
import EmployeeGoalSingleComment from "../details/EmployeeGoalSingleComment";
import {IEmployeeGoal} from "../../../app/models/employeeGoals";
import EmployeeGoalCommentForm from "../form/EmployeeGoalCommentForm";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";

interface OwnProps {
    employeeGoal: IEmployeeGoal
}

type Props = OwnProps

const EmployeeGoalCommentCell: FunctionComponent<Props> = ({employeeGoal}) => {
    const [addCommentMode, setAddCommentMode] = useState(false)

    const lastComment = (employeeGoal.comments.length > 0) ?
        employeeGoal.comments[employeeGoal.comments.length - 1] : null


    return <Grid centered verticalAlign='middle'>
        {/*     Last comment     */}
        {lastComment && <Grid.Row>
            <Grid.Column>
                <Comment.Group>
                    <EmployeeGoalSingleComment compact comment={lastComment}/>
                </Comment.Group>
            </Grid.Column>
        </Grid.Row>}

        <Grid.Row>
            <Grid.Column textAlign='center'>
                {/*     Add comment     */}
                {addCommentMode ?
                    <EmployeeGoalCommentForm
                        small
                        employeeGoalId={employeeGoal.id}
                        onSubmitSuccess={() => setAddCommentMode(false)}
                        onCancel={() => setAddCommentMode(false)}
                    /> :
                    <ShowMoreButton
                        content='Dodaj komentar'
                        onClick={() => setAddCommentMode(true)}/>}
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default EmployeeGoalCommentCell
