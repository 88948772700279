import React, {FunctionComponent, useContext} from 'react'
import {Breadcrumb} from "semantic-ui-react";
import {RootStoreContext} from "../../app/stores/rootStore";
import {Link} from "react-router-dom";

export interface IBreadcrumbNavigationItem {
    text: string,
    active?: boolean
    linkWithoutCurrentTrafficId?: string
}

interface OwnProps {
  items: IBreadcrumbNavigationItem[]
}

type Props = OwnProps

const BreadcrumbNavigation: FunctionComponent<Props> = ({ items }) => {
  const rootStore = useContext(RootStoreContext)
  const { currentTraffic } = rootStore.trafficsStore
  const { homePageWithCurrentLinkTrafficOrDefault } = rootStore.navbarStore
  
  return <Breadcrumb>
    <Breadcrumb.Section
      as={Link}
      to={homePageWithCurrentLinkTrafficOrDefault}>
      Početna
    </Breadcrumb.Section>
    {items.map((item, index) => <span key={index}>
      <Breadcrumb.Divider />
      <Breadcrumb.Section
        active={item.active}
        as={item.linkWithoutCurrentTrafficId ? Link : undefined}
        to={item.linkWithoutCurrentTrafficId
        ? `/${currentTraffic?.id}/${item.linkWithoutCurrentTrafficId}`
        : undefined}
      >
        {item.text}
      </Breadcrumb.Section>
    </span>)}
  </Breadcrumb>
}

export default BreadcrumbNavigation
