import {RouteComponentProps} from "react-router";
import React, {useContext, useEffect} from "react";
import {RootStoreContext} from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Grid} from "semantic-ui-react";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {observer} from "mobx-react-lite";
import ClientTourDetails from "./ClientTourDetails";
import {ClientTour} from "../../../app/models/clientTours";

interface DetailParams {
    id: string
}


const ClientTourDetailsRouteWrapper: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                                      match,
                                                                                      history
                                                                                  }) => {

    const rootStore = useContext(RootStoreContext)
    const { loadClientTour, loading, clientTour} = rootStore.clientToursStore
    const { currentTraffic } = rootStore.trafficsStore

    useEffect(() => {
        loadClientTour(match.params.id)
    }, [
        loadClientTour,
        match.params.id
    ])

    if (loading || !clientTour)
        return <LoadingComponent content='Učitavanje obilaska...'/>

    return <Grid>
        <Grid.Row centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation items={[
                    {
                        text: 'Plan rada',
                    },
                    {
                        text: 'Kalendar obilazaka',
                        linkWithoutCurrentTrafficId: 'clientTours'
                    },
                    {
                        text: clientTour.title,
                        active: true
                    }
                ]} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column computer={10} tablet={12} mobile={16}>
                <ClientTourDetails 
                    clientTour={new ClientTour(clientTour)} 
                    outsideModal
                    onDeleteSuccess={() => history.push(`/${currentTraffic?.id}/clientTours`)}
                    
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default observer(ClientTourDetailsRouteWrapper)
