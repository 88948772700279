import React, {FunctionComponent, useContext} from 'react'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import UserPostDetails from "../details/UserPostDetails";
import {UserPost} from '../../../app/models/userPosts'
import {Message} from "semantic-ui-react";

interface OwnProps {
}

type Props = OwnProps

const UserPostsList: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {userPostsArray: userPosts} = rootStore.userPostsStore

    if (userPosts.length === 0) {
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje postovi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>
    }
    
    return <>
        {userPosts.map(userPost =>
            <UserPostDetails key={userPost.id} userPost={new UserPost(userPost)}/>
        )}
    </>
}

export default observer(UserPostsList)
