import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import ClientsReceivablesList from "../../clients/list/ClientsReceivablesList";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Button, Checkbox, Form, Grid, Icon, Input, Message, Segment} from "semantic-ui-react";
import {PdfService} from "../../../app/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../app/common/util/format";
import {IClient} from "../../../app/models/clients";
import moment from "moment";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

const TRACKING_ID = 'receivablesList'

const defaultStartDate = moment().startOf('year').format('YYYY-MM-DD')
const defaultEndDate = moment().endOf('year').format('YYYY-MM-DD')

interface IProps {
}

const ReceivablesDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clearPredicate,
        setPredicate,
        loadClients,
        loading,
        generatingBalancesWithNoPaidBills,
        generateBalancesWithNoPaidBills
    } = rootStore.clientsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const [startDate, setStartDate] = useState(defaultStartDate)
    const [endDate, setEndDate] = useState(defaultEndDate)
    const [loadedStartDate, setLoadedStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'))
    const [loadedEndDate, setLoadedEndDate] = useState(moment().endOf('year').format('YYYY-MM-DD'))
    const [datesAreDirty, setDatesAreDirty] = useState(false)
    const [withInitialState, setWithInitialState] = useState(true)

    useNavbarSelectedItem('receivables')
    
    const handleLoadClients = async (start: string, end: string) => {
        clearPredicate()
        setPredicate('withFinancialState', 'true')
        setPredicate('startDate', start)
        setPredicate('endDate', end)
        await loadClients()
        setDatesAreDirty(false)
        setLoadedStartDate(start)
        setLoadedEndDate(end)
    }
    
    useEffect(() => {
        clearPredicate()
        setPredicate('withFinancialState', 'true')
        setPredicate('startDate', defaultStartDate)
        setPredicate('endDate', defaultEndDate)
        loadClients()


        return () => {
            clearPredicate()
            removeFromRegistry(TRACKING_ID)
        }
    }, [clearPredicate, setPredicate, removeFromRegistry, loadClients])


    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(`Tabela potraživanja (${withInitialState ? 'sa pocetnim stanjem' : 'bez pocetnog stanja'}) za period ${moment(loadedStartDate).format('l')} - ${moment(loadedEndDate).format('l')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Klijent', 'Saldo', 'Dospelo', 'Ostvarenje']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((client: IClient, index) => ([
                index + 1,
                client.name,
                formatRsd(client.financialState?.balance!),
                formatRsd(client.financialState?.toPaid!),
                `${client.financialState?.placedBaseValue!} / ${client.financialState?.scheduleValue!}`
            ])) ?? []
        })

        doc.save("Potraživanja.pdf")
    }

    const validateDates = () => {
        const start = moment(startDate)
        const end = moment(endDate)

        let errors: string[] = []

        if (!start.isValid()) {
            errors.push('Neispravan početni datum')
        }
        if (!end.isValid()) {
            errors.push('Neispravan krajnji datum')
        }
        if (!start.isValid() || !end.isValid()) {
            return errors
        }

        if (start.year() !== end.year()) {
            errors.push('Opseg mora biti u istoj godini')
            return errors
        }

        if (start.isAfter(end, 'day')) {
            errors.push('Neispravan opseg')
        }

        return errors
    }

    const handleChangeDate = (date: string, type: 'start' | 'end') => {
        setDatesAreDirty(true)
        if (type === 'start') {
            setStartDate(date)
        } else {
            setEndDate(date)
        }
    }

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Potraživanja',
                            active: true
                        }
                    ]}
                    />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment.Group>
                    <Segment attached='top' color='blue' clearing>
                        <Grid>
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Form>
                                    <Form.Group inline>
                                        <Form.Field>
                                            <Checkbox
                                                slider
                                                label='Početno stanje'
                                                checked={withInitialState}
                                                onChange={(event, data) => setWithInitialState(data.checked!)}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Input
                                                type='date'
                                                value={startDate}
                                                onChange={(event, data) => handleChangeDate(data.value, 'start')}
                                                label={{basic: true, content: 'od'}}
                                                labelPosition='left'/>
                                        </Form.Field>
                                        <Form.Field>
                                            <Input
                                                type='date'
                                                value={endDate}
                                                onChange={(event, data) => handleChangeDate(data.value, 'end')}
                                                label={{basic: true, content: 'do'}}
                                                labelPosition='left'
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Button
                                                onClick={() => handleLoadClients(startDate, endDate)}
                                                loading={loading}
                                                disabled={loading || validateDates().length > 0 || !datesAreDirty}
                                                color='blue'
                                                content={'Primeni'}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Grid.Column>

                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <Button
                                    disabled={loading}
                                    icon
                                    labelPosition='left'
                                    floated='right'
                                    color='teal'
                                    onClick={() => exportPdf()}>
                                    <Icon name='file pdf'/>
                                    PDF
                                </Button>
                                <Button
                                    disabled={loading || validateDates().length > 0}
                                    loading={generatingBalancesWithNoPaidBills}
                                    icon
                                    labelPosition='left'
                                    floated='right'
                                    color='pink'
                                    onClick={() => generateBalancesWithNoPaidBills(startDate, endDate, withInitialState)}
                                >
                                    <Icon name='file pdf outline'/>
                                    Saldo & neplaćeni računi
                                </Button>
                            </Grid.Column>
                        </Grid>
                        
                        {validateDates().length > 0 &&
                        <Message error>
                            <Message.Header>Greške</Message.Header>
                            <Message.List>
                                {validateDates().map(error => <Message.Item key={error} content={error}/>)}
                            </Message.List>
                        </Message>
                        }

                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <ClientsReceivablesList withInitialState={withInitialState} trackingId={TRACKING_ID}/>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid.Row>
    </Grid>);
};

export default observer(ReceivablesDashboard)
