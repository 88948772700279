import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Segment} from "semantic-ui-react";
import ClientActivitiesList from "../../../clientActivities/list/ClientActivitiesList";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";

interface IProps {
    clientId: string
}

const ClientDetailsActivities: FunctionComponent<IProps> = ({clientId}) => {
    const rootStore = useContext(RootStoreContext)
    const { setPredicate, loading, loadClientActivities, clearPredicate } = rootStore.clientActivitiesStore
    const { year } = rootStore.clientsStore
    
    useEffect(() => {
        clearPredicate()
        setPredicate('clientId', clientId)
        setPredicate('startDate', `${year}-01-01`)
        setPredicate('endDate', `${year}-12-31`)
        loadClientActivities()
        
    }, [
        setPredicate,
        loadClientActivities,
        clientId,
        year,
        clearPredicate
    ])
    
  return (<Segment loading={loading}>
      <ClientActivitiesList hideClient />
  </Segment>)
}

export default observer(ClientDetailsActivities)
