import React, {FunctionComponent, useContext, useEffect} from 'react';
import BillsList from "../../../bills/list/BillsList";
import {Header, List, Segment} from "semantic-ui-react";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {billUsedBrandsWithValue} from "../../../../app/common/bills";
import {IBrand} from "../../../../app/models/brands";
import {formatRsd} from "../../../../app/common/util/format";
import {Link} from "react-router-dom";
import {getNiceColorFromStingAsciiSum} from "../../../../app/common/util/colors";
import {EXCLUDED} from "../../../../app/common/filters/3sFilter";

interface IProps {
    clientId: string,
    year: string
}

const ClientDetailsBills: FunctionComponent<IProps> = ({ clientId, year }) => {
    const rootStore = useContext(RootStoreContext)
    const { SHIT_getFilteredRowsById, removeFromRegistry } = rootStore.tablesStore
    const { currentTraffic } = rootStore.trafficsStore
    const { loadBills, setPredicate, clearPredicate, loading } = rootStore.billsStore
    
    useEffect(() => {
        clearPredicate()
        setPredicate('clientId', clientId)
        setPredicate('startDate', `${year}-01-01`)
        setPredicate('endDate', `${year}-12-31`)
        setPredicate('gratis', EXCLUDED)
        loadBills()
        
        return () => {
            removeFromRegistry('clientDetailsBills')  
            clearPredicate()
        } 
    }, [
        removeFromRegistry,
        clearPredicate,
        clientId,
        loadBills,
        setPredicate,
        year
    ])
    
    return (
        <Segment loading={loading}>
            <List divided verticalAlign='middle'>
                {billUsedBrandsWithValue(SHIT_getFilteredRowsById('clientDetailsBills') ?? [])
                    .map((brand: IBrand) => (<List.Item key={brand.id}>
                        <List.Content floated='right'>
                            {formatRsd(brand.value!)}
                        </List.Content>
                        <List.Content>
                            <Link to={`/${currentTraffic?.id}/brands/${brand.id}`}><Header as='h3' content={brand.name} style={{color: brand.color  ?? getNiceColorFromStingAsciiSum(brand.name)}}/></Link>
                        </List.Content>
                    </List.Item>))}
            </List>
            <BillsList smallCreator hideClient trackingId='clientDetailsBills'/>
        </Segment>
    )
}

export default observer(ClientDetailsBills)
