import React, {FunctionComponent, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Form, Input, Label, List} from "semantic-ui-react";
import {formatRsd} from "../../../app/common/util/format";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Types} from "../../../app/common/options/bill";
import {BillCreationType} from "../../../app/models/bills";

interface IProps {
}

const SummaryPrice: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        summaryPrice,
        type,
        creationType,
        manualBillValue, setManualBillValue
    } = rootStore.billsCartStore
    
    return (
        <List>
            {creationType === BillCreationType.Cart &&
            <>
                <List.Item>
                    <List.Content>
                        <List.Header>
                            Osnovica bez popusta
                        </List.Header>
                        <Label content={formatRsd(summaryPrice.baseWithoutDiscount)}/>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>
                            Popust
                        </List.Header>
                        <Label content={formatRsd(summaryPrice.discount)}/>
                    </List.Content>
                </List.Item>
            </>}
            <List.Item>
                <List.Content>
                    <List.Header>
                        Osnovica {creationType === BillCreationType.Cart && 'sa popustom'}
                    </List.Header>
                    {creationType === BillCreationType.Cart ?
                        <Label content={formatRsd(summaryPrice.baseWithDiscount)}/> :
                        creationType === BillCreationType.Manual ?

                            <Input
                                name='manualBillValue'
                                as={Form.Field}
                                type='number'
                                min={0}
                                fluid
                                labelPosition='right'
                                label={{basic: true, content: 'RSD'}}
                                value={manualBillValue}
                                onChange={(event, data) => setManualBillValue(Number(data.value))}/> : ''}
                </List.Content>
            </List.Item>
            {(type === Types.Invoice) &&
            <>
                <List.Item>
                    <List.Content>
                        <List.Header>
                            PDV
                        </List.Header>
                        <Label content={creationType === BillCreationType.Cart ? formatRsd(summaryPrice.taxValue) :
                            creationType === BillCreationType.Manual ? formatRsd(summaryPrice.manualTaxValue) : ''}/>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Content>
                        <List.Header>
                            Sa PDV-om
                        </List.Header>
                        <Label content={creationType === BillCreationType.Cart ? formatRsd(summaryPrice.taxedWithDiscount) :
                            creationType === BillCreationType.Manual ? formatRsd(summaryPrice.manualTaxed) : ''}/>
                    </List.Content>
                </List.Item>
            </>}
        </List>
    );
};

export default observer(SummaryPrice)
