import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Divider, Form, Header, Input, Label, Modal} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import moment from "moment";
import {generateClientCardPredicatesTypes} from "../../../../app/stores/clientsStore";
import {IClient} from "../../../../app/models/clients";


interface IProps {
    client: IClient,
    text?: string
}

const ClientDetailsGenerateCardModal: FunctionComponent<IProps> = ({client, text}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        generatingClientCard,
        generateClientCardPDF,
        getGenerateClientCardPredicate,
        setGenerateClientCardPredicate,
        clearGenerateClientCardPredicate
    } = rootStore.clientsStore

    const [open, setOpen] = React.useState(false)
    const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('year').format('YYYY-MM-DD'))

    useEffect(() => {   
        clearGenerateClientCardPredicate()
        
        //  set defaults predicates
        setGenerateClientCardPredicate(generateClientCardPredicatesTypes.BILLS, true.toString())
        setGenerateClientCardPredicate(generateClientCardPredicatesTypes.PAYMENTS, true.toString())
        setGenerateClientCardPredicate(generateClientCardPredicatesTypes.FINANCIAL_STATE, true.toString())
        
    }, [
        clearGenerateClientCardPredicate,
        setGenerateClientCardPredicate
    ])
    
    const isGenerateClientCardPdfFormValid = () =>  {
        let errors = []
        if (!startDate) {
            errors.push('Početni datum je obavezan')
        }
        if (!endDate) {
            errors.push('Krajnji datum je obavezan')
        }
        if (startDate && endDate) {
            if (moment(startDate).isAfter(endDate)) {
                errors.push('Neispravan opseg datuma')
            }
            if (moment(startDate).year() !== moment(endDate).year()) {
                errors.push('Opseg datuma mora biti u istoj godini')
            }
        }
        
        return errors
    }
    
    return (<Modal
        size='mini'
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        dimmer='blurring'
        trigger={<Button
            color='blue'
            floated='right'
            icon='file'
            content={text} />}
    >
        <Modal.Header>Kartica klijenta</Modal.Header>
        <Modal.Content image>
            <Modal.Description>
                <Header>Generiši PDF karticu klijenta<br/><strong>{client.name}</strong></Header>
                <p>Izaberi opseg datuma</p>
                <Form>
                    <Form.Field>
                        <Input 
                            type='date'
                            value={startDate}
                            label={{basic: true, content: 'Od'}}
                            onChange={((event, data) => setStartDate(data.value))}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            type='date'
                            value={endDate}
                            label={{basic: true, content: 'Do'}}
                            onChange={((event, data) => setEndDate(data.value))}
                        />
                    </Form.Field>
                    
                    <Form.Group inline>
                        <Form.Field>
                            <Checkbox 
                                label='Plasman'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.BILLS) === 'true'}
                                onChange={((event, data) => 
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.BILLS, data.checked!.toString()))}
                            />
                        </Form.Field>
                        
                        <Form.Field>
                            <Checkbox
                                label='Učinak brendova'
                                disabled={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.BILLS) !== 'true'}
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.BRANDS_IN_BILLS) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.BRANDS_IN_BILLS, data.checked!.toString()))}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider />
                    <Form.Group inline>
                        <Form.Field>
                            <Checkbox
                                label='Uplate'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.PAYMENTS) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.PAYMENTS, data.checked!.toString()))}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Checkbox
                                label='Stanje'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.FINANCIAL_STATE) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.FINANCIAL_STATE, data.checked!.toString()))}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider />
                    <Form.Group inline>
                        <Form.Field>
                            <Checkbox
                                label='Gratis'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.GRATIS_BILLS) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.GRATIS_BILLS, data.checked!.toString()))}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Checkbox
                                label='Učinak brendova'
                                disabled={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.GRATIS_BILLS) !== 'true'}
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.BRANDS_IN_GRATIS) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.BRANDS_IN_GRATIS, data.checked!.toString()))}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider />
                    <Form.Group inline>
                        <Form.Field>
                            <Checkbox
                                label='Aktivnosti'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.ACTIVITIES) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.ACTIVITIES, data.checked!.toString()))}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Checkbox
                                label='Top 5 proizvoda'
                                toggle
                                checked={getGenerateClientCardPredicate(generateClientCardPredicatesTypes.TOP_FIVE_PRODUCTS) === 'true'}
                                onChange={((event, data) =>
                                    setGenerateClientCardPredicate(generateClientCardPredicatesTypes.TOP_FIVE_PRODUCTS, data.checked!.toString()))}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
                {isGenerateClientCardPdfFormValid().length > 0 &&
                isGenerateClientCardPdfFormValid().map(error => (<p><Label basic color='red' content={error} /></p>))} 
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={() => setOpen(false)}>
                Odustani
            </Button>
            <Button
                loading={generatingClientCard}
                disabled={isGenerateClientCardPdfFormValid().length > 0}
                content='Generiši'
                labelPosition='right'
                icon='file'
                onClick={() => {
                    generateClientCardPDF(client!.id!, startDate, endDate)
                        .then(() => setOpen(false))
                }}
                positive />
        </Modal.Actions>
    </Modal>)
}

export default observer(ClientDetailsGenerateCardModal)
