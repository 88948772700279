import React, {FunctionComponent, useContext} from 'react';
import MyPieChart from "../../../../app/common/charts/MyPieChart";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {formatRsd} from "../../../../app/common/util/format";
import {IExpenseEntity} from "../../../../app/models/expenses";
import {IBillOrderAnalyzeEntity} from "../../../../app/models/billOrders";
import {semanticColorToHash} from "../../../../app/common/util/semanticUi";
import {PROFITABILITY_EXPENSE_TRACKING_ID} from "../expense/ProfitabilityDashboardExpenseSection";
import {
    profitabilityIncomeGroupByBrandListTableTrackingId,
    profitabilityIncomeGroupByClientCategoryTableTrackingId
} from "../income/ProfitabilityDashboardIncomeSection";

interface IProps {
}

const ProfitabilityDashboardDiagram: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore
    const { incomeView } = rootStore.profitabilityStore
    
    const incomeFilteredRows =  incomeView === 'brand' ?
        SHIT_getFilteredRowsById(profitabilityIncomeGroupByBrandListTableTrackingId) ?? [] :
        incomeView === 'clientCategory' ?
            SHIT_getFilteredRowsById(profitabilityIncomeGroupByClientCategoryTableTrackingId) ?? [] :
            []
    const expenseFilteredRows = SHIT_getFilteredRowsById(PROFITABILITY_EXPENSE_TRACKING_ID) ?? []
        
    return (<>
        <MyPieChart
            data={[
                expenseFilteredRows.reduce((total: IExpenseEntity, current: IExpenseEntity) => ({ ...total, cost: total.cost + current.cost }), { name: 'Rashodi', color: 'red', cost: 0 } as IExpenseEntity),
                incomeFilteredRows.reduce((total: IExpenseEntity, current: IBillOrderAnalyzeEntity) => ({ ...total, cost: total.cost + current.summaryPriceWithDiscountWithBillDiscount }), { name: 'Prihodi', color: 'teal', cost: 0 } as IExpenseEntity)
            ].map(entity => ({ ...entity, xName: entity.name }))}
            colors={[semanticColorToHash('red'), semanticColorToHash('green')]}
            xAccessor='name'
            yAccessor='cost'
            title={[
                'Profitabilnost',
                formatRsd(
                    incomeFilteredRows.reduce((total: IExpenseEntity, current: IBillOrderAnalyzeEntity) => ({ cost: total.cost + current.summaryPriceWithDiscountWithBillDiscount }), { name: 'Prihodi', color: 'teal', cost: 0 } as IExpenseEntity).cost -
                    expenseFilteredRows.reduce((total: IExpenseEntity, current: IExpenseEntity) => ({ cost: total.cost + current.cost }), { name: 'Rashodi', color: 'red', cost: 0 } as IExpenseEntity).cost

                )
            
            ]}
        
        />
    </>);
};

export default observer(ProfitabilityDashboardDiagram)
