import React from 'react';
import { IClientGoal} from "../../../app/models/clientGoals";
import ClientGoalDetailedParticipants from "./ClientGoalDetailedParticipants";
import ClientGoalDocuments from "../documents/ClientGoalDocuments";

interface IProps {
    clientGoal: IClientGoal 
}

const ClientGoalDetailedSidebar: React.FunctionComponent<IProps> = ({clientGoal}) => {
    
    return <>
        <ClientGoalDetailedParticipants clientGoal={clientGoal} />
        
        <ClientGoalDocuments clientGoal={clientGoal} />
    </>
}

export default ClientGoalDetailedSidebar