import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {ClientFormValues, IClientFormValues} from "../../../app/models/clients";
import {observer} from "mobx-react-lite";
import {clientStatusOptions, clientTypesOptions} from './../../../app/common/options/client'
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import SelectNumberInput from "../../../app/common/form/SelectNumberInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    category: isRequired({message: 'Kategorija je obavezana'})
});

interface DetailParams {
    id: string;
}

const ClientForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        loadClient,
        createClient,
        editClient,
        submitting,
        categories,
        loadingCategories,
        loadClientCategories
    } = rootStore.clientsStore
    const {regionsNames, loadRegions, loading: loadingRegions} = rootStore.regionsStore
    const {
        pricelistArrayWithoutSpecials: pricelists,
        loadPricelists,
        loading: loadingPircelists
    } = rootStore.pricelistsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {loadUsers, loadingUsers, employeesArray: employees} = rootStore.usersStore

    const [client, setClient] = useState<IClientFormValues>(new ClientFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem('clientCreate')

    useEffect(() => {
        loadUsers(true)
        loadClientCategories()
        loadRegions()
        loadPricelists()
        if (match.params.id) {
            setLoading(true)
            loadClient(match.params.id)
                .then(client => setClient(new ClientFormValues(client)))
                .finally(() => setLoading(false))
        }

    }, [
        loadClient,
        loadClientCategories,
        match.params.id,
        loadRegions,
        loadPricelists,
        loadUsers
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const client = {
            ...values,
            pricelistCategoryId: Number(values.pricelistCategoryId),
            agentId: values.agentId ?? ''
        }

        if (!client.id) {
            let newClient = {
                ...client,
                id: uuid()
            }

            return createClient(newClient)
        } else {
            return editClient(client)
        }
    }

    return (
        <Grid centered>

            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Klijenti',
                            linkWithoutCurrentTrafficId: 'clients'
                        },
                        {
                            text: match.params.id ? `Izmena klijenta ${client.name}` : 'Dodavanje klijenta',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing>
                    <FinalForm onSubmit={(values: IClientFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={client}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading || loadingCategories || loadingRegions || loadingPircelists || loadingUsers}>

                                       <Form.Field>
                                           <label>Naziv</label>
                                           <Field
                                               name='name'
                                               placeholder='Naziv'
                                               value={client.name}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Kategorija</label>
                                           <Field
                                               name='category'
                                               placeholder='Kategorija'
                                               value={client.category}
                                               component={TextInput}
                                               suggestions={categories}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Cenovnik</label>
                                           <Field
                                               name='pricelistCategoryId'
                                               placeholder='Cenovnik'
                                               value={client.pricelistCategoryId!}
                                               options={pricelists
                                                   ?.filter(pricelist => !pricelist.isBase)
                                                   .map(pricelist => ({
                                                       key: pricelist.id,
                                                       text: pricelist.isSelling ? 'Redovni' : pricelist.name,
                                                       value: pricelist.id
                                                   })) ?? []}
                                               component={SelectNumberInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Vrsta</label>
                                           <Field
                                               name='type'
                                               placeholder='Vrsta'
                                               value={client.type}
                                               options={clientTypesOptions}
                                               component={SelectStringInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Status</label>
                                           <Field
                                               name='status'
                                               placeholder='Status'
                                               value={client.status}
                                               options={clientStatusOptions}
                                               component={SelectStringInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Agent</label>
                                           <Field
                                               search
                                               clearable
                                               name='agentId'
                                               placeholder='Agent'
                                               value={client.agentId}
                                               options={employees.map(user => ({
                                                   key: user.id,
                                                   value: user.id,
                                                   text: `${user.displayName} [${user.email}]`
                                               }))}
                                               component={SelectStringInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Region</label>
                                           <Field
                                               name='regionName'
                                               placeholder='Region'
                                               value={client.regionName}
                                               component={TextInput}
                                               suggestions={regionsNames}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Adresa</label>
                                           <Field
                                               name='address'
                                               placeholder='Adresa'
                                               value={client.address}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Grad</label>
                                           <Field
                                               name='city'
                                               placeholder='Grad'
                                               value={client.city}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Poštanski broj</label>
                                           <Field
                                               name='postalCode'
                                               placeholder='Poštanski broj'
                                               value={client.postalCode}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Broj telefona</label>
                                           <Field
                                               name='telNumber'
                                               placeholder='Broj telefona'
                                               value={client.telNumber}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Privatna napomena</label>
                                           <Field
                                               name='privateNote'
                                               placeholder='Privatna napomena'
                                               value={client.privateNote}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Javna napomena</label>
                                           <Field
                                               name='publicNote'
                                               placeholder='Javna napomena'
                                               value={client.publicNote}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Kontaktne informacije</label>
                                           <Field
                                               name='contactInfo'
                                               placeholder='Kontaktne informacije'
                                               value={client.contactInfo}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>E-mail</label>
                                           <Field
                                               name='email'
                                               placeholder='E-mail'
                                               value={client.email}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       <Form.Field>
                                           <label>Broj računa</label>
                                           <Field
                                               name='currentAccount'
                                               placeholder='Broj računa'
                                               value={client.currentAccount}
                                               component={TextInput}
                                           />
                                       </Form.Field>

                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!client.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={client.id ? () => history.push(`/${currentTraffic?.id}/clients/${client.id}`) : () => history.push(`/${currentTraffic?.id}/clients`)}
                                           disabled={loading || loadingCategories}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ClientForm)
