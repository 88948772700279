const rsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'RSD',
});

const eurFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
});

export const formatRsd = (value: number) => {
    return rsdFormatter.format(value)
}

export const formatEur = (value: number) => {
    return eurFormatter.format(value)
}
