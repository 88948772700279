import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction, toJS} from "mobx";
import {
    Client,
    ClientFormValues,
    IClient,
    IClientComment,
    IClientEmployee,
    IClientFormValues
} from "../models/clients";
import agent from "../api/agent";
import {toast} from "react-toastify";
import {history} from '../..'
import moment from "moment";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {SemanticCOLORS} from "semantic-ui-react";
import {v4 as uuid} from 'uuid'

export const generateClientCardPredicatesTypes = {
    BILLS: 'bills',
    PAYMENTS: 'payments',
    FINANCIAL_STATE: 'financialState',
    BRANDS_IN_BILLS: 'brandsInBills',
    TOP_FIVE_PRODUCTS: 'topFiveProducts',
    ACTIVITIES: 'activities',
    GRATIS_BILLS: 'gratisBills',
    BRANDS_IN_GRATIS : 'brandsInGratis'
}


export default class ClientsStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable clientsRegistry = new Map<string, IClient>();
    @observable client: IClient | null = null
    @observable loading = false
    @observable submitting = false
    @observable deleting = false
    @observable categories: string[] | null = null
    @observable loadingCategories = false
    @observable deletingId: string | null = null
    @observable predicate = new Map()
    @observable generateClientCardPredicate = new Map()
    @observable year = moment().format('YYYY')
    @observable.ref hubConnection: HubConnection | null = null;
    @observable hubConnectionStatusColor: SemanticCOLORS | undefined = undefined
    @observable editingCommentId: string | null = null
    @observable deletingCommentId: string | null = null
    @observable editingEmployeeId: string | null = null
    @observable submittingEmployee = false
    @observable removingEmployeeId: string | null = null
    @observable updatingFinancialState = false
    @observable updatingFinancialStateLastRequestId = ''
    @observable focusingId: string | null = null
    @observable unfocusingId: string | null = null
    @observable generatingClientCard = false
    @observable generatingBalancesWithNoPaidBills = false
    @observable commentsLastRequestId = ''
    @observable loadingComments = false

    @action setHubConnectionStatusColor = (color: SemanticCOLORS | undefined) => this.hubConnectionStatusColor = color

    startHubConnection = async (clientGoalId: string) => {
        if (this.hubConnection?.state !== 'Connected') {
            this.hubConnection!
                .start()
                .then(() => {
                    console.log(this.hubConnection!.state)
                    this.setHubConnectionStatusColor('green')
                    // toast.success('Hub uspešno konektovan', { autoClose: 1000 })
                })
                .then(() => {
                    console.log('Attempting to join group');
                    this.hubConnection?.invoke('AddToGroup', clientGoalId);
                })
                .catch((error) => {
                    console.log('Error establishing connection: ', error)
                    this.setHubConnectionStatusColor('red')
                    // toast.error('Hub neuspešno konektovan', { autoClose: 1000 })
                    setTimeout(() => {
                        // toast.warning('Pokušaj rekonekcije na hub', { autoClose: 1000 })
                        // this.setHubConnectionStatusColor('yellow')
                        this.startHubConnection(clientGoalId)
                    }, 5000);
                })
        }
    }

    @action setEditingCommentId = (id: string | null) => this.editingCommentId = id

    @action createHubConnection = (clientId: string) => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CLIENT_CHAT_URL!, {
                accessTokenFactory: () => this.rootStore.commonStore.token!
            })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection?.onclose((error) => {
            this.setHubConnectionStatusColor('red')
            // toast.error('Konekcija sa Hub-om izgubljena', { autoClose: 1000 })
            if (error) {
                setTimeout(() => {
                    // this.setHubConnectionStatusColor('yellow')
                    this.startHubConnection(clientId)
                }, 5000)
            } else {
                this.setHubConnectionStatusColor(undefined)
            }
        })

        this.startHubConnection(clientId)

        this.hubConnection.onreconnected(() => {
            this.setHubConnectionStatusColor('green')
            console.log('Reconnected')
        })

        this.hubConnection.onreconnecting(() => {
            // this.setHubConnectionStatusColor('yellow')
            console.log('Reconnecting')
        })

        this.hubConnection.on('ReceiveClientComment', (clientComment: IClientComment) => {
            runInAction(() => {
                this.client?.comments!.push(clientComment)
            })
        })

        this.hubConnection.on('ReceiveUpdatedClientComment', (clientComment: IClientComment) => {
            runInAction(() => {
                if (this.client && this.client.comments) {
                    this.client.comments = this.client?.comments?.map(comment => comment.id === clientComment.id ? clientComment : comment)
                }
            })
        })

        this.hubConnection.on('ReceiveDeletedClientComment', (id: string) => {
            runInAction(() => {
                if (this.client && this.client.comments) {
                    this.client.comments = this.client?.comments?.filter(comment => comment.id !== id)
                }
            })
        })

        // this.hubConnection.on('CreateClientOrder', message => {
        //     toast.info(message)
        // })
    }

    @action stopHubConnection = () => {
        if (this.hubConnection) {
            this.hubConnection!.stop()
                .then(() => console.log('Connection stopped'))
                .catch((error) => console.log(error))
        }
    }
    
    @computed get clientCategoriesAsOptions() {
        if (!this.categories) {
            return []
        }
        
        return this.categories.map(category => ({
            key: category,
            value: category,
            text: category
        }))
    }
    
    @computed get clientsArray(): IClient[] {
        return Array.from(this.clientsRegistry.values())
    }

    @action setPredicate = (key: string, value: string) => {
        this.predicate.set(key, value)
    }

    @action clearPredicate = () => {
        this.predicate.clear()
    }

    @computed get axiosParams() {
        const params = new URLSearchParams()
        this.predicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    @action setGenerateClientCardPredicate = (key: string, value: string) => {
        this.generateClientCardPredicate.set(key, value)
    }

    @action clearGenerateClientCardPredicate = () => {
        this.generateClientCardPredicate.clear()
    }

    @computed get generateClientCardAxiosParams() {
        const params = new URLSearchParams()
        this.generateClientCardPredicate.forEach(((value, key) => {
            params.append(key, value)
        }))

        return params
    }

    getGenerateClientCardPredicate = (predicate: string) => toJS(this.generateClientCardPredicate.get(predicate))
    
    @action loadToRegistry = (clients: IClient[],) => {
        this.clientsRegistry.clear()
        clients.forEach(client => {
            this.clientsRegistry.set(client.id!, client)
        })
    }
    
    @action loadClients = async (local = false) => {
        console.log('Size: ', this.clientsRegistry.size)
        
        this.loading = true
        try {
            if (!local || this.clientsRegistry.size <= 1)  {
                const clients = await agent.Clients.list(this.axiosParams)
                runInAction(() => {
                    this.clientsRegistry.clear()
                    this.loadToRegistry(clients)
                })
            }
        } catch (error) {
            console.log(error)
            toast.error('Error due fetching clients')
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }
    }
    

    @action getClient = (id: string) => {
        return this.clientsRegistry.get(id) ?? null
    }

    @action getClientByName = (name: string) => {
        return this.clientsArray.filter(x => x.name === name)[0] ?? null
    }

    @action loadClient = async (id: string) => {
        this.loading = true;
        try {
            const client = await agent.Clients.details(id, this.axiosParams)
            runInAction(() => {
                this.client = client;
                this.clientsRegistry.set(client.id!, client);
            })
            return client;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false
            })
        }

    }

    @action createClient = async (clientFormData: IClientFormValues) => {
        this.submitting = true
        try {
            await agent.Clients.create(clientFormData)
            runInAction(() => {
                // this.clientsRegistry.set(clientFormData.id, new Client(clientFormData))    
                this.loadClients()
                this.loadClient(clientFormData.id!)

            })
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clients`)
        } catch (error) {
            toast.error('Greška prilikom dodavanja klijenta')
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editClient = async (clientFormValues: IClientFormValues) => {
        this.submitting = true
        try {
            await agent.Clients.edit(clientFormValues)
            runInAction(() => {
                this.clientsRegistry.set(clientFormValues.id!, new Client(clientFormValues))
            })
            await this.loadClient(clientFormValues.id!)
            history.push(`/${this.rootStore.trafficsStore.currentTraffic?.id}/clients/${clientFormValues.id}`)
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom ažuriranja klijenta')
            throw error
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action editPaymentNote = async (paymentNote: string) => {
        this.submitting = true
        let updatedClientFormValues = new ClientFormValues(this.client!)
        updatedClientFormValues.paymentNote = paymentNote
        try {
            await agent.Clients.edit(updatedClientFormValues)
            runInAction(() => {
                this.client!.paymentNote = paymentNote
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submitting = false
            })
        }
    }

    @action deleteClient = async (id: string) => {
        this.deletingId = id
        this.deleting = true
        try {
            await agent.Clients.delete(id)
            runInAction(() => {
                this.clientsRegistry.delete(id)
                toast.success('Klijent uspešno obirsan!')
            })
        } catch (error) {
            console.log(error)
            toast.error('Greška prilikom brisanja klijenta')
        } finally {
            runInAction(() => {
                this.deleting = false
                this.deletingId = null
            })
        }
    }

    @action loadClientCategoriesIfNull = async () => {
        if (!this.categories) {
            await this.loadClientCategories()
        }
    }

    @action loadClientCategories = async () => {
        this.loadingCategories = true
        this.categories = null
        try {
            const categories = await agent.Clients.categories()
            runInAction(() => {
                this.categories = categories
            })
        } catch (error) {
            console.log(error)
            toast.error("Greška prilikom pribljanja kategorija klijenata")
        } finally {
            runInAction(() => {
                this.loadingCategories = false
            })
        }
    }
    
    @action setYear = (year: string) => {
        if (Number(year) >= 1990 && Number(year) <= 2100) {
            this.year = year
        }
    }

    @action loadClientComments = async (id: string) => {
        this.loadingComments = true
        const lastRequestId = uuid()
        this.commentsLastRequestId = lastRequestId
        
        const params = new URLSearchParams()
        params.append('clientId', id)
        try {
            const comments = await agent.ClientComments.list(params)
            runInAction(() => {
                if (lastRequestId === this.commentsLastRequestId) {
                    this.client!.comments = comments
                }
            })
        } catch (error) {
            
        } finally {
            if (lastRequestId === this.commentsLastRequestId) {
                this.loadingComments = false
            }
        }
    }
    
    @action addComment = async (values: any) => {
        values.clientId = this.client!.id
        try {
            await this.hubConnection!.invoke('SendComment', values)
        } catch (error) {
            console.log(error)
        }
    }

    @action editComment = async (values: any) => {
        values.clientId = this.client!.id
        values.id = this.editingCommentId
        try {
            await this.hubConnection!.invoke('EditComment', values)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action deleteComment = async (id: string) => {
        const request = {
            id
        }
        try {
            await this.hubConnection!.invoke('DeleteComment', request)
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingCommentId = null
            })
        }
    }

    @action editClientEmployee = async (clientEmployee: IClientEmployee) => {
        this.editingEmployeeId = clientEmployee.id
        try {
            await agent.ClientEmployees.edit(clientEmployee)
            runInAction(() => {
                if (this.client && this.client?.employees) {
                    this.client.employees = this.client?.employees?.map(employee =>
                        employee.id === clientEmployee.id ?
                            clientEmployee :
                            employee
                    )
                }

            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.editingEmployeeId = null
            })
        }
    }

    @action addClientEmployee = async (clientEmployee: IClientEmployee) => {
        this.submittingEmployee = true
        const newClientEmployee: IClientEmployee = {
            ...clientEmployee,
            id: uuid(),
            clientId: this.client?.id!
        }
        try {
            await agent.ClientEmployees.add(newClientEmployee)
            runInAction(() => {
                this.client!.employees = this.client!.employees ? [...this.client!.employees, newClientEmployee] : [newClientEmployee]
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.submittingEmployee = false
            })
        }
    }

    @action removeClientEmployee = async (id: string) => {
        this.removingEmployeeId = id
        try {
            await agent.ClientEmployees.remove(id)
            runInAction(() => {
                this.client!.employees = this.client!.employees?.filter(employee => employee.id !== id) ?? []
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.removingEmployeeId = null
            })
        }
    }

    @action updateFinancialState = async () => {
        const lastRequestId = uuid()
        this.updatingFinancialStateLastRequestId = lastRequestId
        this.updatingFinancialState = true
        if (this.client) {
            try {
                const financialState = await agent.Clients.financialState(this.client?.id!, this.year)
                runInAction(() => {
                    if (this.updatingFinancialStateLastRequestId === lastRequestId) {
                        this.client!.financialState = financialState   
                    }
                })
            } catch (error) {
                console.log(error)
            } finally {
                runInAction(() => {
                    if (this.updatingFinancialStateLastRequestId === lastRequestId) {
                        this.updatingFinancialState = false   
                    }
                })
            }
        }
    }

    @observable focusClient = async (id: string) => {
        this.focusingId = id
        try {
            await agent.Clients.focus(id)
            runInAction(() => {
                if (this.client && this.client.id === id) {
                    this.client.isInFocus = true
                }

                const clientInRegistry = this.clientsRegistry.get(id) ?? null
                if (clientInRegistry) {
                    this.clientsRegistry.set(id, {...clientInRegistry, isInFocus: true})
                }
            })
        } catch (error) {
            console.log(error)

        } finally {
            runInAction(() => {
                this.focusingId = null
            })
        }
    }

    @observable unfocusClient = async (id: string) => {
        this.unfocusingId = id
        try {
            await agent.Clients.unfocus(id)
            runInAction(() => {
                if (this.client && this.client.id === id) {
                    this.client.isInFocus = false
                }

                const clientInRegistry = this.clientsRegistry.get(id) ?? null
                if (clientInRegistry) {
                    this.clientsRegistry.set(id, {...clientInRegistry, isInFocus: false})
                }
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.unfocusingId = null
            })
        }
    }

    @action generateClientCardPDF = async (id: string, startDate: string, endDate: string) => {
        this.generatingClientCard = true
        try {
            const file = await agent.Clients.generateClientCardPDF(id, startDate, endDate, this.generateClientCardAxiosParams)
            runInAction(() => {
                window.open(
                    URL.createObjectURL(file),
                    '_blank' // <- This is what makes it open in a new window.
                );
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.generatingClientCard = false
            })
        }
    }

    @action generateBalancesWithNoPaidBills = async (startDate: string, endDate: string, withInitialState: boolean) => {
        this.generatingBalancesWithNoPaidBills = true
        try {
            const params = new URLSearchParams()
            params.append('startDate', startDate)
            params.append('endDate', endDate)
            params.append('withInitialState', withInitialState.toString())
            const file = await agent.Clients.generateClientsBalancesWithUnpaidBillsPDF(params)
            runInAction(() => {
                window.open(
                    URL.createObjectURL(file),
                    '_blank' // <- This is what makes it open in a new window.
                );
            })
        } catch (error) {
            console.log(error)
        } finally {
            runInAction(() => {
                this.generatingBalancesWithNoPaidBills = false
            })
        }
    }
}