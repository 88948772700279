import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../app/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import ProductsTableBasicInfoCell from "../../products/list/ProductsTableBasicInfoCell";
import {IOnHoldProduct} from "../../../app/models/onHoldProduct";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Button, Header, Icon, Label, Message, Segment, Statistic} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import moment from "moment";
import {DateFilter} from "../../../app/common/tables/filters/date/DateFilter";
import {ProductsDetailedFilter} from "../../../app/common/tables/filters/products/detailed/ProductsDetailedFilter";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";

interface IProps {
    trackingId?: string
}

const OnHoldProductsGroupedByProductList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        onHoldProductsGroupedByProduct: onHoldProducts,
        deletingId,
        deleting,
        deleteOnHoldProduct
    } = rootStore.onHoldProductsStore
    const { closeModal, openModal } = rootStore.modalStore
    const { brandsArray: brands, loadBrandsIfNull } = rootStore.brandsStore

    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])


    const columns = useMemo(() => [
        {
            id: 'product',
            Header: 'Proizvod',
            accessor: 'name',
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => onHoldProduct.isOther ?
                <Header>
                    <Header.Subheader>
                        Nestandardni proizvod
                    </Header.Subheader>
                    <Header.Content>
                        {onHoldProduct.otherContent}
                    </Header.Content>
                </Header> :
                (<ProductsTableBasicInfoCell product={onHoldProduct.product!}/>),
            Filter: (d: FilterProps<any>) => ProductsDetailedFilter(d, brands),
            filter: 'productsDetailedFilter'
        },
        {
            Header: 'Količina',
            accessor: (d: IOnHoldProduct) => d.count,
            Cell: ({ row: { original: onHoldProduct } }: Cell<IOnHoldProduct>) => (<Statistic content={onHoldProduct.count} />),
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: (d: IOnHoldProduct) => d.date,
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (
                <Label
                    basic
                    color={moment(onHoldProduct.date).isBefore(new Date(), 'day') ?
                        'red' :
                        moment(onHoldProduct.date).isSame(new Date(), 'day') ?
                            'orange' : undefined}
                    content={moment(onHoldProduct.date).format('l')}
                />),
            Filter: DateFilter,
            filter: 'dateFilter'
        },
        {
            Header: 'Brisanje',
            Cell: ({row: {original: onHoldProduct}}: Cell<IOnHoldProduct>) => (
                <Button.Group>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje proizvoda na čekanju
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš proizvod na čeknaju <strong>{onHoldProduct.product!.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteOnHoldProduct(onHoldProduct.product!.id!, true)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={(deletingId === onHoldProduct.product!.id) && deleting}/>
                </Button.Group>)
        }
    ], [
        openModal, 
        closeModal, 
        deleteOnHoldProduct, 
        deleting, 
        deletingId,
        brands
    ])

    if (onHoldProducts.length === 0) return <div style={{marginTop: 50}}>
        <Message warning>
            <Message.Header>Ne postoje proizvodi na čekanju po zadatom kriterijumu</Message.Header>
            <p>Pokušaj da osvežiš stranicu.</p>
        </Message>
    </div>
    
  return (<Table
      trackingId={trackingId}
      columns={columns} 
      data={onHoldProducts} />)

}

export default observer(OnHoldProductsGroupedByProductList)
