import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Cell} from 'react-table'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {IClient, IClientFilter} from "../../../app/models/clients";
import {formatRsd} from "../../../app/common/util/format";
import ClientProgress from "../commom/ClientProgress";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";
import ClientDetailedCell from "./ClientDetailedCell";
import {ClientDetailedFilter} from "../../../app/common/tables/filters/clients/ui/ClientDetailedFilter";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import {clientDetailedFilter} from "../../../app/common/tables/filters/clients/logic/clientDetailedFilter";
import {Header, Message} from "semantic-ui-react";
import {clientStatusToColorName} from "../../../app/common/clients/util";
import {getClientRankByStatusAndType} from "../../../app/common/util/clients";
import {alphabet, numberToStringToFixedOrInt} from "../../../app/common/util/string";

interface IProps {
    trackingId?: string,
    withInitialState: boolean
}

const ClientsReceivablesList: FunctionComponent<IProps> = ({trackingId, withInitialState}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientsArray: clients,
        loadClientCategoriesIfNull,
        categories
    } = rootStore.clientsStore


    const {regionsArray: regions, loadRegionsIfNull} = rootStore.regionsStore

    useEffect(() => {
        loadClientCategoriesIfNull()
        loadRegionsIfNull()
    }, [
        loadClientCategoriesIfNull,
        loadRegionsIfNull
    ])

    const columns = React.useMemo(
        () => [
            {
                id: 'client',
                Header: 'Klijent',
                accessor: 'name',
                Cell: ({row: {original: client}}: Cell<IClient>) => (<ClientDetailedCell client={client}/>),
                Filter: (d: any) => ClientDetailedFilter(d, regions!, categories!),
                filter: (rows: any, id: any, filterValue: IClientFilter) => clientDetailedFilter(rows, id, filterValue, true)
            },
            {
                id: withInitialState ? 'financialState.balanceWithInitialState' : 'financialState.balance',
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Saldo'}
                                                      accessor={withInitialState ? 'financialState.balanceWithInitialState' : 'financialState.balance'}/>,
                accessor: (d: IClient) => withInitialState ? d.financialState?.balanceWithInitialState : d.financialState?.balance,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (
                    <div>
                        {withInitialState ?
                            formatRsd(financialState?.balanceWithInitialState ?? NaN) :
                            formatRsd(financialState?.balance ?? NaN)}
                    </div>
                ),
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: withInitialState ? 'financialState.toPaidWithInitialState' : 'financialState.toPaid',
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Dospelo'}
                                                      accessor={withInitialState ? 'financialState.toPaidWithInitialState' : 'financialState.toPaid'}/>,
                accessor: (d: IClient) => withInitialState ? d.financialState?.toPaidWithInitialState : d.financialState?.toPaid,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (
                    <div>
                        {withInitialState ?
                            formatRsd(financialState?.toPaidWithInitialState ?? NaN) :
                            formatRsd(financialState?.toPaid ?? NaN)}
                    </div>
                ),
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: 'realization',
                accessor: (d: IClient) => {

                    const placed = d.financialState?.placedBaseValue
                    const scheduled = d.financialState?.scheduleValue

                    if (!placed || !scheduled) {
                        return 0
                    }

                    return placed / scheduled
                },
                Header: (row: any) => {

                    const filteredRows: IClient[] = row.filteredRows.map((row: any) => row.original)

                    const sum = filteredRows.reduce((previousValue, currentValue) => {
                            const placed = currentValue.financialState?.placedBaseValue ?? 0
                            const scheduled = currentValue.financialState?.scheduleValue ?? 0

                            let percentage = 0

                            if (scheduled !== 0) {
                                percentage = placed / scheduled * 100
                                if (percentage > 100) {
                                    percentage = 100
                                }
                            }

                            return {
                                placed: previousValue.placed + (scheduled === 0 ? 0 : placed),
                                scheduled: previousValue.scheduled + (scheduled === 0 ? 0 : scheduled),
                                percentageSum: previousValue.percentageSum + (scheduled === 0 ? 0 : percentage),
                                withScheduleCount: previousValue.withScheduleCount + (scheduled === 0 ? 0 : 1)
                            }
                        },
                        {
                            placed: 0,
                            scheduled: 0,
                            percentageSum: 0,
                            withScheduleCount: 0
                        })

                    return <>
                        <Header sub>
                            Ostvarenje
                        </Header>
                        <strong>
                            {formatRsd(sum.placed)} / {formatRsd(sum.scheduled)} [{numberToStringToFixedOrInt(sum.percentageSum / sum.withScheduleCount, 2)}%]
                        </strong>
                    </>
                },
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => <ClientProgress
                    value={financialState?.placedBaseValue} total={financialState?.scheduleValue}/>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: 'rank',
                accessor: 'id',
                Header: 'Rang',
                Cell: ({row: {original: client}}: Cell<IClient>) => <Header
                    color={clientStatusToColorName(client.status!)} as='h4'
                    content={`${alphabet[getClientRankByStatusAndType(client)].toUpperCase()}${client.financialState?.placedBaseValue}`}/>,
                Filter: <></>,
                sortType: 'clientRank',
            }
        ],
        [
            regions,
            categories,
            withInitialState]
    )

    if (clients.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje klijenti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={clients}
        />
    );
};

export default observer(ClientsReceivablesList)
