import React, {FunctionComponent, useContext, Fragment} from 'react';
import {Icon, Item, Label, Message, Popup, Segment} from "semantic-ui-react";
import ClientOrderListItem from "./ClientOrderListItem";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IClientOrder} from "../../../app/models/clientsOrders";
import PostExpressDocumentDownload from "../postExpressDocuments/commom/PostExpressDocumentDownload";

interface IProps {
}

const ClientOrdersList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {clientOrdersDateGroupsArray} = rootStore.clientOrdersStore
    const {
        loading: loadingPostExpressDocuments,
        // getPostExpressDocument,
        postExpressDocumentsRegistry
    } = rootStore.postExpressDocumentsStore

    if (clientOrdersDateGroupsArray.length === 0) return <Segment style={{marginTop: 50}}>
        <Message warning>
            <Message.Header>Ne postoje narudžbine po zadatom kriterijumu</Message.Header>
            <p>Pokušaj da osvežiš stranicu.</p>
        </Message>
    </Segment>


    return (<>
        {clientOrdersDateGroupsArray.map(([date, group]) => {
            const postExpressDocument = postExpressDocumentsRegistry.get(date)
            return <Fragment key={date}>
                <Label size='large' color='teal'>
                    {date}{' '}{loadingPostExpressDocuments && <Icon name='hourglass outline' loading/>}
                    {postExpressDocument && <Popup
                        content={postExpressDocument.name}
                        trigger={<span><PostExpressDocumentDownload
                            postExpressDocument={postExpressDocument}/></span>}
                    />}
                </Label>
                <Segment clearing>
                    <Item.Group divided>
                        {group.map((order: IClientOrder) => <ClientOrderListItem key={order.id} clientOrder={order}/>)}
                    </Item.Group>
                </Segment>
            </Fragment>
        })}
    </>)
}

export default observer(ClientOrdersList)
