import React, {FunctionComponent, useContext} from 'react'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Button, Label} from "semantic-ui-react";

interface OwnProps {
}

type Props = OwnProps

const UserPostsFilters: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {isReadByMeFilterValue, setPredicate, loading, unreadByMeCount} = rootStore.userPostsStore

    return <Button.Group fluid>
        <Label basic icon='filter' style={{border: 0}} color='blue' content='Filteri'/>
        <Button
            color={(isReadByMeFilterValue === 'included') ? 'blue' : undefined}
            onClick={() => setPredicate('isReadByMe', 'included', true)}
            disabled={loading}
        >
            Sve
        </Button>

        <Button
            color={isReadByMeFilterValue === 'only' ? 'blue' : undefined}
            onClick={() => setPredicate('isReadByMe', 'only', true)}
            disabled={loading}
        >
            Procitani
        </Button>

        <Button
            color={isReadByMeFilterValue === 'excluded' ? 'blue' : undefined}
            onClick={() => setPredicate('isReadByMe', 'excluded', true)}
            disabled={loading}
        >
            Neprocitani
            {unreadByMeCount > 0 &&
            <Label
                size='mini'
                color='red'
                circular
            >
                {unreadByMeCount}
            </Label>}

        </Button>
    </Button.Group>
}

export default observer(UserPostsFilters)
