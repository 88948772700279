import React, {useContext, useEffect} from 'react';
import {Grid} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {RouteComponentProps} from 'react-router';
import LoadingComponent from '../../../app/layout/LoadingComponent'
import {RootStoreContext} from "../../../app/stores/rootStore";
import ClientGoalHeader from "./ClientGoalHeader";
import ClientGoalDetailedSidebar from "./ClientGoalDetailedSidebar";
import Responsive from "../../../app/common/responsive/Responsive";
import ClientGoalDetailedInfo from "./ClientGoalDetailedInfo";
import ClientGoalDetailedChat from "./ClientGoalDetailedChat";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {ClientGoalType} from "../../../app/models/clientGoals";

interface DetailParams {
    id: string
}

const navigationTitle = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment:
            return 'Naplate'
        case ClientGoalType.clientRequest:
            return 'Zahtevi klijenata'
        case ClientGoalType.classic:
            return 'Ciljevi'
    }
}

const ClientGoalDetails: React.FC<RouteComponentProps<DetailParams>> = ({
                                                                            match,
                                                                            history
                                                                        }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        clientGoal,
        loadClientGoal,
        loading
    } = rootStore.clientGoalsStore

    useEffect(() => {
        loadClientGoal(match.params.id)
    }, [loadClientGoal, match.params.id, history]);


    if (loading) return <LoadingComponent content='Učitavanje...' />;

    if (!clientGoal) return <h2>Cilj/Naplata nije pornadjen</h2>;

    return (
        <Grid>

            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Plan rada'
                        },
                        {
                            text: navigationTitle(clientGoal.type),
                            linkWithoutCurrentTrafficId: `clientGoals?type=${clientGoal.type}`
                        },
                        {
                            text: clientGoal.name,
                            active: true
                        }
                    ]}

                />
            </Grid.Column>

            <Grid.Column computer={10} tablet={10} mobile={16}>
                <ClientGoalHeader clientGoal={clientGoal}/>
                <ClientGoalDetailedInfo clientGoal={clientGoal}/>
                <ClientGoalDetailedChat clientGoal={clientGoal}/>
            </Grid.Column>
            <Responsive.NotMobile>
                <Grid.Column width={6}>
                    <ClientGoalDetailedSidebar clientGoal={clientGoal}/>
                </Grid.Column>
            </Responsive.NotMobile>
        </Grid>
    );
};

export default observer(ClientGoalDetails);