import React, {FunctionComponent, useState} from 'react'
import {IClientGoal} from "../../../../app/models/clientGoals";
import {Divider, Grid} from "semantic-ui-react";
import ClientGoalSingleDocument from "../../documents/ClientGoalSingleDocument";
import ClientGoalDocumentForm from "../../form/ClientGoalDocumentForm";
import ShowMoreButton from "../../../commom/buttons/ShowMoreButton";

interface OwnProps {
  clientGoal: IClientGoal
}

type Props = OwnProps

const ClientGoalDocumentsCell: FunctionComponent<Props> = ({ clientGoal }) => {

  const [addDocumentMode, setAddDocumentMode] = useState(false)

  const lastDocument = (clientGoal.documents.length > 0) ?
      clientGoal.documents[clientGoal.documents.length - 1] : null


  return <Grid centered>
    {/*     Last document     */}
    {lastDocument && <Grid.Row>
      <Grid.Column>
          <ClientGoalSingleDocument compact alone clientGoalDocument={lastDocument}/>
        <Divider />
      </Grid.Column>
    </Grid.Row>}

    <Grid.Row>
      <Grid.Column textAlign='center' verticalAlign='middle'>
        {/*     Add document     */}
        {addDocumentMode ?
            <ClientGoalDocumentForm
                small
                clientGoalId={clientGoal.id}
                onUploadSuccess={() => setAddDocumentMode(false)}
                onCancel={() => setAddDocumentMode(false)}
            /> :
            <ShowMoreButton content='Dodaj dokument' onClick={() => setAddDocumentMode(true)} />}
      </Grid.Column>
    </Grid.Row>
  </Grid>
}

export default ClientGoalDocumentsCell
