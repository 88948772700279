import React, {FunctionComponent, useContext} from 'react';
import {IBrand} from "../../app/models/brands";
import {Header} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../app/stores/rootStore";

interface IProps {
    brand: IBrand | [number, string, string]
}

const BrandBasicCell: FunctionComponent<IProps> = ({brand}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    
    let id
    let name
    let color
    
    if (Array.isArray(brand)) {
        [id, name, color] = brand
    } else {
        ({ id, name, color  } = brand)
    }
    
    if (id === -1) return (<Header
        style={{color}}
        content={name}/>)
    
    return (<Header
        style={{color}}
        as={Link}
        to={`/${currentTraffic?.id}/brands/${id}`}
        content={name}/>)
}

export default observer(BrandBasicCell)
