import React, {FunctionComponent, useContext} from 'react';
import {Header} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IProduct} from "../../../app/models/products";
import {getNiceColorFromStingAsciiSum} from "../../../app/common/util/colors";

interface IProps {
    product: IProduct,
    hideBrand?: boolean
    hideProduct?: boolean
}

const ProductsTableBasicInfoCell: FunctionComponent<IProps> = ({
                                                                   product: {
                                                                       name,
                                                                       id,
                                                                       brandId,
                                                                       brandName,
                                                                       brandColor,
                                                                       sku
                                                                   },
                                                                   hideBrand,
                                                                   hideProduct
                                                               }) => {

    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return (
        <Header as='h2'>
            {!hideBrand &&
            <Header.Subheader>
                {brandId !== -1 ?
                    <Header as={Link} to={`/${currentTraffic?.id}/brands/${brandId}`} size='tiny' content={brandName}
                            style={{color: brandColor ?? getNiceColorFromStingAsciiSum(brandName)}}/> :
                    <Header size='tiny' content={brandName} style={{color: brandColor ?? getNiceColorFromStingAsciiSum(brandName)}}/>}
            </Header.Subheader>}
            {!hideProduct && <>
                <Header.Subheader content={sku} />
                <Header.Subheader>
                    <Header as='h3' content={name}/>
                </Header.Subheader>
            </>}
        </Header>
    )
}

export default observer(ProductsTableBasicInfoCell)
