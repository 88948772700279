import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {IProductConsumption} from "../../../app/models/productConsumptions";
import {Cell, FilterProps} from "react-table";
import {formatRsd} from "../../../app/common/util/format";
import moment from "moment";
import {DateFilter} from "../../../app/common/tables/filters/date/DateFilter";
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";
import {productConsumptionsCategoriesOptions} from "../../../app/common/options/productConsumptions";
import {stringPreview} from "../../../app/common/util/string";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";

interface IProps {
    trackingId?: string
}

const ProductConsumptionsList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {productConsumptionsArray, deletingId, deleteProductConsumption} = rootStore.productConsumptionsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {currentTraffic} = rootStore.trafficsStore

    const columns = useMemo(() => [
            {
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Vrednost'}
                                                      accessor={'value'}/>,
                accessor: 'value',
                Cell: ({row: {original: productConsumption}}: Cell<IProductConsumption>) => <>{formatRsd(productConsumption.value)}</>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: 'Kategorija',
                accessor: 'category',
                Cell: ({row: {original: productConsumption}}: Cell<IProductConsumption>) => <>{productConsumption.category}</>,
                Filter: (d: FilterProps<any>) => MultiselectFilter(d, productConsumptionsCategoriesOptions, 'Kategorija'),
                filter: 'multiselectFilter'
            },
            {
                id: 'date',
                Header: 'Datum',
                accessor: 'date',
                Cell: ({row: {original: productConsumption}}: Cell<IProductConsumption>) => <>{moment(productConsumption.date).format('l')}</>,
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                id: 'note',
                Header: 'Napomena',
                accessor: 'note',
                Cell: ({row: {original: productConsumption}}: Cell<IProductConsumption>) => <>{stringPreview(productConsumption.note, 100)}</>
            },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: productConsumption}}: Cell<IProductConsumption>) => (
                    <Button.Group>
                        <Button as={Link} to={`/${currentTraffic?.id}/productConsumptions/${productConsumption.id}`}
                                icon='eye'
                                color='blue'/>
                        <Button as={Link}
                                to={`/${currentTraffic?.id}/productConsumptions/manage/${productConsumption.id}/edit`}
                                icon='edit'
                                color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje rashoda
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš rashod proizvoda
                                    datuma <strong>{moment(productConsumption.date).format('l')} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteProductConsumption(productConsumption.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={deletingId === productConsumption.id}/>
                    </Button.Group>)
            }
        ],
        [
            openModal,
            closeModal,
            deleteProductConsumption,
            deletingId,
            currentTraffic
        ])

    if (productConsumptionsArray.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje rashodi proizvoda po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (<Table
            trackingId={trackingId}
            columns={columns}
            data={productConsumptionsArray}
        />)
}

export default observer(ProductConsumptionsList)
