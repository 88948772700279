import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import Table from "../../../app/common/tables/Table";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IUser} from "../../../app/models/user";
import {Cell} from "react-table";
import UsersTableBasicCell from "./UsersTableBasicCell";
import UsersTableRolesCell from "./UsersTableRolesCell";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Button, Header, Icon, Segment} from "semantic-ui-react";

interface IProps {
}

const UsersList: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {usersArray: users, roles, deletingId, deleteUser} = rootStore.usersStore
    const { openModal, closeModal } = rootStore.modalStore
    const { user: currentUser } = rootStore.userStore
    
    const columns = useMemo(() => [
        {
            Header: 'Ime',
            Cell: ({row: {original: user}}: Cell<IUser>) => (<UsersTableBasicCell user={user}/>)
        },
        {
            Header: 'Uloge',
            Cell: ({row: {original: user}}: Cell<IUser>) => (<UsersTableRolesCell user={user}/>)
        },
        {
            Header: 'Kontrole',
            id: 'controls',
            Cell: ({row: {original: user}}: Cell<IUser>) => (
                
                    <Button
                        icon='trash alternate' 
                        color='red' 
                        loading={deletingId === user.id}
                        disabled={currentUser?.id === user.id || user.roles.includes('SuperAdministrator')}
                        onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje Korisnika
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš korisnika <strong>{user.displayName} [{user.username}] ?</strong>
                            </p>
                            <p style={{textAlign: 'center'}}>
                                <i>Brisanje neće biti moguće ukoliko postoje računi i uplate vezane za ovog korisnika</i>  
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteUser(user.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} />
                )
        }
    ], [
        openModal,
        closeModal,
        deleteUser,
        deletingId,
        currentUser
    ])

    if (!roles) return <LoadingComponent content='Učitavanje uloga'/>

    return (
        <Table
            columns={columns}
            data={users}/>
    );
};

export default observer(UsersList)
