import React, {FunctionComponent} from 'react';
import {Header, Segment} from "semantic-ui-react";
import {IDocumentation} from "../../../app/models/documentations";
import {convertStringToState} from "../../../app/common/util/draft-js";
import {Editor} from "draft-js";

interface IProps {
    documentation: IDocumentation
}

const DocumentationDetailsDescription: FunctionComponent<IProps> = ({documentation}) => {

    return (
        <Segment.Group>
            <Segment
                textAlign='center'
                style={{border: 'none'}}
                attached='top'
                secondary
                inverted
                color='blue' >
                <Header 
                    content='Opis'
                />
            </Segment>
            <Segment attached='bottom' padded={"very"}>
                <Editor onChange={() => {
                }} editorState={convertStringToState(documentation.description)} readOnly={true}/>
            </Segment>    
        </Segment.Group>
        );
};

export default DocumentationDetailsDescription;
