import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Segment} from "semantic-ui-react";
import ClientsList from "../../clients/list/ClientsList";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string;
}

const RegionDetails: FunctionComponent<RouteComponentProps<DetailParams>> = ({match}) => {
    const rootStore = useContext(RootStoreContext)
    const {region, loadRegion} = rootStore.regionsStore
    const {setPredicate, clearPredicate, loadClients} = rootStore.clientsStore


    useEffect(() => {
        loadRegion(Number(match.params.id))
        clearPredicate()
        setPredicate('withFinancialState', 'true')
        setPredicate('byRegion', match.params.id)
        loadClients()
    }, [loadRegion, match.params.id, setPredicate, clearPredicate, loadClients])

    if (region === null)
        return <LoadingComponent content='Učitavanje regiona'/>

    return (
        <>
            <BreadcrumbNavigation 
                items={[
                    {
                        text: 'Regioni',
                        linkWithoutCurrentTrafficId: 'regions'
                    },
                    {
                        text: region.name,
                        active: true
                    }
                ]}
            />
            <Segment color='blue'>
                <ClientsList hideRegion hideBalance hideProgress hideRank/>
            </Segment>
        </>
        
    )
};

export default observer(RegionDetails)
