import React, {FC, FunctionComponent, SyntheticEvent, useContext, useEffect} from 'react';
import {
    Button,
    Checkbox,
    Dropdown,
    DropdownProps,
    Header,
    Icon,
    Label,
    Menu,
    Segment,
    Select, SemanticCOLORS
} from "semantic-ui-react";
import {typesOptions} from "../../../app/common/options/bill";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {PdfService} from "../../../app/common/pdf/PdfService";
import {formatDateOrDefault} from "../../../app/common/util/date";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../app/common/util/format";
import {
    TRAFFIC_ANALYSIS_BY_BRAND_TRACKING_ID,
    TRAFFIC_ANALYSIS_BY_CLIENT_AND_BRAND_TRACKING_ID,
    TRAFFIC_ANALYSIS_BY_CLIENT_AND_PRODUCT_TRACKING_ID,
    TRAFFIC_ANALYSIS_BY_CLIENT_CATEGORY_TRACKING_ID,
    TRAFFIC_ANALYSIS_BY_CLIENT_TRACKING_ID,
    TRAFFIC_ANALYSIS_BY_PRODUCT_TRACKING_ID
} from "./TrafficAnalysisDashboard";
import {IBillOrderAnalyzeEntity} from "../../../app/models/billOrders";
import moment from "moment";
import {DatePicker} from "antd";

interface IProps {
    currentTab: string
    setCurrentTab: (currentTab: string) => void
}

const TrafficAnalysisDashboardSidebar: FunctionComponent<IProps> = ({currentTab, setCurrentTab}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        typeFilter,
        setTypeFilterAndUpdate,
        startDateFilter,
        endDateFilter,
        // setGratisFilterAndUpdate,
        // gratisFilter,
        mode, setMode,
        loadingAll: loading,
        regionFilter,
        setRegionFilterAndUpdate,
        setTrafficFilterAndUpdate,
        trafficFilter,
        setWithManualBillsFilterAndUpdate, withManualBillsFilter,
        setDateRangeFilter
    } = rootStore.trafficAnalyseStore
    const {regionsArray: regions, loadRegionsIfNull, loading: loadingRegions} = rootStore.regionsStore
    const {trafficsArray: traffics} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore

    useEffect(() => {
        loadRegionsIfNull()
    }, [
        loadRegionsIfNull
    ])


    const getHeadForCurrentTab = () => {

        let headArray = ['#']

        switch (currentTab) {
            case 'brands':
                headArray = [...headArray, 'Brend']
                break;
            case 'products':
                headArray = [...headArray, 'Proizvod']
                break;
            case 'clients':
                headArray = [...headArray, 'Klijent']
                break;
            case 'clientCategories':
                headArray = [...headArray, 'Kategorija klijenta']
                break;
            case 'clientsAndBrands':
                headArray = [...headArray, 'Klijent', 'Brend']
                break;
            case 'clientsAndProducts':
                headArray = [...headArray, 'Klijent', 'Proizvod']
                break;
            case 'onSaleNoteAndClientsAndProducts':
                headArray = [...headArray, 'Klijent', 'Proizvod', 'AP']
                break;
        }

        headArray = [...headArray, 'Količina', 'Nabavna cena u RSD', 'Bez popusta', 'Sa popustom']

        if (mode !== 'gratis') {
            headArray = [...headArray, 'RUC', 'Koeficijent RUC-a', 'Marza']
        }

        return [headArray]
    }

    const getBodyForCurrentTab = () => {
        switch (currentTab) {
            case 'brands':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_BRAND_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.brand?.name!,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'products':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_PRODUCT_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        `${analyzeEntity.product?.brandName}\n${analyzeEntity.product?.sku}\n${analyzeEntity.product!.name}`,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'clients':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_CLIENT_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.client?.name!,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'clientCategories':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_CLIENT_CATEGORY_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.clientCategory,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'clientsAndBrands':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_CLIENT_AND_BRAND_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.client?.name!,
                        analyzeEntity.brand?.name!,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'clientsAndProducts':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_CLIENT_AND_PRODUCT_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.client?.name!,
                        `${analyzeEntity.product?.brandName}\n${analyzeEntity.product?.sku}\n${analyzeEntity.product!.name}`,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            case 'onSaleNoteAndClientsAndProducts':
                return SHIT_getFilteredRowsById(TRAFFIC_ANALYSIS_BY_CLIENT_AND_PRODUCT_TRACKING_ID)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.client?.name!,
                        `${analyzeEntity.product?.brandName}\n${analyzeEntity.product?.sku}\n${analyzeEntity.product!.name}`,
                        analyzeEntity.onSaleNote,
                        analyzeEntity.count,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithoutDiscount),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            default:
                return []
        }
    }


    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(`Analiza prometa za period ${formatDateOrDefault(startDateFilter, 'start')} - ${formatDateOrDefault(endDateFilter, 'end')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: getHeadForCurrentTab(),
            body: getBodyForCurrentTab()
        })

        doc.save("AnalizaPrometa.pdf")
    }

    const ViewItem: FC<{ text: string, id: string, color?: SemanticCOLORS }> = ({text, id, color = 'teal'}) => (<Menu.Item
        color={currentTab === id ? color : undefined}
        active={currentTab === id}
        onClick={() => setCurrentTab(id)}
    >{text}
    </Menu.Item>)


    return (
        <>
            <Menu
                fluid
                widths='3'
                tabular
                size='large'
                style={{marginBottom: 0}}>
                <Menu.Item
                    // disabled={loading}
                    active={mode === 'classic'}
                    onClick={() => setMode('classic', true)}
                >
                    <Header as='h4' content='Obično' color={mode === 'classic' ? 'blue' : 'grey'}/>
                </Menu.Item>

                <Menu.Item
                    // disabled={loading}
                    active={mode === 'gratis'}
                    onClick={() => setMode('gratis', true)}
                >
                    <Header as='h4' content='Gratis' color={mode === 'gratis' ? 'yellow' : 'grey'}/>
                </Menu.Item>

                <Menu.Item
                    // disabled={loading}
                    active={mode === 'onSale'}
                    onClick={() => setMode('onSale', true)}
                >
                    <Header as='h4' content='AP' color={mode === 'onSale' ? 'red' : 'grey'}/>
                </Menu.Item>
            </Menu>

            <Menu vertical fluid style={{marginTop: 0}}>
                <Menu.Item>
                    <Dropdown
                        clearable
                        // disabled={loading}
                        placeholder='Promet'
                        fluid
                        multiple
                        selection
                        value={trafficFilter}
                        onChange={(event, data) => setTrafficFilterAndUpdate(data.value as number[])}
                        renderLabel={item => <Label content={item.text} color={item.isExpense ? 'red' : 'green'}/>}
                        options={traffics?.map(traffic => ({
                            key: traffic.id!,
                            value: traffic.id!,
                            text: traffic.name,
                            isExpense: traffic.isExpense
                        }))}/>
                </Menu.Item>

                <Menu.Item>
                    <Dropdown
                        clearable
                        // disabled={loading}
                        loading={loadingRegions}
                        placeholder='Region'
                        fluid
                        multiple
                        selection
                        value={regionFilter}
                        onChange={(event, data) => setRegionFilterAndUpdate(data.value as number[])}
                        options={regions?.map(region => ({
                            key: region.id!,
                            value: region.id!,
                            text: region.name
                        }))}/>
                </Menu.Item>
                <Menu.Item>
                    <Select
                        // disabled={loading}
                        fluid
                        // text={typeFilter === 'all' ? 'Tip: Sve' : typeFilter === Types.Invoice ? 'Tip: Faktura' : typeFilter === Types.Specification ? 'Tip: Specifikacija' : ''}
                        className='icon'
                        floating
                        // icon='file text'
                        options={[
                            {key: 'all', text: 'Sve', value: 'all'},
                            ...typesOptions]}
                        value={typeFilter}
                        onChange={(event: SyntheticEvent<HTMLElement>, data: DropdownProps) => setTypeFilterAndUpdate(data.value as any)}
                    />
                </Menu.Item>

                {/*<Menu.Item>*/}

                {/*    <Button.Group fluid>*/}
                {/*        <Button*/}
                {/*            disabled={loading}*/}
                {/*            positive={mode === 'classic'}*/}
                {/*            onClick={() => setMode('classic', true)}*/}
                {/*        >Ne-Gratis</Button>*/}
                {/*        <Button.Or text='ili'/>*/}
                {/*        <Button*/}
                {/*            disabled={loading}*/}
                {/*            positive={mode === 'gratis'}*/}
                {/*            onClick={() => setMode('gratis', true)}*/}
                {/*        >Gratis</Button>*/}
                {/*    </Button.Group>*/}

                {/*</Menu.Item>*/}

                <Menu.Item>
                    <Checkbox
                        label='Ručni računi'
                        toggle
                        // disabled={loading}
                        checked={withManualBillsFilter}
                        onChange={(event, data) => setWithManualBillsFilterAndUpdate(data.checked!)}
                    />
                </Menu.Item>

                <Menu.Item>

                    <DatePicker.RangePicker
                        // disabled={loading}
                        placeholder={['Početni datum', 'Krajni datum']}
                        format='DD.MM.YYYY.'
                        onChange={(date, dateString) =>
                            setDateRangeFilter(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '',
                                date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '',
                                true)}
                        value={[startDateFilter ? moment(startDateFilter) : null, endDateFilter ? moment(endDateFilter) : null]}
                    />

                    {/*<Input*/}
                    {/*    disabled={loading}*/}
                    {/*    type='date'*/}
                    {/*    label={{basic: true, content: 'Od', icon: {color: 'teal', name: 'calendar'}}}*/}
                    {/*    value={startDateFilter}*/}
                    {/*    onChange={(event, data) => setStartDateFilterAndUpdate(data.value)}*/}
                    {/*/>*/}
                    {/*<Input*/}
                    {/*    disabled={loading}*/}
                    {/*    type='date'*/}
                    {/*    label={{basic: true, content: 'Do', icon: {color: 'teal', name: 'calendar'}}}*/}
                    {/*    value={endDateFilter}*/}
                    {/*    onChange={(event, data) => setEndDateFilterAndUpdate(data.value)}*/}
                    {/*/>*/}
                </Menu.Item>
                <Menu.Item basic as={Segment} clearing>
                    <Button
                        disabled={loading}
                        icon
                        labelPosition='left'
                        floated='right'
                        color='teal'
                        onClick={() => exportPdf()}>
                        <Icon name='file pdf'/>
                        PDF
                    </Button>
                </Menu.Item>

                {mode === 'onSale'
                && <>
                    <ViewItem color='red' text='AP: Klijenti i proizvodi' id='onSaleAndClientsAndProducts'/>
                </>}

                <ViewItem text='Brendovi' id='brands'/>

                <ViewItem text='Proizvodi' id='products'/>

                <ViewItem text='Klijenti' id='clients'/>

                <ViewItem text='Kategorije klijenata' id='clientCategories'/>

                <ViewItem text='Klijenti i brendovi' id='clientsAndBrands'/>

                <ViewItem text='Klijenti i proizvodi' id='clientsAndProducts'/>
                
            </Menu>
        </>)
}

export default observer(TrafficAnalysisDashboardSidebar)
