import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../app/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IGeneralExpense} from "../../../app/models/generalExpenses";
import {Cell, FilterProps} from "react-table";
import {formatRsd} from "../../../app/common/util/format";
import {stringPreview} from "../../../app/common/util/string";
import moment from "moment";
import {DateFilter} from "../../../app/common/tables/filters/date/DateFilter";
import {Button, Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import MyPieChart from "../../../app/common/charts/MyPieChart";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";
import MyBarChart from "../../../app/common/charts/MyBarChart";
import {getNiceColorFromStingAsciiSum} from "../../../app/common/util/colors";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";

export type CHART_TYPE = 'none' | 'pie' | 'bar'

interface IProps {
    chartType: CHART_TYPE,
    trackingId?: string
}

const GeneralExpensesList: FunctionComponent<IProps> = ({chartType, trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        generalExpensesArray: generalExpenses,
        deleteGeneralExpense,
        generalExpenseDeletingId,
        filteredGroupByCategory,
        loadGeneralExpensesCategories,
        generalExpensesCategories
    } = rootStore.expensesStore
    const {currentTraffic} = rootStore.trafficsStore
    const {closeModal, openModal} = rootStore.modalStore

    useEffect(() => {
        loadGeneralExpensesCategories(true)
    }, [
        loadGeneralExpensesCategories
    ])

    const columns = useMemo(() => [
            {
                Header: 'Naziv',
                accessor: 'name'
            },
            {
                Header: 'Kategorija',
                accessor: 'category',
                Cell: ({row: {original: generalExpense}}: Cell<IGeneralExpense>) =>
                    <strong
                        style={{color: getNiceColorFromStingAsciiSum(generalExpense.category)}}>{generalExpense.category}</strong>,
                Filter: (d: FilterProps<any>) =>
                    generalExpensesCategories ?
                        MultiselectFilter(d, generalExpensesCategories.map(category => ({
                            key: category,
                            value: category,
                            text: category
                        })), 'Kategorija') :
                        <span>
                            Učitavanje kategorija..
                        </span>,
                filter: 'multiselectFilter'
            },
            {
                Header: (row: any) =>
                    <SumTableHeader row={row} type={'money'} name={'Vrednost'}
                                    accessor={'value'}/>,
                accessor: 'value',
                Cell: ({row: {original: generalExpense}}: Cell<IGeneralExpense>) => (<>{formatRsd(generalExpense.value)}</>),
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: 'Napomena',
                accessor: 'note',
                Cell: ({row: {original: generalExpense}}: Cell<IGeneralExpense>) => (<>{stringPreview(generalExpense.note, 100)}</>)
            },
            {
                id: 'date',
                Header: 'Datum',
                accessor: 'date',
                Cell: ({row: {original: generalExpense}}: Cell<IGeneralExpense>) => moment(generalExpense.date).format('l'),
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: generalExpense}}: Cell<IGeneralExpense>) => (
                    <Button.Group>
                        <Button as={Link} to={`/${currentTraffic?.id}/generalExpenses/manage/${generalExpense.id}/edit`}
                                icon='edit'
                                color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje troška
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš trošak <strong>{generalExpense.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteGeneralExpense(generalExpense.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={generalExpenseDeletingId === generalExpense.id}/>
                    </Button.Group>)
            }
        ],
        [
            closeModal,
            currentTraffic,
            deleteGeneralExpense,
            generalExpenseDeletingId,
            openModal,
            generalExpensesCategories
        ])

    if (generalExpenses.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje opšti troškovi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Grid>
            <Grid.Column computer={chartType === 'pie' ? 5 : 16} tablet={chartType === 'pie' ? 8 : 16} mobile={16}>
                {chartType === 'pie' ?
                    <MyPieChart
                        title={formatRsd(filteredGroupByCategory.reduce((total: any, current: any) => total + current.value, 0))}
                        data={filteredGroupByCategory}
                        colors={filteredGroupByCategory.map(el => getNiceColorFromStingAsciiSum(el.category))}
                        xAccessor='category'
                        yAccessor='value'
                    /> :
                    chartType === 'bar' ?

                        <MyBarChart
                            title={formatRsd(filteredGroupByCategory.reduce((total: any, current: any) => total + current.value, 0))}
                            data={filteredGroupByCategory}
                            xAccessor='category'
                            yAccessor='value'
                        /> : null}

            </Grid.Column>
            <Grid.Column width={16}>
                <Table
                    trackingId={trackingId}
                    data={generalExpenses}
                    columns={columns}
                />
            </Grid.Column>

        </Grid>)
}

export default observer(GeneralExpensesList)
