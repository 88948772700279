import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import {IBillOrderAnalyzeEntity} from "../../../app/models/billOrders";
import BrandBasicCell from "../../brands/BrandBasicCell";
import {formatRsd} from "../../../app/common/util/format";
import {Checkbox, Grid, Header, Message, Segment} from "semantic-ui-react";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";
import MyPieChart from "../../../app/common/charts/MyPieChart";
import {summator} from "../../../app/common/util/array";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";

interface IProps {
    showingCharts: string[],
    setShowingCharts: any,
    trackingId: string
}

const TrafficAnalysisByBrandList: FunctionComponent<IProps> = ({setShowingCharts, showingCharts, trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {groupByBrand, mode} = rootStore.trafficAnalyseStore
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore
    const {loadBrandsIfNull, brandsAsOptions} = rootStore.brandsStore

    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])

    const filteredRows = SHIT_getFilteredRowsById(trackingId) ?? []

    const charts =
        [
            {
                show: showingCharts.includes('count'),
                id: 'count',
                title: [
                    'Količina',
                    formatRsd(summator(filteredRows, 'count'))],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: 'count'
            },
            {
                show: showingCharts.includes('base'),
                id: 'base',
                title: [
                    'Nabavna cena',
                    formatRsd(summator(filteredRows, 'summaryBasePriceInRsd'))],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: 'summaryBasePriceInRsd'
            },
            {
                show: showingCharts.includes('withoutDiscount'),
                id: 'withoutDiscount',
                title: [
                    'Bez popusta',
                    formatRsd(summator(filteredRows, 'summaryPriceWithoutDiscount'))],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: (row: any) => row.summaryPriceWithoutDiscount,
            },
            {
                show: showingCharts.includes('summaryPriceWithDiscountWithBillDiscount'),
                id: 'summaryPriceWithDiscountWithBillDiscount',
                title: [
                    'Sa popustom',
                    formatRsd(summator(filteredRows, 'summaryPriceWithDiscountWithBillDiscount'))],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: (row: any) => row.summaryPriceWithDiscountWithBillDiscount,
            },
            {
                show: showingCharts.includes('ruc'),
                id: 'ruc',
                title: [
                    'RUC',
                    formatRsd(summator(filteredRows, 'differenceInPrice'))],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: (row: any) => row.differenceInPrice,
                hideInGratis: true
            },
            {
                show: showingCharts.includes('rucCoef'),
                id: 'rucCoef',
                title: [
                    'RUC koef',
                    filteredRows.reduce((total: number[], current: any) => {
                        return [
                            total[0] + current.differenceInPrice,
                            total[1] + current.summaryPriceWithDiscountWithBillDiscount
                        ]
                    }, [
                        0,
                        0
                    ]).reduce((total: number, current: number, index: number, array: number[]) => {
                        if (index === 0) {
                            return total + current
                        } else if (index === 1) {
                            return total / current
                        }

                        return null
                    }, 0).toFixed(2)],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: (row: any) => row.differenceInPrice / row.summaryPriceWithDiscountWithBillDiscount,
                hideInGratis: true
            },
            {
                show: showingCharts.includes('margin'),
                id: 'margin',
                title: [
                    'Marža',
                    filteredRows.reduce((total: number[], current: any) => {
                        return [
                            total[0] + current.summaryPriceWithDiscountWithBillDiscount,
                            total[1] + current.summaryBasePriceInRsd
                        ]
                    }, [
                        0,
                        0
                    ]).reduce((total: number, current: number, index: number, array: number[]) => {
                        if (index === 0) {
                            return total + current
                        } else if (index === 1) {
                            return total / current
                        }

                        return null
                    }, 0).toFixed(2)],
                data: filteredRows,
                colors: filteredRows.map(row => row.brand.color),
                xAccessor: 'brand.name',
                yAccessor: (row: any) => row.summaryPriceWithDiscountWithBillDiscount / row.summaryBasePriceInRsd,
                hideInGratis: true
            },
        ]

    const columns = useMemo(() => [
        {
            Header: 'Brend',
            accessor: 'brand.name',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) => <BrandBasicCell
                brand={analyseEntity.brand!}/>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, [...brandsAsOptions, {
                key: 'N/A - Ručni račun',
                value: 'N/A - Ručni račun',
                text: 'N/A - Ručni račun'
            }], 'Brend'),
            filter: 'multiselectFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'count'} name={'Količina'} accessor={'count'}/>,
            accessor: 'count',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) => <span>{analyseEntity.count}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Nabavna cena u RSD'}
                                                  accessor={'summaryBasePriceInRsd'}/>,
            accessor: 'summaryBasePriceInRsd',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryBasePriceInRsd)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Bez popusta'}
                                                  accessor={'summaryPriceWithoutDiscount'}/>,
            accessor: 'summaryPriceWithoutDiscount',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryPriceWithoutDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Sa popustom'}
                                                  accessor={'summaryPriceWithDiscountWithBillDiscount'}/>,
            accessor: 'summaryPriceWithDiscountWithBillDiscount',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.summaryPriceWithDiscountWithBillDiscount)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'RUC'}
                                                  accessor={'differenceInPrice'}/>,
            accessor: 'differenceInPrice',
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{formatRsd(analyseEntity.differenceInPrice)}</span>,
            id: 'differenceInPrice',
            hide: mode === 'gratis',
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            hide: mode === 'gratis',
            id: 'diffInPriceCoef',
            Header: (row: any) => {
                const {
                    differenceInPrice,
                    summaryPriceWithDiscountWithBillDiscount
                } = row.filteredRows.reduce((total: { differenceInPrice: number, summaryPriceWithDiscountWithBillDiscount: number }, current: any) => {
                    return {
                        differenceInPrice: total.differenceInPrice + current.original.differenceInPrice,
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount
                    }
                }, {
                    differenceInPrice: 0,
                    summaryPriceWithDiscountWithBillDiscount: 0
                })

                return (
                    <div>
                        <Header sub>Koeficijent RUC-a</Header>
                        <strong>Prosek: {(differenceInPrice / summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</strong>
                    </div>
                )
            },
            accessor: (d: IBillOrderAnalyzeEntity) => d.differenceInPrice / d.summaryPriceWithDiscountWithBillDiscount,
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{(analyseEntity.differenceInPrice / analyseEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            hide: mode === 'gratis',
            id: 'margin',
            Header: (row: any) => {
                const {
                    summaryPriceWithDiscountWithBillDiscount,
                    summaryBasePriceInRsd
                } = row.filteredRows.reduce((total: { summaryPriceWithDiscountWithBillDiscount: number, summaryBasePriceInRsd: number }, current: any) => {
                    return {
                        summaryPriceWithDiscountWithBillDiscount: total.summaryPriceWithDiscountWithBillDiscount + current.original.summaryPriceWithDiscountWithBillDiscount,
                        summaryBasePriceInRsd: total.summaryBasePriceInRsd + current.original.summaryBasePriceInRsd
                    }
                }, {
                    summaryPriceWithDiscountWithBillDiscount: 0,
                    summaryBasePriceInRsd: 0
                })

                return (
                    <div>
                        <Header sub>Marža</Header>
                        <strong>Prosek: {(summaryPriceWithDiscountWithBillDiscount / summaryBasePriceInRsd).toFixed(2)}</strong>
                    </div>
                )
            },
            accessor: (d: IBillOrderAnalyzeEntity) => d.summaryPriceWithDiscountWithBillDiscount / d.summaryBasePriceInRsd,
            Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                <span>{(analyseEntity.summaryPriceWithDiscountWithBillDiscount / analyseEntity.summaryBasePriceInRsd).toFixed(2)}</span>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        }
    ], [
        mode,
        brandsAsOptions
    ])

    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header
                                as='h4'
                                color='teal'
                                icon='chart pie'
                            />
                            {charts.map((chart, index) => {
                                    if (!((mode === 'gratis') && chart.hideInGratis)) {
                                        return (<Checkbox
                                            key={index}
                                            style={{marginRight: '2em'}}
                                            slider
                                            label={Array.isArray(chart.title) ? chart.title[0] : chart.title}
                                            checked={showingCharts.includes(chart.id)}
                                            onChange={(event, data) => {
                                                if (data.checked) {
                                                    setShowingCharts((prevState: string[]) => [...prevState, chart.id])
                                                } else {
                                                    setShowingCharts((prevState: string[]) => prevState.filter(ch => ch !== chart.id))
                                                }
                                            }}
                                        />)
                                    } else {
                                        return null
                                    }

                                }
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {filteredRows.length === 0 ?
                            <Message
                                style={{marginLeft: '1em', marginRight: '1em'}}
                                info
                                icon='eye slash'
                                header='Nema podataka za prikaz'
                                content='Pokušaj da promeniš filtere'
                            /> :

                            charts.map((chart, index) => {
                                if (showingCharts.includes(chart.id) && !((mode === 'gratis') && chart.hideInGratis)) {
                                    return (<Grid.Column computer={5} tablet={8} mobile={16}>
                                        <MyPieChart
                                            key={index}
                                            title={chart.title}
                                            data={chart.data}
                                            colors={chart.colors}
                                            xAccessor={chart.xAccessor}
                                            yAccessor={chart.yAccessor}
                                        />
                                    </Grid.Column>)
                                } else {
                                    return null
                                }
                            })
                        }


                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment attached='bottom'>
                <Table
                    trackingId={trackingId}
                    data={groupByBrand}
                    columns={columns}
                />
            </Segment>
        </Segment.Group>
    )
}

export default observer(TrafficAnalysisByBrandList)
