import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Header, Input, Message, Segment, Select} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {generateYearsOptions} from "../../../app/common/util/options";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {history} from "../../../index";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const ClientInitialStatesGenerate: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { 
        loadYearsWithAny,
        yearsWithAny,
        loadingYearsWithAny,
        generateClientInitialState,
        generating
    } = rootStore.clientInitialStatesStore
    const { currentTraffic } = rootStore.trafficsStore
    
    const [year, setYear] = useState('')
    const [confirmation, setConfirmation] = useState('')

    useNavbarSelectedItem('clientInitialStatesGeneration')
    
    useEffect(() => {
        loadYearsWithAny()
    }, [
        loadYearsWithAny,
    ])
    
    if (loadingYearsWithAny) return <LoadingComponent content='Učitavanje godina sa početnim stanjem...' />
    
    return (<Grid centered>
        <Grid.Column width={16}>
            <BreadcrumbNavigation 
                items={[
                    {
                        text: 'Početna stanja',
                        linkWithoutCurrentTrafficId: 'clientInitialStates'
                    },
                    {
                        text: 'Generisanje početnog stanja',
                        active: true
                    }
                ]}
                />
        </Grid.Column>
        <Grid.Column computer={8} tablet={10} mobile={16}>
            <Segment 
                clearing
                loading={loadingYearsWithAny} 
                color='blue'>
                <Form>
                    <Form.Field>
                        <Select
                            value={year}
                            onChange={(event, data) => setYear(data.value as string)}
                            labeled
                            placeholder='Godina'
                            options={generateYearsOptions(2010, 2050)} 
                        />
                        {yearsWithAny?.includes(year!) &&
                        <Message
                            color='orange'
                            header='Za ovu godinu već postoje početna stanja'  
                            content='Generisanjem početnih stanja za ovu godinu postojeća će biti pregažena'
                        />}
                        
                    </Form.Field>
                    {year.length > 0 &&
                    <Form.Field>
                        <Header as='h4'>
                            Operacija visokog rizika
                            <Header.Subheader>
                                U polje ispod unesi godinu za koju želiš da generišeš početno stanje
                            </Header.Subheader>
                        </Header>
                        <Input
                            value={confirmation}
                            onChange={(event, data) => setConfirmation(data.value)}
                        />
                    </Form.Field>}
                    
                    <Form.Field>
                        <Button 
                            onClick={() => generateClientInitialState(year)}
                            loading={generating}
                            floated='right'
                            color='blue'
                            content='Generiši'
                            disabled={loadingYearsWithAny || year.length < 1 || year !== confirmation}
                        />
                        <Button
                            floated='right'
                            content='Odustani'
                            onClick={() => history.push(`/${currentTraffic?.id}/clientInitialStates`)}
                        />
                    </Form.Field>
                </Form>

            </Segment>
        </Grid.Column>
    </Grid>)
};

export default observer(ClientInitialStatesGenerate)
