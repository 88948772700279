import React, {FunctionComponent, useRef, useState} from 'react';
import {Button, Form, Segment} from "semantic-ui-react";
import {Form as FinalForm, Field} from "react-final-form";
import {combineValidators, isRequired} from "revalidate";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import {v4 as uuid} from 'uuid';
import TextInput from "../../app/common/form/TextInput";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import DateInput from "../../app/common/form/DateInput";


interface IField {
    name: string,
    type: 'text' | 'date'
    placeholder: string,
    required?: boolean,
    requiredMessage?: string
}


interface IProps {
    onCancel: () => any,
    onSubmit: (values: any) => any,
    submitting: boolean,
    fields: IField[]
}

const FileUploadForm: FunctionComponent<IProps> = ({
                                                       onSubmit,
                                                       onCancel,
                                                       submitting,
                                                       fields
                                                   }) => {

    let validateFields: any = {}
    fields.forEach(field => {
        validateFields[field.name] = isRequired({message: field.requiredMessage ?? `${field.placeholder} je obavezan`})
    })
    const validate = combineValidators(validateFields);


    const [file, setFile] = useState<Blob | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileUpload = (event: any) => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            setFile(file)
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        const document = {...values}

        document.id = uuid()
        document.file = file

        return onSubmit(document)
    }

    return (
        <Segment clearing>
            <FinalForm
                onSubmit={(values: any) => handleFinalFormSubmit(values).catch((error: any) => ({
                        [FORM_ERROR]: error
                    })
                )}
                validate={validate}
                render={({
                             handleSubmit,
                             invalid,
                             pristine,
                             submitError,
                             dirtySinceLastSubmit,
                             form
                         }) => (
                    <Form onSubmit={handleSubmit}>


                        {fields.map(field => <Field
                            key={field.name}
                            placeholder={field.placeholder}
                            name={field.name}
                            component={field.type === 'text' ? TextInput : DateInput}
                        />)}

                        <Button
                            type='button'
                            color={file !== null ? 'green' : undefined}
                            content="Otpremi fajl"
                            labelPosition="left"
                            icon="file"
                            onClick={() => fileInputRef!.current!.click()}
                        />
                        <Field
                            name='file'
                            ref={fileInputRef}
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                            component={"input"}
                        />


                        {(submitError && !dirtySinceLastSubmit) &&
                        <ErrorMessage error={submitError}/>}
                        <Button
                            loading={submitting}
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine || !file}
                            floated='right'
                            color='blue'
                            type='submit'
                            content={'Dodaj'}
                        />
                        <Button
                            onClick={() => {
                                onCancel()
                            }}
                            floated='right'
                            type='button'
                            content='Odustani'
                        />
                    </Form>
                )}
            />
        </Segment>
    );
};

export default observer(FileUploadForm)
