import React, {FunctionComponent, useState} from 'react'
import {Comment, Grid} from "semantic-ui-react";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";
import ClientScheduleCommentForm from "../form/ClientScheduleCommentForm";
import {IClientSchedule} from "../../../app/models/clientSchedules";
import ClientScheduleSingleComment from "../commom/ClientScheduleSingleComment";

interface OwnProps {
    clientSchedule: IClientSchedule
}

type Props = OwnProps

const ClientScheduleCommentCell: FunctionComponent<Props> = ({clientSchedule}) => {
    const [addCommentMode, setAddCommentMode] = useState(false)

    const lastComment = (clientSchedule.comments.length > 0) ?
        clientSchedule.comments[clientSchedule.comments.length - 1] : null


    return <Grid centered verticalAlign='middle'>
        {/*     Last comment     */}
        {lastComment && <Grid.Row>
            <Grid.Column>
                <Comment.Group>
                    <ClientScheduleSingleComment compact comment={lastComment}/>
                </Comment.Group>
            </Grid.Column>
        </Grid.Row>}

        <Grid.Row>
            <Grid.Column textAlign='center'>
                {/*     Add comment     */}
                {addCommentMode ?
                    <ClientScheduleCommentForm
                        small
                        clientScheduleId={clientSchedule.id}
                        onSubmitSuccess={() => setAddCommentMode(false)}
                        onCancel={() => setAddCommentMode(false)}
                    /> :
                    <ShowMoreButton
                        content='Dodaj komentar'
                        onClick={() => setAddCommentMode(true)}/>}
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

export default ClientScheduleCommentCell
