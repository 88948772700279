import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {Button, Form, Grid, Segment, Select} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, composeValidators, isRequired, isRequiredIf} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import DateInput from "../../../app/common/form/DateInput";
import TextInput from "../../../app/common/form/TextInput";
import {ClientActivityFormValues, IClientActivityFormValues} from "../../../app/models/clientActivities";
import {toast} from "react-toastify";
import {isDecimal} from "../../../app/common/validators/general";
import {clientActivitiesOptions} from "../../../app/common/options/clientActivities";
import {convertStateToString, convertStringToState} from "../../../app/common/util/draft-js";
import {EditorState} from "draft-js"
import WysiwygEditor from "../../wysiwygEditor/WysiwygEditor"
import {useNavbarSelectedItem} from "../../nav/utils/index.js"
import {FieldArray} from "react-final-form-arrays"
import arrayMutators from "final-form-arrays"
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    cost: composeValidators(
        isRequired({message: 'Trošak je obavezan'}),
        isDecimal(2)
    )(),
    clientId: isRequired({message: 'Klijent je obavezan'}),
    //@ts-ignore
    mainParticipant: isRequiredIf((values: IClientActivityFormValues) => values.participants.length > 1)({message: 'Izaberi glavnog učesnika'}),
});

interface DetailParams {
    id: string;
}

const ClientActivityForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);

    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadClientActivity,
        submitting,
        createClientActivity,
        editClientActivity,
        loadClientActivityDoc,
        loadingId
    } = rootStore.clientActivitiesStore
    const {loadingUsers, loadUsers, employeesArray: employees} = rootStore.usersStore

    
    const [clientActivity, setClientActivity] = useState<IClientActivityFormValues>(new ClientActivityFormValues())
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState<Blob | null>(null)
    const [hasDocumentUIState, setHasDocumentUIState] = useState(false)
    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    );
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])

    useNavbarSelectedItem(match.params.id ? '' : 'clientActivities')

    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        loadClients()
        loadUsers(true)

        if (match.params.id) {
            setLoading(true)
            loadClientActivity(match.params.id)
                .then(clientActivity => {
                    setEditorState(convertStringToState(clientActivity!.content))
                    setClientActivity(new ClientActivityFormValues(clientActivity))
                    setHasDocumentUIState(clientActivity!.hasDocument)
                    setSelectedParticipants(clientActivity?.participants.map(p => p.user.id) ?? [])
                })
                .finally(() => setLoading(false))
        }
        
    }, [
        match.params.id,
        loadClients,
        loadClientActivity,
        loadUsers
    ]);

    const handleManualFileUpload = (event: any) => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            if (file.type !== 'application/pdf') {
                toast.error('Fajl mora biti u PDF formatu')
                setFile(null)
            } else {
                setFile(file)
            }
        }
    }


    const handleFinalFormSubmit = (values: any) => {
        const clientActivity = {...values, cost: Number(values.cost)}

        if (!clientActivity.id) {
            let newClientActivity = {
                ...clientActivity,
                id: uuid()
            }

            return createClientActivity(newClientActivity, file)
        } else {
            return editClientActivity(clientActivity, file)
                .then(() => 
                    history.push(`/${currentTraffic?.id}/clientActivities/${clientActivity.id}`))
        }
    }
    

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Aktivnosti',
                            linkWithoutCurrentTrafficId: 'clientActivities'
                        },
                        {
                            text: `${match.params.id ? 'Izmena' : 'Dodavanje'} aktivnosti`,
                            active: true
                        }
                    ]} 
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IClientActivityFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        mutators={{...arrayMutators}}
                        validate={validate}
                        initialValues={clientActivity}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingClients || loadingUsers}>

                                <Field
                                    name='clientId'
                                    placeholder='Klijent'
                                    search
                                    value={clientActivity.clientId}
                                    options={clients.map(client => ({
                                        key: client.id,
                                        value: client.id,
                                        text: client.name
                                    }))}
                                    component={SelectStringInput}/>

                                <Field
                                    name='type'
                                    placeholder='Tip'
                                    value={clientActivity.type}
                                    options={clientActivitiesOptions}
                                    component={SelectStringInput}/>

                                <Field
                                    component={DateInput}
                                    name='date'
                                    date={true}
                                    placeholder='Date'
                                    value={clientActivity.date}
                                />

                                <Form.Field>
                                    <label>Učesnici</label>
                                    <FieldArray name='participants'>
                                        {({fields, meta}) => {

                                            return <Select
                                                value={selectedParticipants}
                                                multiple
                                                clearable
                                                onChange={((event, data) => {
                                                    setSelectedParticipants(data.value as string[])
                                                    form.change('participants', data.value as string[])

                                                    // todo little hack, try to do this in validator
                                                    const mainParticipantFromForm = form.getState().values.mainParticipant
                                                    const dataValues = data.value as string[]
                                                    if (mainParticipantFromForm && !dataValues.includes(mainParticipantFromForm)) {
                                                        form.change('mainParticipant', '')
                                                    }
                                                })}
                                                options={employees.map(user => ({
                                                    key: user.id,
                                                    value: user.id,
                                                    text: `${user.displayName} [${user.email}]`
                                                }))}
                                            />}}
                                    </FieldArray>
                                </Form.Field>

                                {selectedParticipants.length > 1 &&
                                <Form.Field>
                                    <label>Glavni učesnik</label>
                                    <Field
                                        name='mainParticipant'
                                        value={clientActivity.mainParticipant}
                                        component={SelectStringInput}
                                        options={employees
                                            .filter(user => selectedParticipants.includes(user.id))
                                            .map(user => ({
                                                key: user.id,
                                                value: user.id,
                                                text: `${user.displayName} [${user.email}]`
                                            }))}
                                    />
                                </Form.Field>}

                                <Field
                                    name='content'
                                    render={() =>
                                        <WysiwygEditor
                                            editorState={editorState}
                                            setEditorState={setEditorState}
                                            onChangeCallback={() => {
                                                if (editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getPlainText().length > 0) {
                                                    form.change('content', convertStateToString(editorState))    
                                                } else {
                                                    form.change('content', '')
                                                }                                                
                                            }}/>}
                                />

                                <Form.Field>

                                    <Button.Group>

                                        <Button
                                            type='button'
                                            color={file !== null ? 'green' : undefined}
                                            content={hasDocumentUIState ? 'Otpremi novi dokument' : 'Otpremi dokument'}
                                            labelPosition="left"
                                            icon="file"
                                            onClick={() => fileInputRef!.current!.click()}
                                        />

                                        {file &&
                                        <Button icon='x'
                                                onClick={() => {
                                                    setHasDocumentUIState(false)
                                                    form.change('hasDocument', false)
                                                    form.change('editedDocument', uuid())
                                                    setFile(null)
                                                }}
                                        />}

                                        {hasDocumentUIState && file === null &&
                                        <>
                                            <Button
                                                type='button'
                                                icon='eye'
                                                color='blue'
                                                content='Pogledaj'
                                                loading={loadingId === clientActivity.id}
                                                onClick={() => loadClientActivityDoc(clientActivity.id)}
                                            />
                                            <Button icon='x'
                                                    onClick={() => {
                                                        setHasDocumentUIState(false)
                                                        form.change('hasDocument', false)
                                                        form.change('editedDocument', uuid())
                                                        setFile(null)
                                                    }}
                                            />
                                        </>}
                                        
                                    </Button.Group>
                                    
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        hidden
                                        onChange={event => {
                                            handleManualFileUpload(event)
                                            form.change('editedDocument', uuid())
                                            // form.change('cost', uuid())
                                        }}
                                    />
                                </Form.Field>

                                <Field
                                    name='editedDocument'
                                    component='input'
                                    hidden
                                />
                                
                                <Field
                                    name='hasDocument'
                                    value={hasDocumentUIState}
                                    component='input'
                                    hidden
                                />
                                
                                <Field
                                    labelPosition='right'
                                    label={{basic: true, content: 'RSD'}}
                                    name='cost'
                                    placeholder='Trošak'
                                    value={clientActivity.cost.toString()}
                                    component={TextInput}/>

                                <Field
                                    name='observation'
                                    placeholder='Zapažanje'
                                    value={clientActivity.observation}
                                    component={TextAreaInput}/>

                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!clientActivity.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={clientActivity.id ?
                                        () => history.push(`/${currentTraffic?.id}/clientActivities/${clientActivity.id}`) :
                                        () => history.push(`/${currentTraffic?.id}/clientActivities`)}
                                    disabled={loading || loadingClients}
                                    floated='right'
                                    type='button'
                                    content='Odustani'/>
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default observer(ClientActivityForm)
