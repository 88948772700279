import React from 'react';
import { IEmployeeGoal} from "../../../app/models/employeeGoals";
import EmployeeGoalDetailedParticipants from "./EmployeeGoalDetailedParticipants";
import EmployeeGoalDocuments from "../documents/EmployeeGoalDocuments";

interface IProps {
    employeeGoal: IEmployeeGoal 
}

const EmployeeGoalDetailedSidebar: React.FunctionComponent<IProps> = ({employeeGoal}) => {
    
    return <>
        <EmployeeGoalDetailedParticipants employeeGoal={employeeGoal} />
        
        <EmployeeGoalDocuments employeeGoal={employeeGoal} />
    </>
}

export default EmployeeGoalDetailedSidebar