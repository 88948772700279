import React, {Fragment, FunctionComponent, SyntheticEvent, useContext, useEffect, useState} from 'react';
import {Accordion, Button, Dropdown, DropdownItemProps, Header, Menu, SemanticCOLORS} from 'semantic-ui-react';
import {RootStoreContext} from '../../../app/stores/rootStore';
import {observer} from 'mobx-react-lite';
import {DatePicker} from 'antd'
import moment from "moment";
import {ClientOrderCompleteness} from "../../../app/models/clientsOrders";
import {EXCLUDED, INCLUDED, ONLY} from "../../../app/common/filters/3sFilter";
import {clientHTMLSelectOptions} from "../../../app/common/clients/util";
import {DropdownProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";


interface IProps {
    vertical?: boolean,
    hideClient?: boolean
}

type secondLayerFilterName = 'billCreated' | 'delivered' | 'correction' | 'postExpress'

interface secondLayerFilter {
    title: string,
    name: secondLayerFilterName,
    color?: SemanticCOLORS,
    icon?: string,
    showIn: {
        all?: boolean,
        completed?: boolean,
        uncompleted?: boolean
    }
}

const useShowAdvancedFilter = () => {
    const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(window.localStorage
        .getItem('clientOrdersShowAdvancedFilter') === 'true')

    useEffect(() => {
        window.localStorage.setItem('clientOrdersShowAdvancedFilter', showAdvancedFilter.toString())
    }, [
        showAdvancedFilter
    ])

    return [showAdvancedFilter, setShowAdvancedFilter] as const
}

const ClientOrdersFilters: FunctionComponent<IProps> = ({ vertical, hideClient }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        setDateRangeAndUpdate,
        startDateFilter,
        endDateFilter,
        completenessFilter,
        setCompletenessFilter,
        setSecondFilter,
        loading,
        deliveredStartDateFilter,
        deliveredEndDateFilter,
        deliveredFilter,
        setDeliveredDateRangeFilter,
        billCreatedFilter,
        correctionFilter,
        postExpressFilter,
        clientIdFilter, setClientIdFilter,
        resetPagingClearRegistryAndLoadClientOrders
    } = rootStore.clientOrdersStore
    const {
        clientsArray: clients,
        loadClients,
        loading: loadingClients
    } = rootStore.clientsStore

    const [showAdvancedFilter, setShowAdvancedFilter] = useShowAdvancedFilter()
    
    useEffect(() => {
        loadClients(true)
    }, [
        loadClients
    ])
    
    const handleChangeClientIdFilter = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        setClientIdFilter(data.value as string)
        resetPagingClearRegistryAndLoadClientOrders()
    }
    
    return (
        <Fragment>
            <Menu vertical={vertical} size={'large'} style={{width: '100%', marginTop: 45, marginBottom: 0}}>
                <Header
                    icon={'calendar'}
                    attached
                    color={'teal'}
                    content={'Datum'}/>
                <Menu.Item>
                    <DatePicker.RangePicker
                        placeholder={['Početni datum', 'Krajni datum']}
                        format='DD.MM.YYYY.'
                        onChange={(date, dateString) =>
                            setDateRangeAndUpdate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '',
                                date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')}
                        value={[startDateFilter ? moment(startDateFilter) : null, endDateFilter ? moment(endDateFilter) : null]}
                    />
                </Menu.Item>
            </Menu>

            {!hideClient &&
            <Menu
                vertical={vertical}
                size='large'
                style={{width: '100%'}}
            >
                <Header
                    icon='user'
                    attached
                    color='teal'
                    content='Klijent'
                />

                <Menu.Item>
                    <Dropdown
                        placeholder='Svi'
                        fluid
                        search
                        selection
                        options={clientHTMLSelectOptions(clients) as DropdownItemProps[]}
                        value={clientIdFilter as string}
                        onChange={handleChangeClientIdFilter}
                        loading={loadingClients}
                        clearable
                    />
                </Menu.Item>
            </Menu>}
            
            
            <Menu
                vertical={vertical}
                size={'large'}
                style={{width: '100%', marginBottom: 0}}>
                <Header icon={'filter'} attached color={'teal'} content={'Filteri'}/>

                <Menu.Item
                    active={completenessFilter === ClientOrderCompleteness.All}
                    onClick={() => {
                        setCompletenessFilter(ClientOrderCompleteness.All, true)
                    }}
                    icon='ban'
                    color={'blue'}
                    content={'Sve'}
                />

                <Menu.Item
                    active={completenessFilter === ClientOrderCompleteness.Completed}
                    onClick={() => {
                        setCompletenessFilter(ClientOrderCompleteness.Completed, true)
                    }}
                    icon={'check'}
                    color={'blue'}
                    content={'Kompletne'}
                />

                <Menu.Item
                    active={completenessFilter === ClientOrderCompleteness.Uncompleted}
                    onClick={() => setCompletenessFilter(ClientOrderCompleteness.Uncompleted, true)}
                    color={'blue'}
                    icon='tasks'
                    content={'Nekompletne'}
                />

            </Menu>

            <Menu vertical={true} size={'mini'} style={{width: '100%', marginTop: 0}}>

                <Accordion as={Menu.Item}>
                    <Accordion.Title
                        active={showAdvancedFilter}
                        content={showAdvancedFilter ? 'Zatvori' : 'Više'}
                        onClick={() => setShowAdvancedFilter(prevState => !prevState)}
                    />
                    <Accordion.Content
                        active={showAdvancedFilter}
                        content={<>
                            {/*     Delivered   */}
                            {completenessFilter !== ClientOrderCompleteness.Completed && <Menu.Item>
                                <Header as='h5' icon='shipping fast' content='Otpremljeno'/>
                                <Button.Group fluid size='mini'>
                                    <Button
                                        content='Sve'
                                        disabled={loading}
                                        color={deliveredFilter === INCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (deliveredFilter !== INCLUDED) {
                                                setSecondFilter('deliveredFilter', INCLUDED)
                                                setDeliveredDateRangeFilter(null, null, true)
                                            }
                                        }}/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Da'
                                        disabled={loading}
                                        color={deliveredFilter === ONLY ? 'blue' : undefined}
                                        onClick={() => {
                                            if (deliveredFilter !== ONLY) {
                                                setSecondFilter('deliveredFilter', ONLY)
                                                setDeliveredDateRangeFilter(null, null, true)
                                            }
                                        }
                                        }/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Ne'
                                        disabled={loading}
                                        color={deliveredFilter === EXCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (deliveredFilter !== EXCLUDED) {
                                                setSecondFilter('deliveredFilter', EXCLUDED)
                                                setDeliveredDateRangeFilter(null, null, true)
                                            }

                                        }}/>
                                </Button.Group>
                            </Menu.Item>}

                            {deliveredFilter !== EXCLUDED &&
                            <Menu.Item>
                                <Header as='h5' content='Otpremljeno u opsegu'/>
                                <DatePicker.RangePicker
                                    format='DD.MM.YYYY.'
                                    value={[
                                        deliveredStartDateFilter
                                            ? moment(deliveredStartDateFilter) : null,
                                        deliveredEndDateFilter
                                            ? moment(deliveredEndDateFilter) : null]}
                                    onChange={(dates) => setDeliveredDateRangeFilter(
                                        dates && dates[0]
                                            ? dates[0].format('YYYY-MM-DD') : null,
                                        dates && dates[1]
                                            ? dates[1].format('YYYY-MM-DD') : null
                                    )}
                                />
                            </Menu.Item>}
                            {/*     End of Delivered   */}

                            {/*     BillCreated   */}
                            {completenessFilter !== ClientOrderCompleteness.Completed && <Menu.Item>
                                <Header as='h5' icon='file alternate outline' content='Napravljen račun'/>
                                <Button.Group fluid size='mini'>
                                    <Button
                                        content='Sve'
                                        disabled={loading}
                                        color={billCreatedFilter === INCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (billCreatedFilter !== INCLUDED) {
                                                setSecondFilter('billCreatedFilter', INCLUDED, true)
                                            }
                                        }}/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Da'
                                        disabled={loading}
                                        color={billCreatedFilter === ONLY ? 'blue' : undefined}
                                        onClick={() => {
                                            if (billCreatedFilter !== ONLY) {
                                                setSecondFilter('billCreatedFilter', ONLY, true)
                                            }
                                        }
                                        }/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Ne'
                                        disabled={loading}
                                        color={billCreatedFilter === EXCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (billCreatedFilter !== EXCLUDED) {
                                                setSecondFilter('billCreatedFilter', EXCLUDED, true)
                                            }
                                        }}/>
                                </Button.Group>
                            </Menu.Item>}
                            {/*     End of BillCreated  */}

                            {/*     Correction   */}
                            {completenessFilter !== ClientOrderCompleteness.Completed && <Menu.Item>
                                <Header as='h5' icon='edit' content='Korekicja'/>
                                <Button.Group fluid size='mini'>
                                    <Button
                                        content='Sve'
                                        disabled={loading}
                                        color={correctionFilter === INCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (correctionFilter !== INCLUDED) {
                                                setSecondFilter('correctionFilter', INCLUDED, true)
                                            }
                                        }}/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Da'
                                        disabled={loading}
                                        color={correctionFilter === ONLY ? 'blue' : undefined}
                                        onClick={() => {
                                            if (correctionFilter !== ONLY) {
                                                setSecondFilter('correctionFilter', ONLY, true)
                                            }
                                        }
                                        }/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Ne'
                                        disabled={loading}
                                        color={correctionFilter === EXCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (correctionFilter !== EXCLUDED) {
                                                setSecondFilter('correctionFilter', EXCLUDED, true)
                                            }
                                        }}/>
                                </Button.Group>
                            </Menu.Item>}
                            {/*     End of Correction  */}

                            {/*     PostExpress   */}
                            {completenessFilter !== ClientOrderCompleteness.Completed && <Menu.Item>
                                <Header as='h5' icon='mail outline' content='PostExpress'/>
                                <Button.Group fluid size='mini'>
                                    <Button
                                        content='Sve'
                                        disabled={loading}
                                        color={postExpressFilter === INCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (postExpressFilter !== INCLUDED) {
                                                setSecondFilter('postExpressFilter', INCLUDED, true)
                                            }
                                        }}/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Da'
                                        disabled={loading}
                                        color={postExpressFilter === ONLY ? 'blue' : undefined}
                                        onClick={() => {
                                            if (postExpressFilter !== ONLY) {
                                                setSecondFilter('postExpressFilter', ONLY, true)
                                            }
                                        }}/>
                                    <Button.Or text='ili'/>
                                    <Button
                                        content='Ne'
                                        disabled={loading}
                                        color={postExpressFilter === EXCLUDED ? 'blue' : undefined}
                                        onClick={() => {
                                            if (postExpressFilter !== EXCLUDED) {
                                                setSecondFilter('postExpressFilter', EXCLUDED, true)
                                            }
                                        }}/>
                                </Button.Group>
                            </Menu.Item>}
                            {/*     End of PostExpress  */}
                        </>}
                    />
                </Accordion>
            </Menu>
        </Fragment>
    );
}

export default observer(ClientOrdersFilters);