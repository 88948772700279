import React, {FunctionComponent, useContext, useEffect} from 'react';
import Table from "../../../app/common/tables/Table";
import {Button, Message, Reveal, Statistic} from "semantic-ui-react";
import {Cell, FilterProps} from "react-table";
import {Link} from "react-router-dom";
import {formatRsd} from "../../../app/common/util/format";
import moment from "moment";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IBillInstallment} from "../../../app/models/billInstallments";
import {DateFilter} from "../../../app/common/tables/filters/date/DateFilter";
import {ClientDetailedFilter} from "../../../app/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientDetailedCell from "../../clients/list/ClientDetailedCell";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";
import {TrueFalseAllFilter} from "../../../app/common/tables/filters/trueFallseAll/TrueFalseAllFilter";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";

interface IProps {
    startDate: string
    endDate: string
    trackingId?: string
}

const BillsInstallmentsList: FunctionComponent<IProps> = ({startDate, endDate, trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        billsInstallmentsArray: installments,
        loadBillsInstallments,
        paidBillInstallment, unpaidBillInstallment,
        paiding, unpaiding, focusId,
        setPredicate,
        clearPredicate
    } = rootStore.billsInstallmentsStore
    const {currentTraffic, trafficsArray: traffics} = rootStore.trafficsStore
    const {loadClientCategoriesIfNull, categories} = rootStore.clientsStore
    const {loadRegionsIfNull, regionsArray: regions} = rootStore.regionsStore

    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
        clearPredicate()
        // setPredicate('byTraffic', currentTraffic!.id.toString())
        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)

        loadBillsInstallments()
    }, [
        loadBillsInstallments,
        clearPredicate,
        setPredicate,
        currentTraffic,
        endDate,
        startDate,
        loadRegionsIfNull,
        loadClientCategoriesIfNull
    ])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Šifra',
                accessor: 'idString',
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) => (
                    <Link
                        to={`/${currentTraffic?.id}/bills/manage/${installment.billId}`}>{installment.billIdString}</Link>)
            },
            {
                id: 'traffic',
                accessor: 'traffic.id',
                Header: 'Promet',
                Cell: ({row: {original: billInstallment}}: Cell<IBillInstallment>) =>
                    <span>{billInstallment.traffic.name}</span>,
                Filter: (d: FilterProps<any>) => MultiselectFilter(d, traffics
                    .filter(traffic => !traffic.isExpense) // expense traffics doesnt has a installments to pay
                    .map(traffic => ({
                    key: traffic.id,
                    value: traffic.id,
                    text: traffic.name,
                })), 'Promet'),
                filter: 'multiselectFilter'
            },
            {
                id: 'client',
                Header: 'Klijent',
                accessor: (d: IBillInstallment) => d.client!.name,
                Filter: (d: any) => ClientDetailedFilter(d, regions!, categories!),
                filter: 'clientDetailedFilter',
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) => <ClientDetailedCell
                    client={installment.client!}/>
            },
            {
                // accessor: 'ordinal',
                Header: 'Redni broj',
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) =>
                    <Statistic size='mini'>
                        <Statistic.Value>{installment.ordinal}</Statistic.Value>
                        <Statistic.Label>od {installment.installmentsCount}</Statistic.Label>
                    </Statistic>,
                disableSortBy: true
            },
            {
                accessor: 'value',
                Header: 'Vrednost',
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) => <>{formatRsd(installment.value)}</>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                id: 'date',
                Header: 'Datum',
                accessor: 'date',
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) => {
                    let color: string

                    if (installment.paid) {
                        color = semanticColorToHash('green')
                    } else {
                        color = moment(installment.date).isSame(new Date(), 'day') ? semanticColorToHash('orange') :
                            moment(installment.date).isBefore(new Date(), 'day') ? semanticColorToHash('red') :
                                'black'
                    }

                    return <span style={{color: color}}>{moment(installment.date).format('l')}</span>
                },
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                Header: 'Napomena',
                accessor: (d: IBillInstallment) => d.note
            },
            {
                Header: 'Plaćeno',
                accessor: (d: IBillInstallment) => d.paid,
                Cell: ({row: {original: installment}}: Cell<IBillInstallment>) =>
                    <Reveal animated='move'>
                        <Reveal.Content visible style={{width: '100%'}}>
                            <Button
                                fluid
                                loading={(paiding || unpaiding) && (focusId === installment.id)}
                                disabled={(paiding || unpaiding)}
                                color={installment.paid ? 'green' : 'red'}
                                content={installment.paid ? 'Plaćeno' : 'Neplaćeno'}
                            />
                        </Reveal.Content>
                        <Reveal.Content hidden>
                            <Button
                                loading={(paiding || unpaiding) && (focusId === installment.id)}
                                disabled={(paiding || unpaiding)}
                                fluid
                                basic
                                color={installment.paid ? 'red' : 'green'}
                                content={installment.paid ? 'Storniraj' : 'Plati'}
                                onClick={
                                    installment.paid
                                        ? () => unpaidBillInstallment(installment.id)
                                        : () => paidBillInstallment(installment.id)
                                }
                            />
                        </Reveal.Content>
                    </Reveal>,
                Filter: (d: FilterProps<any>) => TrueFalseAllFilter(d, 'Plaćeno', 'Neplaćeno'),
                filter: 'trueFalseAllFilter'
            }
        ],
        [
            paidBillInstallment, 
            unpaidBillInstallment, 
            focusId, 
            paiding, 
            unpaiding, 
            currentTraffic, 
            regions, 
            categories,
            traffics
        ]
    )

    if (installments.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje rate po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={installments}/>
    );
};

export default observer(BillsInstallmentsList)
