import React, {useContext, useEffect} from 'react';
import {Button, Grid, Icon, Segment, Select} from "semantic-ui-react";
import {Link, useLocation} from "react-router-dom";
import ScheduleSectionLayout from "../../scheduleSection/layout/ScheduleSectionLayout";
import {RootStoreContext} from "../../../app/stores/rootStore";
import ClientGoalsList from "../list/ClientGoalsList";
import {observer} from "mobx-react-lite";
import {PdfService} from "../../../app/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {clientGoalStatusToString, ClientGoalType, IClientGoal} from "../../../app/models/clientGoals";
import moment from "moment";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";
import {useResponsive} from "../../../app/common/responsive/Responsive";

const TRACKING_ID = 'clientGoals'

export const clientGoalNavbarIdFromType = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment: 
            return 'clientGoals-payments'
        case ClientGoalType.clientRequest:
            return 'clientGoals-clientRequests'
        case ClientGoalType.classic:
            return 'clientGoals'
        // dont use default here
    }
}

const clientGoalTableTitle = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment:
            return 'Tabela naplata'
        case ClientGoalType.clientRequest:
            return 'Tabela zahteva klijentata'
        case ClientGoalType.classic:
            return 'Tabela ciljeva'
        // dont use default here
    }
}

const clientGoalFileName = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment:
            return 'Naplate.pdf'
        case ClientGoalType.clientRequest:
            return 'ZahteviKlijenta.pdf'
        case ClientGoalType.classic:
            return 'Ciljevi.pdf'
        // dont use default here
    }
}

const addButtonText = (type: ClientGoalType): string => {
    let prefix = 'Dodaj '
    switch (type) {
        case ClientGoalType.payment:
            return prefix + 'naplatu'
        case ClientGoalType.clientRequest:
            return prefix + 'zahtev klijenta'
        case ClientGoalType.classic:
            return prefix + 'cilj'
        // dont use default here
    }
}


const ClientGoalsDashboard = () => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loading,
        setPredicateFromFilter,
        firstFilter,
        setFirstFilter,
        secondFilter,
        setSecondFilter,
        setPredicate,
        clearPredicate,
        loadClientGoals,
    } = rootStore.clientGoalsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const { isMobile } = useResponsive()
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    const type: ClientGoalType = query.get('type') ? Number(query.get('type')) : ClientGoalType.classic

    useNavbarSelectedItem(clientGoalNavbarIdFromType(type))

    useEffect(() => () => {
        removeFromRegistry(TRACKING_ID)
    }, [
        removeFromRegistry
    ])


    useEffect(() => {
        clearPredicate()
        setPredicate('type', type.toString())
        setPredicateFromFilter()
        loadClientGoals()
    }, [
        setPredicateFromFilter,
        setPredicate,
        type,
        firstFilter,
        secondFilter,
        clearPredicate,
        loadClientGoals
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        const colorator = (color: 'red' | 'blue' | 'orange' | '') => {
            switch (color) {
                case "blue":
                    return 'Zakazano/Poslato'
                case "orange":
                    return 'U planu'
                case "red":
                    return 'Prioritetno'
                default:
                    return 'N/A'
            }
        }

        doc.text(clientGoalTableTitle(type), 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            willDrawCell: data => {
                if (data.row.section === 'body' && data.column.index === 1) {
                    // @ts-ignore
                    switch (data.row.raw[2]) {
                        case 'Zakazano/Poslato':
                            doc.setTextColor(semanticColorToHash('blue'))
                            break
                        case 'U planu':
                            doc.setTextColor(semanticColorToHash('orange'))
                            break
                        case 'Prioritetno':
                            doc.setTextColor(semanticColorToHash('red'))
                            break
                    }
                }
            },
            startY: 75,
            head: [
                ['#', 'Naziv', 'Boja', 'Klijent', 'Datum', 'Status']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((clientGoal: IClientGoal, index) => ([
                index + 1,
                clientGoal.name,
                colorator(clientGoal.color),
                clientGoal.client.name,
                clientGoal.date ? moment(clientGoal.date).format('l') : 'N/A',
                clientGoalStatusToString(clientGoal)
            ])) ?? []
        })

        doc.save(clientGoalFileName(type))
    }

    return (<ScheduleSectionLayout
        // contentLoading={loading /* || loadingYearsWithAny */}
    >
        <Segment loading={loading} style={{marginTop: 0}}>
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Select
                            options={[
                                {key: 'all', value: 'all', text: 'Sve'},
                                {key: 'current', value: 'current', text: 'Aktuelan'},
                                {key: 'failed', value: 'failed', text: 'Neispunjen'},
                                {key: 'met', value: 'met', text: 'Ispunjen'},
                                {key: 'currentPartlyMet', value: 'currentPartlyMet', text: 'Delimično ispunjen'},
                            ]}
                            value={firstFilter}
                            onChange={((event, data) => setFirstFilter(data.value as 'all' | 'current' | 'failed' | 'met'))}
                        />
                        {/*Second filter*/}
                        {firstFilter === 'current' &&
                        <Select
                            options={[
                                {key: 'currentAll', value: 'currentAll', text: 'Sve'},
                                {key: 'currentBasic', value: 'currentBasic', text: 'U roku'},
                                {key: 'currentPartlyMet', value: 'currentPartlyMet', text: 'Delimično ispunjen'},
                                {key: 'currentExpired', value: 'currentExpired', text: 'Istekao'}
                            ]}
                            value={secondFilter}
                            onChange={((event, data) => setSecondFilter(data.value as 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired'))}
                        />}
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Button
                            fluid={isMobile}
                            as={Link}
                            to={`/${currentTraffic?.id}/clientGoals/create?type=${type}`}
                            floated='right'
                            color='blue'
                            icon
                            labelPosition='left'>
                            <Icon name='add'/>
                            {addButtonText(type)}
                        </Button>
                        <Button
                            fluid={isMobile}
                            disabled={loading}
                            icon
                            labelPosition='left'
                            floated='right'
                            color='teal'
                            onClick={() => exportPdf()}>
                            <Icon name='file pdf'/>
                            PDF
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ClientGoalsList type={type} hideMetFilter trackingId={TRACKING_ID}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </ScheduleSectionLayout>)
}

export default observer(ClientGoalsDashboard)
