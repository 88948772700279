import React, {FunctionComponent, useContext} from 'react';
import {IClientOrder, isClientOrderComplete} from "../../../app/models/clientsOrders";
import {Button, Checkbox, Grid, Header, Icon, Item, Label, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import moment from "moment";
import {convertStringToState} from "../../../app/common/util/draft-js";
import {stringPreview} from "../../../app/common/util/string";
import {observer} from "mobx-react-lite";
import Responsive from "../../../app/common/responsive/Responsive";
import BillPopup from "../../bills/components/BillPopup";
import DateTimePickerWithShortcuts from "../../commom/date/DateTimePickerWithShortcuts";
import ClientOrderBillDocumentDownloadButton from "../commom/ClientOrderBillDocumentDownloadButton";
import ClientOrderContentDocumentDownloadButton from "../commom/ClientOrderContentDocumentDownloadButton";

interface IProps {
    clientOrder: IClientOrder
}

const ClientOrderListItem: FunctionComponent<IProps> = ({
                                                            clientOrder
                                                        }) => {
    const {
        text,
        note,
        deliveredDate,
        billCreated,
        client,
        date,
        id,
        createdBy,
        orderNumber,
        createdAt,
        updatedAt,
        bill,
        correction,
        postExpress
    } = clientOrder

    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        setBillCreated,
        setCorrection,
        setPostExpress,
        editingId,
        setDelivered,
        deletingId,
        deleting,
        deleteClientOrder,
        newClientOrdersIds,
    } = rootStore.clientOrdersStore
    const {openModal, closeModal} = rootStore.modalStore
    const {isEmployee} = rootStore.userStore

    return (
        <Segment.Group raised>
            <Segment>

                <Label basic color='blue' style={{border: '0px'}} size='big' attached='top right'>
                    {correction &&
                    <Icon size='big' color='red' name='pencil'/>}
                    {clientOrder.postExpress &&
                    <Icon size='big' color='yellow' name='mail'/>}
                    {isClientOrderComplete(clientOrder) &&
                    <Icon size='big' color='green' name='check'/>}
                    {newClientOrdersIds.includes(id) &&
                    <Label color='violet' content='NOVO'/>}


                </Label>

                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header
                                to={`/${currentTraffic?.id}/clients/${client.id}`}
                                as={Link}>
                                {client.name}
                            </Item.Header>
                            <Item.Description>
                                <Link to={`/${currentTraffic?.id}/clientOrders/${id}`}> {orderNumber}</Link>
                            </Item.Description>

                            <Item.Description>
                                Kreirao
                                <Link
                                    to={`/${currentTraffic?.id}/profile/${createdBy.username}`}> {createdBy.displayName}</Link>
                            </Item.Description>
                            <Item.Description>
                                
                                {clientOrder.hasBillDocument &&
                                <ClientOrderBillDocumentDownloadButton clientOrder={clientOrder} />}

                                {bill &&
                                <span style={{margin: 3}}>
                                    <BillPopup bill={bill}/>                                        
                                </span>}

                                {billCreated && (
                                    <Label
                                        style={{margin: 3}}
                                        basic
                                        color='orange'
                                        content='Uradjen račun'
                                    />
                                )}
                                {deliveredDate && (
                                    <Label
                                        style={{margin: 3}}
                                        basic
                                        color='blue'
                                        content='Otpremljeno'
                                    />
                                )}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    {isEmployee &&
                    <Item>
                        <Grid>
                            <Grid.Column width={16}>
                                <label>Otpremljeno: </label>
                                <DateTimePickerWithShortcuts
                                    placeholder='Datum otpreme'
                                    shortcuts={{
                                        today: true,
                                        tomorrow: true,
                                        yesterday: true
                                    }}
                                    onChange={date => setDelivered(id, date ? moment(date).format('YYYY-MM-DD') : '')}
                                    date={deliveredDate}
                                />                                
                            </Grid.Column>

                            <Grid.Column width={16}>
                                
                                <Checkbox
                                    style={{margin: 5}}
                                    label='Napravljen račun'
                                    disabled={id === editingId}
                                    toggle
                                    checked={billCreated}
                                    onChange={(event, data) => setBillCreated(id, data.checked!)}
                                />

                                <Checkbox
                                    style={{margin: 5}}
                                    label='Korekcija'
                                    disabled={id === editingId}
                                    toggle
                                    checked={correction}
                                    onChange={(event, data) => setCorrection(id, data.checked!)}
                                />

                                {clientOrder.deliveredDate && <Checkbox
                                    style={{margin: 5}}
                                    label='Post express'
                                    disabled={id === editingId}
                                    toggle
                                    checked={postExpress}
                                    onChange={(event, data) => setPostExpress(id, data.checked!)}
                                />}
                            </Grid.Column>
                        </Grid>
                        
                    </Item>}
                </Item.Group>
            </Segment>

            <Segment>
                <Icon name='calendar'/> {moment(date).format('l')}
                <Icon style={{marginLeft: 2}} name='clock outline'/> dodato {moment(createdAt).calendar()}
                {createdAt !== updatedAt ? `, izmenjeno ${moment(updatedAt).calendar()}` : ''}
            </Segment>
            <Segment secondary>
                {stringPreview(note, 100)}
            </Segment>
            <Segment clearing>
                <div>{stringPreview(convertStringToState(text).getCurrentContent().getPlainText(), 100)}</div>
                {isEmployee &&
                <>
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/clientOrders/${id}`}
                        floated='right'
                        color='blue'
                        icon
                    >
                        <Icon name='eye'/>
                        <Responsive.NotMobile>
                            Prikaži
                        </Responsive.NotMobile>
                    </Button>

                    {clientOrder.hasContentDocument &&
                    <ClientOrderContentDocumentDownloadButton clientOrder={clientOrder} />}
                    
                    <Button
                        icon
                        as={Link}
                        to={`/${currentTraffic?.id}/clientOrders/manage/${id}/edit`}
                        floated='right'
                        color='orange'
                    >
                        <Icon name='edit'/>
                        <Responsive.NotMobile>
                            Izmeni
                        </Responsive.NotMobile>
                    </Button>

                    <Button
                        onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje narudžbine
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš narudžbinu <strong>{orderNumber} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any, data) => {
                                    event.target.className += ' loading'
                                    deleteClientOrder(id)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )}
                        color='red'
                        icon
                        loading={(deletingId === id) && deleting}
                        floated='right'>
                        <Icon name='trash alternate'/>
                        <Responsive.NotMobile>
                            Ukloni
                        </Responsive.NotMobile>
                    </Button>
                </>}

            </Segment>
        </Segment.Group>
    )
};

export default observer(ClientOrderListItem)
