import React, {FunctionComponent, useContext} from 'react';
import {Dropdown, Icon} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../app/stores/rootStore";
import {Link} from "react-router-dom";
import {FaTrafficLight, IoGlobeOutline, RiTrademarkFill} from "react-icons/all";

interface IProps {
}

const SpecialMenu: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {selectedItem, setRightSidebarVisible} = rootStore.navbarStore
    const {currentTraffic} = rootStore.trafficsStore
    const {isTrafficManager, isSuperAdmin} = rootStore.userStore

    return (<>
        {isSuperAdmin && <>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'globalSettings'}
                to={`/${currentTraffic?.id}/globalSettings`}
                onClick={() => setRightSidebarVisible(false)}>
                <Icon name='settings'/>
                Podešavanja
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'usersDashboard'}
                to={`/${currentTraffic?.id}/users`}>
                <Icon name='users'/>
                Korisnici
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'pricelists'}
                to={`/${currentTraffic?.id}/pricelists`}
                onClick={() => setRightSidebarVisible(false)}>
                <Icon name='print'/>
                Cenovnici
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'brands'}
                to={`/${currentTraffic?.id}/brands`}>
                <Icon>
                    <RiTrademarkFill/>
                </Icon>
                Brendovi
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'regions'}
                to={`/${currentTraffic?.id}/regions`}>
                <Icon>
                    <IoGlobeOutline/>
                </Icon>
                Regioni
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'traffics'}
                to={`/${currentTraffic?.id}/traffics`}>
                <Icon>
                    <FaTrafficLight/>
                </Icon>
                Prometi
            </Dropdown.Item>
        </>}
        {isTrafficManager && <>
            <Dropdown.Divider/>
            <Dropdown.Header>
                Početno stanje
            </Dropdown.Header>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'clientInitialStatesCreate'}
                to={`/${currentTraffic?.id}/clientInitialStates/create`}>
                <Icon name='add'/>
                Dodaj
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'clientInitialStatesDashboard'}
                to={`/${currentTraffic?.id}/clientInitialStates`}>
                <Icon name='clipboard list'/>
                Lista
            </Dropdown.Item>
            <Dropdown.Item
                as={Link}
                active={selectedItem === 'clientInitialStatesGeneration'}
                to={`/${currentTraffic?.id}/clientInitialStates/generate`}>
                <Icon name='microchip'/>
                Generiši
            </Dropdown.Item>
        </>}
    </>)
}

export default observer(SpecialMenu)
