import React, {FunctionComponent} from 'react';
import {Header, Progress} from "semantic-ui-react";
import {formatRsd} from "../../../app/common/util/format";
import {numberToStringToFixedOrInt} from "../../../app/common/util/string";

interface IProps {
    value?: number
    total?: number,
    linkTo?: string
}

const ClientProgress: FunctionComponent<IProps> = ({value, total}) => {

    if ((!value && value !== 0) || (!total && total !== 0)) return <>N/A</>

    if (total <= 0)
        return <Header
            as='h4'
            content={`${value}/${total}`}
            color={total < 0 ? 'red' : undefined}
        />

    const percentageReal = value / total * 100
    const percentageNormalized = percentageReal > 100 ? 100 : percentageReal // 100 is max
    
    return <>
        <Progress
            style={{marginBottom: '0.0em'}}
            indicating={value < total}
            color={value >= total ? 'blue' : undefined}
            value={value}
            total={total}
            progress='percent'
            precision={2}/>
        <p><strong>{formatRsd(value)}</strong> / {formatRsd(total)} [{numberToStringToFixedOrInt(percentageNormalized, 2)}%]</p>
    </>

}

export default ClientProgress
