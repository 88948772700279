import React, {FunctionComponent, useContext} from 'react';
import {IUser} from "../../../app/models/user";
import {Header, Item, SemanticSIZES} from "semantic-ui-react";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";

interface IProps {
    user: IUser,
    imageSize?: SemanticSIZES
    textSize?: "tiny" | "small" | "medium" | "large" | "huge" | undefined,
    detailed?: boolean,
    avatar?: boolean,
    avatarBorder?: string,
    color?: string,
    hideImage?: boolean
}

const UserBasicCell: FunctionComponent<IProps> = ({
                                                      user,
                                                      imageSize,
                                                      textSize,
                                                      detailed,
                                                      avatar,
                                                      avatarBorder,
                                                      color,
                                                      hideImage
                                                  }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return <Item.Group>
        <Item>
            {!hideImage &&
            <Item.Image style={{border: avatarBorder}} avatar={avatar} size={imageSize}
                        src={user.image ?? '/assets/user.png'}/>}
            

            <Item.Content verticalAlign='middle'>
                <Item.Header as={Link} to={`/${currentTraffic?.id}/profile/${user.id}`}>
                    <Header
                        size={textSize}
                        style={{color}}>
                        {user.displayName}
                    </Header>
                </Item.Header>
                {detailed &&
                <>
                    {/*<Item.Description>*/}
                    {/*    {user.username}*/}
                    {/*</Item.Description>*/}
                    <Item.Extra>
                        <a href={`mailto:${user.email}`}>
                            <Header size={textSize} style={{color}}>
                                {user.email}
                            </Header>
                        </a>
                    </Item.Extra>
                </>}

            </Item.Content>
        </Item>
    </Item.Group>
};

UserBasicCell.defaultProps = {
    imageSize: 'tiny',
    textSize: 'medium',
    detailed: true
}

export default observer(UserBasicCell)
