import React, {FunctionComponent, useContext} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {Header, Icon, Menu} from "semantic-ui-react";
import IncomeTrafficsGroupByBrandList from "../../lists/IncomeTrafficsGroupByBrandList";
import IncomeTrafficsGroupByClientCategoryList from "../../lists/IncomeTrafficsGroupByClientCategoryList";
import {FcInternal} from "react-icons/all";

interface IProps {
}

export const profitabilityIncomeGroupByBrandListTableTrackingId = 'profitabilityIncomeByBrand'
export const profitabilityIncomeGroupByClientCategoryTableTrackingId = 'profitabilityIncomeByClientCategory'


const ProfitabilityDashboardIncomeSection: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {incomeView, setIncomeView} = rootStore.profitabilityStore

    const viewator = () => {
        switch (incomeView) {
            case 'brand':
                return <IncomeTrafficsGroupByBrandList trackingId={profitabilityIncomeGroupByBrandListTableTrackingId} />
            case 'clientCategory':
                return <IncomeTrafficsGroupByClientCategoryList trackingId={profitabilityIncomeGroupByClientCategoryTableTrackingId} />
            default:
                return ''
        }
    }

    return (<>
        <Header as='h2'>
            <Icon  >
                <FcInternal />
            </Icon>
            <Header.Content>Prihodi</Header.Content>
        </Header>
        
        <Menu secondary color='teal'>
            <Menu.Item 
                active={incomeView === 'brand'}
                onClick={() => setIncomeView('brand')}
                content='Brendovi'
            />

            <Menu.Item
                active={incomeView === 'clientCategory'}
                onClick={() => setIncomeView('clientCategory')}
                content='Kategorije klijenata'
            />
        </Menu>
        {viewator()}
    </>)
}

export default observer(ProfitabilityDashboardIncomeSection)
