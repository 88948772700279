import {RootStore} from "./rootStore";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {v4 as uuid} from "uuid";
import agent from "../api/agent";
import {IClientActivity} from "../models/clientActivities";
import {ONLY} from "../common/filters/3sFilter";
import {IClientComment} from "../models/clients";
import {ClientGoalType, getClientGoalColorSerbianText, IClientGoalComment} from "../models/clientGoals";
import {EmployeeGoalType, IEmployeeGoalComment} from "../models/employeeGoals";
import {getClientTourStatusSerbianString, IClientTour} from "../models/clientTours";
import {IUserPostComment} from "../models/userPosts";
import {ICommentSectionItem} from "../models/commentsSection";
import {convertStringToState} from "../common/util/draft-js";
import moment from "moment";


const CLIENT_ACTIVITIES_HAS_CONTENT_PARAM = 'hasContent'
const CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_START_DATE_PARAM = 'contentUpdatedAtStartDate'
const CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_END_DATE_PARAM = 'contentUpdatedAtEndDate'
const CLIENT_ACTIVITIES_HAS_OBSERVATION_PARAM = 'hasObservation'
const CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_START_DATE_PARAM = 'observationUpdatedAtStartDate'
const CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_END_DATE_PARAM = 'observationUpdatedAtEndDate'
const CLIENT_COMMENTS_UPDATED_AT_START_DATE_PARAM = 'updatedAtStartDateTime'
const CLIENT_COMMENTS_UPDATED_AT_END_DATE_PARAM = 'updatedAtEndDateTime'
const CLIENT_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM = 'updatedAtStartDateTime'
const CLIENT_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM = 'updatedAtEndDateTime'
const EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM = 'updatedAtStartDateTime'
const EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM = 'updatedAtEndDateTime'
const CLIENT_TOURS_NOTE_UPDATED_AT_START_DATE_PARAM = 'noteUpdatedAtStartDateTime'
const CLIENT_TOURS_NOTE_UPDATED_AT_END_DATE_PARAM = 'noteUpdatedAtEdDateTime'
const USER_POST_COMMENTS_UPDATED_AT_START_DATE_PARAM = 'updatedAtStartDateTime'
const USER_POST_COMMENTS_UPDATED_AT_END_DATE_PARAM = 'updatedAtEdDateTime'

// const clientActivitiesContentsFilter = 'clientActivitiesContentsFilter'
// const clientActivitiesObservationsFilter = 'clientActivitiesObservationsFilter'
// const clientCommentsFilter = 'clientCommentsFilter'
// const clientGoalCommentsFilter = 'clientGoalCommentsFilter'
// const employeeClientGoalCommentsFilter = 'employeeClientGoalCommentsFilter'
// const clientToursFilter = 'clientToursFilter'
// const userPostsCommentsFilter = 'userPostsCommentsFilter'

export type IItemCategory =
    'clientActivitiesContents'
    | 'clientActivitiesObservations'
    | 'clientComments'
    | 'clientGoalClassicTypeComments'
    | 'clientGoalPaymentTypeComments'
    | 'employeeGoalClassicTypeComments'
    | 'employeeGoalProblemTypeComments'
    | 'clientTours'
    | 'userPostsComments'

export const categoryFiltersCategories = [
    { value: 'clientActivitiesContents', text: 'Aktivnost - Sadržaj', color: 'teal'},
    { value: 'clientActivitiesObservations', text: 'Aktivnost - Zapažanje', color: 'blue' },
    { value: 'clientComments', text: 'Komentari kod klijenta', color: 'orange' },
    { value: 'clientGoalClassicTypeComments', text: 'Ciljevi', color: 'green' },
    { value: 'clientGoalPaymentTypeComments', text: 'Naplate', color: 'yellow' },
    { value: 'employeeGoalClassicTypeComments', text: 'Tekuće obaveze', color: 'red' },
    { value: 'employeeGoalProblemTypeComments', text: 'Uočeni problemi', color: 'violet' },
    { value: 'clientTours', text: 'Kalendar obilazaka', color: 'olive' },
    { value: 'userPostsComments', text: 'Postovi', color: 'pink' },
 
]

export default class CommentsSectionStore {
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        makeObservable(this)
    }

    @observable loadingAll = false
    @observable lastRequestId = ''
    @observable categoryFilter: IItemCategory[] = []
    @observable clientActivitiesContentsRegistry = new Map<string, IClientActivity>()
    @observable clientActivitiesContentsPredicate = new Map<string, string>()
    @observable clientActivitiesObservationsRegistry = new Map<string, IClientActivity>()
    @observable clientActivitiesObservationsPredicate = new Map<string, string>()
    @observable clientCommentsRegistry = new Map<string, IClientComment>()
    @observable clientCommentsPredicate = new Map<string, string>()
    @observable clientGoalClassicTypeCommentsRegistry = new Map<string, IClientGoalComment>()
    @observable clientGoalClassicTypeCommentsPredicate = new Map<string, string>()
    @observable clientGoalPaymentTypeCommentsRegistry = new Map<string, IClientGoalComment>()
    @observable clientGoalPaymentTypeCommentsPredicate = new Map<string, string>()
    @observable employeeGoalClassicTypeCommentsRegistry = new Map<string, IEmployeeGoalComment>()
    @observable employeeGoalProblemTypeCommentsRegistry = new Map<string, IEmployeeGoalComment>()
    @observable employeeGoalClassicTypeCommentsPredicate = new Map<string, string>()
    @observable employeeGoalProblemTypeCommentsPredicate = new Map<string, string>()
    @observable clientToursRegistry = new Map<string, IClientTour>()
    @observable clientToursPredicate = new Map<string, string>()
    @observable userPostsCommentsRegistry = new Map<string, IUserPostComment>()
    @observable userPostsCommentsPredicate = new Map<string, string>()


    //  Axios params

    @computed get clientActivitiesContentsAxiosParams() {
        const params = new URLSearchParams()

        params.append(CLIENT_ACTIVITIES_HAS_CONTENT_PARAM, ONLY)

        this.clientActivitiesContentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get clientActivitiesObservationsAxiosParams() {
        const params = new URLSearchParams()

        params.append(CLIENT_ACTIVITIES_HAS_OBSERVATION_PARAM, ONLY)

        this.clientActivitiesObservationsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get clientCommentsAxiosParams() {
        const params = new URLSearchParams()

        this.clientCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get clientGoalClassicTypeCommentsAxiosParams() {
        const params = new URLSearchParams()

        params.append('type', ClientGoalType.classic.toString())

        this.clientGoalClassicTypeCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get clientGoalPaymentTypeCommentsAxiosParams() {
        const params = new URLSearchParams()

        params.append('type', ClientGoalType.payment.toString())

        this.clientGoalPaymentTypeCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get employeeGoalClassicTypeCommentsAxiosParams() {
        const params = new URLSearchParams()

        params.append('type', EmployeeGoalType.classic.toString())
        
        this.employeeGoalClassicTypeCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get employeeGoalProblemTypeCommentsAxiosParams() {
        const params = new URLSearchParams()

        params.append('type', EmployeeGoalType.problem.toString())
        
        this.employeeGoalProblemTypeCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get clientToursAxiosParams() {
        const params = new URLSearchParams()

        this.clientToursPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    @computed get userPostsCommentsAxiosParams() {
        const params = new URLSearchParams()

        this.userPostsCommentsPredicate.forEach((value, key) => {
            params.append(key, value)
        })

        return params
    }

    // Computed arrays

    //  Activities - Contents    

    @computed get clientActivitiesContentsArray() {
        return Array.from(this.clientActivitiesContentsRegistry.values())
    }

    @computed get clientActivitiesContentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientActivitiesContentsArray
            .map(clientActivity => ({
                type: 'Aktivnost',
                color: 'teal',
                title: clientActivity.type,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clientActivities/${clientActivity.id}`,
                client: clientActivity.client,
                content: convertStringToState(clientActivity.content).getCurrentContent().getPlainText(),
                category: 'Sadržaj',
                createdAt: clientActivity.contentUpdatedAt,
                updatedAt: clientActivity.contentUpdatedAt
            }))
    }


    //  Activities - Observations

    @computed get clientActivitiesObservationsArray() {
        return Array.from(this.clientActivitiesObservationsRegistry.values())
    }

    @computed get clientActivitiesObservationsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientActivitiesObservationsArray
            .map(clientActivity => ({
                type: 'Aktivnost',
                color: 'blue',
                title: clientActivity.type,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clientActivities/${clientActivity.id}`,
                client: clientActivity.client,
                content: clientActivity.observation,
                category: 'Zapažanje',
                createdAt: clientActivity.observationUpdatedAt,
                updatedAt: clientActivity.observationUpdatedAt
            }))
    }

    // Client comments

    @computed get clientCommentsArray() {
        return Array.from(this.clientCommentsRegistry.values())
    }

    @computed get clientCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientCommentsArray
            .map(clientComment => ({
                type: 'Komentar kod klijenta',
                color: 'orange',
                title: 'Komentar',
                client: clientComment.client,
                content: (clientComment.content),
                category: 'Komentar kod klijenta',
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clients/${clientComment.client.id}`,
                createdAt: clientComment.createdAt,
                updatedAt: clientComment.updatedAt,
                commentator: clientComment.user
            }))
    }

    //  ClientGoal comments

    //  Classic type

    @computed get clientGoalClassicTypeCommentsArray() {
        return Array.from(this.clientGoalClassicTypeCommentsRegistry.values())
    }

    @computed get clientGoalClassicTypeCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientGoalClassicTypeCommentsArray
            .map(clientGoalComment => ({
                type: 'Cilj',
                color: 'green',
                title: clientGoalComment.clientGoalName,
                client: clientGoalComment.client,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clientGoals/${clientGoalComment.clientGoalId}`,
                content: clientGoalComment.content,
                category: getClientGoalColorSerbianText(clientGoalComment.clientGoalColor),
                commentator: clientGoalComment.user,
                createdAt: clientGoalComment.createdAt,
                updatedAt: clientGoalComment.updatedAt
            }))
    }

    //  Payment type

    @computed get clientGoalPaymentTypeCommentsArray() {
        return Array.from(this.clientGoalPaymentTypeCommentsRegistry.values())
    }

    @computed get clientGoalPaymentTypeCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientGoalPaymentTypeCommentsArray
            .map(clientGoalComment => ({
                type: 'Naplata',
                color: 'yellow',
                title: clientGoalComment.clientGoalName,
                client: clientGoalComment.client,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clientGoals/${clientGoalComment.clientGoalId}`,
                content: clientGoalComment.content,
                category: getClientGoalColorSerbianText(clientGoalComment.clientGoalColor),
                commentator: clientGoalComment.user,
                createdAt: clientGoalComment.createdAt,
                updatedAt: clientGoalComment.updatedAt
            }))
    }

    //  EmployeeGoal comments

    //  Classic type

    @computed get employeeGoalClassicTypeCommentsArray() {
        return Array.from(this.employeeGoalClassicTypeCommentsRegistry.values())
    }

    @computed get employeeGoalClassicTypeCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.employeeGoalClassicTypeCommentsArray
            .map(employeeGoalComment => ({
                type: 'Tekuća obaveza',
                color: 'red',
                title: employeeGoalComment.employeeGoalName,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/employeeGoals/${employeeGoalComment.employeeGoalId}`,
                commentator: employeeGoalComment.user,
                category: getClientGoalColorSerbianText(employeeGoalComment.employeeGoalColor),
                content: employeeGoalComment.content,
                createdAt: employeeGoalComment.createdAt,
                updatedAt: employeeGoalComment.updatedAt
            }))
    }

    //  Problem type

    @computed get employeeGoalProblemTypeCommentsArray() {
        return Array.from(this.employeeGoalProblemTypeCommentsRegistry.values())
    }

    @computed get employeeGoalProblemTypeCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.employeeGoalProblemTypeCommentsArray
            .map(employeeGoalComment => ({
                type: 'Uočeni problem',
                color: 'violet',
                title: employeeGoalComment.employeeGoalName,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/employeeGoals/${employeeGoalComment.employeeGoalId}`,
                commentator: employeeGoalComment.user,
                category: getClientGoalColorSerbianText(employeeGoalComment.employeeGoalColor),
                content: employeeGoalComment.content,
                createdAt: employeeGoalComment.createdAt,
                updatedAt: employeeGoalComment.updatedAt
            }))
    }

    //  ClientTours notes

    @computed get clientToursArray() {
        return Array.from(this.clientToursRegistry.values())
    }

    @computed get clientToursAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.clientToursArray
            .map(clientTour => ({
                type: 'Kalendar obilazaka - napomena',
                color: 'olive',
                title: clientTour.title,
                client: clientTour.client,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/clientTours/${clientTour.id}`,
                content: clientTour.note,
                category: getClientTourStatusSerbianString(clientTour.status),
                createdAt: clientTour.noteUpdatedAt!,
                updatedAt: clientTour.noteUpdatedAt!
            }))
    }


    //  UserPosts comments

    @computed get userPostsCommentsArray() {
        return Array.from(this.userPostsCommentsRegistry.values())
    }

    @computed get userPostsCommentsAsCommentsSectionItemsArray(): ICommentSectionItem[] {
        return this.userPostsCommentsArray
            .map(userPostComment => ({
                type: 'Post',
                color: 'pink',
                title: userPostComment.userPostTitle,
                link: `/${this.rootStore.trafficsStore.currentTraffic?.id}/userPosts/${userPostComment.userPostId}`,
                commentator: userPostComment.commentator,
                category: 'Komentar',
                content: userPostComment.content,
                createdAt: userPostComment.createdAt,
                updatedAt: userPostComment.updatedAt
            }))
    }

    getArrayByItemCategory = (itemCategory: IItemCategory): ICommentSectionItem[] => {
        switch (itemCategory) { 
            case 'clientActivitiesContents':
                return this.clientActivitiesContentsAsCommentsSectionItemsArray
            case 'clientActivitiesObservations':
                return this.clientActivitiesObservationsAsCommentsSectionItemsArray
            case 'clientComments':
                return this.clientCommentsAsCommentsSectionItemsArray
            case 'clientGoalClassicTypeComments':
                return this.clientGoalClassicTypeCommentsAsCommentsSectionItemsArray
            case 'clientGoalPaymentTypeComments':
                return this.clientGoalPaymentTypeCommentsAsCommentsSectionItemsArray
            case 'employeeGoalClassicTypeComments':
                return this.employeeGoalClassicTypeCommentsAsCommentsSectionItemsArray
            case 'employeeGoalProblemTypeComments':
                return this.employeeGoalProblemTypeCommentsAsCommentsSectionItemsArray
            case 'clientTours':
                return this.clientToursAsCommentsSectionItemsArray
            case 'userPostsComments':
                return this.userPostsCommentsAsCommentsSectionItemsArray
        }
    }

    @computed get commentsArray(): ICommentSectionItem[] {

        let array: ICommentSectionItem[] = []
        
            if (this.categoryFilter.length) {
                this.categoryFilter.forEach(filter => {
                    array = [
                        ...array,
                        ...this.getArrayByItemCategory(filter)
                    ]
                })
            } else {
             array = [
                 ...this.clientActivitiesContentsAsCommentsSectionItemsArray,
                 ...this.clientActivitiesObservationsAsCommentsSectionItemsArray,
                 ...this.clientCommentsAsCommentsSectionItemsArray,
                 ...this.clientGoalClassicTypeCommentsAsCommentsSectionItemsArray,
                 ...this.clientGoalPaymentTypeCommentsAsCommentsSectionItemsArray,
                 ...this.employeeGoalClassicTypeCommentsAsCommentsSectionItemsArray,
                 ...this.employeeGoalProblemTypeCommentsAsCommentsSectionItemsArray,
                 ...this.clientToursAsCommentsSectionItemsArray,
                 ...this.userPostsCommentsAsCommentsSectionItemsArray
             ]   
            }
            
        return array.sort((a, b) => {
                return moment(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1
            })
    }

    //  Filters

    @action setCategoryFilters = (filters: IItemCategory[]) => {
        this.categoryFilter = filters
    }

    //  Set params

    @action setAllDateRangeParams = (startDate: string, endDate: string, commit = false) => {
        //  Start
        if (startDate) {
            this.clientActivitiesContentsPredicate.set(CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_START_DATE_PARAM, startDate)
            this.clientActivitiesObservationsPredicate.set(CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_START_DATE_PARAM, startDate)
            this.clientCommentsPredicate.set(CLIENT_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
            this.clientGoalClassicTypeCommentsPredicate.set(CLIENT_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
            this.clientGoalPaymentTypeCommentsPredicate.set(CLIENT_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
            this.employeeGoalClassicTypeCommentsPredicate.set(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
            this.employeeGoalProblemTypeCommentsPredicate.set(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
            this.clientToursPredicate.set(CLIENT_TOURS_NOTE_UPDATED_AT_START_DATE_PARAM, startDate)
            this.userPostsCommentsPredicate.set(USER_POST_COMMENTS_UPDATED_AT_START_DATE_PARAM, startDate)
        } else {
            this.clientActivitiesContentsPredicate.delete(CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_START_DATE_PARAM)
            this.clientActivitiesObservationsPredicate.delete(CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_START_DATE_PARAM)
            this.clientCommentsPredicate.delete(CLIENT_COMMENTS_UPDATED_AT_START_DATE_PARAM)
            this.clientGoalClassicTypeCommentsPredicate.delete(CLIENT_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM)
            this.clientGoalPaymentTypeCommentsPredicate.delete(CLIENT_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM)
            this.employeeGoalClassicTypeCommentsPredicate.delete(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM)
            this.employeeGoalProblemTypeCommentsPredicate.delete(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_START_DATE_PARAM)
            this.clientToursPredicate.delete(CLIENT_TOURS_NOTE_UPDATED_AT_START_DATE_PARAM)
            this.userPostsCommentsPredicate.delete(USER_POST_COMMENTS_UPDATED_AT_START_DATE_PARAM)
        }

        //  End
        if (endDate) {
            this.clientActivitiesContentsPredicate.set(CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_END_DATE_PARAM, endDate)
            this.clientActivitiesObservationsPredicate.set(CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_END_DATE_PARAM, endDate)
            this.clientCommentsPredicate.set(CLIENT_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
            this.clientGoalClassicTypeCommentsPredicate.set(CLIENT_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
            this.clientGoalPaymentTypeCommentsPredicate.set(CLIENT_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
            this.employeeGoalClassicTypeCommentsPredicate.set(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
            this.employeeGoalProblemTypeCommentsPredicate.set(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
            this.clientToursPredicate.set(CLIENT_TOURS_NOTE_UPDATED_AT_END_DATE_PARAM, endDate)
            this.userPostsCommentsPredicate.set(USER_POST_COMMENTS_UPDATED_AT_END_DATE_PARAM, endDate)
        } else {
            this.clientActivitiesContentsPredicate.delete(CLIENT_ACTIVITIES_CONTENT_UPDATED_AT_END_DATE_PARAM)
            this.clientActivitiesObservationsPredicate.delete(CLIENT_ACTIVITIES_OBSERVATION_UPDATED_AT_END_DATE_PARAM)
            this.clientCommentsPredicate.delete(CLIENT_COMMENTS_UPDATED_AT_END_DATE_PARAM)
            this.clientGoalClassicTypeCommentsPredicate.delete(CLIENT_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM)
            this.clientGoalPaymentTypeCommentsPredicate.delete(CLIENT_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM)
            this.employeeGoalClassicTypeCommentsPredicate.delete(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM)
            this.employeeGoalProblemTypeCommentsPredicate.delete(EMPLOYEE_GOAL_COMMENTS_UPDATED_AT_END_DATE_PARAM)
            this.clientToursPredicate.delete(CLIENT_TOURS_NOTE_UPDATED_AT_END_DATE_PARAM)
            this.userPostsCommentsPredicate.delete(USER_POST_COMMENTS_UPDATED_AT_END_DATE_PARAM)
        }

        //  Commit
        if (commit) {
            this.loadAll()
        }
    }

    //  Load

    @action loadClientActivitiesContents = async (requestId: string) => {
        try {
            const clientActivities = await agent.ClientActivities.list(this.clientActivitiesContentsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientActivitiesContentsRegistry.clear()
                    clientActivities.forEach(clientActivity => {
                        this.clientActivitiesContentsRegistry.set(clientActivity.id, clientActivity)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.clientActivitiesContentsRegistry.clear()
        }
    }

    @action loadClientActivitiesObservations = async (requestId: string) => {
        try {
            const clientActivities = await agent.ClientActivities.list(this.clientActivitiesObservationsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientActivitiesObservationsRegistry.clear()
                    clientActivities.forEach(clientActivity => {
                        this.clientActivitiesObservationsRegistry.set(clientActivity.id, clientActivity)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.clientActivitiesObservationsRegistry.clear()
        }
    }

    @action loadClientComments = async (requestId: string) => {
        try {
            const clientComments = await agent.ClientComments.list(this.clientCommentsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientCommentsRegistry.clear()
                    clientComments.forEach(clientComment => {
                        this.clientCommentsRegistry.set(clientComment.id, clientComment)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.clientCommentsRegistry.clear()
        }
    }

    @action loadClientGoalComments = async (type: ClientGoalType, requestId: string) => {
        try {
            const clientGoalComments = type === ClientGoalType.payment
                ? await agent.ClientGoalComments.list(this.clientGoalPaymentTypeCommentsAxiosParams)
                : await agent.ClientGoalComments.list(this.clientGoalClassicTypeCommentsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    switch (type) {
                        case ClientGoalType.payment:
                            this.clientGoalPaymentTypeCommentsRegistry.clear()
                            clientGoalComments.forEach(clientGoalComment => {
                                this.clientGoalPaymentTypeCommentsRegistry.set(clientGoalComment.id, clientGoalComment)
                            })
                            break
                        default:
                            this.clientGoalClassicTypeCommentsRegistry.clear()
                            clientGoalComments.forEach(clientGoalComment => {
                                this.clientGoalClassicTypeCommentsRegistry.set(clientGoalComment.id, clientGoalComment)
                            })
                            break
                    }
                }
            })
        } catch (error) {
            console.log(error)
            switch (type) {
                case ClientGoalType.payment:
                    this.clientGoalPaymentTypeCommentsRegistry.clear()
                    break
                default:
                    this.clientGoalClassicTypeCommentsRegistry.clear()
                    break
            }
        }
    }

    @action loadEmployeeClientGoalComments = async (type: EmployeeGoalType, requestId: string) => {
        try {
            const employeeGoalComments = type === EmployeeGoalType.problem
                ? await agent.EmployeeGoalComments.list(this.employeeGoalProblemTypeCommentsAxiosParams)
                : await agent.EmployeeGoalComments.list(this.employeeGoalClassicTypeCommentsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    switch (type) {
                        case EmployeeGoalType.problem:
                            this.employeeGoalProblemTypeCommentsRegistry.clear()
                            employeeGoalComments.forEach(employeeGoalComment => {
                                this.employeeGoalProblemTypeCommentsRegistry.set(employeeGoalComment.id, employeeGoalComment)
                            })
                            break
                        default:
                            this.employeeGoalClassicTypeCommentsRegistry.clear()
                            employeeGoalComments.forEach(employeeGoalComment => {
                                this.employeeGoalClassicTypeCommentsRegistry.set(employeeGoalComment.id, employeeGoalComment)
                            })
                            break
                    }

                }
            })
        } catch (error) {
            console.log(error)
            switch (type) {
                case EmployeeGoalType.problem:
                    this.employeeGoalProblemTypeCommentsRegistry.clear()
                    break
                default:
                    this.employeeGoalClassicTypeCommentsRegistry.clear()
                    break
            }


        }
    }

    @action loadClientTours = async (requestId: string) => {
        try {
            const clientTours = await agent.ClientTours.list(this.clientToursAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.clientToursRegistry.clear()
                    clientTours.forEach(clientTour => {
                        this.clientToursRegistry.set(clientTour.id, clientTour)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.clientToursRegistry.clear()
        }
    }

    @action loadUserPostsComments = async (requestId: string) => {
        try {
            const userPostsComments = await agent.UserPostsComments.list(this.userPostsCommentsAxiosParams)
            runInAction(() => {
                if (this.lastRequestId === requestId) {
                    this.userPostsCommentsRegistry.clear()
                    userPostsComments.forEach(userPostComment => {
                        this.userPostsCommentsRegistry.set(userPostComment.id, userPostComment)
                    })
                }
            })
        } catch (error) {
            console.log(error)
            this.userPostsCommentsRegistry.clear()
        }
    }

    @action loadAll = async () => {
        const requestId = uuid()
        this.lastRequestId = requestId

        this.loadingAll = true

        await Promise.all([
            this.loadClientActivitiesContents(requestId),
            this.loadClientActivitiesObservations(requestId),
            this.loadClientComments(requestId),
            this.loadClientGoalComments(ClientGoalType.classic, requestId),
            this.loadClientGoalComments(ClientGoalType.payment, requestId),
            this.loadEmployeeClientGoalComments(EmployeeGoalType.classic, requestId),
            this.loadEmployeeClientGoalComments(EmployeeGoalType.problem, requestId),
            this.loadClientTours(requestId),
            this.loadUserPostsComments(requestId)
        ])

        runInAction(() => {
            if (this.lastRequestId === requestId) {
                this.loadingAll = false
            }
        })
    }

}