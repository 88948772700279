import React, {FunctionComponent, useContext} from 'react';
import {Header, Icon, Item, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {IClient} from "../../../app/models/clients";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {clientStatusToColor} from "../../../app/common/clients/util";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";


interface IProps {
    client: IClient
    withRealization?: boolean
}

const ClientDetailedCell: FunctionComponent<IProps> = ({
                                                           client: {
                                                               name,
                                                               id,
                                                               status,
                                                               isInFocus,
                                                               category,
                                                               type,
                                                               regionName,
                                                               financialState,
                                                               pricelistCategoryIsSelling,
                                                               pricelistCategoryName,
                                                               regionId,
                                                               city
                                                           },
                                                           withRealization
                                                       }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return (<Item>
        {isInFocus && <Label
            color='violet' ribbon>Aktuelan</Label>}
        <Header color='teal'>
            <Link
                style={{
                    color: type === 'Aktivan'
                        ? clientStatusToColor(status!)
                        : semanticColorToHash('grey')
                }}
                to={`/${currentTraffic?.id}/clients/${id}`}>
                {name}
            </Link>
            <Header.Subheader>
                <div>{category}</div>
                <div>{type}</div>
                <div>
                    <Link
                        to={`/${currentTraffic?.id}/regions/${regionId}`}>
                        {regionName}
                    </Link>{city && `, ${city}`}
                </div>
                {type === 'Aktivan' && <div style={{color: clientStatusToColor(status!)}}>{status}</div>}
                {!pricelistCategoryIsSelling &&
                <div>
                    <Label style={{marginLeft: 0}} icon='dollar sign' size='mini' color={'olive'}>
                        <Icon name='dollar sign'/> {pricelistCategoryName}
                    </Label>
                </div>}
            </Header.Subheader>
        </Header>

        {/*{withRealization &&*/}
        {/*<div>*/}
        {/*    <ClientProgress value={financialState?.placed} total={financialState?.scheduleValue} />*/}
        {/*</div>}*/}
    </Item>);
};

export default observer(ClientDetailedCell)
