import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Form as FinalForm, Field} from "react-final-form";
import {combineValidators, composeValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import {v4 as uuid} from 'uuid';
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {
    GeneralExpenseFormValues, 
    IGeneralExpenseFormValues
} from "../../../../app/models/generalExpenses";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";
import TextInput from "../../../../app/common/form/TextInput";
import {isDecimal, isNumberGreaterThan} from "../../../../app/common/validators/general";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import DateInput from "../../../../app/common/form/DateInput";
import {useNavbarSelectedItem} from "../../../nav/utils/index.js";
import BreadcrumbNavigation from "../../../nav/BreadcrumbNavigation";



const validate = combineValidators({
    value: composeValidators(
        isRequired({message: 'Iznos je obavezan'}),
        isDecimal(2),
        isNumberGreaterThan(0)({message: 'Iznos mora biti veći od 0'})
    )(),
    name: isRequired({message: 'Naziv je obavezan'}),
    category: isRequired({message: 'Kategorija je obavezna'}),
    date: isRequired({message: 'Datum je obavezan'})
});

interface DetailParams {
    id: string;
}

const GeneralExpenseForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        submittingGeneralExpenses: submitting,
        loadGeneralExpense,
        createGeneralExpense,
        editGeneralExpense,
        loadGeneralExpensesCategories,
        generalExpensesCategories
    } = rootStore.expensesStore
    const {currentTraffic} = rootStore.trafficsStore

    const [generalExpense, setGeneralExpense] = useState<IGeneralExpenseFormValues>(new GeneralExpenseFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'generalExpensesCreate')
    
    useEffect(() => {
        loadGeneralExpensesCategories()
        
        if (match.params.id) {
            setLoading(true)
            loadGeneralExpense(match.params.id)
                .then(generalExpense => setGeneralExpense(new GeneralExpenseFormValues(generalExpense)))
                .finally(() => setLoading(false))

        } else {
            setGeneralExpense(new GeneralExpenseFormValues())
        }

    }, [
        match.params.id,
        loadGeneralExpense,
        loadGeneralExpensesCategories
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const generalExpense = {...values, value: Number(values.value)}

        if (!generalExpense.id) {
            let newGeneralExpense = {
                ...generalExpense,
                id: uuid()
            }

            return createGeneralExpense(newGeneralExpense)
        } else {

            return editGeneralExpense(generalExpense)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Opšti troškovi',
                            linkWithoutCurrentTrafficId: 'generalExpenses'
                        },
                        {
                            text: match.params.id ? 'Izmena opšteg troška' : 'Dodavanje opšteg troška',
                            active: true
                        }
                    ]} 
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: IGeneralExpenseFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={generalExpense}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading}>

                                       
                                       
                                       <Field
                                           name='name'
                                           placeholder='Naziv'
                                           value={generalExpense.name}
                                           component={TextInput}
                                       />

                                       <Field
                                           name='category'
                                           placeholder='Kategorija'
                                           value={generalExpense.category}
                                           suggestions={generalExpensesCategories}
                                           component={TextInput}
                                       />

                                       <Field
                                           name='note'
                                           placeholder='Napomena'
                                           value={generalExpense.note}
                                           suggestions={generalExpensesCategories}
                                           component={TextAreaInput}
                                       />

                                       <Field
                                           labelPosition='right'
                                           label={{basic: true, content: 'RSD'}}
                                           name='value'
                                           placeholder='Iznos'
                                           value={generalExpense.value}
                                           component={TextInput}/>

                                           <Field
                                               component={DateInput}
                                               name='date'
                                               date={true}
                                               placeholder='Date'
                                               value={generalExpense.date} />
                                       
                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!generalExpense.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={generalExpense.id ? () => history.push(`/${currentTraffic?.id}/generalExpenses`) : 
                                               () => history.push(`/${currentTraffic?.id}/generalExpenses`)}
                                           disabled={loading}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(GeneralExpenseForm)
