import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, composeValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import {IPaymentFormValues, PaymentFormValues} from "../../../app/models/payments";
import {isDecimal, isNumberGreaterThan} from "../../../app/common/validators/general";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import DateInput from "../../../app/common/form/DateInput";
import TextInput from "../../../app/common/form/TextInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    value: composeValidators(
        isRequired({message: 'Iznos je obavezan'}),
        isDecimal(2),
        isNumberGreaterThan(0)({message: 'Iznos mora biti pozitivan'})
    )(),
    clientId: isRequired({message: 'Klijent je obavezan'})
});

interface DetailParams {
    id: string;
}

const PaymentForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        createPayment,
        loadPayment,
        editPayment,
        submitting
    } = rootStore.paymentsStore
    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore

    const [payment, setPayment] = useState<IPaymentFormValues>(new PaymentFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'paymentCreate')
    
    useEffect(() => {
        if (match.params.id) {
            setLoading(true)
            loadPayment(match.params.id)
                .then(payment => setPayment(new PaymentFormValues(payment)))
                .finally(() => setLoading(false))

        } else {
            setPayment(new PaymentFormValues())
        }

        loadClients()
    }, [
        match.params.id,
        loadClients,
        loadPayment
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const payment = {...values, value: Number(values.value)}

        if (!payment.id) {
            let newPayment = {
                ...payment,
                id: uuid()
            }

            return createPayment(newPayment)
        } else {

            return editPayment(payment)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Uplate',
                            linkWithoutCurrentTrafficId: 'payments'
                        },
                        {
                            text: match.params.id ? 'Izmena uplate' : 'Dodavanje uplate',
                            active: true
                        }
                    ]} 
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: IPaymentFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={payment}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading || loadingClients}>

                                       <Field
                                           name='clientId'
                                           search
                                           placeholder='Klijent'
                                           value={payment.clientId}
                                           options={clients.map(client => ({
                                               key: client.id,
                                               value: client.id,
                                               text: client.name
                                           }))}
                                           component={SelectStringInput}/>
                                       <Field
                                           labelPosition='right'
                                           label={{basic: true, content: 'RSD'}}
                                           name='value'
                                           placeholder='Iznos'
                                           value={payment.value.toString()}
                                           component={TextInput}/>
                                       <Field
                                           component={DateInput}
                                           name='date'
                                           date={true}
                                           placeholder='Date'
                                           value={payment.date}
                                       />
                                       <Field
                                           name='note'
                                           placeholder='Napomena'
                                           value={payment.note}
                                           component={TextAreaInput}/>

                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!payment.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={() => history.push(`/${currentTraffic?.id}/payments`)}
                                           disabled={loading || loadingClients}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(PaymentForm)
