import React, {FunctionComponent, useContext} from 'react';
import {Segment, Header, Comment, Divider} from 'semantic-ui-react';
import {RootStoreContext} from '../../../app/stores/rootStore';
import {observer} from 'mobx-react-lite';
import {IClientSchedule} from "../../../app/models/clientSchedules";
import ClientScheduleCommentForm from "../form/ClientScheduleCommentForm";
import ClientScheduleSingleComment from "../commom/ClientScheduleSingleComment";

interface IProps {
    clientSchedule: IClientSchedule
}

const ClientScheduleDetailedChat: FunctionComponent<IProps> = ({clientSchedule}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        sortedComments,
    } = rootStore.clientScheduleStore
    

    return (
        <div>
            <Segment
                textAlign='center'
                attached='top'
                inverted
                color='blue'
                style={{border: 'none'}}
            >
                <Header>Komentari</Header>
            </Segment>
            <Segment>
                <Comment.Group>

                    <ClientScheduleCommentForm clientScheduleId={clientSchedule.id}/>

                    <Divider hidden/>

                    {sortedComments && sortedComments.map((comment) => (
                        <ClientScheduleSingleComment key={comment.id} comment={comment}/>
                    ))}
                </Comment.Group>
            </Segment>
        </div>
    );
};

export default observer(ClientScheduleDetailedChat);