import {IClient} from "./clients";
import {IUser} from "./user";
import moment from "moment";
import {IBill} from "./bills";

export const isClientOrderComplete = (clientOrder: IClientOrder) => 
    clientOrder.billCreated &&  clientOrder.deliveredDate && !clientOrder.correction && !clientOrder.postExpress

export enum ClientOrderCompleteness
{
    All = 0,
    Completed = 1,
    Uncompleted = 2
}

export interface IClientOrdersEnvelope {
    clientOrders: IClientOrder[];
    clientOrdersCount: number;
}

export interface IClientOrder {
    id: string
    number: number
    orderNumber: string
    bill?: IBill
    date: Date
    client: IClient
    text: string
    note: string
    createdBy: IUser
    deliveredDate?: Date
    billCreated: boolean
    createdAt: Date
    updatedAt: Date
    correction: boolean
    postExpress: boolean
    hasContentDocument: boolean
    hasBillDocument: boolean
    
}

export interface IClientOrderFormValues {
    id: string
    billId: string | undefined
    billIdString: string
    date: string
    clientName: string
    text: string
    note: string
    deliveredDate?: string
    billCreated: boolean
    newClientId: string
    correction: boolean
    postExpress: boolean
    hasContentDocument: boolean
    hasBillDocument: boolean

    editedDocument: string
}

export class ClientOrderFormValues implements IClientOrderFormValues {
    constructor(clientOrder?: IClientOrder) {
        if (clientOrder) {
            this.id = clientOrder.id
            this.billId = clientOrder.bill?.id
            this.billIdString = clientOrder.bill?.idString ?? ''
            this.date = moment(clientOrder.date).format('YYYY-MM-DD')
            this.clientName = clientOrder.client.name!
            this.text = clientOrder.text
            this.note = clientOrder.note
            this.deliveredDate = clientOrder.deliveredDate ? moment(clientOrder.deliveredDate).format('YYYY-MM-DD') : ''
            this.billCreated = clientOrder.billCreated
            this.correction = clientOrder.correction
            this.postExpress = clientOrder.postExpress
            
            this.hasBillDocument = clientOrder.hasBillDocument
            this.hasContentDocument = clientOrder.hasContentDocument
        }
    }
    
    id: string = ''
    billId: string | undefined = undefined
    billIdString = ''
    date = moment().format('YYYY-MM-DD')
    clientName: string = ''
    text: string = ''
    note: string =''
    deliveredDate: string = ''
    billCreated = false
    correction = false
    postExpress = false
    newClientId = ''
    hasBillDocument = false
    hasContentDocument = false
    editedDocument = ''
}
