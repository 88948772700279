import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {Header, List, Checkbox, Grid, Button, Icon, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";

interface IProps {
    clientId: string
}

const ClientDetailsBrands: FunctionComponent<IProps> = ({ clientId }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        brandsArray,
        addBrandToClient,
        addingBrandClientIds,
        removeBrandToClient,
        removingBrandClientIds,
        generateUsedBrandsForSingleClient,
        generatingUsedBrands,
        loadingBrandsUsedByClient,
        loadBrandsUsedByClient
    } = rootStore.brandsStore
    const {client} = rootStore.clientsStore
    const { isEmployee } = rootStore.userStore

    useEffect(() => {
        loadBrandsUsedByClient(clientId)
    }, [
        loadBrandsUsedByClient,
        clientId
    ])
    
    return (
        <Grid as={Segment} loading={loadingBrandsUsedByClient}>
            <Grid.Column computer={8} tablet={10} mobile={16}>
                {isEmployee &&
                <Button
                    disabled={generatingUsedBrands}
                    onClick={() => generateUsedBrandsForSingleClient(client?.id!)}
                    color='blue'
                    icon
                    labelPosition='left'
                >
                    <Icon
                        name='sync'
                        loading={generatingUsedBrands}
                    />
                    Sinhronizuj
                </Button>}
                <List divided verticalAlign='middle'>
                    {brandsArray.map(brand => <List.Item key={brand.id}>
                        {isEmployee &&
                        <List.Content floated='right'>
                            <Checkbox
                                disabled={(
                                    ((addingBrandClientIds !== null) && (addingBrandClientIds[0] === brand.id)) ||
                                    ((removingBrandClientIds !== null) && (removingBrandClientIds[0] === brand.id))
                                ) || generatingUsedBrands}
                                toggle
                                checked={brand.used}
                                onClick={((event, data) => data.checked ?
                                    addBrandToClient(brand.id, client!.id!) :
                                    removeBrandToClient(brand.id, client!.id!))}
                            />
                        </List.Content>}
                        <List.Content>
                            <Header
                                disabled={!brand.used}
                                style={{color: brand.used ? brand.color : null}}
                                content={brand.name}/>
                        </List.Content>
                    </List.Item>)}
                </List>
            </Grid.Column>
        </Grid>
    )

}


export default observer(ClientDetailsBrands)
