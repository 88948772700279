import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../app/stores/rootStore";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {Header, Segment, Tab} from "semantic-ui-react";
import ProductsList from "../products/list/ProductsList";
import ClientsList from "../clients/list/ClientsList";
import {getNiceColorFromStingAsciiSum} from "../../app/common/util/colors";

interface DetailParams {
    id: string;
}

const panes = [
    { menuItem: 'Proizvodi', render: () => <Tab.Pane><ProductsList /></Tab.Pane> },
    { menuItem: 'Klijenti', render: () => <Tab.Pane><ClientsList hideBalance /></Tab.Pane> },
]

const RegionDetails: FunctionComponent<RouteComponentProps<DetailParams>> = ({match}) => {
    const rootStore = useContext(RootStoreContext)
    const {brand, loadBrand} = rootStore.brandsStore
    // const {setPredicate, clearPredicate} = rootStore.productsStore
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        loadBrand(Number(match.params.id))
            .finally(() => setLoading(false))
        // setPredicate('byBrand', match.params.id)
    }, [loadBrand, match.params.id])

    if (loading)
        return <LoadingComponent content='Učitavanje brenda'/>

    return (
        <Segment>
            <Header as='h1' style={{color: brand!.color ?? getNiceColorFromStingAsciiSum(brand!.name)}} content={brand!.name}/>
            <Tab panes={panes} />
        </Segment>
    )
};

export default observer(RegionDetails)

