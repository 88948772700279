import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Grid, Loader} from "semantic-ui-react";
import InfiniteScroll from 'react-infinite-scroller';
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ClientOrdersFilters from "../../../clientOrders/dashboard/ClientOrdersFilters";
import ClientOrdersList from "../../../clientOrders/list/ClientOrdersList";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {ClientOrderCompleteness} from "../../../../app/models/clientsOrders";

interface IProps {
    clientId: string
}

const ClientOrdersDashboard: FunctionComponent<IProps> = ({ clientId }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClientOrders,
        loading,
        setPage,
        page,
        totalPages,
        setCompletenessFilter,
        setClientIdFilter
    } = rootStore.clientOrdersStore

    

    const [loadingNext, setLoadingNext] = useState(false);

    useEffect(() => {
        
        setClientIdFilter(clientId)
        setCompletenessFilter(ClientOrderCompleteness.Uncompleted)
        loadClientOrders()
        
        return () => {
            setClientIdFilter(null)    
        }
        
    }, [
        setClientIdFilter,
        setCompletenessFilter,
        loadClientOrders,
        clientId
    ])
    
    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadClientOrders().then(() => setLoadingNext(false));
    };
    

    if (loading && page === 0)
        return <LoadingComponent content='Učitavanje porudžbina'/>;

    return (
        <Grid>
            <Grid.Column width={16}>
                <ClientOrdersFilters hideClient vertical={false}/>
            </Grid.Column>
            <Grid.Column width={16}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handleGetNext}
                    hasMore={!loadingNext && page + 1 < totalPages}
                    initialLoad={false}
                >
                    <ClientOrdersList/>
                </InfiniteScroll>
            </Grid.Column>
            <Grid.Column computer={10} tablet={12} mobile={16}>
                <Loader active={loadingNext}/>
            </Grid.Column>
        </Grid>

    )
}

export default observer(ClientOrdersDashboard)