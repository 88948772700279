import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
// for selectable
import {observer} from "mobx-react-lite"
import {RootStoreContext} from "../../../app/stores/rootStore"
import moment from "moment"
import {Button, Checkbox, Grid, Header, Icon, Label, Modal, Segment} from "semantic-ui-react"
import {
    ClientTourStatus,
    getClientTourStatusColorHash, getClientTourStatusColorName,
    getClientTourStatusSerbianString,
    IClientTour
} from "../../../app/models/clientTours"
import ClientTourDetails from "../details/ClientTourDetails";
import {DATE_SERIALIZABLE_FORMAT_STRING, DATE_TIME_SERIALIZABLE_FORMAT_STRING} from "../../../app/common/util/date";
import ClientTourForm from "../form/ClientTourForm";

import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import {TZDate, ISchedule, IGridDateModel, IMonthDayNameInfo, ITemplateConfig} from "tui-calendar";
import {useResponsive} from "../../../app/common/responsive/Responsive";
import {toast} from "react-toastify";

function getTimeTemplate(schedule: ISchedule, isAllDay: boolean) {
    var html = [];
    
    const start = moment((schedule.start as TZDate).toUTCString())
    // const end = moment((schedule.end as TZDate).toUTCString())
    
    if (isAllDay) {
        if (schedule.location) {
            html.push(`<span style="color: white" class="calendar-font-icon ic-location-b">${schedule.location}</span>`);
        }
        html.push(`<strong style="color: white"> , ${(schedule.raw!.tour as IClientTour).client.name} </strong>`)
        html.push(`<strong style="color: white"> , ${schedule.title}</strong>`)
    } else {
        html.push('<strong>' + start.format('HH:mm') + '</strong> ');
        if (schedule.location) {
            html.push(`<span class="calendar-font-icon ic-location-b">, ${schedule.location}</span>`);
        }
        html.push(`<strong>, ${(schedule.raw!.tour as IClientTour).client.name}</strong>`)
        html.push(', ' + schedule.title);
    }

    return html.join('');
}

function getGridTitleTemplate(type: string) {
    var title = '';

    switch (type) {
        case 'milestone':
            title = '<span class="tui-full-calendar-left-content">MILESTONE</span>';
            break;
        case 'task':
            title = '<span class="tui-full-calendar-left-content">TASK</span>';
            break;
        case 'allday':
            title = '<span class="tui-full-calendar-left-content">CEO DAN</span>';
            break;
    }

    return title;
}

function getGridCategoryTemplate(category: string, schedule: ISchedule) {
    var tpl;

    switch (category) {
        case 'milestone':
            tpl = '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + schedule.bgColor + '">' + schedule.title + '</span>';
            break;
        case 'task':
            tpl = '#' + schedule.title;
            break;
        case 'allday':
            tpl = getTimeTemplate(schedule, true);
            break;
    }

    return tpl;
}

const tuiTemplates: ITemplateConfig = {
    milestone: function (schedule: ISchedule) {
        return getGridCategoryTemplate('milestone', schedule) ?? '';
    },
    milestoneTitle: function () {
        return getGridTitleTemplate('milestone');
    },
    task: function (schedule: ISchedule) {
        return getGridCategoryTemplate('task', schedule) ?? '';
    },
    taskTitle: function () {
        return getGridTitleTemplate('task');
    },
    allday: function (schedule: ISchedule) {
        return getTimeTemplate(schedule, true);
    },
    alldayTitle: function () {
        return getGridTitleTemplate('allday');
    },
    time: function (schedule: ISchedule) {
        return getTimeTemplate(schedule, false);
    },
    goingDuration: function (schedule: ISchedule) {
        return '<span class="calendar-icon ic-travel-time"></span>' + schedule.goingDuration + 'min.';
    },
    comingDuration: function (schedule: ISchedule) {
        return '<span class="calendar-icon ic-travel-time"></span>' + schedule.comingDuration + 'min.';
    },
    monthMoreTitleDate: function (date: string, dayname: string) {
        var day = date.split('.')[2];
        return '<span class="tui-full-calendar-month-more-title-day">' + day + '</span> <span class="tui-full-calendar-month-more-title-day-label">' + dayname + '</span>';
    },
    monthMoreClose: function () {
        return '<span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span>';
    },
    monthGridHeader: function (dayModel: IGridDateModel) {
        var date = parseInt(dayModel.date.split('-')[2], 10);
        var classNames = ['tui-full-calendar-weekday-grid-date '];

        if (dayModel.isToday) {
            classNames.push('tui-full-calendar-weekday-grid-date-decorator');
        }

        return '<span class="' + classNames.join(' ') + '">' + date + '</span>';
    },
    monthGridHeaderExceed: function (hiddenSchedules: number) {
        return '<span class="weekday-grid-more-schedules">+' + hiddenSchedules + '</span>';
    },
    monthGridFooter: function () {
        return '';
    },
    monthGridFooterExceed: function (hiddenSchedules: number) {
        return '';
    },
    monthDayname: function (model: IMonthDayNameInfo) {
        return String(model.label).toLocaleUpperCase();
    },
    dayGridTitle: function (viewName: string) {
        /*
         * use another functions instead of 'dayGridTitle'
         * milestoneTitle: function() {...}
         * taskTitle: function() {...}
         * alldayTitle: function() {...}
        */

        return getGridTitleTemplate(viewName);
    },
    schedule: function (schedule: ISchedule) {
        /*
         * use another functions instead of 'schedule'
         * milestone: function() {...}
         * task: function() {...}
         * allday: function() {...}
        */

        return getGridCategoryTemplate(schedule.category!, schedule) ?? ''
    }
};


const MONTHLY_CUSTOM_THEME = {
    // month header 'dayname'
    'month.dayname.height': '42px',
    'month.dayname.borderLeft': 'none',
    'month.dayname.paddingLeft': '8px',
    'month.dayname.paddingRight': '0',
    'month.dayname.fontSize': '13px',
    'month.dayname.backgroundColor': 'inherit',
    'month.dayname.fontWeight': 'normal',
    'month.dayname.textAlign': 'left',

    // month day grid cell 'day'
    'month.holidayExceptThisMonth.color': '#f3acac',
    'month.dayExceptThisMonth.color': '#bbb',
    'month.weekend.backgroundColor': '#fafafa',
    'month.day.fontSize': '16px',

    // month schedule style
    'month.schedule.borderRadius': '5px',
    'month.schedule.height': '18px',
    'month.schedule.marginTop': '2px',
    'month.schedule.marginLeft': '10px',
    'month.schedule.marginRight': '10px',

    // month more view
    'month.moreView.boxShadow': 'none',
    'month.moreView.paddingBottom': '0',
    'month.moreView.border': '1px solid #9a935a',
    'month.moreView.backgroundColor': '#ffffff',
    'month.moreViewTitle.height': '28px',
    'month.moreViewTitle.marginBottom': '0',
    'month.moreViewTitle.backgroundColor': '#9f9f9f',
    'month.moreViewTitle.borderBottom': '1px solid #ddd',
    'month.moreViewTitle.padding': '0 10px',
    'month.moreViewList.padding': '10px'
}

const daynames = [
    'Ned',
    'Pon',
    'Uto',
    'Sre',
    'Čet',
    'Pet',
    'Sub'
]

const DEFAULT_START_DATETIME = moment().startOf('month').subtract(6, 'days').format('YYYY-MM-DD')
const DEFAULT_END_DATETIME = moment().endOf('month').add(6, 'days').format('YYYY-MM-DD')

interface OwnProps {
}

type Props = OwnProps

interface IClientTourModalOptions {
    initialStartDateTime?: Date
    initialEndDateTime?: Date
}

interface IClientTourModalWithOptions {
    open: boolean
    options: IClientTourModalOptions
}


const ClientToursCalendar: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loading,
        clientToursArray: clientTours,
        setDateTimePredicate,
        updateClientTour,
        deleteClientTour
    } = rootStore.clientToursStore

    const [addTour, setAddTour] = useState<IClientTourModalWithOptions>({open: false, options: {}})
    const [calendarEditEnabled, setCalendarEditEnabled] = useState(false)
    const [editTour, setEditTour] = useState<IClientTour | null>(null)
    const [detailsTour, setDetailsTour] = useState<IClientTour | null>(null)

    const [currentView, setCurrentView] = useState<'month' | 'day' | 'week'>('month')
    const [currentDate, setCurrentDate] = useState<{start: string, end: string}>({
        start: DEFAULT_START_DATETIME, 
        end: DEFAULT_END_DATETIME
    })

    const {isMobile, isTablet} = useResponsive()

    const calendarRef = React.createRef<Calendar>();   
        
    const getDateRangeFromCalendar = () => {
        const calendarInstance = calendarRef!.current!.getInstance();
        
        const start = moment(calendarInstance.getDateRangeStart().toDate()).format(DATE_SERIALIZABLE_FORMAT_STRING)
        const end = moment(calendarInstance.getDateRangeEnd().toDate()).format(DATE_SERIALIZABLE_FORMAT_STRING)

        setCurrentDate({start, end})
    }
    
    // fetch
    useEffect(() => {
        setDateTimePredicate(currentDate.start, 'start')
        setDateTimePredicate(currentDate.end, 'end', true)
    }, [
        currentDate,
        setDateTimePredicate,
    ])
    
    
    useEffect(() => {
        getDateRangeFromCalendar()
    },
        // eslint-disable-next-line
        [
        currentView,
    ])
    
    const handleClickNextButton = () => {
        const calendarInstance = calendarRef!.current!.getInstance();
        calendarInstance.next();

        getDateRangeFromCalendar()
    }

    const handleClickPrevButton = () => {
        const calendarInstance = calendarRef!.current!.getInstance();
        calendarInstance.prev();

        getDateRangeFromCalendar()
    }

    const handleClickTodayButton = () => {
        const calendarInstance = calendarRef!.current!.getInstance();
        calendarInstance.today()

        getDateRangeFromCalendar()
    }

    return <Grid as={Segment} style={{marginTop: 0}}>
        <Grid.Column computer={5} tablet={16} mobile={16}>
            <Checkbox
                slider
                checked={calendarEditEnabled}
                onChange={(event, data) => setCalendarEditEnabled(data.checked!)}
                label='Prevuci & Otpusti'
            />
        </Grid.Column>
        <Grid.Column
            computer={6}
            tablet={16}
            mobile={16}
            textAlign='center'
            style={{paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20}}
        >
            {Object.values(ClientTourStatus).filter(key => !isNaN(Number(key)))     // Hacking :D
                .map(status => <Label
                    key={status}
                    size='mini'
                    color={getClientTourStatusColorName(status as ClientTourStatus)}
                    content={getClientTourStatusSerbianString(status as ClientTourStatus)}/>)}
        </Grid.Column>
        <Grid.Column
            computer={5}
            tablet={16}
            mobile={16}
            style={{paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20}}
        >
            <Modal
                size='tiny'
                onClose={() => setAddTour({open: false, options: {}})}
                onOpen={() => setAddTour({...addTour, open: true})}
                open={addTour.open}
                trigger={<Button
                    fluid={isMobile || isTablet}
                    floated='right'
                    icon
                    labelPosition='left'
                    color='blue'
                >
                    <Icon name='add'/>
                    Dodaj obilazak</Button>}
                dimmer='blurring'
            >
                <Modal.Header>
                    <Header
                        as='h2'
                        color='blue'
                        content='Dodavanje obilaska'
                    />
                </Modal.Header>

                <Modal.Content scrolling>
                    <ClientTourForm
                        initialStartDateTime={addTour.options.initialStartDateTime}
                        initialEndDateTime={addTour.options.initialEndDateTime}
                        onCancelCallback={() => {
                            setAddTour({open: false, options: {}})
                        }}
                        onSubmitSuccess={() => {
                            setAddTour({open: false, options: {}})
                        }}
                    />
                </Modal.Content>
            </Modal>
        </Grid.Column>
        <Grid.Column
            computer={5}
            tablet={16}
            mobile={16}
            style={{paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20}}>
            <Button.Group size='small' fluid={isMobile || isTablet}>
                <Button
                    color='blue'
                    labelPosition='left'
                    icon='left chevron'
                    content='Prethodni'
                    onClick={handleClickPrevButton}
                />
                <Button
                    onClick={handleClickTodayButton}
                    content='Danas'/>
                <Button
                    color='blue'
                    labelPosition='right'
                    icon='right chevron'
                    content='Sledeći'
                    onClick={handleClickNextButton}
                />
            </Button.Group>
        </Grid.Column>
        <Grid.Column
            computer={6}
            tablet={16}
            mobile={16}
            textAlign='center'
            style={{paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20}}
        >
            <Button.Group fluid={isMobile || isTablet} size='small'>
                <Button
                    color={currentView === 'month' ? 'blue' : undefined}
                    onClick={() => setCurrentView('month')}
                >
                    Mesec
                </Button>
                <Button
                    color={currentView === 'week' ? 'blue' : undefined}
                    onClick={() => setCurrentView('week')}
                >
                    Nedelja
                </Button>
                <Button
                    color={currentView === 'day' ? 'blue' : undefined}
                    onClick={() => setCurrentView('day')}
                >
                    Dan
                </Button>
            </Button.Group>
        </Grid.Column>
        <Grid.Column
            computer={5}
            tablet={16}
            mobile={16}
            style={{paddingTop: 5, paddingBottom: 5, paddingRight: 20, paddingLeft: 20}}
            textAlign={isMobile ? 'center' : 'right'}
        >
            <Header
                as='h3'
                color='grey'
                content={currentView === 'month'
                    ? moment(currentDate.start).add('10', 'days').format('MMMM YYYY.').toUpperCase()
                    : currentView === 'week'
                        ? `${moment(currentDate.start).format('l')} - ${moment(currentDate.end).format('l')}`
                        : moment(currentDate.start).format('dddd DD.MM.YY.').toUpperCase()}
            />
        </Grid.Column>

        <Grid.Column width={16}>
            <Segment basic loading={loading}>

                {/*edit modal*/}
                <Modal
                    size='tiny'
                    dimmer='blurring'
                    open={editTour !== null}
                    onClose={() => setEditTour(null)}
                    // onOpen={() => setEditTour(true)}
                >
                    <Modal.Content scrolling>
                        <ClientTourForm
                            clientTour={editTour!}
                            onSubmitSuccess={() => {
                                setEditTour(null)
                                setDetailsTour(null)
                                toast.success('Obilazak uspešno izmenjen')
                            }}
                            onCancelCallback={() => {
                                setEditTour(null)
                            }}
                        />
                    </Modal.Content>
                </Modal>

                {/*details modal*/}
                <Modal
                    size='tiny'
                    dimmer='blurring'
                    open={detailsTour !== null}
                    onClose={() => setDetailsTour(null)}
                    // onOpen={() => setDetailsMode(true)}
                >
                    <Modal.Content
                        scrolling
                        as={ClientTourDetails}
                        clientTour={detailsTour}
                        onCancelClick={() => setDetailsTour(null)}
                        onEditClick={() => {
                            setEditTour(detailsTour)
                        }}
                        onDeleteSuccess={() => {
                            setDetailsTour(null)
                        }}
                    />
                </Modal>

                <Calendar
                    ref={calendarRef}
                    taskView={false}
                    
                    height='900px'
                    view={currentView}

                    theme={MONTHLY_CUSTOM_THEME}
                    schedules={clientTours.map(tour => ({
                        id: tour.id,
                        calendarId: '0',
                        title: tour.title,
                        category: 'time',
                        dueDateClass: '',
                        start: tour.startDateTime,
                        end: tour.endDateTime,
                        bgColor: getClientTourStatusColorHash(tour.status),
                        location: tour.location ?? undefined,
                        color: currentView === 'month' ? getClientTourStatusColorHash(tour.status) : 'white',
                        raw: {
                            tour,
                            loading: tour.flags.updating
                        },
                        isReadOnly: tour.flags.updating || tour.flags.deleting || loading || !calendarEditEnabled,
                        borderColor: getClientTourStatusColorHash(tour.status),
                        dragBgColor: 'whitesmoke'
                    }))}
                    disableDblClick={true}
                    disableClick={false}
                    // isReadOnly={loading || !calendarEditEnabled}
                    month={{
                        startDayOfWeek: 1,
                        visibleScheduleCount: 5,
                        daynames

                    }}
                    week={{
                        startDayOfWeek: 1,
                        daynames
                    }}
                    scheduleView
                    template={tuiTemplates}

                    onBeforeCreateSchedule={eventObj => {
                        // console.log('onBeforeCreateSchedule', eventObj)

                        const start = (eventObj.start as TZDate).toDate()
                        const end = (eventObj.end as TZDate).toDate()

                        setAddTour({
                            open: true, options: {
                                initialStartDateTime: start,
                                initialEndDateTime: end
                                // initialEndDateTime: new Date(moment(eventObj.end as Date).subtract(1, 'day').format('YYYY-MM-DD'))
                            }
                        })
                    }}

                    onBeforeDeleteSchedule={eventObj => {
                        const tour = eventObj.schedule.raw!.tour as IClientTour

                        deleteClientTour(tour.id)
                    }}

                    onBeforeUpdateSchedule={eventObj => {
                        // console.log('onBeforeUpdateSchedule', eventObj)
                        const tour = eventObj.schedule.raw?.tour as IClientTour


                        if (eventObj.triggerEventName && eventObj.triggerEventName === 'click') {
                            if (tour) {
                                setEditTour(tour)
                            }
                        } else {
                            const momentStartChange = moment((eventObj.changes?.start as TZDate).toUTCString())
                            const momentEndChange = moment((eventObj.changes?.end as TZDate).toUTCString())

                            if (!(momentStartChange.isSame(tour.startDateTime) && momentEndChange.isSame(tour.endDateTime))) {
                                updateClientTour({
                                    id: (eventObj.schedule.raw!.tour as IClientTour).id,
                                    startDateTime: moment(eventObj.start.toUTCString()).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING),
                                    endDateTime: moment(eventObj.end.toUTCString()).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING),
                                })
                            }

                        }

                    }}

                    // onClickDayname={eventObj => {
                    //     console.log('onClickDayname', eventObj)
                    // }}
                    // onClickMore={eventObj => {
                    //     console.log('onClickMore', eventObj)
                    // }}
                    onClickSchedule={eventObj => {
                        // console.log('onClickSchedule', eventObj)

                        const tour = eventObj.schedule.raw?.tour
                        if (tour) {
                            setDetailsTour(tour as IClientTour)
                        }
                    }}
                    // onClickTimezonesCollapseBtn={eventObj => {
                    //     console.log('onClickTimezonesCollapseBtn', eventObj)
                    // }}

                />


                {/*<FullCalendar*/}
                {/*    // themeSystem='bootstrap'*/}
                {/*    locale={SR}*/}
                {/*    plugins={[*/}
                {/*        dayGridPlugin,*/}
                {/*        // timeGridPlugin,*/}
                {/*        interactionPlugin*/}
                {/*    ]}*/}
                {/*    // headerToolbar={{*/}
                {/*    //     right: 'prev,next today',*/}
                {/*    //     center: 'title'*/}
                {/*    // }}*/}
                {/*    eventResizableFromStart*/}
                {/*    editable={calendarEditEnabled}*/}
                {/*    initialView="dayGridMonth"*/}
                {/*    eventBackgroundColor='transparent'*/}
                {/*    eventColor='transparent'*/}
                {/*    events={clientTours.map(tour => ({*/}
                {/*                id: tour.id,*/}
                {/*                start: tour.startDateTime,*/}
                {/*                end: tour.endDateTime,*/}
                {/*                title: tour.client.name ?? 'N/A',*/}
                {/*                extendedProps: {*/}
                {/*                    clientTour: tour*/}
                {/*                },*/}
                {/*                color: getClientTourStatusColorHash(tour.status),*/}
                {/*                // editable: calendarEditEnabled,*/}
                {/*                // allDay: true,*/}
                {/*            })*/}
                {/*        )*/}
                {/*    }*/}
                {/*    selectable*/}

                {/*    // eventContent={hookProps => {*/}
                {/*    //    */}
                {/*    //     return <ClientToursCalendarEvent*/}
                {/*    //         allowEdit={calendarEditEnabled}*/}
                {/*    //         loading={(hookProps.event.extendedProps.clientTour as IClientTour)?.flags?.updating}*/}
                {/*    //         key={(hookProps.event.extendedProps.clientTour as IClientTour).id}*/}
                {/*    //         clientTourSingleEvent={hookProps.event}/>*/}
                {/*    // }}*/}

                {/*    select={arg => {*/}
                {/*        setOpen({*/}
                {/*            open: true, options: {*/}
                {/*                initialStartDateTime: arg.start,*/}
                {/*                initialEndDateTime: arg.end*/}
                {/*                // initialEndDateTime: new Date(moment(arg.end).subtract(1, 'day').format('YYYY-MM-DD'))*/}
                {/*            }*/}
                {/*        })*/}
                {/*    }}*/}

                {/*    datesSet={arg => {*/}
                {/*        setDateTimePredicate(moment(arg.start).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING), 'start')*/}
                {/*        setDateTimePredicate(moment(arg.end).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING), 'end', true)*/}
                {/*    }}*/}

                {/*    eventChange={arg => updateClientTour({*/}
                {/*        id: arg.event.extendedProps.clientTour.id,*/}
                {/*        startDateTime: moment(arg.event.start).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING),*/}
                {/*        endDateTime: moment(arg.event.end).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING),*/}
                {/*    }).then(() => {*/}
                {/*        arg.event.remove() // because duplicates*/}
                {/*    })}*/}
                {/*/>*/}
            </Segment>
        </Grid.Column>
    </Grid>

}

export default observer(ClientToursCalendar)
