import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {IDocumentation} from "../../../app/models/documentations";
import {Cell, FilterProps} from "react-table";
import {Link} from "react-router-dom";
import {Button, Header, Icon, Message, Popup, Segment} from "semantic-ui-react";
import {stringPreview} from "../../../app/common/util/string";
import {convertStringToState} from "../../../app/common/util/draft-js";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";
import DocumentationDocumentForm from "../form/DocumentationDocumentForm";

interface IProps {
    trackingId?: string
}

const DocumentationsList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {
        documentationsArray: documentations,
        deleteDocumentation,
        documentationDeletingId,
        loadDocumentationCategories,
        documentationCategories,
        getFile,
        downloadingDocumentId,
        currentFolderId
    } = rootStore.documentationsStore


    const [addingDocumentDocumentationId, setAddingDocumentDocumentationId] = useState('')

    useEffect(() => {
        loadDocumentationCategories(currentFolderId, true)
    }, [
        loadDocumentationCategories,
        currentFolderId
    ])

    const columns = useMemo(() => [
            {
                Header: 'Naziv',
                accessor: 'name',
                cellWidth: 3,
                Cell: ({row: {original: documentation}}: Cell<IDocumentation>) => <><Header size={'small'} as={Link}
                                                                                            to={`/${currentTraffic?.id}/documentations/${documentation.id}`}>{documentation.name}
                </Header>
                    {documentation.note &&
                    <>{' '}<Popup content={documentation.note} trigger={<Icon color='grey' name='info circle'/>} /></>}</>,

            },
            {
                Header: 'Kategorija',
                accessor: 'category',
                cellWidth: 3,
                Filter: (d: FilterProps<any>) =>
                    documentationCategories ?
                        MultiselectFilter(d, documentationCategories.map(category => ({
                            key: category,
                            value: category,
                            text: category
                        })), 'Kategorija') :
                        <span>
                            Učitavanje kategorija...
                        </span>,
                filter: 'multiselectFilter'
            },
            {
                Header: 'Opis',
                accessor: 'description',
                Cell: ({row: {original: documentation}}: Cell<IDocumentation>) =>
                    <p>{stringPreview(convertStringToState(documentation.description).getCurrentContent().getPlainText(), 2000)}</p>
            },
            {
                Header: 'Dokumenta',
                id: 'documents',
                cellWidth: 1,
                Cell: ({row: {original: documentation}}: Cell<IDocumentation>) => <Button.Group vertical>
                    {addingDocumentDocumentationId === documentation.id ?
                        <DocumentationDocumentForm
                            documentation={documentation}
                            onCancel={() => setAddingDocumentDocumentationId('')}
                            onUploadSuccess={() => setAddingDocumentDocumentationId('')}
                        /> :
                        <Button
                            size='mini'
                            icon
                            labelPosition='left'
                            color='blue'
                            onClick={() => setAddingDocumentDocumentationId(documentation.id)}
                        >
                            Dodaj dokument
                            <Icon name='add'/>
                        </Button>}

                    {documentation.documents.length > 0 &&
                    <Button
                        loading={documentation.documents[documentation.documents.length - 1].id === downloadingDocumentId}
                        onClick={() => getFile(documentation.documents[documentation.documents.length - 1].id)}
                        size='mini'
                        icon
                        labelPosition='left'
                        floated='right'>
                        <Icon name='download'/>
                        Poslednji dokument:
                        {documentation.documents[documentation.documents.length - 1].name}
                        {' '}({documentation.documents[documentation.documents.length - 1].file?.extension.replace('.', '').toUpperCase() ?? 'N/A'})
                    </Button>}
                </Button.Group>
            },
            // {
            //     Header: 'Datum',
            //     accessor: 'date',
            //     id: 'date',
            //     Filter: DateFilter,
            //     filter: 'dateFilter',
            //     Cell: ({row: {original: documentation}}: Cell<IDocumentation>) => <>{moment(documentation.date).format('l')}</>
            // },
            {
                Header: 'Kontrole',
                cellWidth: 1,
                Cell: ({row: {original: documentation}}: Cell<IDocumentation>) => (
                    <Button.Group>
                        <Button as={Link} to={`/${currentTraffic?.id}/documentations/manage/${documentation.id}/edit`}
                                icon='edit'
                                color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje dokumentacije
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš dokumentaciju <strong>{documentation.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteDocumentation(documentation.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={documentationDeletingId === documentation.id}/>
                    </Button.Group>)
            },
        ],
        [
            openModal,
            closeModal,
            currentTraffic,
            deleteDocumentation,
            documentationDeletingId,
            documentationCategories,
            addingDocumentDocumentationId,
            downloadingDocumentId,
            getFile
        ])

    if (documentations.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoji dokumentacija po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            data={documentations}
            columns={columns}
        />
    );
}


export default observer(DocumentationsList)
