import React from 'react';
import { Tab } from 'semantic-ui-react';
import ProfilePhotos from './ProfilePhotos';
import ProfileDescription from './ProfileDescription';
import Responsive from "../../../app/common/responsive/Responsive";

const panes = [
    { menuItem: 'Opis', render: () => <ProfileDescription /> },
    { menuItem: 'Fotografije', render: () => <ProfilePhotos /> }
];

interface IProps {
    setActiveTab: (activeIndex: any) => void;
}

const ProfileContent: React.FC<IProps> = ({setActiveTab}) => {
    
    const tabara = (vertical?: boolean) => {
        return (
            <Tab
                menu={{ fluid: true, vertical: vertical }}
                menuPosition='right'
                panes={panes}
                onTabChange={(e, data) => setActiveTab(data.activeIndex)}
            />
        )    
    }
    
    return <>
            <Responsive.Mobile>
                {tabara(false)}
            </Responsive.Mobile>
        <Responsive.NotMobile>
            {tabara(true)}
        </Responsive.NotMobile>
        </>
    
    
};

export default ProfileContent;