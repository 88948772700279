import React, {FunctionComponent, useContext} from 'react'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import CommentSectionItem from "../item/CommentSectionItem";

interface OwnProps {}

type Props = OwnProps

const CommentsSectionList: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { commentsArray: comments } = rootStore.commentsSectionStore
    
  return <div>
      {comments.map(comment => <CommentSectionItem commentSectionItem={comment} />)}
  </div>
}

export default observer(CommentsSectionList)
