import React, {FunctionComponent} from 'react'
import ScheduleSectionMenu from "../menu/ScheduleSectionMenu"
import ChildMenuLayout from "../../../app/layout/ChildMenuLayout";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
    // contentLoading?: boolean,
    header?: React.ReactNode,

}

const ScheduleSectionLayout: FunctionComponent<IProps> = ({
                                                              children,
                                                              // contentLoading,
                                                              header
                                                          }) => {


    return (<>
            <BreadcrumbNavigation
                items={[
                    {
                        text: 'Plan rada',
                        active: true
                    }
                ]}
            />

            <ChildMenuLayout
                // contentLoading={contentLoading}
                header={header}
                menuItems={<ScheduleSectionMenu 
                    // loading={contentLoading ?? false}
                />}>
                {children}
            </ChildMenuLayout>
        </>
    )
}

export default ScheduleSectionLayout
