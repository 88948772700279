import React, {FunctionComponent, useContext} from 'react';
import MyPieChart from "../../../../../app/common/charts/MyPieChart";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import {formatRsd} from "../../../../../app/common/util/format";
import {summator} from "../../../../../app/common/util/array";
import {
    profitabilityIncomeGroupByBrandListTableTrackingId,
    profitabilityIncomeGroupByClientCategoryTableTrackingId
} from "../../income/ProfitabilityDashboardIncomeSection";

interface IProps {
}

const ProfitabilityDashboardIncomeDiagram: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore
    const {incomeView} = rootStore.profitabilityStore


    const filteredRows = incomeView === 'brand' ?
        SHIT_getFilteredRowsById(profitabilityIncomeGroupByBrandListTableTrackingId) ?? [] :
        incomeView === 'clientCategory' ?
            SHIT_getFilteredRowsById(profitabilityIncomeGroupByClientCategoryTableTrackingId) ?? [] :
        []

    const colors = incomeView === 'brand' ? 
        filteredRows.map(row => row.brand.color) :
        incomeView === 'clientCategory' ?
            filteredRows.map(row => row.clientCategoryColor) :
        []
    
    const yAccessor = incomeView === 'brand' ? 'brand.name' :
        incomeView === 'clientCategory' ? 'clientCategory' : ''
        
    return (<>
        <MyPieChart
            data={filteredRows}
            colors={colors}
            xAccessor={yAccessor}
            yAccessor='summaryPriceWithDiscountWithBillDiscount'
            title={[
                'Prihodi',
                formatRsd(summator(filteredRows, 'summaryPriceWithDiscountWithBillDiscount'))]}/>
    </>);
};

export default observer(ProfitabilityDashboardIncomeDiagram)
