import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {RootStoreContext} from "../../../app/stores/rootStore";
import ProductConsumptionsList from "../list/ProductConsumptionsList";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {PdfService} from "../../../app/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import moment from "moment";
import {formatDateOrDefault} from "../../../app/common/util/date";
import {IProductConsumption} from "../../../app/models/productConsumptions";
import {formatRsd} from "../../../app/common/util/format";
import ProductConsumptionsAnalysisDashboard from "../analysis/ProductConsumptionsAnalysisDashboard";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {DatePicker} from "antd";

export const PRODUCT_CONSUMPTIONS_TRACKING_ID = 'productConsumptions'

interface IProps {
}

const ProductConsumptionsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadProductConsumptions,
        loading,
        setPredicate,
        clearPredicate,
        analysisMode,
        setAnalysisMode
    } = rootStore.productConsumptionsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    useNavbarSelectedItem('productConsumptions')

    useEffect(() => {
        clearPredicate()
        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)
        loadProductConsumptions()

        return () => {
            removeFromRegistry(PRODUCT_CONSUMPTIONS_TRACKING_ID)
        }
    }, [
        loadProductConsumptions,
        setPredicate,
        clearPredicate,
        startDate,
        endDate,
        removeFromRegistry
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(
            `Tabela rashoda proizvoda za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`,
            25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Vrednost', 'Kategorija', 'Datum']
            ],
            body: SHIT_getFilteredRowsById(PRODUCT_CONSUMPTIONS_TRACKING_ID)
                ?.map((productConsumption: IProductConsumption, index) => ([
                    index + 1,
                    formatRsd(productConsumption.value),
                    productConsumption.category,
                    moment(productConsumption.date).format('l')
                ])) ?? []
        })

        doc.save("RashodiProizvoda.pdf")
    }

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Rashodi proizvoda',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/productConsumptions/create`}
                    floated='right'
                    color='blue'
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj rashod
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment.Group>
                    <Segment clearing color='blue' attached='top'>

                        <DatePicker.RangePicker
                            disabled={loading}
                            placeholder={['Početni datum', 'Krajni datum']}
                            format='DD.MM.YYYY.'
                            onChange={(date, dateString) => {
                                setStartDate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '')
                                setEndDate(date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')

                            }}
                            value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                        />
                        
                        {/*<Input*/}
                        {/*    label={{basic: true, content: 'Od'}}*/}
                        {/*    type='date'*/}
                        {/*    value={startDate}*/}
                        {/*    onChange={((event, data) => setStartDate(data.value))}*/}
                        {/*/>*/}
                        {/*<Input*/}
                        {/*    label={{basic: true, content: 'Do'}}*/}
                        {/*    type='date'*/}
                        {/*    value={endDate}*/}
                        {/*    onChange={((event, data) => setEndDate(data.value))}*/}
                        {/*/>*/}
                        <Button
                            disabled={loading}
                            icon
                            labelPosition='left'
                            floated='right'
                            color='teal'
                            onClick={() => exportPdf()}>
                            <Icon name='file pdf'/>
                            PDF
                        </Button>
                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <ProductConsumptionsList trackingId={PRODUCT_CONSUMPTIONS_TRACKING_ID}/>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column textAlign='center'>
                {!analysisMode &&
                <Button
                    onClick={() => setAnalysisMode(!analysisMode)}
                    icon
                    color='blue'
                    labelPosition='left'>
                    <Icon name='chart line'/>
                    Analiza...
                </Button>}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                {analysisMode &&
                <ProductConsumptionsAnalysisDashboard
                    startDate={startDate}
                    endDate={endDate}/>}
            </Grid.Column>
        </Grid.Row>
    </Grid>);
};

export default observer(ProductConsumptionsDashboard)
