import React, {FunctionComponent, useContext} from 'react';
import {Header, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {IClient} from "../../../app/models/clients";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {clientStatusToColorName} from "../../../app/common/clients/util";

interface IProps {
    client: IClient,
    size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge'
}

const ClientTableBasicCell: FunctionComponent<IProps> = ({
                                                             client: {
                                                                 name,
                                                                 id,
                                                                 isInFocus,
                                                                 status,
                                                                 type
                                                             },
                                                             size
                                                         }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return (<>
        {isInFocus && <Label
            size={size}
            color='violet' ribbon>Aktuelan</Label>}<Link
        to={`/${currentTraffic?.id}/clients/${id}`}>
        <Header
            size={size}
            color={type === 'Aktivan' ? clientStatusToColorName(status!) : 'grey'}>
            {name}
        </Header>
    </Link>
    </>);
};

ClientTableBasicCell.defaultProps = {
    size: 'medium'
}

export default observer(ClientTableBasicCell)
