import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react'
import {EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {Button, Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {convertStateToString, convertStringToState} from "../../../app/common/util/draft-js";
import {ClientOrderFormValues, IClientOrder, IClientOrderFormValues} from "../../../app/models/clientsOrders";
import {v4 as uuid} from 'uuid'
import {Field, Form as FinalForm} from "react-final-form";
import {FORM_ERROR} from "final-form";
import TextInput from "../../../app/common/form/TextInput";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import DateInput from "../../../app/common/form/DateInput";
import {combineValidators, isRequired} from "revalidate";
import WysiwygEditor from "../../wysiwygEditor/WysiwygEditor";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js"
import {
    signalRHubErrorMessageToFormErrorObject
} from "../../../app/common/signalR";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


interface DetailParams {
    id: string
}

const validate = combineValidators({
    clientName: isRequired({message: 'Klijent je obavezan'}),
    date: isRequired({message: 'Datum je obavezan'}),
});

const ClientOrderForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadClientOrder,
        createClientOrder,
        editClientOrder,
        submitting,
        loadClientOrderDoc,
        loadingContentDocId,
        loadingBillDocId
    } = rootStore.clientOrdersStore
    const {
        loadClients,
        clientsArray: clients,
        loading: loadingClients
    } = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadSmallBills,
        smallBillsLoading,
        smallBills,
        clearSmallBillsPredicate,
        setSmallBillsPredicate
    } = rootStore.billsStore

    const [contentFile, setContentFile] = useState<Blob | null>(null)
    const [billFile, setBillFile] = useState<Blob | null>(null)

    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty()
    )

    const [clientOrder, setClientOrder] = useState<IClientOrderFormValues>(new ClientOrderFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'clientOrdersCreate')

    const contentFileInputRef = useRef<HTMLInputElement>(null)
    const billFileInputRef = useRef<HTMLInputElement>(null)


    useEffect(() => {
        loadClients(true)
        clearSmallBillsPredicate()
        setSmallBillsPredicate('linkedWithClientOrder', 'excluded')
        loadSmallBills()
        if (match.params.id) {
            setLoading(true)
            loadClientOrder(match.params.id)
                .then((clientOrder: IClientOrder) => {
                    setEditorState(convertStringToState(clientOrder!.text))
                    setClientOrder(new ClientOrderFormValues(clientOrder))
                })
                .finally(() => setLoading(false))
        } else {
            setClientOrder(new ClientOrderFormValues())
        }

    }, [
        match.params.id,
        loadClientOrder,
        loadClients,
        loadSmallBills,
        setSmallBillsPredicate,
        clearSmallBillsPredicate
    ])


    const handleFileUpload = (event: any, type: 'bill' | 'content') => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            if (type === 'bill') {
                setBillFile(file)
            } else {
                setContentFile(file)
            }
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        let clientOrder: IClientOrderFormValues = {
            ...values,
            text: convertStateToString(editorState),
            newClientId: uuid(),
            deliveredDate: values.deliveredDate ? values.deliveredDate : undefined
        }

        if (match.params.id) {
            return editClientOrder(clientOrder, billFile, contentFile)
        } else {
            return createClientOrder({...clientOrder, id: uuid()}, billFile, contentFile)
        }
    }


    return (
        <Grid centered>

                <Grid.Column width={16}>
                    <BreadcrumbNavigation 
                        items={[
                            {
                                text: 'Porudžbine',
                                linkWithoutCurrentTrafficId: 'clientOrders'
                            },
                            {
                                text: match.params.id ? 'Izmena narudžbine' : 'Nova narudžbina',
                                active: true
                            }
                        ]} 
                    />
                </Grid.Column>
            
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IClientOrderFormValues) => handleFinalFormSubmit(values).catch(error => {
                                return ({
                                    [FORM_ERROR]: signalRHubErrorMessageToFormErrorObject(error)
                                })
                            }
                        )}
                        validate={validate}
                        initialValues={clientOrder}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form: {
                                         change
                                     },
                                     values
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingClients || smallBillsLoading}>

                                <Field
                                    suggestions={clients.map(client => client.name)}
                                    name='clientName'
                                    placeholder='Klijent'
                                    value={clientOrder.clientName}
                                    component={TextInput}/>

                                <Field
                                    name='billId'
                                    search
                                    clearable
                                    options={match.params.id ? [
                                            {
                                                key: clientOrder.billId,
                                                value: clientOrder.billId,
                                                text: clientOrder.billIdString
                                            },
                                            ...smallBills.map(bill => ({
                                                key: bill.id,
                                                value: bill.id,
                                                text: bill.idString
                                            }))]
                                        : smallBills.map(bill => ({
                                            key: bill.id,
                                            value: bill.id,
                                            text: bill.idString
                                        }))}
                                    placeholder='Račun'
                                    value={clientOrder.billId}
                                    component={SelectStringInput}/>

                                {/*     Hidden fields   */}
                                <Field
                                    name='hasBillDocument'
                                    component='input'
                                    type='hidden'
                                />
                                <Field
                                    name='hasContentDocument'
                                    component='input'
                                    type='hidden'
                                />
                                <Field
                                    name='editedDocument'
                                    component='input'
                                    type='hidden'
                                />

                                <Form.Field>
                                    <label>Račun dokument</label>
                                    <Button.Group>

                                        <Button
                                            type='button'
                                            color={billFile !== null ? 'green' : undefined}
                                            content={values.hasBillDocument ? 'Otpremi novi dokument' : 'Otpremi dokument'}
                                            labelPosition="left"
                                            icon="file"
                                            onClick={() => billFileInputRef!.current!.click()}
                                        />

                                        {billFile &&
                                        <Button icon='x'
                                                onClick={() => {
                                                    change('hasBillDocument', false)
                                                    setBillFile(null)
                                                }}
                                        />}

                                        {values.hasBillDocument && billFile === null &&
                                        <>
                                            <Button
                                                type='button'
                                                icon='eye'
                                                color='blue'
                                                content='Pogledaj'
                                                loading={loadingBillDocId === clientOrder.id}
                                                onClick={() => loadClientOrderDoc(clientOrder.id, 'bill')}
                                            />
                                            <Button icon='x'
                                                    onClick={() => {
                                                        change('hasBillDocument', false)
                                                        setBillFile(null)
                                                    }}
                                            />
                                        </>}

                                    </Button.Group>

                                    <input
                                        ref={billFileInputRef}
                                        type="file"
                                        hidden
                                        onChange={event => {
                                            handleFileUpload(event, 'bill')
                                            change('hasBillDocument', true)
                                            change('editedDocument', uuid())
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Datum</label>
                                    <Field
                                        component={DateInput}
                                        name='date'
                                        date={true}
                                        placeholder='Datum'
                                        value={clientOrder.date}/>
                                </Form.Field>

                                <Field
                                    name='note'
                                    placeholder='Napomena'
                                    value={clientOrder.note}
                                    component={TextInput}
                                />

                                <Form.Group widths='equal'>

                                    <Form.Field>
                                        <label>Datum otpreme</label>
                                        <Field
                                            name='deliveredDate'
                                            placeholder='Datum otpreme'
                                            value={clientOrder.deliveredDate}
                                            component={DateInput}
                                            parse={value => {
                                                
                                                if (!value) {
                                                    change('postExpress', false)
                                                }
                                                
                                                return value
                                            }}
                                        />    
                                    </Form.Field>
                                    
                                    <Form.Field>
                                        <label style={{color: 'transparent'}}>.</label> {/*Hack*/}
                                        <Field
                                            name='billCreated'
                                            render={() => <Form.Field>
                                                <Checkbox
                                                    toggle
                                                    label='Napravljen račun'
                                                    checked={values.billCreated}
                                                    onChange={(event, data) => {
                                                        const isChecked: boolean = data.checked!
                                                        // setBillCreatedUIstate(isChecked)
                                                        change('billCreated', isChecked)
                                                    }}/>
                                            </Form.Field>}/>    
                                    </Form.Field>
                                    
                                </Form.Group>

                                <Form.Group widths='equal'>
                                                                        
                                    <Field
                                        name='correction'
                                        render={() => <Form.Field>
                                            <Checkbox
                                                toggle
                                                label='Korekcija'
                                                checked={values.correction}
                                                onChange={(event, data) => {
                                                    const isChecked: boolean = data.checked!
                                                    // setCorrectionUIstate(isChecked)
                                                    change('correction', isChecked)
                                                }}/>
                                        </Form.Field>}/>

                                    {values.deliveredDate &&
                                    <Field
                                        name='postExpress'
                                        render={() => <Form.Field>
                                            <Checkbox
                                                toggle
                                                label='Post express'
                                                checked={values.postExpress}
                                                onChange={(event, data) => {
                                                    const isChecked: boolean = data.checked!
                                                    change('postExpress', isChecked)
                                                }}/>
                                        </Form.Field>}/>}

                                </Form.Group>

                                <Form.Field>
                                    <label>Sadržaj dokument</label>
                                    <Button.Group>

                                        <Button
                                            type='button'
                                            color={contentFile !== null ? 'green' : undefined}
                                            content={values.hasContentDocument ? 'Otpremi novi dokument' : 'Otpremi dokument'}
                                            labelPosition="left"
                                            icon="file"
                                            onClick={() => contentFileInputRef!.current!.click()}
                                        />

                                        {contentFile &&
                                        <Button icon='x'
                                                onClick={() => {
                                                    change('hasContentDocument', false)
                                                    setContentFile(null)
                                                }}
                                        />}

                                        {values.hasContentDocument && contentFile === null &&
                                        <>
                                            <Button
                                                type='button'
                                                icon='eye'
                                                color='blue'
                                                content='Pogledaj'
                                                loading={loadingContentDocId === clientOrder.id}
                                                onClick={() => loadClientOrderDoc(clientOrder.id, 'content')}
                                            />
                                            <Button icon='x'
                                                    onClick={() => {
                                                        change('hasContentDocument', false)
                                                        setContentFile(null)
                                                    }}
                                            />
                                        </>}

                                    </Button.Group>

                                    <input
                                        ref={contentFileInputRef}
                                        type="file"
                                        hidden
                                        onChange={event => {
                                            handleFileUpload(event, 'content')
                                            change('hasContentDocument', true)
                                            change('editedDocument', uuid())
                                        }}
                                    />
                                </Form.Field>

                                <Field
                                    name='text'
                                    render={() =>
                                        <WysiwygEditor
                                            editorState={editorState}
                                            setEditorState={setEditorState}
                                            onChangeCallback={() => change('text', convertStateToString(editorState))}/>}
                                />
                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!clientOrder.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={clientOrder.id ?
                                        () => history.push(`/${currentTraffic?.id}/clientOrders/${clientOrder.id}`) :
                                        () => history.push(`/${currentTraffic?.id}/clientOrders`)}
                                    disabled={loading || loadingClients}
                                    floated='right'
                                    type='button'
                                    content='Odustani'
                                />
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default observer(ClientOrderForm)
