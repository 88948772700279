import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {Button, Form, Grid, Icon, List, Segment, Select} from "semantic-ui-react";
import {FORM_ERROR} from "final-form";
import arrayMutators from "final-form-arrays";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {FieldArray} from "react-final-form-arrays";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, isRequiredIf} from "revalidate";
import {Avatar} from "antd";
import ShowMoreButton from "../../commom/buttons/ShowMoreButton";
import UserIdentity from "../../users/commom/UserIdentity";
import {
    ClientActivityFormValues,
    IClientActivity,
    IClientActivityFormValues
} from "../../../app/models/clientActivities";

interface OwnProps {
    clientActivity: IClientActivity
}

interface IFormParticipants {
    participants: string[],
    mainParticipant?: string
}

type Props = OwnProps

const validate = combineValidators({
    //@ts-ignore
    mainParticipant: isRequiredIf((values: IClientActivityFormValues) => values.participants.length > 1)({message: 'Izaberi glavnog učesnika'}),
})

const ClientActivityParticipantsIdentity: FunctionComponent<Props> = ({clientActivity}) => {
    const rootStore = useContext(RootStoreContext)
    const {editClientActivity} = rootStore.clientActivitiesStore
    //loadUsers from parent
    const {employeesArray: employees, loadingUsers} = rootStore.usersStore

    const [editMode, setEditMode] = useState(false)

    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])
    const [clientActivityFormValues, setClientActivityFormValues] = useState<IClientActivityFormValues>(new ClientActivityFormValues(clientActivity))
    const [showEditIcon, setShowEditIcon] = useState(false)


    useEffect(() => {
        setSelectedParticipants(clientActivity.participants.map(p => p.user.id))
        setClientActivityFormValues(new ClientActivityFormValues(clientActivity))
    }, [
        clientActivity,
        editMode,
        setEditMode
    ])

    const handleFinalFormSubmit = (values: any) => {
        const clientActivity: IClientActivityFormValues = {
            ...values,
            date: values.date ? values.date : null,
            completedDate: values.completedDate ? values.completedDate : null
        }

        return editClientActivity(clientActivity)
            .then(() => setEditMode(false))
    }

    if (editMode) {

        return <Segment clearing>
            <FinalForm
                onSubmit={(values: IFormParticipants) => handleFinalFormSubmit(values).catch(error => ({
                        [FORM_ERROR]: error
                    })
                )}
                mutators={{...arrayMutators}}
                validate={validate}
                initialValues={clientActivityFormValues}
                render={({
                             handleSubmit,
                             invalid,
                             pristine,
                             submitError,
                             dirtySinceLastSubmit,
                             form
                         }) => (
                    <Form size='mini' onSubmit={handleSubmit}
                          loading={loadingUsers}>

                        <Form.Field>
                            <label>Učesnici</label>
                            <FieldArray name='participants'>
                                {({fields, meta}) => {

                                    return <Select
                                        value={selectedParticipants}
                                        multiple
                                        clearable
                                        onChange={((event, data) => {
                                            setSelectedParticipants(data.value as string[])
                                            form.change('participants', data.value as string[])

                                            // todo little hack, try to do this in validator
                                            const mainParticipantFromForm = form.getState().values.mainParticipant
                                            const dataValues = data.value as string[]
                                            if (mainParticipantFromForm && !dataValues.includes(mainParticipantFromForm)) {
                                                form.change('mainParticipant', '')
                                            }
                                        })}
                                        options={employees.map(user => ({
                                            key: user.id,
                                            value: user.id,
                                            text: `${user.displayName} [${user.email}]`
                                        }))}
                                    />
                                }
                                }
                            </FieldArray>
                        </Form.Field>

                        {selectedParticipants.length > 1 &&
                        <Form.Field>
                            <label>Glavni učesnik</label>
                            <Field
                                name='mainParticipant'
                                value={clientActivityFormValues.mainParticipant}
                                component={SelectStringInput}
                                options={employees
                                    .filter(user => selectedParticipants.includes(user.id))
                                    .map(user => ({
                                        key: user.id,
                                        value: user.id,
                                        text: `${user.displayName} [${user.email}]`
                                    }))}
                            />
                        </Form.Field>}

                        {(submitError && !dirtySinceLastSubmit) &&
                        <ErrorMessage error={submitError}/>}
                        <Button
                            size='mini'
                            // TODO loading={clientActivity.flags?.updating ?? false}
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                            floated='right'
                            color='blue'
                            type='submit'
                            content={'Primeni'}
                        />
                        <Button
                            size='mini'
                            onClick={() => setEditMode(false)}
                            // disabled={loading || loadingClients}
                            floated='right'
                            type='button'
                            content='Odustani'/>
                    </Form>
                )}
            />

        </Segment>
    }

    if (clientActivity.participants.length === 0)
        return <Grid textAlign='center'>
            <Grid.Column>
                <ShowMoreButton content='Dodaj učesnike' onClick={() => setEditMode(true)} />
            </Grid.Column>
        </Grid>

    return <List
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
    >
        {showEditIcon && <List.Header>
            <Grid textAlign='right'>
                <Grid.Column width={16}>
                    <Icon
                        link
                        name='pencil alternate'
                        onClick={() => setEditMode(true)}
                        color='orange'/>
                </Grid.Column>
            </Grid>
        </List.Header>}

        <Avatar.Group
            maxCount={4}
            maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}
        >
            {clientActivity.participants
                .map(participant => <UserIdentity key={participant.id} user={participant.user} />)}
        </Avatar.Group>
    </List>
}

export default observer(ClientActivityParticipantsIdentity)
