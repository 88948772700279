import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, composeValidators, isRequired, isRequiredIf} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import NumberInput from "../../../app/common/form/NumberInput";
import DateInput from "../../../app/common/form/DateInput";
import {isNumberGreaterThan} from "../../../app/common/validators/general";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import {IRequiredProductFormValues, RequiredProductFormValues} from "../../../app/models/requiredProducts";

interface DetailParams {
    id: string;
}

const validate = combineValidators({
    //@ts-ignore
    productId: isRequiredIf((values: IRequiredProductFormValues) => !values.isOther)({message: 'Prozivod je obavezan'}),
    //@ts-ignore
    otherContent: isRequiredIf((values: IRequiredProductFormValues) => values.isOther)({message: 'Opis nestandardnog proizvoda je obavezan'}),
    count: composeValidators(
        isRequired({message: 'Količina je obavezana'}),
        isNumberGreaterThan(0)({message: 'Količina mora biti pozitivan broj'})
    )(),
    date: isRequired({message: 'Datum je obavezan'}),
})

const RequiredProductForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadRequiredProduct,
        addRequiredProduct,
        editRequiredProduct,
        submitting
    } = rootStore.requiredProductsStore
    const {productsByNameArray: products, loadProducts, loading: loadingProducts} = rootStore.productsStore

    const [requiredProduct, setRequiredProduct] = useState<IRequiredProductFormValues>(new RequiredProductFormValues())
    const [loading, setLoading] = useState(false)

    const [isOtherUiState, setIsOtherUiState] = useState(false)

    useNavbarSelectedItem(match.params.id ? 'requiredProductsEdit' : 'requiredProductsAdd')

    useEffect(() => {
        loadProducts()
        if (match.params.id) {
            setLoading(true)
            loadRequiredProduct(match.params.id)
                .then(requiredProduct => {
                    setRequiredProduct(new RequiredProductFormValues(requiredProduct))
                    setIsOtherUiState(requiredProduct.isOther)
                })
                .finally(() => setLoading(false))
        } else {
            setRequiredProduct(new RequiredProductFormValues())
        }

    }, [
        match.params.id,
        currentTraffic,
        loadRequiredProduct,
        loadProducts,
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const requiredProduct= {...values, count: Number(values.count)} as IRequiredProductFormValues

        if (requiredProduct.isOther) {
            requiredProduct.productId = uuid()
        }

        if (!requiredProduct.id) {
            let newRequiredProduct = {
                ...requiredProduct,
                id: uuid()
            }

            return addRequiredProduct(newRequiredProduct)
        } else {
            return editRequiredProduct(requiredProduct)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Traženi proizvodi',
                            linkWithoutCurrentTrafficId: 'requiredProducts'
                        },
                        {
                            text: match.params.id ? 'Izmeni traženi proizvod' : 'Dodaj traženi proizvod',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IRequiredProductFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        validate={validate}
                        initialValues={requiredProduct}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading  || loadingProducts}>
                                
                                <Field
                                    name='isOther'
                                    type='checkbox'
                                    render={() => <Form.Field>
                                        <Checkbox
                                            toggle
                                            label='Nestandardan proizvod'
                                            checked={isOtherUiState}
                                            onChange={(event, data) => {
                                                const isChecked: boolean = data.checked!
                                                setIsOtherUiState(isChecked)
                                                form.change<'isOther'>('isOther', isChecked)
                                            }}/>
                                    </Form.Field>}/>

                                {isOtherUiState ?
                                    <Field
                                        name='otherContent'
                                        placeholder='Opis nestandardnnog proizvoda'
                                        value={requiredProduct.otherContent}
                                        component={TextAreaInput}/> :

                                    <Field
                                        search
                                        name='productId'
                                        placeholder='Proizvod'
                                        value={requiredProduct.productId}
                                        options={products.map(product => ({
                                            key: product.id,
                                            value: product.id,
                                            text: `[${product.brandName}] ${product.name} - ${product.sku}`
                                        }))}
                                        component={SelectStringInput}/>}

                                <Field
                                    name='count'
                                    placeholder='Količina'
                                    value={requiredProduct.count}
                                    component={NumberInput}
                                    min={1}
                                    max={1000000}
                                    type='number'/>

                                <Field
                                    component={DateInput}
                                    name='date'
                                    date={true}
                                    placeholder='Datum'
                                    value={requiredProduct.date}
                                />

                                <Field
                                    name='note'
                                    placeholder='Napomena'
                                    value={requiredProduct.note}
                                    component={TextInput}/>

                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!requiredProduct.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={() => history.push(`/${currentTraffic?.id}/requiredProducts`)}
                                    disabled={loading || loadingProducts}
                                    floated='right'
                                    type='button'
                                    content='Odustani'
                                />
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(RequiredProductForm)
