import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {Button, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import TrafficsList from "../lists/TrafficsList";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface IProps {
}

const TrafficsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {loadTraffics, loading, currentTraffic} = rootStore.trafficsStore

    useEffect(() => {
        loadTraffics()
    }, [
        loadTraffics
    ])

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Prometi',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/traffics/create`}
                    floated='right'
                    color='blue'
                    icon
                    labelPosition='left'>
                    <Icon name='add'/>
                    Dodaj promet
                </Button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Segment attached='bottom' loading={loading}>
                    <TrafficsList/>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>)
}

export default observer(TrafficsDashboard)
