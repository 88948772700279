import React, {FunctionComponent, useContext, useState} from 'react'
import {EmployeeGoalStatus, IEmployeeGoal} from "../../../app/models/employeeGoals";
import {Button, Divider, Form, Popup, Segment, TextArea} from "semantic-ui-react";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    employeeGoal: IEmployeeGoal,
    fluid?: boolean
}

type Props = OwnProps

const EmployeeGoalMet: FunctionComponent<Props> = ({employeeGoal, fluid}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        setEmployeeGoalStatus,
        metingId,
        unmetingId
    } = rootStore.employeeGoalsStore

    const [statusForm, setStatusForm] = useState<{ active: boolean, status: EmployeeGoalStatus.partlyMet | EmployeeGoalStatus.failed | null, note: string }>({
        active: false,
        status: null,
        note: ''
    })

    return statusForm.active ?
        <Segment clearing>
            <Form>
                <Form.Field>
                    <TextArea
                        placeholder='Obrazloženje...'
                        value={statusForm.note}
                        onChange={((event, data) => setStatusForm(prevState => ({
                            ...prevState,
                            note: data.value as string
                        })))}
                    />

                    <Divider hidden/>

                    <Button
                        disabled={!statusForm.note}
                        color='blue'
                        floated='right'
                        loading={employeeGoal.id === metingId}
                        onClick={() => setEmployeeGoalStatus({
                            id: employeeGoal.id,
                            status: statusForm.status!,
                            statusNote: statusForm.note
                        })
                            .then(() => setStatusForm({active: false, status: null, note: ''}))}
                    >
                        Primeni
                    </Button>
                    <Button
                        onClick={() => setStatusForm({active: false, status: null, note: ''})}
                        floated='right'>
                        Odustani
                    </Button>
                </Form.Field>
            </Form>
        </Segment> :
        <Button.Group size='mini' fluid={fluid}>
            <Button
                disabled={metingId === employeeGoal.id || unmetingId === employeeGoal.id}
                color={employeeGoal.status === EmployeeGoalStatus.inProgress ? 'blue' : undefined}
                onClick={() => (employeeGoal.status !== EmployeeGoalStatus.inProgress) && setEmployeeGoalStatus({
                    id: employeeGoal.id,
                    status: EmployeeGoalStatus.inProgress,
                    statusNote: ''
                })}
            >U toku</Button>

            <Button
                disabled={metingId === employeeGoal.id || unmetingId === employeeGoal.id}
                color={employeeGoal.status === EmployeeGoalStatus.met ? 'green' : undefined}
                onClick={() => employeeGoal.status !== EmployeeGoalStatus.met &&
                    setEmployeeGoalStatus({
                        id: employeeGoal.id,
                        status: EmployeeGoalStatus.met,
                        statusNote: ''
                    })}
            >Ispunjen</Button>

            <Popup
                disabled={employeeGoal.status !== EmployeeGoalStatus.partlyMet}
                header='Obrazloženje'
                content={employeeGoal.statusNote}
                trigger={<Button
                    onClick={() => employeeGoal.status !== EmployeeGoalStatus.partlyMet && setStatusForm({
                        active: true,
                        status: EmployeeGoalStatus.partlyMet,
                        note: ''
                    })}
                    disabled={metingId === employeeGoal.id || unmetingId === employeeGoal.id}
                    color={employeeGoal.status === EmployeeGoalStatus.partlyMet ? 'grey' : undefined}>
                    Delimično
                </Button>}
            />

            <Popup
                disabled={employeeGoal.status !== EmployeeGoalStatus.failed}
                header='Obrazloženje'
                content={employeeGoal.statusNote}
                trigger={<Button
                    disabled={metingId === employeeGoal.id || unmetingId === employeeGoal.id}
                    color={employeeGoal.status === EmployeeGoalStatus.failed ? 'red' : undefined}
                    onClick={() => employeeGoal.status !== EmployeeGoalStatus.failed &&
                        setStatusForm({active: true, status: EmployeeGoalStatus.failed, note: ''})}
                >Neispunjen</Button>}
            />

        </Button.Group>
}

export default observer(EmployeeGoalMet)
