import React, {Fragment, FunctionComponent, useContext} from 'react'
import {Image, Item, Label, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {IClientGoal} from "../../../app/models/clientGoals";

interface OwnProps {
    clientGoal: IClientGoal
}

type Props = OwnProps

const ClientGoalDetailedParticipants: FunctionComponent<Props> = ({ clientGoal }) => {
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore
    
  return <Fragment>
      <Segment
          textAlign='center'
          style={{border: 'none'}}
          attached='top'
          secondary
          inverted
          color='teal'
      >
          Učesnici
      </Segment>
      <Segment attached>
          <List relaxed divided>
              {[
                  ...clientGoal.participants.filter(p => p.isMain),
                  ...clientGoal.participants.filter(p => !p.isMain)   // todo find better way
              ]
                  .map(participant => (
                      <Item key={participant.user.username} style={{position: 'relative'}}>
                          {participant.isMain && (
                              <Label
                                  style={{position: 'absolute'}}
                                  color='orange'
                                  ribbon='right'
                              >
                                  Glavni
                              </Label>
                          )}
                          <Image size='tiny' src={participant.user.image || '/assets/user.png'}/>
                          <Item.Content verticalAlign='middle'>
                              <Item.Header as='h3'>
                                  <Link to={`/${currentTraffic?.id}/profile/${participant.user.username}`}>{participant.user.displayName}</Link>
                              </Item.Header>
                          </Item.Content>
                      </Item>
                  ))}
          </List>
      </Segment>
  </Fragment>
}

export default observer(ClientGoalDetailedParticipants)
