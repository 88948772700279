import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { combineValidators, isRequired } from 'revalidate';
import { Form, Button } from 'semantic-ui-react';
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {IProfile} from "../../../app/models/profile";


const validate = combineValidators({
    displayName: isRequired('displayName')
});

interface IProps {
    updateProfile: (profile: Partial<IProfile>) => void
    profile: IProfile
    onCancel: () => void
}

const ProfileEditForm: React.FC<IProps> = ({ updateProfile, profile, onCancel }) => {
    return (
        <FinalForm
            onSubmit={updateProfile}
            validate={validate}
            initialValues={profile!}
            render={({ handleSubmit, invalid, pristine, submitting }) => (
                <Form onSubmit={handleSubmit} error>
                    <Field
                        name='displayName'
                        component={TextInput}
                        placeholder='Ime za prikaz'
                        value={profile!.displayName}
                    />
                    <Field
                        name='bio'
                        component={TextAreaInput}
                        rows={3}
                        placeholder='Bio'
                        value={profile!.bio}
                    />

                    <Button
                        loading={submitting}
                        floated='right'
                        disabled={invalid || pristine}
                        positive
                        content='Ažuriraj'
                    />
                    <Button
                        floated='right'
                        content='Odustani'
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                    />
                </Form>
            )}
        />
    );
};

export default observer(ProfileEditForm);