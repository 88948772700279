import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../app/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IClientSchedule} from "../../../app/models/clientSchedules";
import {Cell, FilterProps} from "react-table";
import ClientProgress from "../../clients/commom/ClientProgress";
import {Button, Header, Icon, Label, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {ClientDetailedFilter} from "../../../app/common/tables/filters/clients/ui/ClientDetailedFilter";
import ClientDetailedCell from "../../clients/list/ClientDetailedCell";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import ClientScheduleCommentCell from "./ClientScheduleCommentCell";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";
import ClientScheduleIndebtedEmployeeIdentity from "../commom/ClientScheduleIndebtedEmployeeIdentity";
import ClientScheduleForm from "../form/ClientScheduleForm";
import {toast} from "react-toastify";
import SumPercentageTableHeader from "../../../app/common/tables/headers/SumPercentageTableHeader";

interface IProps {
    trackingId?: string
}

const ClientSchedulesList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {clientSchedulesArray: clientSchedules, deleteClientSchedule, deletingId} = rootStore.clientScheduleStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {loadClientCategoriesIfNull, categories} = rootStore.clientsStore
    const {regionsArray: regions, loadRegionsIfNull} = rootStore.regionsStore
    const {employeesArray: employees, loadUsers} = rootStore.usersStore

    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
        loadUsers(true)
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadUsers
    ])

    const columns = useMemo(() => [
        {
            id: 'client',
            Header: 'Klijent',
            accessor: (d: IClientSchedule) => d.client.name,
            Filter: (d: any) => ClientDetailedFilter(d, regions!, categories!),
            filter: 'clientDetailedFilter',
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) => <ClientDetailedCell withRealization
                                                                                                    client={clientSchedule.client}/>
        },
        {
            Header: 'Godina',
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) => (<>{clientSchedule.year}</>)
        },
        {
            id: 'realizationValue',
            accessor: (d: IClientSchedule) => d.realizationValue / d.value,
            Header: (row: any) => <SumPercentageTableHeader 
                row={row} 
                type='money' 
                name='Iznos' 
                integrantAccessor='original.realizationValue'
                totalityAccessor='original.value'
                percentageType='average'
                disallowAveragePercentageOverload
            />,
            zeroPadding: true,
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) => <div>
                {clientSchedule.realizationValue >= clientSchedule.value &&
                <Segment
                    basic>
                    <Label
                        style={{
                            paddingRight: 0,
                        }}
                        corner
                        color='blue'
                        attached='top right'
                    >
                        100%
                    </Label>
                </Segment>}

                <Segment
                    basic>
                    <Link to={`/${currentTraffic?.id}/clientSchedules/${clientSchedule.id}`}>
                        <ClientProgress
                            value={clientSchedule.realizationValue}
                            total={clientSchedule.value}
                        />
                    </Link>
                </Segment>

            </div>,
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        },
        {
            accessor: 'indebtedEmployee.id',
            Header: 'Zadužen',
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) =>
                <ClientScheduleIndebtedEmployeeIdentity clientSchedule={clientSchedule}/>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, employees.map(user => ({
                key: user.id,
                value: user.id,
                text: `${user.displayName} [${user.email}]`
            })), 'Učesnik'),
            filter: 'multiselectFilter',
            cellWidth: 2
        },
        {
            accessor: 'note',
            Header: 'Napomena'
        },
        {
            accessor: 'id',
            Header: 'Komentari',
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) => <ClientScheduleCommentCell
                clientSchedule={clientSchedule}/>,
            sortType: 'clientSchedulesLastComment'
        },
        {
            Header: 'Kontrole',
            Cell: ({row: {original: clientSchedule}}: Cell<IClientSchedule>) => (
                <Button.Group>
                    <Button

                        onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Izmena plana
                                </Header>
 
                                <ClientScheduleForm 
                                    clientScheduleId={clientSchedule.id} 
                                    onCancel={closeModal}
                                    onSubmitSuccess={() => {
                                        closeModal()
                                        toast.success('Plan uspešno izmenjen')
                                    }}
                                />
                                
                            </Segment>, 'small'
                        )}
                        
                        // as={Link} 
                        // to={`/${currentTraffic?.id}/clientSchedules/manage/${clientSchedule.id}/edit`}

                        icon='edit'
                        color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje plana
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš plan klijenta <strong>{clientSchedule.client.name} za
                                godinu {clientSchedule.year} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteClientSchedule(clientSchedule.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )}
                            icon='trash alternate' color='red' loading={deletingId === clientSchedule.id}/>
                </Button.Group>)
        }
    ], [
        closeModal,
        openModal,
        currentTraffic,
        deleteClientSchedule,
        deletingId,
        regions,
        categories,
        employees
    ])

    if (clientSchedules.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje planovi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (<Table
        trackingId={trackingId}
        data={clientSchedules}
        columns={columns}
    />);
};

export default observer(ClientSchedulesList)
