import React, {FunctionComponent, useContext, useRef, useState} from 'react';
import {Button, Form, Segment} from "semantic-ui-react";
import {Form as FinalForm, Field} from "react-final-form";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import {IDocumentation, IDocumentationDocumentFormValues} from "../../../app/models/documentations";
import TextInput from "../../../app/common/form/TextInput";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
});


interface IProps {
    documentation: IDocumentation,
    onCancel?: () => void,
    onUploadSuccess?: () => void,
    onUploadFailed?: () => void
}

const DocumentationDocumentForm: FunctionComponent<IProps> = ({
                                                                  documentation,
                                                                  onCancel,
                                                                  onUploadSuccess,
                                                                  onUploadFailed
                                                              }) => {

    const rootStore = useContext(RootStoreContext);
    const {
        addDocument,
        submittingDocument,
        setAddingDocumentMode
    } = rootStore.documentationsStore

    const [file, setFile] = useState<Blob | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleFileUpload = (event: any) => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            setFile(file)
        }
    }

    const handleFinalFormSubmit = (values: any) => {
        const document: IDocumentationDocumentFormValues = {...values}

        document.id = uuid()
        document.file = file
        document.documentationId = documentation!.id

        return addDocument(document, documentation.id)
            .then(() => {
                if (onUploadSuccess) {
                    onUploadSuccess()
                }
            })
            .catch(() => {
                if (onUploadFailed) {
                    onUploadFailed()
                }
            })
    }

    return (
        <Segment clearing>            
            <FinalForm
                onSubmit={(values: IDocumentationDocumentFormValues) => handleFinalFormSubmit(values).catch(error => ({
                        [FORM_ERROR]: error
                    })
                )}
                validate={validate}
                render={({
                             handleSubmit,
                             invalid,
                             pristine,
                             submitError,
                             dirtySinceLastSubmit,
                             form
                         }) => (
                    <Form onSubmit={handleSubmit}>

                        <Field
                            placeholder='Naziv'
                            name='name'
                            component={TextInput}
                        />

                        <Button
                            type='button'
                            color={file !== null ? 'green' : undefined}
                            content="Otpremi fajl"
                            labelPosition="left"
                            icon="file"
                            onClick={() => fileInputRef!.current!.click()}
                        />
                        <Field
                            name='file'
                            ref={fileInputRef}
                            type="file"
                            hidden
                            onChange={handleFileUpload}
                            component={"input"}
                        />


                        {(submitError && !dirtySinceLastSubmit) &&
                        <ErrorMessage error={submitError}/>}
                        <Button
                            loading={submittingDocument}
                            disabled={(invalid && !dirtySinceLastSubmit) || pristine || !file}
                            floated='right'
                            color='blue'
                            type='submit'
                            content={'Dodaj'}
                        />
                        <Button
                            onClick={() => {
                                setAddingDocumentMode(false)
                                if (onCancel) {
                                    onCancel()
                                }
                            }}
                            floated='right'
                            type='button'
                            content='Odustani'
                        />
                    </Form>
                )}
            />
        </Segment>
    );
};

export default observer(DocumentationDocumentForm)
