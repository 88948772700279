import React, {FunctionComponent} from 'react';
import {Button, Grid, Header, Icon, Item, Segment} from "semantic-ui-react";
import moment from "moment";
import {Link} from "react-router-dom";
import {IDocumentation} from "../../../app/models/documentations";
import {ITraffic} from "../../../app/models/traffics";
import {TiWarning} from "react-icons/all";

interface IProps {
    documentation: IDocumentation
    currentTraffic: ITraffic
}

const headerImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};

const DocumentationDetailsHeader: FunctionComponent<IProps> = ({documentation, currentTraffic}) => {
     
    
    
  return (<>
      <Segment.Group>
          <Segment
              basic
              attached='top'
              className='stellarBackground'
              style={{height: '20em', padding: '0'}}>
              <Segment style={headerImageTextStyle} basic>
                  <Item.Group>
                      <Item>
                          <Item.Content>
                              <Header
                                  size='huge'
                                  content={documentation.name}
                                  style={{color: 'white'}}
                              />
                              <p>{moment(documentation.date).format('l')}</p>
                              <p>{documentation.category}</p>
                          </Item.Content>
                      </Item>
                  </Item.Group>
              </Segment>
          </Segment>
          <Segment clearing attached='bottom'>
              {documentation.note &&
              <Grid>
                  <Grid.Column width={1}>
                      <TiWarning size='2em' color='#348AC7' />
                  </Grid.Column>
                  <Grid.Column width={15}>
                      <p>
                          {documentation.note}
                      </p>
                  </Grid.Column>
              </Grid>
              }
              <Button
                  as={Link}
                  to={`/${currentTraffic?.id}/documentations/manage/${documentation.id}/edit`}
                  color='orange'
                  floated='right'
              >
                  <Icon name='edit'/>
                  Izmeni dokumentaciju
              </Button>
          </Segment>
      </Segment.Group>
  </>);
};

export default DocumentationDetailsHeader
