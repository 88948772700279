import React, {FunctionComponent, useContext, useEffect} from 'react';
import OnHoldProductsList from "../../../onHoldProducts/list/OnHoldProductsList";
import {Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";

interface IProps {
    clientId: string
}

const ClientDetailsOnHoldProducts: FunctionComponent<IProps> = ({clientId}) => {
    const rootStore = useContext(RootStoreContext)
    const {setPredicate, loadOnHoldProducts, loading, clearPredicates} = rootStore.onHoldProductsStore

    useEffect(() => {
        clearPredicates()
        setPredicate('clientId', clientId)

        loadOnHoldProducts()
    }, [
        loadOnHoldProducts,
        setPredicate,
        clientId,
        clearPredicates
    ])


    return (<Segment loading={loading}>
        <OnHoldProductsList/>
    </Segment>);
};

export default observer(ClientDetailsOnHoldProducts)
