import React, {FunctionComponent, useContext, useState} from 'react'
import {UserPost} from "../../../app/models/userPosts";
import {Button, Comment, Feed, Form, Header, Icon, Label, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import moment from "moment";
import {Avatar} from "antd";
import {getNiceColorFromStingAsciiSum} from "../../../app/common/util/colors";
import {stringPreview} from "../../../app/common/util/string";
import UserPostForm from "../form/UserPostForm";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";

interface OwnProps {
    userPost: UserPost
}

type Props = OwnProps

const UserPostDetails: FunctionComponent<Props> = ({userPost}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {user: currentUser} = rootStore.userStore
    const {
        setCurrentUserReadPost, 
        createUserPostComment, 
        submittingComment,
        deleteUserPostComment,
        updateUserPostComment,
        deleteUserPost,
        deletingPostId
    } = rootStore.userPostsStore
    const { openModal, closeModal } = rootStore.modalStore
    
    const [commentContent, setCommentContent] = useState('')
    const [editingComment, setEditingComment] = useState<{id: string, content: string}>({id: '', content: ''})
    const [editPostMode, setEditPostMode] = useState(false)
    
    if (editPostMode) {
        return <UserPostForm 
            userPostId={userPost.id}
            onCancel={() => setEditPostMode(false)}
            onSubmit={() => setEditPostMode(false)}
        />
    }
    
    return <Feed as={Segment} raised >
        {userPost.creator.id === currentUser?.id &&
        <Label
            attached='top right'
            style={{ background: 'transparent' }}
        >
            
            <Button
                inverted
                circular
                color='orange'
                size='mini'
                icon='edit'
                onClick={() => setEditPostMode(true)}
            />
            
            <Button
                inverted
                circular
                color='red'
                size='mini'
                loading={deletingPostId === userPost.id}
                icon='x'
                onClick={() => openModal(
                    <Segment basic clearing>
                        <Header textAlign='center' as='h2'>
                            Brisanje posta
                            <Icon color='red' name='warning circle'/>
                        </Header>
                        <p style={{textAlign: 'center'}}>
                            Da li zaista želiš da obrišeš post <strong>{userPost.title} ?</strong>
                        </p>
                        <Button color='red' floated='right' onClick={(event: any) => {
                            event.target.className += ' loading'
                            deleteUserPost(userPost.id)
                                .then(() => closeModal())
                        }}>
                            <Icon name='checkmark'/> Da
                        </Button>
                        <Button onClick={() => closeModal()} floated='right'>
                            <Icon name='remove'/> Ne
                        </Button>
                    </Segment>, 'small'
                )}
                
            />
        </Label>}
                

        <Feed.Event>
            <Feed.Label>
                {userPost.creator.image ?
                    <Avatar
                        size='large'
                        src={userPost.creator.image}
                    /> :
                    <Avatar
                        size='large'
                        style={{
                            backgroundColor: getNiceColorFromStingAsciiSum(userPost.creator.id)
                        }}>{userPost.creator.displayName[0]}</Avatar>}
                {/*<img src={userPost.creator.image || '/assets/user.png'} alt={userPost.creator.displayName}/>*/}
            </Feed.Label>
            <Feed.Content>
                <Feed.Summary>
                    <Feed.User as={Link}
                               to={`/${currentTraffic?.id}/profile/${userPost.creator.username}`}>{userPost.creator.displayName}
                    </Feed.User> <span style={{ color: semanticColorToHash('grey') }}>je postovao</span> <Header as='span' content={userPost.title}/>
                    <Feed.Date>{moment(userPost.createdAt).calendar()} {!moment(userPost.createdAt).isSame(userPost.updatedAt) && `, izmenjen ${moment(userPost.updatedAt).calendar()}`}</Feed.Date>
                    <Feed.Extra>
                        <Avatar.Group
                            maxCount={6}
                            maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}
                        >
                            {userPost.participants
                                .filter(participant => currentUser?.id !== participant.user.id)
                                .map(participant =>
                                    participant.user.image ?
                                        <Avatar
                                            key={participant.id}
                                            src={participant.user.image}
                                            style={{border: participant.isRead ? '3px solid orange' : undefined}}

                                        /> :
                                        <Avatar
                                            key={participant.id}
                                            style={{
                                                backgroundColor: getNiceColorFromStingAsciiSum(participant.user.id),
                                                border: participant.isRead ? '3px solid orange' : undefined
                                            }}>{participant.user.displayName[0]}</Avatar>
                                )}
                        </Avatar.Group>
                    </Feed.Extra>
                </Feed.Summary>

                <Feed.Extra text>
                    {userPost.content}
                </Feed.Extra>

                <Feed.Meta>
                    <Feed.Like>
                        <Icon
                            size='big'
                            disabled={userPost.flags.updating}
                            loading={userPost.flags.updating}
                            name='book'
                            color={userPost.isReadByUser(currentUser!.id) ? 'blue' : 'grey'}
                            onClick={() => setCurrentUserReadPost(userPost.id, !userPost.isReadByUser(currentUser!.id))}
                        />
                    </Feed.Like>
                </Feed.Meta>
                
                <Comment.Group>
                    {userPost.comments.length > 0 &&
                    <>
                        <Header as='h3' dividing>
                            Komentari
                        </Header>

                        {userPost.comments.map(comment => editingComment.id === comment.id ?
                            <Form
                                key={comment.id}
                                onSubmit={() => updateUserPostComment(comment.id, editingComment.content)
                                .then(() => {
                                    setEditingComment({ id: '', content: '' })
                                })}>
                                <Form.TextArea
                                    spellCheck={false}
                                    value={editingComment.content}
                                    onChange={((event, data) => setEditingComment(prevState => ({ ...prevState, content: data.value as string })))}
                                />
                                
                                <Button 
                                    type='button'
                                    onClick={() => setEditingComment({ id: '', content: '' })}>
                                    Odustani
                                </Button>
                                
                                <Button
                                    color='orange'
                                    labelPosition='left'
                                    icon
                                    loading={submittingComment}
                                    disabled={!editingComment.content || editingComment.content === comment.content || submittingComment}
                                >
                                    <Icon name='edit' />
                                    Izmeni
                                </Button>
                            </Form>:
                            <Comment key={comment.id}>
                                <Comment.Avatar src={comment.commentator.image || '/assets/user.png'} />
                                <Comment.Content>
                                    <Comment.Author as={Link} to={`/${currentTraffic?.id}/profile/${comment.commentator.id}`}>{comment.commentator.displayName}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{moment(comment.createdAt).calendar()} {!moment(comment.createdAt).isSame(comment.updatedAt) && `, izmenjen ${moment(comment.updatedAt).calendar()}`}</div>
                                    </Comment.Metadata>
                                    <Comment.Text>{comment.content}</Comment.Text>
                                    {comment.commentator.id === currentUser?.id &&
                                    <Comment.Actions>
                                        <Comment.Action
                                            onClick={() => setEditingComment({ ...comment })}
                                        >Izmeni</Comment.Action>
                                        
                                        <Comment.Action onClick={() => openModal(
                                            <Segment basic clearing>
                                                <Header textAlign='center' as='h2'>
                                                    Brisanje komentara
                                                    <Icon color='red' name='warning circle'/>
                                                </Header>
                                                <p style={{textAlign: 'center'}}>
                                                    Da li zaista želiš da obrišeš komentar <strong>{stringPreview(comment.content, 200)} ?</strong>
                                                </p>
                                                <Button color='red' floated='right' onClick={(event: any) => {
                                                    event.target.className += ' loading'
                                                    deleteUserPostComment(comment.id)
                                                        .then(() => closeModal())
                                                }}>
                                                    <Icon name='checkmark'/> Da
                                                </Button>
                                                <Button onClick={() => closeModal()} floated='right'>
                                                    <Icon name='remove'/> Ne
                                                </Button>
                                            </Segment>, 'small'
                                        )} >
                                            Obriši
                                        </Comment.Action>
                                                                                
                                    </Comment.Actions>}
                                </Comment.Content>
                            </Comment>
                        )}
                    </>}

                    <Form reply
                        onSubmit={(event, data) => 
                            createUserPostComment(userPost.id, commentContent)
                                .then(() => {
                                    setCommentContent('')
                                })
                        }
                    >
                        <Form.TextArea
                            spellCheck={false}
                            value={commentContent} 
                            onChange={((event, data) => 
                                setCommentContent(data.value as string))} />
                        <Button 
                            content='Dodaj komentar' 
                            labelPosition='left' 
                            icon='edit' 
                            color='blue'
                            loading={submittingComment}
                            disabled={!commentContent || submittingComment}
                        />
                    </Form>
                </Comment.Group>
                
            </Feed.Content>
        </Feed.Event>
    </Feed>
}

export default observer(UserPostDetails)
