import React, {useContext} from 'react';
import {Button, Header, Icon, Item, Label, Loader, Segment} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import {RootStoreContext} from '../../../app/stores/rootStore';
import {EmployeeGoalStatus, EmployeeGoalType, IEmployeeGoal} from "../../../app/models/employeeGoals";
import moment from "moment";
import DateTimePickerWithShortcuts from "../../commom/date/DateTimePickerWithShortcuts";
import EmployeeGoalMet from "../commom/EmployeeGoalMet";
import {FiCheck, HiOutlineX, IoMdCheckmarkCircleOutline} from "react-icons/all";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";

const activityImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};


const EmployeeGoalHeader: React.FC<{ employeeGoal: IEmployeeGoal }> = ({
                                                                     employeeGoal
                                                                 }) => {
    const rootStore = useContext(RootStoreContext);
    const {currentTraffic} = rootStore.trafficsStore
    const {
        updateDate
    } = rootStore.employeeGoalsStore

    return (
        <Segment.Group>
            <Segment
                basic
                attached='top'
                className={`${employeeGoal.color}Background`}
                style={{height: '20em'}}>


                <Label
                    style={{backgroundColor: 'transparent'}}
                    attached='top right'
                >
                    {employeeGoal.status === EmployeeGoalStatus.met ?

                        <IoMdCheckmarkCircleOutline
                            color='#08db0c'
                            size='10em'/> :
                        employeeGoal.status === EmployeeGoalStatus.partlyMet ?
                            <FiCheck
                                color={semanticColorToHash('grey')}
                                size='10em'/> :
                            employeeGoal.status === EmployeeGoalStatus.failed ?
                                <HiOutlineX
                                    color={semanticColorToHash('red')}
                                    size='10em'/> :
                                null
                    }

                </Label>

                <Segment style={activityImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={employeeGoal.name}
                                    style={{color: 'white'}}
                                />
                                <p>
                                    <strong>
                                        {employeeGoal.category}
                                    </strong>
                                </p>
                                {employeeGoal.date &&
                                <p>{moment(employeeGoal.date).format('l')}</p>}
                                {/*<p>*/}
                                {/*    Hosted by{' '}*/}
                                {/*    <Link to={`/profile/${host.username}`}>*/}
                                {/*        <strong>{host.displayName}</strong>*/}
                                {/*    </Link>*/}
                                {/*</p>*/}
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached>
                <EmployeeGoalMet employeeGoal={employeeGoal}/>

                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/employeeGoals/manage/${employeeGoal.id}/edit?type=${employeeGoal.type}`}
                    color='orange'
                    floated='right'
                >
                    <Icon name='edit'/>
                    Izmeni {employeeGoal.type === EmployeeGoalType.problem ? 'uočen problem' : 'tekuću obavezu'}
                </Button>
            </Segment>

            <Segment attached='bottom'>
                <Header
                    color='grey'
                    as='h4'>Datum zavrsetka <Loader size='mini' inline active={employeeGoal.flags?.updating}/></Header>
                <DateTimePickerWithShortcuts
                    placeholder='Datum zavrsetka'
                    shortcuts={{
                        today: true,
                        tomorrow: true,
                        nextWeek: true
                    }}
                    date={employeeGoal.completedDate ? moment(employeeGoal.completedDate) : null}
                    disabled={employeeGoal.flags?.updating}
                    onChange={(date) => {
                        updateDate(employeeGoal.id, 'completed', date ? date.format('YYYY-MM-DD') : null)
                    }}
                />
            </Segment>
        </Segment.Group>
    );
};

export default observer(EmployeeGoalHeader);