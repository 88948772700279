import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {Button, Dropdown, Form, Grid, Icon, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import GeneralExpensesList, {CHART_TYPE} from "../../list/GeneralExpensesList";
import moment from "moment";
import {PdfService} from "../../../../app/common/pdf/PdfService";
import {formatDateOrDefault} from "../../../../app/common/util/date";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../../app/common/util/format";
import {IGeneralExpense} from "../../../../app/models/generalExpenses";
import {useNavbarSelectedItem} from "../../../nav/utils/index.js";
import BreadcrumbNavigation from "../../../nav/BreadcrumbNavigation";
import {DatePicker} from "antd";

interface IProps {
}

const TRACKING_ID = 'generalExpenses'

const GeneralExpensesDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadGeneralExpenses,
        loadingGeneralExpenses: loading,
        setTrackingId,
        clearGeneralExpensesPredicate: clearPredicate,
        setGeneralExpensesPredicate: setPredicate
    } = rootStore.expensesStore
    const {currentTraffic} = rootStore.trafficsStore
    const {removeFromRegistry, SHIT_getFilteredRowsById} = rootStore.tablesStore

    const [chartType, setChartType] = useState<CHART_TYPE>('bar')
    const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('year').format('YYYY-MM-DD'))

    useNavbarSelectedItem('expenses')

    useEffect(() => {
        setTrackingId(TRACKING_ID)
        clearPredicate()
        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)
        loadGeneralExpenses()

        return () => {
            removeFromRegistry(TRACKING_ID)
        }
    }, [
        loadGeneralExpenses,
        setTrackingId,
        startDate,
        endDate,
        setPredicate,
        clearPredicate,
        removeFromRegistry
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        doc.text(`Tabela opštih troškova za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Naziv', 'Kategorija', 'Vrednost', 'Napomena', 'Datum']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((generalExpense: IGeneralExpense, index) => ([
                index + 1,
                generalExpense.name,
                generalExpense.category,
                formatRsd(generalExpense.value),
                generalExpense.note,
                moment(generalExpense.date).format('l')

            ])) ?? []
        })

        doc.save("OpštiTroškovi.pdf")
    }

    return <Grid>
        <Grid.Row>
            <Grid.Column>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Opšti troškovi',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row as={Segment} loading={loading}>
            <Grid.Column width={16}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Form>
                                <Form.Group inline>
                                    <Form.Field>
                                        <DatePicker.RangePicker
                                            disabled={loading}
                                            placeholder={['Početni datum', 'Krajni datum']}
                                            format='DD.MM.YYYY.'
                                            onChange={(date, dateString) => {
                                                setStartDate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '')
                                                setEndDate(date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')

                                            }}
                                            value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                                        />
                                    </Form.Field>
                                    
                                    <Form.Field>
                                        <Dropdown
                                            icon={chartType === 'bar' ? 'chart bar' : chartType === 'pie' ? 'chart pie' : 'dont'}
                                            floating
                                            labeled
                                            button
                                            className='icon'
                                            value={chartType}
                                            onChange={((event, data) => setChartType(data.value as CHART_TYPE))}
                                            options={[
                                                {key: 'none', value: 'none', text: 'Bez grafika', icon: 'dont'},
                                                {key: 'pie', value: 'pie', text: 'Pita', icon: 'chart pie'},
                                                {key: 'bar', value: 'bar', text: 'Bar', icon: 'chart bar'}
                                            ]}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={16} mobile={16}>
                            <Button
                                as={Link}
                                to={`/${currentTraffic?.id}/generalExpenses/create`}
                                floated='right'
                                color='blue'
                                icon
                                labelPosition='left'>
                                <Icon name='add'/>
                                Dodaj Trošak
                            </Button>
                            <Button
                                disabled={loading}
                                icon
                                labelPosition='left'
                                floated='right'
                                color='teal'
                                onClick={() => exportPdf()}>
                                <Icon name='file pdf'/>
                                PDF
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <GeneralExpensesList chartType={chartType} trackingId={TRACKING_ID}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Grid.Column>

        </Grid.Row>

    </Grid>

}

export default observer(GeneralExpensesDashboard)
