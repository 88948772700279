import React from 'react';
import {
    Segment,
    Item,
    Header,
    Grid, List, Label
} from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import {IProfile} from "../../../app/models/profile";
import {roleToColorName} from "../../../app/common/util/semanticUi";

interface IProps {
    profile: IProfile;
    isCurrentUser: boolean;
    loading: boolean;
}

const ProfileHeader: React.FC<IProps> = ({
                                             profile,
                                             isCurrentUser,
                                             loading
                                         }) => {
    return (
        <Segment>
            <Grid>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                    <Item.Group>
                        <Item>
                            <Item.Image
                                avatar
                                size='small'
                                src={profile.image || '/assets/user.png'}
                            />
                            <Item.Content verticalAlign='middle'>
                                <Header as='h1'>{profile.displayName}</Header>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <List>
                        {profile.roles.map(role => <List.Item>
                            <Label color={roleToColorName(role)} content={role} />
                        </List.Item>)}
                    </List>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default observer(ProfileHeader);