import React, {FunctionComponent, useContext, useEffect} from 'react';
import ExpensesList from "../../lists/ExpensesList";
import {Header, Icon, Menu} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {FcExternal} from "react-icons/all";

export const PROFITABILITY_EXPENSE_TRACKING_ID = 'profitabilityExpense'

interface IProps {}

const ProfitabilityDashboardExpenseSection: FunctionComponent<IProps> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { removeFromRegistry } = rootStore.tablesStore
  
  useEffect(() => () => {
    removeFromRegistry(PROFITABILITY_EXPENSE_TRACKING_ID)
  }, [
      removeFromRegistry
  ])
  
  return (<>
    <Header as='h2'>
      <Icon  >
        <FcExternal />
      </Icon>
      <Header.Content>Rashodi</Header.Content>
    </Header>

    {/*<div>Filteri</div>*/}

    <Menu secondary color='teal' style={{color: 'transparent'}}>
      <Menu.Item
          style={{color: 'transparent'}}
          content='Bzv'
      />
      
    </Menu>
    
    <ExpensesList trackingId={PROFITABILITY_EXPENSE_TRACKING_ID} />
  </>);
};

export default observer(ProfitabilityDashboardExpenseSection)
