import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../app/stores/rootStore";
import {RouteComponentProps} from "react-router";
import LoadingComponent from "../../app/layout/LoadingComponent";
import {Button, Grid, Header, Icon, List, Segment} from "semantic-ui-react";
import Table from "../../app/common/tables/Table";
import {Cell} from "react-table";
import {IProductConsumptionItem} from "../../app/models/productConsumptions";
import ProductsTableBasicInfoCell from "../products/list/ProductsTableBasicInfoCell";
import {formatRsd} from "../../app/common/util/format";
import moment from "moment";
import {Link} from "react-router-dom";
import BreadcrumbNavigation from "../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string
}

const ProductConsumptionDetails: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {
    const rootStore = useContext(RootStoreContext)
    const {loadProductConsumption, loading, productConsumption, deletingId, deleteProductConsumption} = rootStore.productConsumptionsStore
    const { currentTraffic } = rootStore.trafficsStore
    const { openModal, closeModal } = rootStore.modalStore
    
    const columns = useMemo(() => [
        {
            Header: 'Proizvod',
            accessor: 'product.id',
            Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <ProductsTableBasicInfoCell
                product={item.product}/>
        },
        {
            Header: 'Količina',
            accessor: 'count',
            Cell: ({row: {original: item}}: Cell<any>) => <>{item.count}</>
        },
        {
            Header: 'Cena po komadu',
            accessor: (d: IProductConsumptionItem) => d.singleRsdValue,
            Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <>{formatRsd(item.singleRsdValue)}</>
        },
        {
            Header: 'Ukupna cena',
            accessor: (d: IProductConsumptionItem) => d.summaryRsdValue,
            Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <>{formatRsd(item.summaryRsdValue)}</>
        },
    ], [])

    useEffect(() => {
        loadProductConsumption(match.params.id)
    }, [loadProductConsumption, match.params.id])

    if (loading || !productConsumption) return <LoadingComponent content='Učitavanje rashoda'/>

    return (<Grid centered>
        <Grid.Column width={16}>
            <BreadcrumbNavigation 
                items={[
                    {
                        text: 'Rashod proizvoda',
                        linkWithoutCurrentTrafficId: 'productConsumptions'
                    },
                    {
                        text: 'Detalji rashoda',
                        active: true
                    }
                ]} 
            />
        </Grid.Column>
        <Grid.Column computer={14} tablet={14} mobile={16}>
            <Segment clearing color='blue'>
                <Grid>
                    <Grid.Column computer={10} tablet={10} mobile={16}>
                        <Table
                            columns={columns}
                            data={productConsumption!.items}
                        />
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={16}>

                        <List divided size='huge'>
                            <List.Item>
                                <List.Content>
                                    <List.Header>
                                        Vrednost
                                    </List.Header>
                                    <List.Description>
                                        {formatRsd(productConsumption.value)}
                                    </List.Description>
                                </List.Content>
                            </List.Item>

                            <List.Item>
                                <List.Content>
                                    <List.Header>
                                        Datum
                                    </List.Header>
                                    <List.Description>
                                        {moment(productConsumption.date).format('l')}
                                    </List.Description>
                                </List.Content>
                            </List.Item>

                            <List.Item>
                                <List.Content>
                                    <List.Header>
                                        Kategorija
                                    </List.Header>
                                    <List.Description>
                                        {productConsumption.category}
                                    </List.Description>
                                </List.Content>
                            </List.Item>

                            {productConsumption.note &&
                            <List.Item>
                                <List.Content>
                                    <List.Header>
                                        Napomena
                                    </List.Header>
                                    <List.Description>
                                        {productConsumption.note}
                                    </List.Description>
                                </List.Content>
                            </List.Item>}

                        </List>
                        
                    </Grid.Column>
                </Grid>
                <Button
                    floated='right'
                    icon='trash'
                    color='red'
                    content='Obriši'
                    loading={deletingId === productConsumption.id}
                    onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje rashoda
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš rashod proizvoda datuma <strong>{moment(productConsumption.date).format('l')} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteProductConsumption(productConsumption.id!)
                                    .then(() => {
                                        closeModal()
                                        history.push(`/${currentTraffic?.id}/productConsumptions`)
                                    })
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )}
                />
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/productConsumptions/manage/${match.params.id}/edit`}
                    floated='right'
                    icon='edit'
                    color='orange'
                    content='Izmeni'
                />
            </Segment>
        </Grid.Column>
    </Grid>)
}

export default observer(ProductConsumptionDetails)
