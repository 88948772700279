import React, {useContext, useState} from 'react';
import {Tab, Grid, Header, Button, Segment} from 'semantic-ui-react';
import ProfileEditForm from './ProfileEditForm';
import {observer} from 'mobx-react-lite';
import {RootStoreContext} from "../../../app/stores/rootStore";
import UserChangePasswordForm from "../forms/UserChangePasswordForm";
import {toast} from "react-toastify";

const ProfileDescription = () => {
    const rootStore = useContext(RootStoreContext);
    const {updateProfile, profile, isCurrentUser} = rootStore.profileStore;
    const [editMode, setEditMode] = useState(false);
    const [changePasswordMode, setChangePasswordMode] = useState(false);


    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16}>
                    <Header
                        floated='left'
                        icon='user'
                        content={`O ${profile!.displayName}`}
                    />
                    {isCurrentUser && (<>

                            {!changePasswordMode &&
                            <Button
                                floated='right'
                                basic
                                content={'Promeni lozinku'}
                                onClick={() => setChangePasswordMode(!changePasswordMode)}
                            />}

                            {!editMode &&
                            <Button
                                floated='right'
                                basic
                                content={'Izmeni Profil'}
                                onClick={() => setEditMode(!editMode)}
                            />}

                        </>
                    )}
                </Grid.Column>
                <Grid.Column width={16}>

                    {changePasswordMode &&
                    <Segment clearing>
                        <UserChangePasswordForm
                            onCancel={() => setChangePasswordMode(false)}
                            onSuccess={() => {
                                setChangePasswordMode(false)
                                toast.success('Lozinka uspešno promenjena')
                            }}/>
                    </Segment>}

                    {editMode 
                        ? <Segment clearing>
                            <ProfileEditForm
                                updateProfile={updateProfile}
                                profile={profile!}
                                onCancel={() => setEditMode(false)}
                            />
                        </Segment>
                        : <span>{profile!.bio}</span>
                    }
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default observer(ProfileDescription);