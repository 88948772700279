import React, {FunctionComponent, useContext, useEffect} from 'react';
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {Button, Divider, Grid, Header, Icon, Segment, Select} from "semantic-ui-react";
import ClientInitialStatesList from "../list/ClientInitialStatesList";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {Link} from "react-router-dom";
import {PdfService} from "../../../app/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../app/common/util/format";
import {IClientInitialState} from "../../../app/models/clientInitialStates";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

const TRACKING_ID = 'clientInitialStates'

interface IProps {
}

const ClientInitialStatesDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loading,
        loadClientInitialStates,
        loadYearsWithAny,
        loadingYearsWithAny,
        yearsWithAny,
        filterYear, setFilterYear
    } = rootStore.clientInitialStatesStore
    const {currentTraffic} = rootStore.trafficsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    useNavbarSelectedItem('clientInitialStatesDashboard')

    useEffect(() => () => {
        removeFromRegistry(TRACKING_ID)

    }, [
        removeFromRegistry,
    ])


    useEffect(() => {
        loadYearsWithAny()
            .then((yearsWithAnys) => {
                if (!yearsWithAnys?.includes(filterYear)) {
                    setFilterYear('all')
                }
            })
        loadClientInitialStates()
    }, [
        loadClientInitialStates,
        loadYearsWithAny,
        filterYear,
        setFilterYear,
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        const yeartor = (year: string) => year === 'all' ? 'sve' : year
        const yearSufix = (year: string) => year === 'all' ? ' godine' : '. godinu'

        doc.text(`Tabela početnih stanja za ${yeartor(filterYear)}${yearSufix(filterYear)}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                ['#', 'Klijent', 'Godina', 'Iznos']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((clientInitialState: IClientInitialState, index) => ([
                index + 1,
                clientInitialState.client.name,
                clientInitialState.year,
                formatRsd(clientInitialState.value)
            ])) ?? []
        })

        doc.save(`PočetnaStanja${filterYear}.pdf`)
    }

    if (loadingYearsWithAny || !yearsWithAny) return <LoadingComponent content='Učitavanje godina...'/>

    if (yearsWithAny.length === 0) return <Grid centered>
        <Grid.Column computer={8} tablet={12} mobile={16}>
            <Divider hidden/>
            <Segment clearing textAlign='center'>
                <Header color='blue' as='h2' content='Ne postoji nijedno početno stanje'
                        subheader='Dodaj pojedinačno početno stanje za klijenta ili generiši početno stanje svih klijenata za odredjenu godinu'
                />
                <Button
                    size='big'
                    inverted
                    content='Dodaj'
                    color='blue'
                    as={Link}
                    to={`/${currentTraffic?.id}/clientInitialStates/create`}/>
                <Button
                    size='big'
                    inverted
                    content='Generiši'
                    color='orange'
                    as={Link}
                    to={`/${currentTraffic?.id}/clientInitialStates/generate`}/>
            </Segment>
        </Grid.Column>
    </Grid>

    return (<>
        <BreadcrumbNavigation
            items={[
                {
                    text: 'Početna stanja',
                    active: true
                }
            ]}
        />
        <Segment.Group>
            <Segment attached='top' color='blue' clearing>
                <Select
                    options={[
                        {key: 'all', value: 'all', text: 'Sve'},
                        ...yearsWithAny.map(year => ({key: year, value: year, text: year}))
                    ]}
                    value={filterYear}
                    onChange={(event, data) => setFilterYear(data.value as string)}
                />
                <Button
                    disabled={loading}
                    icon
                    labelPosition='left'
                    floated='right'
                    color='teal'
                    onClick={() => exportPdf()}>
                    <Icon name='file pdf'/>
                    PDF
                </Button>
            </Segment>
            <Segment loading={loading} attached='bottom'>
                <ClientInitialStatesList trackingId={TRACKING_ID}/>
            </Segment>
        </Segment.Group>

    </>);
};

export default observer(ClientInitialStatesDashboard)
