import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {Button, Form, Grid, Input, Label, Message, Segment, Select, Statistic,} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {combineValidators, isRequired, composeValidators, isNumeric} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import {v4 as uuid} from 'uuid';
import {RootStoreContext} from "../../../app/stores/rootStore";
import {
    IProductConsumptionFormValues,
    IProductConsumptionItem,
    ProductConsumptionFormValues, ProductConsumptionItem
} from "../../../app/models/productConsumptions";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import Table from "../../../app/common/tables/Table";
import {Cell} from "react-table";
import ProductsTableBasicInfoCell from "../../products/list/ProductsTableBasicInfoCell";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import DateInput from "../../../app/common/form/DateInput";
import {FieldArray} from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import {formatRsd} from "../../../app/common/util/format";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {isNumberGreaterThan} from "../../../app/common/validators/general";
import {productConsumptionsCategoriesOptions} from "../../../app/common/options/productConsumptions";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

const requiredArray = (value: any) => value && value.length > 0 ? undefined : 'Required';

const validate = combineValidators({
    'items[].count': composeValidators(
        isRequired,
        isNumeric,
        isNumberGreaterThan(0)
    )('Količina'),
    date: isRequired({message: 'Datum je obavezan'}),
    category: isRequired({message: 'Kategorija je obavezna'})
});

interface DetailParams {
    id: string;
}

const ProductConsumptionForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        submitting,
        loadProductConsumption,
        createProductConsumption,
        editProductConsumption,
    } = rootStore.productConsumptionsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {loadProducts, productsByNameArray: products, loading: loadingProducts} = rootStore.productsStore
    const {eurExchangeRate} = rootStore.globalSettingsStore

    const [productConsumption, setProductConsumption] = useState<IProductConsumptionFormValues>(new ProductConsumptionFormValues())
    const [loading, setLoading] = useState(false)
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null)
    const [selectedProductCount, setSelectedProductCount] = useState<number>(1)

    useNavbarSelectedItem(match.params.id ? '' : 'productConsumptions')

    const itemsColumns = useMemo(() => [
            {
                Header: 'Proizvod',
                accessor: 'product.id',
                Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <ProductsTableBasicInfoCell
                    product={item.product}/>
            },
            {
                Header: 'Količina',
                accessor: 'count',
                Cell: ({row: {original: item}}: Cell<any>) => {

                    const error = item.errors.filter((e: any) => e[0] === 'count')[0] ?? null

                    return <>
                        <div>
                            {item.count}
                        </div>
                        {error &&
                        <Label basic color='red'>
                            {error[1]}
                        </Label>}
                    </>
                }
            },
            {
                Header: 'Cena po komadu',
                accessor: (d: IProductConsumptionItem) => d.product.prices.filter(p => p.isBase)[0]!.value! * eurExchangeRate,
                Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <>{formatRsd(item.product.prices.filter(p => p.isBase)[0]!.value! * eurExchangeRate)}</>
            },
            {
                Header: 'Ukupna cena',
                accessor: (d: IProductConsumptionItem) => d.product.prices.filter(p => p.isBase)[0]!.value! * eurExchangeRate * d.count,
                Cell: ({row: {original: item}}: Cell<IProductConsumptionItem>) => <>{formatRsd(item.product.prices.filter(p => p.isBase)[0]!.value! * eurExchangeRate * item.count)}</>
            },
            {
                Header: 'Obriši',
                accessor: 'id',
                Cell: ({row: {original: item}}: Cell<any>) => <Button size='mini' color='red' type='button' icon='x'
                                                                      onClick={() => item.remove()}/>
            }

        ],
        [eurExchangeRate])

    useEffect(() => {
        loadProducts()
        if (match.params.id) {
            setLoading(true)
            loadProductConsumption(match.params.id)
                .then(productConsumption => setProductConsumption(new ProductConsumptionFormValues(productConsumption)))
                .finally(() => setLoading(false))

        }
    }, [
        loadProducts,
        match.params.id,
        loadProductConsumption
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const productConsumption = {...values}

        if (!productConsumption.id) {
            let newproductConsumption = {
                ...productConsumption,
                id: uuid()
            }

            return createProductConsumption(newproductConsumption)
        } else {

            return editProductConsumption(productConsumption)
        }
    }

    const clearSelection = () => {
        setSelectedProductId(null)
        setSelectedProductCount(1)
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Rashodi proizvoda',
                            linkWithoutCurrentTrafficId: 'productConsumptions'
                        },
                        {
                            text: match.params.id ? 'Izmena rashoda' : 'Dodavanje rashoda',
                            active: true
                        }
                    ]}
                />

            </Grid.Column>
            <Grid.Column computer={14} mobile={14} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IProductConsumptionFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        validate={validate}
                        mutators={{...arrayMutators}}
                        initialValues={productConsumption}
                        render={({
                                     values,
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form: {
                                         mutators: {push, pop}
                                     }
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading}>
                                <Grid>
                                    <Grid.Column computer={10} tablet={10} mobile={16}>
                                        <Form.Group>
                                            <Form.Field width={12}>
                                                <Select
                                                    loading={loadingProducts}
                                                    clearable
                                                    search
                                                    name='productId'
                                                    placeholder='Proizvod'
                                                    value={selectedProductId!}
                                                    onChange={(event, data) => setSelectedProductId(data.value as string)}
                                                    options={products.map(product => ({
                                                        key: product.id,
                                                        value: product.id,
                                                        text: `[${product.brandName}] ${product.name} - ${product.sku} (${formatRsd(product.prices.filter(p => p.isBase)[0]!.value! * eurExchangeRate)})`
                                                    }))}
                                                    component={SelectStringInput}/>
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <Input
                                                    min={1}
                                                    type='number'
                                                    value={selectedProductCount}
                                                    onChange={((event, data) => setSelectedProductCount(Number(data.value)))}
                                                />
                                            </Form.Field>
                                            <Form.Field width={2}>
                                                <Button
                                                    disabled={!selectedProductId}
                                                    fluid
                                                    type='button'
                                                    color='blue'
                                                    icon='add'
                                                    content='Dodaj'
                                                    onClick={(event, data) => {
                                                        push('items',
                                                            new ProductConsumptionItem(products.filter(p => p.id === selectedProductId)![0],
                                                                selectedProductCount))
                                                        clearSelection()
                                                    }}
                                                />
                                            </Form.Field>
                                        </Form.Group>

                                        <FieldArray validate={requiredArray} name='items'>
                                            {({fields, meta}) => {

                                                const data = fields.map((name, index) => ({
                                                    ...fields.value[index],
                                                    errors: Object.keys(meta.error[index]).map((key) => [key, meta.error[index][key]]),
                                                    remove: () => fields.remove(index)
                                                }))


                                                if (data.length < 1)
                                                    return (
                                                        <Message
                                                            icon='exclamation'
                                                            color={"orange"}
                                                            header='Lista proizvoda je prazna'
                                                            content='Dodaj proizovde odabirom iz liste i klikom na duge "dodaj"'
                                                        />
                                                    )


                                                return <Table
                                                    data={data}
                                                    columns={itemsColumns}/>
                                            }
                                            }
                                        </FieldArray>

                                    </Grid.Column>

                                    <Grid.Column computer={6} tablet={6} mobile={16}>
                                        <Statistic size='tiny' floated='right'>
                                            <Statistic.Label>Ukupno</Statistic.Label>
                                            <Statistic.Value>{formatRsd(values.items.reduce((total: number, current: IProductConsumptionItem) =>
                                                total + current.product.prices.filter(p => p.isBase)[0]!.value! * current.count * eurExchangeRate, 0))}</Statistic.Value>
                                        </Statistic>

                                        <Field
                                            component={DateInput}
                                            name='date'
                                            date={true}
                                            placeholder='Date'
                                            value={productConsumption.date}/>

                                        <Field
                                            component={SelectStringInput}
                                            name='category'
                                            placeholder='Kategorija'
                                            value={productConsumption.category}
                                            options={productConsumptionsCategoriesOptions}
                                        />

                                        <Field
                                            component={TextAreaInput}
                                            name='note'
                                            placeholder='Napomena'
                                            value={productConsumption.note}
                                        />

                                        {(submitError && !dirtySinceLastSubmit) &&
                                        <ErrorMessage error={submitError}/>}
                                        <Button
                                            loading={submitting}
                                            disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine || loadingProducts}
                                            floated='right'
                                            color='blue'
                                            type='submit'
                                            content={(!productConsumption.id) ? 'Dodaj' : 'Izmeni'}
                                        />
                                        <Button
                                            onClick={productConsumption.id ? () => history.push(`/${currentTraffic?.id}/productConsumptions/${productConsumption.id}`) :
                                                () => history.push(`/${currentTraffic?.id}/productConsumptions`)}
                                            disabled={loading}
                                            floated='right'
                                            type='button'
                                            content='Odustani'
                                        />
                                    </Grid.Column>
                                </Grid>

                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ProductConsumptionForm)
