import React, {FunctionComponent, useContext} from 'react';
import MyPieChart from "../../../../../app/common/charts/MyPieChart";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import {formatRsd} from "../../../../../app/common/util/format";
import {summator} from "../../../../../app/common/util/array";
import {getNiceColorFromStingAsciiSum} from "../../../../../app/common/util/colors";


interface IProps {
}

const ProfitabilityExpenseGratisDiagram: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {gratisOrdersGroupByBrand} = rootStore.profitabilityStore

    return (<>
        <MyPieChart
            data={gratisOrdersGroupByBrand.map(entity => ({...entity, xName: entity.brand?.name!}))} // ovo je caka da bi se ispisalo ime
            colors={gratisOrdersGroupByBrand.map(entity => entity.brand?.color ?? getNiceColorFromStingAsciiSum(entity.brand?.name!))}
            xAccessor='name'
            yAccessor='summaryBasePriceInRsd'
            title={[
                'Gratis',
                formatRsd(summator(gratisOrdersGroupByBrand, 'summaryBasePriceInRsd'))]}/>
    </>);
};

export default observer(ProfitabilityExpenseGratisDiagram)
