import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import Table from "../../../../../app/common/tables/Table";
import BrandBasicCell from "../../../../brands/BrandBasicCell";
import {Cell, FilterProps} from "react-table";
import MultiselectFilter from "../../../../../app/common/tables/filters/multiselect/MultiselectFilter";
import {IProductConsumptionAnalyseEntity} from "../../../../../app/models/productConsumptions";
import SumTableHeader from "../../../../../app/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../../../app/common/tables/filters/number/NumberFilter";
import {formatRsd} from "../../../../../app/common/util/format";
import {Grid} from "semantic-ui-react";
import ProductConsumptionsAnalysisByBrandCharts from "./ProductConsumptionsAnalysisByBrandCharts";
import {PRODUCT_CONSUMPTIONS_ANALYSIS_BY_BRAND_TRACKING_ID} from "../../ProductConsumptionsAnalysisDashboard";

interface IProps {
    trackingId: string
}

const ProductConsumptionsAnalysisByBrandList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {filteredItemsGropedByBrand: data} = rootStore.productConsumptionsStore
    const {loadBrandsIfNull, brandsAsOptions} = rootStore.brandsStore


    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])

    const columns = useMemo(() => [
            {
                Header: 'Brend',
                accessor: 'brand.name',
                Cell: ({row: {original: entity}}: Cell<IProductConsumptionAnalyseEntity>) => <BrandBasicCell
                    brand={entity.brand!}/>,
                Filter: (d: FilterProps<any>) => MultiselectFilter(d, brandsAsOptions, 'Brend'),
                filter: 'multiselectFilter'
            },
            {
                Header: (row: any) => <SumTableHeader row={row} type={'count'} name={'Količina'} accessor={'count'}/>,
                accessor: 'count',
                Cell: ({row: {original: entity}}: Cell<IProductConsumptionAnalyseEntity>) =>
                    <span>{entity.count}</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Nabavna cena u RSD'}
                                                      accessor={'value'}/>,
                accessor: 'value',
                Cell: ({row: {original: entity}}: Cell<IProductConsumptionAnalyseEntity>) =>
                    <span>{formatRsd(entity.value)}</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },

        ],
        [
            brandsAsOptions
        ])

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <ProductConsumptionsAnalysisByBrandCharts listId={PRODUCT_CONSUMPTIONS_ANALYSIS_BY_BRAND_TRACKING_ID} />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Table
                    data={data}
                    columns={columns}
                    trackingId={trackingId} 
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>)
}

export default observer(ProductConsumptionsAnalysisByBrandList)
