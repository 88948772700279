import React, {FunctionComponent, useContext, useEffect, useRef} from 'react';
import {
    Button,
    Checkbox,
    Form,
    Grid,
    Input,
    Message,
    Popup,
    Segment,
    Select,
    TextArea
} from "semantic-ui-react";
import {typesOptions} from "../../../app/common/options/bill";
import {RouteComponentProps} from "react-router";
import {RootStoreContext} from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {observer} from "mobx-react-lite";
import BillCartProducts from "./BillCartProducts";
import moment from 'moment'
import SummaryPrice from "./SummaryPrice";
import BillCartInstallments from "./installments/BillCartInstallments";
import {BillCreationType} from "../../../app/models/bills";
import {toast} from "react-toastify";
import BillCartManualDifferenceInPrice from "./manual/BillCartManualDifferenceInPrice";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string;
}


const BillCart: FunctionComponent<RouteComponentProps<DetailParams>> = ({match}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        // brand, setBrand,
        client, setClient,
        date, setDate,
        discount, setDiscount,
        payday, setPayday,
        type, setType,
        resetStore,
        isGratis, setIsGratis,
        isOnSale, setIsOnSale,
        onSaleNote, setOnSaleNote,
        note, setNote,
        traffic, setTraffic,
        createBill, editBill,
        submitting,
        idString, setIdString,
        errors,
        hirePurchase, setHirePurchase,
        loading,
        loadBill,
        waitingFileUpload, setWaitingFileUpload,
        creationType, setIsManualCreationType, isManualCreationType,
        manualFile, setManualFile,
        setEditMode, editMode,
        setActualIdStringSuggestion,
        orders,
        installmentsValueSameAsBillValue
    } = rootStore.billsCartStore
    const {loadClients, clientsArray: clients, loading: clientsLoading} = rootStore.clientsStore
    const {loadTraffics, trafficsArray: traffics} = rootStore.trafficsStore
    const {loadIdStringSuggestions, idStringSuggestions} = rootStore.billsStore
    const {loading: productsLoading} = rootStore.productsStore

    useNavbarSelectedItem('billsCreate')

    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        resetStore()
        if (match.params.id) {
            setEditMode(true)
            loadClients()
            // loadBrands()
            loadTraffics()
            loadIdStringSuggestions()
            loadBill(match.params.id)
        } else {
            setEditMode(false) // izgleda da mora
            loadClients()
            // loadBrands()
            loadTraffics()
            loadIdStringSuggestions()
                .then(() => setActualIdStringSuggestion())
        }

    }, [
        loadClients,
        // loadBrands, 
        resetStore,
        loadTraffics,
        loadBill,
        match.params.id,
        loadIdStringSuggestions,
        setActualIdStringSuggestion,
        setEditMode
    ])

    const formErrors = () => {
        let errors: string[] = []

        if (creationType === BillCreationType.Cart && orders.size === 0) {
            errors.push('Korpa je prazna')
        }

        if (creationType === BillCreationType.Manual && !manualFile && !editMode) {
            errors.push('Fajl je obavezan')
        }

        if (!client) {
            errors.push('Klijent je obavezan')
        }

        if (!idString) {
            errors.push('Broj računa je obavezan')
        }

        if (!isGratis && hirePurchase && !installmentsValueSameAsBillValue) {
            errors.push('Suma rata nije jednaka vrednosti računa')
        }

        if (!date) {
            errors.push('Datum je obavezan')
        }

        if (!isGratis && !hirePurchase && !payday) {
            errors.push('Dospeće je obavezan')
        }

        return errors
    }

    const isFormValid = () => {
        return formErrors().length === 0
    }

    const handleManualFileUpload = (event: any) => {
        const files = event.target.files
        if (files !== null) {
            const file = files[0]

            if (file.type !== 'application/pdf') {
                toast.error('Fajl mora biti u PDF formatu')
                setManualFile(null)
            } else {
                setManualFile(file)
            }
        }
    }

    const handleSubmit = () => {
        if (match.params.id) {
            editBill()
        } else {
            createBill()
        }
    }

    if (clientsLoading) return <LoadingComponent content='Učitavanje klijenata'/>
    // if (brands === null) return <LoadingComponent content='Učitavanje brendova'/>
    if (traffics.length === 0) return <LoadingComponent content='Učitavanje prometa'/>
    if (idStringSuggestions.size === 0) return <LoadingComponent content='Učitavanje sugestije broja računa'/>

    if (clients.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header> Nije moguće napraviti račun jer ne postoje klijenti u sistemu.</Message.Header>
                <p>Dodaj klijente pa se pokušaj ponovo.</p>
            </Message>
        </div>
    return (
        <Form loading={loading}>
            <BreadcrumbNavigation
                items={[
                    {
                        text: 'Računi',
                        linkWithoutCurrentTrafficId: 'bills'
                    },
                    {
                        text: match.params.id ? 'Izmena računa' : 'Nov račun',
                        active: true
                    }
                ]}
            />
            <Grid celled as={Segment} color='blue'>
                <Grid.Row as={Segment} columns={isManualCreationType ? 2 : 3}>
                    <Grid.Column computer={isManualCreationType ? 8 : 3} mobile={16}>
                        <Form.Field>
                            <Checkbox
                                name='manualCreationType'
                                label='Manuelna izrada'
                                checked={creationType === BillCreationType.Manual}
                                onChange={(event, data) => setIsManualCreationType(data.checked!)}
                                slider
                                color='yellow'
                            />
                        </Form.Field>
                        {isManualCreationType &&
                        <Form.Field>
                            <Button
                                color={manualFile !== null ? 'green' : undefined}
                                content="Otpremi fajl"
                                labelPosition="left"
                                icon="file"
                                onClick={() => fileInputRef!.current!.click()}
                            />
                            <input
                                ref={fileInputRef}
                                type="file"
                                hidden
                                onChange={handleManualFileUpload}
                            />
                        </Form.Field>}
                        <Form.Field>
                            <label>Tip računa</label>
                            <Select
                                name='type'
                                value={type}
                                options={typesOptions}
                                fluid
                                onChange={(e: any, data: any) => setType(data.value)}
                                placeholder='Tip'
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Promet</label>
                            <Select
                                name='trafficId'
                                // defaultValue={traffics.filter(t => t.isDefault)[0].id}
                                value={traffic?.id}
                                options={traffics.map(traffic => ({
                                    key: traffic.id,
                                    value: traffic.id,
                                    text: traffic.name
                                }))}
                                fluid
                                onChange={(e: any, data: any) => setTraffic(data.value)}
                                placeholder='Promet'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                name='isGratis'
                                label='Gratis'
                                checked={isGratis}
                                onChange={(event, data) => setIsGratis(data.checked!)}
                                slider
                                color='yellow'
                            />
                        </Form.Field>

                        <Form.Field>
                            <Checkbox
                                name='isOnSale'
                                label='Akcijski promet'
                                checked={isOnSale}
                                onChange={(event, data) => setIsOnSale(data.checked!)}
                                slider
                                color='red'
                            />
                        </Form.Field>

                        {isOnSale 
                        && <Form.Field>
                            <label>Broj akcijksog prometa</label>
                            <Input
                                value={onSaleNote}
                                placeholder='Broj akcijksog prometa'
                                onChange={(event, data) => setOnSaleNote(data.value)}
                            />
                        </Form.Field>}


                        <Form.Field>
                            <label>Šifra računa</label>
                            <Input
                                value={idString}
                                placeholder='Šifra računa'
                                onChange={(event, data) => setIdString(data.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Klijent</label>
                            <Select
                                fluid
                                search
                                name='clientId'
                                value={client?.id?.toString()}
                                onChange={(e: any, data: any) => setClient(data.value)}
                                placeholder='Klijent'
                                options={clients.map(client => ({
                                    key: client.id,
                                    value: client.id!,
                                    text: client.name
                                }))}
                            />
                        </Form.Field>

                        {creationType === BillCreationType.Cart &&
                        <Form.Field>
                            <label>Popust</label>
                            <Input
                                fluid
                                value={discount}
                                onChange={(event, data) => setDiscount(Number(data.value))}
                                type='number'
                                min={0}
                                max={100}
                                label='%'
                                labelPosition='right'/>
                        </Form.Field>}
                        <Form.Field>
                            <label>Datum</label>
                            <Input
                                name='date'
                                value={moment(date).format('YYYY-MM-DD')}
                                onChange={(event, data) => setDate(data.value)}
                                type='date'
                                placeholder='Datum'
                            />
                        </Form.Field>
                        {!isGratis && !hirePurchase &&
                        <Form.Field>
                            <label>Dospeće</label>
                            <Input
                                name='payday'
                                value={moment(payday).format('YYYY-MM-DD')}
                                onChange={(event, data) => setPayday(data.value)}
                                type='date'
                                placeholder='Dospeće'/>
                        </Form.Field>}
                    </Grid.Column>
                    {!isManualCreationType &&
                    <Grid.Column computer={10} mobile={16}>
                        <BillCartProducts/>
                    </Grid.Column>}
                    <Grid.Column computer={isManualCreationType ? 8 : 3} mobile={16}>
                        <SummaryPrice/>

                        {isManualCreationType &&
                        <BillCartManualDifferenceInPrice/>}

                        {!isGratis &&
                        <Form.Field>

                            <Checkbox
                                name='hirePurchase'
                                label='Na rate'
                                checked={hirePurchase}
                                onChange={(event, data) => setHirePurchase(data.checked!)}
                                slider/>
                        </Form.Field>}

                        {!isGratis && hirePurchase &&
                        <BillCartInstallments/>}

                        <Form.Field>
                            <TextArea
                                value={note}
                                onChange={(event, data) => setNote(data.value?.toString()!)}
                                placeholder='Napomena...'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Popup content={!waitingFileUpload ?
                                'Izrada računa će biti brža, ali bez obaveštenja da je fajl uspešno otpremljen u skladište u oblaku' :
                                'Izrada računa će biti sporija, ali sa garancijom da je fajl uspešno otpremljen u skladište u oblaku'
                            }
                                   trigger={
                                       <Button
                                           fluid
                                           name='waitingFileUpload'
                                           icon={!waitingFileUpload ? 'rocket' : 'coffee'}
                                           content={!waitingFileUpload ? 'Žurim' : 'Sačekaj uspešno otpremanje fajla'}
                                           color={!waitingFileUpload ? 'orange' : undefined}
                                           className={waitingFileUpload ? '' : 'flareBackground'}
                                           onClick={() => setWaitingFileUpload(!waitingFileUpload)}
                                       />}/>
                        </Form.Field>
                        <Button
                            disabled={clientsLoading || productsLoading || !isFormValid()}
                            loading={submitting}
                            fluid
                            color={isGratis ? 'yellow' : 'blue'}
                            type='button'
                            onClick={handleSubmit}
                            content={'Gotovo'}
                            className={(isGratis && !clientsLoading && !productsLoading && isFormValid()) ? 'yellowedBackground' : ''} // TODO OVO JE LUDO
                        />
                        {errors.map((error, i) => (<Message key={i} color='red' content={error}/>))}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default observer(BillCart)
