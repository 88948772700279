import React, {useContext} from 'react';
import {Segment, Grid, Icon, Item, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom'
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IClient} from "../../../app/models/clients";
import {isMobile} from 'react-device-detect'

const ClientDetailedInfo: React.FC<{ client: IClient, toggleInfoSegmentVisible: any }> = ({
                                                                                              client,
                                                                                              toggleInfoSegmentVisible
                                                                                          }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return (
        <Segment.Group>
            <Segment attached='top' clearing>
                <Grid>
                    <Grid.Column width={1}>
                        <Icon size='large' color='teal' name='info'/>
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <Item.Group divided>
                            <Item>
                                <Item.Content>
                                    {/*<Item.Description>{client.publicNote}</Item.Description>*/}
                                    <Item.Meta>{client.privateNote}</Item.Meta>
                                </Item.Content>
                            </Item>

                        </Item.Group>
                    </Grid.Column>
                    <Grid.Column width={1}>
                        <Button floated='right' size='mini' icon='minus' onClick={toggleInfoSegmentVisible}/>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid>
                    <Grid.Column width={1}>
                        <Icon name='mail' size='large' color='teal'/>
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <span>
                            <a href={`mailto:${client.email}`}>{client.email}</a>, {isMobile ?
                            <a href={`tel:${client.telNumber}`}>{client.telNumber}</a> : <>{client.telNumber}</>}, {client.contactInfo}
            </span>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='marker' size='large' color='teal'/>
                    </Grid.Column>
                    <Grid.Column width={11}>
            <span>
                {client.address}, {client.postalCode} {client.city} , <Link
                to={`/${currentTraffic?.id}/regions/${client.regionId}`}>{client.regionName}</Link>
            </span>
                    </Grid.Column>
                </Grid>
            </Segment>
            {client.currentAccount && client.currentAccount.length > 0 &&
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='credit card' size='large' color='teal'/>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <span>
                            {client.currentAccount}   
                        </span>
                    </Grid.Column>
                </Grid>
            </Segment>}
        </Segment.Group>
    );
};

export default observer(ClientDetailedInfo)