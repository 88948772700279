import React, {FunctionComponent} from 'react';
import {IPrice} from "../../../app/models/products";
import {Header, List} from "semantic-ui-react";
import {formatEur, formatRsd} from "../../../app/common/util/format";

interface IProps {
    prices: IPrice[]
}

const ProductsTablePricesCell: FunctionComponent<IProps> = ({prices}) => {

    return (
        <List celled>
            {prices.map(price => {
                    if (price.value !== null) {
                        return (
                            <List.Item key={price.id}>
                                <Header 
                                    as='h5'
                                    color={price.isBase ? 'grey' : 
                                        price.isDefault ? 'blue' : 
                                            price.isSelling ? 'orange' :
                                                undefined}
                                    content={price.isBase ? `${price.pricelistName} ${formatEur(price.value)}` : `${price.pricelistName} ${formatRsd(price.value)}`}
                                />
                            </List.Item>
                        )
                    }
                    return ''
                }
            )}
        </List>
    );
};

export default ProductsTablePricesCell;
