
import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import Table from "../../../app/common/tables/Table";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IExpenseEntity} from "../../../app/models/expenses";
import {Cell, FilterProps} from "react-table";
import {formatRsd} from "../../../app/common/util/format";
import {Header} from "semantic-ui-react";
import {NumberFilter} from "../../../app/common/tables/filters/number/NumberFilter";
import MultiselectFilter from "../../../app/common/tables/filters/multiselect/MultiselectFilter";
import SumTableHeader from "../../../app/common/tables/headers/SumTableHeader";

interface IProps {
    trackingId?: string
}

const expensesNames = [
    'Nabavna vrednost',
    'Gratisi', 
    'Opšti troškovi', 
    'Troškovi rashoda proizvoda', 
    'Aktivnosti', 
    'Trošak prometi'
]

const expensesNamesAsOptions = expensesNames.map(expensesName => ({
    key: expensesName,
    value: expensesName,
    text: expensesName
}))        

const ExpensesList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {integratedAllExpenses} = rootStore.profitabilityStore


    const columns = useMemo(() => [
        {
            Header: 'Naziv',
            accessor: 'name',
            Cell: ({ row: { original: expense } }: Cell<IExpenseEntity>) => (<Header content={expense.name} style={{color: expense.color}} />),
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, expensesNamesAsOptions, 'Kategorija'),
            filter: 'multiselectFilter'
        },
        {
            Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Vrednost'}
                                    accessor={'cost'}/>,
            accessor: 'cost',
            Cell: ({ row: { original: expense } }: Cell<IExpenseEntity>) => (<>{formatRsd(expense.cost)}</>),
            Filter: NumberFilter,
            filter: 'numberFilter',
            sortType: 'numeric'
        }
    ], [])

    return (<Table
        columns={columns}
        data={integratedAllExpenses}
        trackingId={trackingId}
    />)
}

export default observer(ExpensesList)
