import React, {FunctionComponent, useContext, useEffect} from 'react';
import Table from "../../../app/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {ClientGoalType} from "../../../app/models/clientGoals";
import {Message} from "semantic-ui-react";
import {useClientGoalsColumns} from "./useClientGoalsColumns";


interface IProps {
    hideClient?: boolean,
    trackingId?: string,
    hideMetFilter?: boolean // todo setiti se zasto sam ovo stavio,
    type: ClientGoalType
}

const messageText = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment:
            return 'naplate'
        case ClientGoalType.clientRequest:
            return 'zahtevi klijenata'
        case ClientGoalType.classic:
            return 'ciljevi'
    }
}

const ClientGoalsList: FunctionComponent<IProps> = ({
                                                        hideClient = false,
                                                        trackingId,
                                                        hideMetFilter,
                                                        type
                                                    }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        clientGoalsFilteredClassic,
        clientGoalsFilteredPayment,
        clientGoalsFilteredClientRequests,
        loadClientGoalsCategories,
    } = rootStore.clientGoalsStore
    const {loadRegionsIfNull} = rootStore.regionsStore
    const {loadClientCategoriesIfNull} = rootStore.clientsStore
    const {loadUsers} = rootStore.usersStore
    const {columns} = useClientGoalsColumns({hideClient, type});


    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
        loadClientGoalsCategories(true)
        loadUsers(true) // for participants
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull,
        loadClientGoalsCategories,
        loadUsers
    ])

    const data = (type: ClientGoalType) => {
        switch (type) {
            case ClientGoalType.payment:
                return clientGoalsFilteredPayment
            case ClientGoalType.clientRequest:
                return clientGoalsFilteredClientRequests
            case ClientGoalType.classic:
                return clientGoalsFilteredClassic
        }
    }
    
    if (data.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje {messageText(type)} po zadatom
                    kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (
        <Table
            trackingId={trackingId}
            columns={columns}
            data={data(type)}/>
    )
}

export default observer(ClientGoalsList)
