import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, composeValidators, isRequired, isRequiredIf} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import NumberInput from "../../../app/common/form/NumberInput";
import {IOnHoldProductFormValues, OnHoldProductFormValues} from "../../../app/models/onHoldProduct";
import DateInput from "../../../app/common/form/DateInput";
import {isNumberGreaterThan} from "../../../app/common/validators/general";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";

interface DetailParams {
    id: string;
}

const validate = combineValidators({
    clientId: isRequired({message: 'Klijent je obavezan'}),
    //@ts-ignore
    productId: isRequiredIf((values: IOnHoldProductFormValues) => !values.isOther)({message: 'Prozivod je obavezan'}),
    //@ts-ignore
    otherContent: isRequiredIf((values: IOnHoldProductFormValues) => values.isOther)({message: 'Opis nestandardnog proizvoda je obavezan'}),
    count: composeValidators(
        isRequired({message: 'Količina je obavezana'}),
        isNumberGreaterThan(0)({message: 'Količina mora biti pozitivan broj'})
    )(),
    date: isRequired({message: 'Datum je obavezan'}),
})

const OnHoldProductForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadOnHoldProduct,
        createOnHoldProduct,
        editOnHoldProduct,
        submitting
    } = rootStore.onHoldProductsStore
    const {productsByNameArray: products, loadProducts, loading: loadingProducts} = rootStore.productsStore

    const [onHoldProduct, setOnHoldProduct] = useState<IOnHoldProductFormValues>(new OnHoldProductFormValues())
    const [loading, setLoading] = useState(false)

    const [isOtherUiState, setIsOtherUiState] = useState(false)

    useNavbarSelectedItem(match.params.id ? 'onHoldProductsEdit' : 'onHoldProductsCreate')

    useEffect(() => {
        loadProducts()
        if (match.params.id) {
            setLoading(true)
            loadOnHoldProduct(match.params.id)
                .then(onHoldProduct => {
                    setOnHoldProduct(new OnHoldProductFormValues(onHoldProduct))
                    setIsOtherUiState(onHoldProduct.isOther)
                })
                .finally(() => setLoading(false))
        } else {
            setOnHoldProduct(new OnHoldProductFormValues())
        }
        loadClients()

    }, [
        match.params.id,
        loadClients,
        currentTraffic,
        loadOnHoldProduct,
        loadProducts,
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const onHoldProduct = {...values, count: Number(values.count)} as IOnHoldProductFormValues

        if (onHoldProduct.isOther) {
            onHoldProduct.productId = uuid()
        }

        if (!onHoldProduct.id) {
            let newOnHoldProduct = {
                ...onHoldProduct,
                id: uuid()
            }

            return createOnHoldProduct(newOnHoldProduct)
        } else {
            return editOnHoldProduct(onHoldProduct)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                          text: 'Proizvodi na čekanju',
                          linkWithoutCurrentTrafficId: 'onHoldProducts'  
                        },
                        {
                            text: match.params.id ? 'Izmeni proizvod na čekanju' : 'Dodaj proizvod na čekanju',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IOnHoldProductFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        validate={validate}
                        initialValues={onHoldProduct}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingClients || loadingProducts}>

                                <Field
                                    search
                                    name='clientId'
                                    placeholder='Klijent'
                                    value={onHoldProduct.clientId}
                                    options={clients.map(client => ({
                                        key: client.id,
                                        value: client.id,
                                        text: client.name
                                    }))}
                                    component={SelectStringInput}/>

                                <Field
                                    name='isOther'
                                    type='checkbox'
                                    render={() => <Form.Field>
                                        <Checkbox
                                            toggle
                                            label='Nestandardan proizvod'
                                            checked={isOtherUiState}
                                            onChange={(event, data) => {
                                                const isChecked: boolean = data.checked!
                                                setIsOtherUiState(isChecked)
                                                form.change<'isOther'>('isOther', isChecked)
                                            }}/>
                                    </Form.Field>}/>

                                {isOtherUiState ?
                                    <Field
                                        name='otherContent'
                                        placeholder='Opis nestandardnnog proizvoda'
                                        value={onHoldProduct.otherContent}
                                        component={TextAreaInput}/> :

                                    <Field
                                        search
                                        name='productId'
                                        placeholder='Proizvod'
                                        value={onHoldProduct.productId}
                                        options={products.map(product => ({
                                            key: product.id,
                                            value: product.id,
                                            text: `[${product.brandName}] ${product.name} - ${product.sku}`
                                        }))}
                                        component={SelectStringInput}/>}

                                <Field
                                    name='count'
                                    placeholder='Količina'
                                    value={onHoldProduct.count}
                                    component={NumberInput}
                                    min={1}
                                    max={1000000}
                                    type='number'/>

                                <Field
                                    component={DateInput}
                                    name='date'
                                    date={true}
                                    placeholder='Datum'
                                    value={onHoldProduct.date}
                                />

                                <Field
                                    name='note'
                                    placeholder='Napomena'
                                    value={onHoldProduct.note}
                                    component={TextInput}/>

                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!onHoldProduct.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={onHoldProduct.id ?
                                        () => history.push(`/${currentTraffic?.id}/onHoldProducts`) :
                                        () => history.push(`/${currentTraffic?.id}/onHoldProducts`)}
                                    disabled={loading || loadingClients || loadingProducts}
                                    floated='right'
                                    type='button'
                                    content='Odustani'
                                />
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(OnHoldProductForm)
