import React, {FunctionComponent, useContext} from 'react'
import {
    getClientTourStatusColorName,
    getClientTourStatusSerbianString,
    IClientTour
} from "../../../app/models/clientTours";
import {Button, Divider, Grid, Header, Icon, Label, Segment} from "semantic-ui-react";
import ClientTableBasicCell from "../../clients/list/ClientTableBasicCell";
import moment from "moment";
import {DATE_TIME_HUMAN_FORMAT_STRING_WO_SECONDS} from "../../../app/common/util/date";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {history} from "../../../index";
import {observer} from "mobx-react-lite";

interface OwnProps {
    clientTour: IClientTour,
    onEditClick?: () => void,
    onCancelClick?: () => void,
    cancelText?: string,
    onDeleteSuccess?: () => void,
    outsideModal?: boolean
}

type Props = OwnProps

const ClientTourDetails: FunctionComponent<Props> = ({
                                                         clientTour,
                                                         cancelText,
                                                         onCancelClick,
                                                         onEditClick,
                                                         onDeleteSuccess,
                                                         outsideModal
                                                     }) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {deleteClientTour} = rootStore.clientToursStore
    const {openModal, closeModal} = rootStore.modalStore

    return <Segment clearing>
        <Header>
            <Header.Subheader>
                <ClientTableBasicCell client={clientTour.client}/>

            </Header.Subheader>
        </Header>

        <Label
            color={getClientTourStatusColorName(clientTour.status)}>{getClientTourStatusSerbianString(clientTour.status)}</Label>

        <Divider/>

        <Grid>

            <Grid.Row>
                <Grid.Column width={1}>
                    <Icon name='time' color='blue'/>
                </Grid.Column>
                <Grid.Column width={15}>
                    {`${moment(clientTour.startDateTime).format(DATE_TIME_HUMAN_FORMAT_STRING_WO_SECONDS)} - ${moment(clientTour.endDateTime).format(DATE_TIME_HUMAN_FORMAT_STRING_WO_SECONDS)}`}
                </Grid.Column>
            </Grid.Row>

            {clientTour.location && <Grid.Row>
                <Grid.Column width={1}>
                    <Icon name='point' color='blue'/>
                </Grid.Column>
                <Grid.Column width={15}>
                    {clientTour.location}
                </Grid.Column>
            </Grid.Row>}

            {clientTour.note &&
            <Grid.Row>
                <Grid.Column width={1}>
                    <Icon name='info' color='blue'/>
                </Grid.Column>
                <Grid.Column width={15}>
                    {clientTour.note}
                </Grid.Column>
            </Grid.Row>}

        </Grid>

        <Divider hidden/>

        <Button
            onClick={() => {
                if (onEditClick) {
                    onEditClick()
                } else {
                    history.push(`/${currentTraffic?.id}/clientTours/manage/${clientTour.id}/edit`)
                }
            }}
            icon
            labelPosition='left'
            color='orange'
            floated='right'>
            <Icon name='edit'/>
            Izmeni
        </Button>

        <Button
            icon='trash alternate'
            color='red'
            loading={(clientTour.flags.deleting)}
            floated='right'
            onClick={() => openModal(
                <Segment basic clearing>
                    <Header textAlign='center' as='h2'>
                        Brisanje obilaska
                        <Icon color='red' name='warning circle'/>
                    </Header>
                    <p style={{textAlign: 'center'}}>
                        Da li zaista želiš da obrišeš obilazak <strong>{clientTour.client.name} ?</strong>
                    </p>
                    <Button color='red' floated='right' onClick={(event: any, data) => {
                        event.target.className += ' loading'
                        deleteClientTour(clientTour.id)
                            .then(() => {
                                closeModal()
                                if (onDeleteSuccess) {
                                    onDeleteSuccess()
                                }
                            })
                    }}>
                        <Icon name='checkmark'/> Da
                    </Button>
                    <Button onClick={() => closeModal()} floated='right'>
                        <Icon name='remove'/> Ne
                    </Button>
                </Segment>, 'small'
            )}/>

        {!outsideModal
        && <Button
            floated='right'
            onClick={() => {
                if (onCancelClick) {
                    onCancelClick()
                } else {
                    // default behavior
                    history.push(`/${currentTraffic?.id}/clientTours`)
                }
            }}>
            {cancelText}
        </Button>}
    </Segment>

}

ClientTourDetails.defaultProps = {
    cancelText: 'Odustani'
}


export default observer(ClientTourDetails)

