import React, {FunctionComponent, useContext, useState} from 'react'
import FileUploadForm from "../../../fileUpload/FileUploadForm";
import {Button, Icon} from "semantic-ui-react";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    fluid?: boolean
}

type Props = OwnProps

const PostExpressAddDocument: FunctionComponent<Props> = ({ fluid }) => {
    const rootStore = useContext(RootStoreContext)
    const {addDocument, submitting} = rootStore.postExpressDocumentsStore


    const [addDocumentMode, setAddDocumentMode] = useState(false)


    return addDocumentMode ?
        <FileUploadForm
            fields={[
                {
                    name: 'name',
                    type: 'text',
                    placeholder: 'Naziv',
                    required: true
                },
                {
                    name: 'date',
                    type: 'date',
                    placeholder: 'Datum',
                    required: true
                }
            ]}
            submitting={submitting}
            onSubmit={values => {
                return addDocument(values)
                    .then(() => setAddDocumentMode(false))
            }}
            onCancel={() => setAddDocumentMode(false)}
        />
        : <Button
            fluid={fluid}
            onClick={() => setAddDocumentMode(true)}
            labelPosition='left'
            floated='right'
            color='yellow'
            icon>
            <Icon
                name='add'/>
            Dodaj dokument
        </Button>
}

export default observer(PostExpressAddDocument)
