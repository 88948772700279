import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Button, Segment} from "semantic-ui-react";
import ClientGoalsList from "../../../clientGoals/list/ClientGoalsList";
import {ClientGoalType} from "../../../../app/models/clientGoals";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";

interface IProps {
    type: ClientGoalType,
    clientId: string
}

const ClientDetailsGoals: FunctionComponent<IProps> = ({type, clientId}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {year} = rootStore.clientsStore
    const {setPredicate, loadClientGoals, loading, clearPredicate} = rootStore.clientGoalsStore

    useEffect(() => {
        clearPredicate()
        setPredicate('type', type.toString())
        setPredicate('clientId', clientId)
        setPredicate('startDate', `${year}-01-01`)
        setPredicate('endDate', `${year}-12-31`)
        loadClientGoals()

    }, [
        loadClientGoals,
        setPredicate,
        clientId,
        type,
        year,
        clearPredicate
    ])


    return (<Segment loading={loading}>
        <Button
            content={type === ClientGoalType.payment ? 'Dodaj naplatu' : 'Dodaj cilj'}
            as={Link}
            to={`/${currentTraffic?.id}/clientGoals/create?type=${type}&clientId=${clientId}`}
        />
        <ClientGoalsList type={type} hideClient/>
    </Segment>);
};

export default observer(ClientDetailsGoals)
