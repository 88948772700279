import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Button, Grid, Icon, Segment, Select} from "semantic-ui-react";
import {Link, useLocation} from "react-router-dom";
import ScheduleSectionLayout from "../../scheduleSection/layout/ScheduleSectionLayout";
import {RootStoreContext} from "../../../app/stores/rootStore";
import EmployeeGoalsList from "../list/EmployeeGoalsList";
import {observer} from "mobx-react-lite";
import {PdfService} from "../../../app/common/pdf/PdfService";
import autoTable from "jspdf-autotable";
import {employeeGoalStatusToString, EmployeeGoalType, IEmployeeGoal} from "../../../app/models/employeeGoals";
import moment from "moment";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import {semanticColorToHash} from "../../../app/common/util/semanticUi";
import {useResponsive} from "../../../app/common/responsive/Responsive";

const TRACKING_ID = 'employeeGoals'

interface IProps {
}

const EmployeeGoalsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loading,
        setPredicate,
        setPredicateFromFilter,
        firstFilter,
        setFirstFilter,
        secondFilter,
        setSecondFilter,
        clearPredicate,
        loadEmployeeGoals
    } = rootStore.employeeGoalsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const { isMobile} = useResponsive()
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    
    const query = useQuery()
    const type: EmployeeGoalType = query.get('type') ? Number(query.get('type'))  : EmployeeGoalType.classic


    useNavbarSelectedItem(type === EmployeeGoalType.problem ? 'employeeGoals-problems' :
        type === EmployeeGoalType.private ? 'employeeGoals-private' :
        'employeeGoals')

    useEffect(() => () => {
        removeFromRegistry(TRACKING_ID)
    }, [
        removeFromRegistry
    ])


    useEffect(() => {
        clearPredicate()
        setPredicate('type', type.toString())
        setPredicateFromFilter()
        loadEmployeeGoals()
    }, [
        setPredicateFromFilter,
        setPredicate,
        type,
        clearPredicate,
        loadEmployeeGoals,
        firstFilter,
        secondFilter
    ])

    const exportPdf = () => {
        const doc = new PdfService("landscape").doc

        const colorator = (color: 'red' | 'blue' | 'orange' | '') => {
            switch (color) {
                case "blue":
                    return 'Redovna aktivnost'
                case "orange":
                    return 'U planu'
                case "red":
                    return 'Prioritetno'
                default:
                    return 'N/A'
            }
        }

        doc.text(type === EmployeeGoalType.problem ? `Tabela uočenih problema` :
            type === EmployeeGoalType.private ? `Tabela privatnih obaveza` :
            `Tabela tekućih obaveza`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            willDrawCell: data => {
                if (data.row.section === 'body' && data.column.index === 1) {
                    // @ts-ignore
                    switch (data.row.raw[2]) {
                        case 'Redovna aktivnost':
                            doc.setTextColor(semanticColorToHash('blue'))
                            break
                        case 'U planu':
                            doc.setTextColor(semanticColorToHash('orange'))
                            break
                        case 'Prioritetno':
                            doc.setTextColor(semanticColorToHash('red'))
                            break
                    }
                }
            },
            startY: 75,
            head: [
                ['#', 'Naziv', 'Prioritet', 'Datum', 'Status']
            ],
            body: SHIT_getFilteredRowsById(TRACKING_ID)?.map((employeeGoal: IEmployeeGoal, index) => ([
                index + 1,
                employeeGoal.name,
                colorator(employeeGoal.color),
                employeeGoal.date ? moment(employeeGoal.date).format('l') : 'N/A',
                employeeGoalStatusToString(employeeGoal)
            ])) ?? []
        })

        const fileName = type === EmployeeGoalType.problem ? 'UočeniProblemi.pdf' :
            type === EmployeeGoalType.private ? 'PrivatneObaveze.pdf' :
            'TekućeObaveze.pdf'
        doc.save(fileName)
    }

    return (<ScheduleSectionLayout
        // contentLoading={loading /* || loadingYearsWithAny */}
    >
        <Grid as={Segment} style={{marginTop: 0}} loading={loading}>
            <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Select
                        options={[
                            {key: 'all', value: 'all', text: 'Sve'},
                            {key: 'current', value: 'current', text: 'Aktuelan'},
                            {key: 'failed', value: 'failed', text: 'Neispunjen'},
                            {key: 'met', value: 'met', text: 'Ispunjen'},
                            {key: 'currentPartlyMet', value: 'currentPartlyMet', text: 'Delimično ispunjeno'},
                        ]}
                        value={firstFilter}
                        onChange={((event, data) => setFirstFilter(data.value as 'all' | 'current' | 'failed' | 'met'))}
                    />
                    {/*Second filter*/}
                    {firstFilter === 'current' &&
                    <Select
                        options={[
                            {key: 'currentAll', value: 'currentAll', text: 'Sve'},
                            {key: 'currentBasic', value: 'currentBasic', text: 'U roku'},
                            {key: 'currentPartlyMet', value: 'currentPartlyMet', text: 'Delimično ispunjen'},
                            {key: 'currentExpired', value: 'currentExpired', text: 'Istekao'}
                        ]}
                        value={secondFilter}
                        onChange={((event, data) => setSecondFilter(data.value as 'currentAll' | 'currentBasic' | 'currentPartlyMet' | 'currentExpired'))}
                    />}
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Button
                        as={Link}
                        to={`/${currentTraffic?.id}/employeeGoals/create?type=${type}`}
                        floated='right'
                        color='blue'
                        icon
                        fluid={isMobile}
                        labelPosition='left'>
                        <Icon name='add'/>
                        Dodaj {type === EmployeeGoalType.problem ? 'uočeni problem' :
                        type === EmployeeGoalType.private ? 'privatnu obavezu' :
                        'tekuću obavezu'}
                    </Button>
                    <Button
                        fluid={isMobile}
                        disabled={loading}
                        icon
                        labelPosition='left'
                        floated='right'
                        color='teal'
                        onClick={() => exportPdf()}>
                        <Icon name='file pdf'/>
                        PDF
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <EmployeeGoalsList type={type} hideMetFilter trackingId={TRACKING_ID}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </ScheduleSectionLayout>)
}

export default observer(EmployeeGoalsDashboard)
