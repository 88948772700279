import React, {FunctionComponent, useContext} from 'react'
import {Link} from 'react-router-dom'
import {Avatar, Tooltip} from "antd";
import {getNiceColorFromStingAsciiSum} from "../../../app/common/util/colors";
import {IUser} from "../../../app/models/user";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";

interface OwnProps {
    user: IUser
}

type Props = OwnProps

const UserIdentity: FunctionComponent<Props> = ({user}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore

    return <Link 
        style={{ padding: 0, margin: 0 }} 
        to={`/${currentTraffic}/profile/${user.username}`}>
        <Tooltip
            title={user.displayName} placement='top'>
            {user.image ?
                <Avatar
                    src={user.image}
                /> :
                <Avatar
                    style={{
                        backgroundColor: getNiceColorFromStingAsciiSum(user.id),
                    }}>{user.displayName[0]}</Avatar>}
        </Tooltip>
    </Link>

}

export default observer(UserIdentity)
