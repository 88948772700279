import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {v4 as uuid} from 'uuid';
import {BrandFormValues, IBrandFormValues} from "../../../app/models/brands"
import {SketchPicker} from 'react-color'
import TextInput from "../../../app/common/form/TextInput";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    color: isRequired({message: 'Boja je obavezna'})
});

interface DetailParams {
    id: string;
}

const BrandForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext);
    const {
        createBrand,
        loadBrand,
        editBrand,
        submitting
    } = rootStore.brandsStore
    const {currentTraffic} = rootStore.trafficsStore

    const [brand, setBrand] = useState<IBrandFormValues>(new BrandFormValues())
    const [loading, setLoading] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'brandsCreate')


    useEffect(() => {
            if (match.params.id) {
                setLoading(true)
                loadBrand(Number(match.params.id))
                    .then(brand => setBrand(new BrandFormValues(brand)))
                    .finally(() => setLoading(false))

            } else {
                setBrand(new BrandFormValues())
            }

        }, [
            match.params.id,
            loadBrand
        ]
    )


    const handleFinalFormSubmit = (values: any) => {
        const brand = {...values}

        if (!brand.id) {
            let newBrand = {
                ...brand,
                id: uuid()
            }

            return createBrand(newBrand)
                .then(() => {
                    history.push(`/${currentTraffic?.id}/brands`)
                })
        } else {

            return editBrand(brand)
                .then(() => {
                    history.push(`/${currentTraffic?.id}/brands`)
                })
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Brendovi',
                            linkWithoutCurrentTrafficId: 'brands'
                        },
                        {
                            text: match.params.id ? 'Izmena brenda' : 'Dodavanje brenda',
                            active: true
                        }
                    ]}
                />

            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={8}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: IBrandFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={brand}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit,
                                            form

                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading}>

                                       <Field
                                           name='name'
                                           component={TextInput}
                                           value={brand.name}
                                       />

                                       <Field
                                           hidden
                                           name='color'
                                           component='input'
                                           value={brand.color}
                                       />

                                       <Grid centered padded>
                                           <Grid.Column>
                                               <SketchPicker width='98%'
                                                             color={form.getState().values.color || 'black'}
                                                             onChange={((color, event) => form.change('color', color.hex))}
                                               />
                                           </Grid.Column>
                                       </Grid>


                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!brand.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={brand.id ? () => history.push(`/${currentTraffic?.id}/brands/${brand.id}`) :
                                               () => history.push(`/${currentTraffic?.id}/brands`)}
                                           disabled={loading}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(BrandForm)
