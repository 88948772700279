import React, {Fragment, FunctionComponent, useContext} from 'react';
import {Segment, Item, Button, Icon, Header} from "semantic-ui-react";
import {
  IDocumentationDocument
} from "../../../app/models/documentations";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import {FcPlus} from "react-icons/all";
import DocumentationDocumentForm from "../form/DocumentationDocumentForm";
import moment from "moment";

interface IProps {
  documents: IDocumentationDocument[]
}

const DocumentsDetailsDocuments: FunctionComponent<IProps> = ({documents}) => {
    const rootStore = useContext(RootStoreContext)
    const { 
        downloadingDocumentId, 
        getFile,
        deleteDocument,
        documentDeletingId,
        addingDocumentMode,
        setAddingDocumentMode,
        documentation
    } = rootStore.documentationsStore
    const { closeModal, openModal } = rootStore.modalStore
        
  return (
      <Fragment>
        <Segment
            textAlign='center'
            style={{border: 'none'}}
            attached='top'
            secondary
            inverted
            color='blue'
        >
          Dokumenta
        </Segment>
          
        <Segment attached>
            {addingDocumentMode ? 
                <DocumentationDocumentForm documentation={documentation!} />
                : <Button
                basic
                icon
                onClick={() => setAddingDocumentMode(true)}>
                <Icon>
                    <FcPlus/>
                </Icon>

                Dodaj dokument
            </Button>}            
          <Item.Group divided>
              {documents
                  .sort((a, b) => moment(a.file?.updatedAt).isBefore(b.file?.updatedAt) ? 1 : -1)
                  .map(document => (
                  <Item key={document.id}>
                      <Item.Content>
                          <Item.Header>{document.name}</Item.Header>
                          <Item.Meta>
                              <span>{document.file?.extension.replace('.', '').toUpperCase() ?? 'N/A'}</span>
                          </Item.Meta>
                          <Item.Extra>
                              <Button
                                  size='mini' 
                                  icon color='red' 
                                  floated='right'
                                  loading={documentDeletingId === document.id}
                                  onClick={() => openModal(
                                      <Segment basic clearing>
                                          <Header textAlign='center' as='h2'>
                                              Brisanje dokumenta
                                              <Icon color='red' name='warning circle'/>
                                          </Header>
                                          <p style={{textAlign: 'center'}}>
                                              Da li zaista želiš da obrišeš dokument <strong>{document.name} ?</strong>
                                          </p>
                                          <Button color='red' floated='right' onClick={(event: any) => {
                                              event.target.className += ' loading'
                                              deleteDocument(document.id!)
                                                  .then(() => closeModal())
                                          }}>
                                              <Icon name='checkmark'/> Da
                                          </Button>
                                          <Button onClick={() => closeModal()} floated='right'>
                                              <Icon name='remove'/> Ne
                                          </Button>
                                      </Segment>, 'small'
                                  )}  
                              >
                                  <Icon name='trash' />
                                  Obriši
                              </Button>
                              <Button
                                  loading={document.id === downloadingDocumentId}
                                  onClick={() => getFile(document.id)}
                                  size='mini' 
                                  icon color='blue' 
                                  floated='right'>
                                  <Icon name='download' />
                                  Preuzmi
                              </Button>
                          </Item.Extra>
                          <Item.Meta>
                              {moment(document.file?.updatedAt).format('l')}
                          </Item.Meta>
                      </Item.Content>
                  </Item>
                  )) 
              }
          </Item.Group>
        </Segment>
      </Fragment>
  )
}

export default observer(DocumentsDetailsDocuments)
