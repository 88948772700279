import {IPeriodAnalyzeEntity, MONTH_YEAR_FORMAT} from "../../../../app/models/billOrders";
import {useContext, useMemo, useState} from "react";
import {SemanticCOLORS} from "semantic-ui-react";
import {semanticColorToHash} from "../../../../app/common/util/semanticUi";
import moment from "moment";
import {IProfitabilityLabels, LabelType, profitabilityPeriodsTrackingId} from "../periods/ProfitabilityPeriods";
import {RootStoreContext} from "../../../../app/stores/rootStore";

export const useProfitabilityPeriods = () => {
    const rootStore = useContext(RootStoreContext);
    const { SHIT_getFilteredRowsById } = rootStore.tablesStore;
    const [activeLabels, setActiveLabels] = useState<IProfitabilityLabels>({
        profit: true,
        expense: true,
        income: true,
        ruc: true
    })
    
    // eslint-disable-next-line
    const filteredData: IPeriodAnalyzeEntity[] = useMemo(() => SHIT_getFilteredRowsById(profitabilityPeriodsTrackingId) ?? [], [SHIT_getFilteredRowsById(profitabilityPeriodsTrackingId)])

    const labels: { id: LabelType, title: string, color: SemanticCOLORS }[] = useMemo(() => [
        {
            id: 'profit',
            title: 'Profit',
            color: 'blue',
        },
        {
            id: 'expense',
            title: 'Troškovi',
            color: 'red'
        },
        {
            id: 'income',
            title: 'Prihod',
            color: 'green'
        },
        {
            id: 'ruc',
            title: 'RUC',
            color: 'yellow'
        }
    ], [])

    const entries = useMemo(() => [
        {
            title: 'Profit',
            color: semanticColorToHash('blue'),
            data: filteredData.map(el => ({
                key: moment(el.monthYearString, MONTH_YEAR_FORMAT).toDate(),
                b: el.profit
            })),
            hide: !activeLabels.profit
        },
        {
            title: 'Troskovi',
            color: semanticColorToHash('red'),
            data: filteredData.map(el => ({
                key: moment(el.monthYearString, MONTH_YEAR_FORMAT).toDate(),
                b: el.expense
            })),
            hide: !activeLabels.expense
        },
        {
            title: 'Prihod',
            color: semanticColorToHash('green'),
            data: filteredData.map(el => ({
                key: moment(el.monthYearString, MONTH_YEAR_FORMAT).toDate(),
                b: el.summaryPriceWithDiscountWithBillDiscount
            })),
            hide: !activeLabels.income
        },
        {
            title: 'Ruc',
            color: semanticColorToHash('yellow'),
            data: filteredData.map(el => ({
                key: moment(el.monthYearString, MONTH_YEAR_FORMAT).toDate(),
                b: el.differenceInPrice
            })),
            hide: !activeLabels.ruc
        },
    ], [activeLabels, filteredData]);
    
    
    return {
        labels,
        entries,
        activeLabels,
        setActiveLabels
    }
}