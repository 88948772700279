import React, {FunctionComponent, useContext, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import Table from "../../../app/common/tables/Table";
import {IBrand} from "../../../app/models/brands";
import {Cell} from "react-table";
import BrandBasicCell from "../BrandBasicCell";
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";

interface IProps {
}

const BrandsList: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {brandsArray: brands, deletingId, deleteBrand} = rootStore.brandsStore
    const { currentTraffic } = rootStore.trafficsStore
    const { openModal, closeModal } = rootStore.modalStore
 
    const columns = useMemo(() => [
            {
                Header: 'Naziv',
                accessor: 'name',
                Cell: ({row: {original: brand}}: Cell<IBrand>) => <BrandBasicCell brand={brand}/>
            },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: brand}}: Cell<IBrand>) => (
                    <Button.Group>
                        <Button as={Link} to={`/${currentTraffic?.id}/brands/manage/${brand.id}/edit`} icon='edit'
                                color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje brenda
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš brend <strong>{brand.name} ?</strong>
                                </p>

                                <p style={{textAlign: 'center'}}>
                                    Brisanjem brenda biće proizvodi i stavke sa računa vezane za ovaj brend
                                </p>
                                
                                <Button
                                    color='red' 
                                    floated='right' 
                                    onClick={(event: any) => {
                                    event.target.className += ' loading'
                                    deleteBrand(brand.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={deletingId === brand.id}/>
                    </Button.Group>)
            }
        ],
        [
            closeModal,
            openModal,
            currentTraffic,
            deleteBrand,
            deletingId
        ])


    if (brands.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje brendovi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (<Table
        columns={columns}
        data={brands}
    />)
}

export default observer(BrandsList)
