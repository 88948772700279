import React, {FunctionComponent, useContext, useMemo} from 'react'
import Table from "../../../../app/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {Cell} from "react-table";
import {IPostExpressDocument} from "../../../../app/models/postExpressDocuments";
import {Button, Header, Icon, Loader, Message, Segment} from "semantic-ui-react";
import moment from "moment";
import {DateFilter} from "../../../../app/common/tables/filters/date/DateFilter";

interface OwnProps {
}

type Props = OwnProps

const PostExpressDocumentsList: FunctionComponent<Props> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        postExpressDocumentsArray: postExpressDocuments,
        deleteDocument,
        documentDeletingId,
        getFile,
        downloadingDocumentId
    } = rootStore.postExpressDocumentsStore
    const {openModal, closeModal} = rootStore.modalStore

    const columns = useMemo(() => [
        {
            Header: 'Naziv',
            accessor: 'name',
            Cell: ({row: {original: postExpressDocument}}: Cell<IPostExpressDocument>) => downloadingDocumentId === postExpressDocument.id
                ? <Loader active inline/>
                : <Header
                    style={{cursor: 'pointer'}}
                    onClick={() => getFile(postExpressDocument.id)}
                >
                    {postExpressDocument.name}
                    <Header.Subheader>
                        {postExpressDocument.file.extension}
                    </Header.Subheader>
                </Header>
        },
        {
            id: 'date',
            Header: 'Datum',
            accessor: (d: IPostExpressDocument) => d.date,
            Cell: ({row: {original: postExpressDocument}}: Cell<IPostExpressDocument>) => moment(postExpressDocument.date).format('l'),
            Filter: DateFilter,
            filter: 'dateFilter',
        },
        {
            Header: 'Kontrole',
            Cell: ({row: {original: postExpressDocument}}: Cell<IPostExpressDocument>) => (
                <Button.Group fluid>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje PostExpress dokumenta
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš PostExpress dokument
                                <strong>{postExpressDocument.name}</strong>
                                za datum <strong>{moment(postExpressDocument.date).format('l')} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteDocument(postExpressDocument)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={documentDeletingId === postExpressDocument.id}/>
                </Button.Group>
            )
        }

    ], [
        closeModal,
        openModal,
        deleteDocument,
        documentDeletingId,
        downloadingDocumentId,
        getFile
    ])

    if (postExpressDocuments.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje dokumenti po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return <Table
        columns={columns}
        data={postExpressDocuments}
    />
}

export default observer(PostExpressDocumentsList)
