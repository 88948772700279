import React, {FunctionComponent, useContext} from 'react';
import {Button, Divider, Header, Icon, Item, Label, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {IClient} from "../../../app/models/clients";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {observer} from "mobx-react-lite";
import ClientDetailsGenerateCardModal from "./header/ClientDetailsGenerateCardModal";
import {clientStatusToColorName} from "../../../app/common/clients/util";
import UserBasicCell from "../../user/table/UserBasicCell";
import Responsive from "../../../app/common/responsive/Responsive";


interface IProps {
    client: IClient,
    year: string
}

const activityImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};


const ClientDetailedHeader: FunctionComponent<IProps> = ({client}) => {
    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        focusClient,
        focusingId,
        unfocusClient,
        unfocusingId
    } = rootStore.clientsStore
    const {isEmployee} = rootStore.userStore


    const handleFocusing = (id: string, focus: boolean) => {
        if (focus) {
            focusClient(id)
        } else {
            unfocusClient(id)
        }
    }

    return (
        <Segment.Group>
            <Segment
                basic
                attached='top'
                className={client.type === 'Aktivan' ? `${clientStatusToColorName(client.status!)}Background` : 'greyBackground' }
                style={{height: '15em'}}>

                {client.agent &&
                <Label
                    basic
                    style={{backgroundColor: 'rgba(0,  0,  0,  0.25)'}}
                    attached='top right'>

                    <Responsive.NotMobile>
                        <UserBasicCell
                            color='white'
                            imageSize='mini'
                            textSize='tiny'
                            avatar
                            user={client.agent}
                        />
                    </Responsive.NotMobile>

                    <Responsive.Mobile>
                        <UserBasicCell
                            color='white'
                            imageSize='mini'
                            textSize='tiny'
                            avatar
                            user={client.agent}
                            hideImage   //todo temp solution
                        />
                    </Responsive.Mobile>
                </Label>}

                <Segment style={activityImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={client.name}
                                    style={{color: 'white'}}
                                />
                                <p>{client.category}</p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached='bottom'>
                {client.isInFocus &&
                <Label size='small' color='violet' content='Aktuelan'/>}
                <Label size='small' icon='tag' content={client.type}/>
                {client.type === 'Aktivan' &&
                <Label size='small' icon='tag' content={client.status} color={clientStatusToColorName(client.status!)}/>}
                <Label size='small' color={client.pricelistCategoryIsSelling ? undefined : 'olive'} icon='dollar'
                       content={client.pricelistCategoryName}/>

                <Divider/>

                <ClientDetailsGenerateCardModal text='Generiši karticu' client={client}/>

                {isEmployee &&
                <Button
                    as={Link}
                    to={`/${currentTraffic?.id}/clients/manage/${client.id}/edit`}
                    color='orange'
                    floated='right'
                >
                    <Icon name='edit'/>
                    Izmeni klijenta
                </Button>}

                <Button
                    floated='right'
                    content={client.isInFocus ? 'Deaktuelizuj klijenta' : 'Aktuelizuj klijenta'}
                    loading={focusingId === client.id || unfocusingId === client.id}
                    disabled={focusingId === client.id || unfocusingId === client.id}
                    color={client.isInFocus ? 'violet' : undefined}
                    onClick={() => handleFocusing(client.id!, !client.isInFocus)}
                />

                {/*<Button*/}
                {/*    floated='right'*/}
                {/*    content={client.isInFocus ? 'Deaktuelizuj klijenta' : 'Aktuelizuj klijenta'}*/}
                {/*    loading={focusingId === client.id || unfocusingId === client.id}*/}
                {/*    disabled={focusingId === client.id || unfocusingId === client.id}*/}
                {/*    color={client.isInFocus ? 'violet' : undefined}*/}
                {/*    onClick={() => handleFocusing(client.id!, !client.isInFocus)}/>*/}

            </Segment>
        </Segment.Group>)
};

export default observer(ClientDetailedHeader)
