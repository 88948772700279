import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import PaymentsList from "../../../payments/list/PaymentsList";
import {Button, Grid, Header, Icon, Input, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../app/stores/rootStore";
import {formatRsd} from "../../../../app/common/util/format";

interface IProps {
    clientId: string,
    year: string
}

const ClientDetailsPayments: FunctionComponent<IProps> = ({ clientId, year }) => {
    const rootStore = useContext(RootStoreContext)
    const { client, editPaymentNote, submitting } = rootStore.clientsStore
    const { loadPayments, setPredicate, clearPredicate } = rootStore.paymentsStore

    const [editingPaymentNote, setEditingPaymentNote] = useState<string | null>(null)

    useEffect(() => {
        clearPredicate()
        setPredicate('clientId', clientId)
        setPredicate('startDate', `${year}-01-01`)
        setPredicate('endDate', `${year}-12-31`)
        loadPayments()

    }, [
        loadPayments,
        setPredicate,
        clientId,
        year,
        clearPredicate
    ])
    
    const handleSavePaymentNote = () => {
        editPaymentNote(editingPaymentNote!)
            .then(() => {
                setEditingPaymentNote(null)
            })
    }
    
    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid>
                    <Grid.Column width={1} verticalAlign='middle'>
                        <Icon size='large' color='teal' name='sticky note'/>
                    </Grid.Column>
                    <Grid.Column width={12} verticalAlign='middle'>
                        {editingPaymentNote !== null ? 
                            <Input
                                disabled={submitting}
                                fluid
                                value={editingPaymentNote}
                                onChange={(event, data) => setEditingPaymentNote(data.value)}
                            /> :
                            <Header
                                content={(Number.isNaN(Number(client?.paymentNote)) || client?.paymentNote === '') ? client?.paymentNote : formatRsd(Number(client?.paymentNote))}
                                as='h4'
                                color='teal' />}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {editingPaymentNote !== null ?
                            <Button.Group>
                                <Button
                                    disabled={editingPaymentNote === client?.paymentNote}
                                    loading={submitting}
                                    color='blue'
                                    icon='save'
                                    content='Sačuvaj'
                                    floated='right'
                                    onClick={handleSavePaymentNote} />
                                    <Button 
                                        disabled={submitting}
                                        floated='right'
                                        icon='x'
                                        onClick={() => setEditingPaymentNote(null)}
                                    />
                            </Button.Group> :
                            <Button
                                color='orange'
                                icon='edit'
                                content='Izmeni'
                                floated='right'
                                onClick={() => setEditingPaymentNote(client!.paymentNote ?? '')} />}
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment>
                <PaymentsList hideClient/>                
            </Segment>
        </Segment.Group>
    )
};

export default observer(ClientDetailsPayments)
