import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../../../app/stores/rootStore";
import Table from "../../../../../app/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import {IProductConsumptionAnalyseEntity} from "../../../../../app/models/productConsumptions";
import SumTableHeader from "../../../../../app/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../../../app/common/tables/filters/number/NumberFilter";
import {formatRsd} from "../../../../../app/common/util/format";
import {Grid} from "semantic-ui-react";
import {IBillOrderAnalyzeEntity} from "../../../../../app/models/billOrders";
import ProductsTableBasicInfoCell from "../../../../products/list/ProductsTableBasicInfoCell";
import {ProductsDetailedFilter} from "../../../../../app/common/tables/filters/products/detailed/ProductsDetailedFilter";

interface IProps {
    trackingId: string
}

const ProductConsumptionsAnalysisByProductList: FunctionComponent<IProps> = ({trackingId}) => {
    const rootStore = useContext(RootStoreContext)
    const {filteredItemsGropedByProduct: data} = rootStore.productConsumptionsStore

    const { brandsArray: brands, loadBrandsIfNull } = rootStore.brandsStore

    useEffect(() => {
        loadBrandsIfNull()
    }, [
        loadBrandsIfNull
    ])    

    const columns = useMemo(() => [
            {
                id: 'product',
                Header: 'Proizvod',
                accessor: 'name',
                Cell: ({row: {original: analyseEntity}}: Cell<IBillOrderAnalyzeEntity>) =>
                    (<ProductsTableBasicInfoCell product={analyseEntity.product!}/>),
                Filter: (d: FilterProps<any>) => ProductsDetailedFilter(d, brands),
                filter: 'productsDetailedFilter'
            },
            {
                Header: (row: any) => <SumTableHeader row={row} type={'count'} name={'Količina'} accessor={'count'}/>,
                accessor: 'count',
                Cell: ({row: {original: entity}}: Cell<IProductConsumptionAnalyseEntity>) =>
                    <span>{entity.count}</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Nabavna cena u RSD'}
                                                      accessor={'value'}/>,
                accessor: 'value',
                Cell: ({row: {original: entity}}: Cell<IProductConsumptionAnalyseEntity>) =>
                    <span>{formatRsd(entity.value)}</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },

        ],
        [
            brands
        ])

    return (<Grid>
        <Grid.Row>
            <Grid.Column>
                <Table
                    data={data}
                    columns={columns}
                    trackingId={trackingId} 
                />
            </Grid.Column>
        </Grid.Row>
    </Grid>)
}

export default observer(ProductConsumptionsAnalysisByProductList)
