import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {Form as FinalForm, Field} from "react-final-form";
import {FORM_ERROR} from "final-form";
import {Button, Form, Header, Icon, Segment, Select} from "semantic-ui-react";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {combineValidators, isRequired} from "revalidate";
import {IUserPostFormValues, UserPostFormValues} from "../../../app/models/userPosts";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {v4 as uuid} from 'uuid'

interface OwnProps {
    userPostId?: string,
    onCancel?: () => void,
    onSubmit?: () => void,
    onError?: (error: any) => void
}

type Props = OwnProps

const validate = combineValidators({
    title: isRequired({message: 'Naslov je obavezan'}),
    content: isRequired({message: 'Sadržaj je obavezan'}),
});

const UserPostForm: FunctionComponent<Props> = ({userPostId, onCancel, onSubmit, onError}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        createUserPost,
        loadUserPost,
        editUserPost,
        submitting
    } = rootStore.userPostsStore
    const {user: currentUser} = rootStore.userStore
    const {loadingUsers, loadUsers, anyRolesUsersArray: users} = rootStore.usersStore

    const [userPost, setUserPost] = useState<IUserPostFormValues>(new UserPostFormValues())
    const [loading, setLoading] = useState(false)
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])


    useEffect(() => {
        loadUsers(true)

        if (userPostId) {
            setLoading(true)

            loadUserPost(userPostId)
                .then(userPost => {
                    setUserPost(new UserPostFormValues(userPost))
                    setSelectedParticipants(userPost!.participants.map(participant => participant.user.id))
                })
                .finally(() => setLoading(false))
        } else {
            setUserPost(new UserPostFormValues())
        }

    }, [
        loadUserPost,
        loadUsers,
        userPostId
    ])

    const handleFinalFormSubmit = (values: any) => {

        const userPostFormValues: IUserPostFormValues = { ...values }
        
        if (userPostFormValues.id) {
            return editUserPost(userPostFormValues)
                .then(() => {
                    if (onSubmit) {
                        onSubmit()
                    }
                })
                .catch(error => {
                    if (onError) {
                        onError(error)
                    }
                })
        } else {
            return createUserPost({...userPostFormValues, id: uuid()})
                .then(() => {
                    if (onSubmit) {
                        onSubmit()
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (onError) {
                        onError(error)
                    }
                })
        }
    }

    return <Segment clearing>
        <Header color='grey' as='h3'>
            {userPostId ? 'Izmena posta' : 'Novi post'}
        </Header>
        <FinalForm
            onSubmit={(values: IUserPostFormValues) => handleFinalFormSubmit(values).catch(error => ({
                    [FORM_ERROR]: error
                })
            )}
            mutators={{...arrayMutators}}
            validate={validate}
            initialValues={userPost}
            render={({
                         handleSubmit,
                         invalid,
                         pristine,
                         submitError,
                         dirtySinceLastSubmit,
                         form
                     }) => (
                <Form onSubmit={handleSubmit}
                      loading={loading || loadingUsers}>

                    <Form.Field>
                        <label>Naslov</label>
                        <Field
                            name='title'
                            placeholder='Naslov'
                            value={userPost.title}
                            component={TextInput}/>
                    </Form.Field>

                    <Form.Field>
                        <label>Sadržaj</label>
                        <Field
                            name='content'
                            placeholder='Unesi sadržaj...'
                            value={userPost.content}
                            component={TextAreaInput}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Učesnici</label>
                        <FieldArray name='participantsIds'>
                            {({fields, meta}) => {

                                return <Select
                                    value={selectedParticipants}
                                    multiple
                                    clearable
                                    onChange={((event, data) => {
                                        setSelectedParticipants(data.value as string[])
                                        form.change('participantsIds', data.value as string[])
                                    })}
                                    options={users
                                        .filter(user => user.id !== currentUser?.id)
                                        .map(user => ({
                                            key: user.id,
                                            value: user.id,
                                            text: `${user.displayName} [${user.email}]`
                                        }))}
                                />
                            }
                            }
                        </FieldArray>
                    </Form.Field>

                    {(submitError && !dirtySinceLastSubmit) &&
                    <ErrorMessage error={submitError}/>}
                    <Button
                        loading={submitting}
                        disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                        floated='right'
                        color='blue'
                        type='submit'
                        icon
                        labelPosition='left'
                    >
                        <Icon name='edit' />
                        {(userPostId) ? 'Izmeni' : 'Dodaj'}
                            
                    </Button>
                    <Button
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        disabled={loading || loadingUsers}
                        floated='right'
                        type='button'
                        content='Odustani'
                    />
                </Form>
            )}
        />
    </Segment>
}

export default observer(UserPostForm)
