import React, {FunctionComponent, useContext} from 'react';
import ProfitabilityDashboardExpenseDiagram from "./ProfitabilityDashboardExpenseDiagram";
import ProfitabilityExpenseGratisDiagram from "./ProfitabilityExpenseGratisDiagram";
import {RootStoreContext} from "../../../../../app/stores/rootStore";

interface IProps {}

const ProfitabilityExpenseDiagrams: FunctionComponent<IProps> = (props) => {
  const rootStore = useContext(RootStoreContext)
  const { showDiagramsExtensions } = rootStore.profitabilityStore
  
  return (<>
    <ProfitabilityDashboardExpenseDiagram />
    {showDiagramsExtensions &&
    <ProfitabilityExpenseGratisDiagram />}
    
  </>);
};

export default ProfitabilityExpenseDiagrams;
