import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Form, Grid, Segment} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {combineValidators, isRequired} from "revalidate";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import {FORM_ERROR} from "final-form";
import {RootStoreContext} from "../../app/stores/rootStore";
import {ITrafficFormValues, TrafficFormValues} from "../../app/models/traffics";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import TextInput from "../../app/common/form/TextInput";
import {useNavbarSelectedItem} from "../nav/utils/index.js";
import BreadcrumbNavigation from "../nav/BreadcrumbNavigation";


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    billIdStringPrefix: isRequired({message: 'Prefiks je obavezan'}),
});

interface DetailParams {
    id: string;
}

const TrafficForm: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, history}) => {

    const rootStore = useContext(RootStoreContext)
    const {
        createTraffic,
        loadTraffic,
        editTraffic,
        submitting,
        currentTraffic
    } = rootStore.trafficsStore

    const [traffic, setTraffic] = useState<ITrafficFormValues>(new TrafficFormValues())
    const [loading, setLoading] = useState(false)
    const [deliveredUIstate, setDeliveredUIstate] = useState(false)

    useNavbarSelectedItem(match.params.id ? '' : 'trafficCreate')
    
    useEffect(() => {
        if (match.params.id) {
            setLoading(true)
            loadTraffic(Number(match.params.id))
                .then(traffic => {
                    setDeliveredUIstate(traffic!.isExpense)
                    setTraffic(new TrafficFormValues(traffic))
                })
                .finally(() => setLoading(false))

        } else {
            setTraffic(new TrafficFormValues())
        }
    }, [
        match.params.id,
        loadTraffic
    ]);


    const handleFinalFormSubmit = (values: any) => {
        const traffic = {...values}

        if (!traffic.id) {
            return createTraffic(traffic)
        } else {
            return editTraffic(traffic)
        }
    }

    return (
        <Grid centered>
            <Grid.Column width={16}>
                <BreadcrumbNavigation 
                    items={[
                        {
                            text: 'Prometi',
                            linkWithoutCurrentTrafficId: 'traffics'
                        },
                        {
                            text: match.params.id ? 'Izmena prometa' : 'Dodavanje prometa',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm onSubmit={(values: ITrafficFormValues) => handleFinalFormSubmit(values).catch(error => ({
                            [FORM_ERROR]: error
                        })
                    )}
                               validate={validate}
                               initialValues={traffic}
                               render={({
                                            handleSubmit,
                                            invalid,
                                            pristine,
                                            submitError,
                                            dirtySinceLastSubmit,
                                            form
                                        }) => (
                                   <Form onSubmit={handleSubmit}
                                         loading={loading}>

                                       <Field
                                           name='name'
                                           placeholder='Naziv'
                                           value={traffic.name}
                                           component={TextInput}/>
                                           
                                       <Field
                                           name='isExpense'
                                           type='checkbox'
                                           render={() => <Form.Field>
                                               <Checkbox
                                                   toggle
                                                   label='Trošak'
                                                   checked={deliveredUIstate}
                                                   onChange={(event, data) => {
                                                       const isChecked: boolean = data.checked!
                                                       setDeliveredUIstate(isChecked)
                                                       form.change<'isExpense'>('isExpense', isChecked)
                                                   }
                                                   }/>
                                           </Form.Field>}/>

                                       <Field
                                           name='billIdStringPrefix'
                                           placeholder='Prefiks šifre računa'
                                           value={traffic.billIdStringPrefix}
                                           component={TextInput}/>
                                           
                                       {/*<Field*/}
                                       {/*    labelPosition='right'*/}
                                       {/*    label={{basic: true, content: 'RSD'}}*/}
                                       {/*    name='value'*/}
                                       {/*    placeholder='Iznos'*/}
                                       {/*    value={payment.value.toString()}*/}
                                       {/*    component={TextInput}/>*/}
                                       {/*<Field*/}
                                       {/*    component={DateInput}*/}
                                       {/*    name='date'*/}
                                       {/*    date={true}*/}
                                       {/*    placeholder='Date'*/}
                                       {/*    value={payment.date}*/}
                                       {/*/>*/}
                                       {/*<Field*/}
                                       {/*    name='note'*/}
                                       {/*    placeholder='Napomena'*/}
                                       {/*    value={payment.note}*/}
                                       {/*    component={TextAreaInput}/>*/}

                                       {(submitError && !dirtySinceLastSubmit) &&
                                       <ErrorMessage error={submitError}/>}
                                       <Button
                                           loading={submitting}
                                           disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                           floated='right'
                                           color='blue'
                                           type='submit'
                                           content={(!traffic.id) ? 'Dodaj' : 'Izmeni'}
                                       />
                                       <Button
                                           onClick={() => history.push(`/${currentTraffic?.id}/traffics`)}
                                           disabled={loading}
                                           floated='right'
                                           type='button'
                                           content='Odustani'
                                       />
                                   </Form>
                               )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(TrafficForm)
