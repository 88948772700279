import React, {FunctionComponent, useContext} from 'react'
import {Button, Header, Icon, Item, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import {IEmployeeGoalDocument} from "../../../app/models/employeeGoals";
import moment from "moment";

interface OwnProps {
    employeeGoalDocument: IEmployeeGoalDocument,
    alone?: boolean,
    hideDelete?: boolean,
    compact?: boolean
}

type Props = OwnProps

const EmployeeGoalSingleDocument: FunctionComponent<Props> = ({
                                                                employeeGoalDocument,
                                                                alone,
                                                                hideDelete,
                                                                compact
                                                            }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        documentDeletingId,
        deleteDocument,
        getDocument,
        downloadingDocumentId
    } = rootStore.employeeGoalsStore
    const {openModal, closeModal} = rootStore.modalStore

    const item = <Item key={employeeGoalDocument.id}>
        <Item.Content>
            <Item.Header>{employeeGoalDocument.name}</Item.Header>
            <Item.Meta>
                <span>{employeeGoalDocument.file?.extension.replace('.', '').toUpperCase() ?? 'N/A'}</span>
            </Item.Meta>
            <Item.Extra>
                {hideDelete ??
                <Button
                    size='mini'
                    icon='trash'
                    color='red'
                    floated='right'
                    loading={documentDeletingId === employeeGoalDocument.id}
                    content={compact ? undefined : 'Obriši'}
                    onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje dokumenta
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš
                                dokument <strong>{employeeGoalDocument.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any) => {
                                event.target.className += ' loading'
                                deleteDocument(employeeGoalDocument)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )}
                />}

                <Button
                    loading={employeeGoalDocument.id === downloadingDocumentId}
                    onClick={() => getDocument(employeeGoalDocument.id)}
                    size='mini'
                    color='blue'
                    content={compact ? undefined : 'Preuzmi'}
                    floated='right'
                    icon='download' />
            </Item.Extra>
            <Item.Meta>
                {moment(employeeGoalDocument.file?.updatedAt).format('l')}
            </Item.Meta>
        </Item.Content>
    </Item>

    return alone ?
        <Item.Group>
            {item}
        </Item.Group> :
        item
}

export default observer(EmployeeGoalSingleDocument)
