import React, {useContext, useEffect} from 'react';
import {Segment, Header, Form, Button, Comment, Icon} from 'semantic-ui-react';
import {RootStoreContext} from '../../../app/stores/rootStore';
import {Form as FinalForm, Field} from 'react-final-form';
import {Link} from 'react-router-dom';
import TextAreaInput from '../../../app/common/form/TextAreaInput';
import {observer} from 'mobx-react-lite';
import moment from "moment";
import {stringPreview} from "../../../app/common/util/string";


const ClientChat = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        client,
        createHubConnection,
        stopHubConnection,
        addComment,
        hubConnectionStatusColor,
        editingCommentId,
        editComment,
        setEditingCommentId,
        deleteComment,
        loadingComments
    } = rootStore.clientsStore
    const {
        currentTraffic
    } = rootStore.trafficsStore
    const {user} = rootStore.userStore
    const {openModal, closeModal} = rootStore.modalStore

    useEffect(() => {
        createHubConnection(client!.id!);
        return () => {
            stopHubConnection();
        }
    }, [
        createHubConnection, 
        stopHubConnection, 
        client
    ])

    return (
        <div id='comments'>
            <Segment
                textAlign='center'
                attached='top'
                inverted
                color='teal'
                style={{border: 'none'}}
            >
                <Header>Komentari</Header>
            </Segment>
            <Segment attached loading={loadingComments}>
                <Comment.Group>
                    {client && client.comments && client.comments.map((comment) => (
                        <Comment key={comment.id}>
                            <Comment.Avatar src={comment.user.image || '/assets/user.png'}/>
                            <Comment.Content>
                                <Comment.Author as={Link}
                                                to={`/${currentTraffic?.id}/profile/${comment.user.username}`}>{comment.user.displayName}</Comment.Author>
                                <Comment.Metadata>
                                    <div>{moment(comment.createdAt).calendar()} {comment.createdAt !== comment.updatedAt ? `izmenjen ${moment(comment.updatedAt).calendar()}` : ''} </div>
                                </Comment.Metadata>
                                {editingCommentId === comment.id ?
                                    <Segment clearing>
                                        <FinalForm
                                            onSubmit={editComment}
                                            render={({handleSubmit, submitting, form}) => (
                                                <Form onSubmit={() => handleSubmit()!.then(() => form.reset())}>

                                                    <Field
                                                        name='content'
                                                        defaultValue={comment.content}
                                                        component={TextAreaInput}
                                                        rows={2}
                                                        placeholder='Izmeni komentar'
                                                    />
                                                    <Button
                                                        floated='right'
                                                        disabled={hubConnectionStatusColor !== 'green'}
                                                        loading={submitting}
                                                        content='Izmeni'
                                                        labelPosition='left'
                                                        icon='edit'
                                                        color='orange'
                                                    />
                                                    <Button
                                                        floated='right'
                                                        content='Odustani'
                                                        onClick={() => setEditingCommentId(null)}
                                                    />
                                                </Form>
                                            )}
                                        />
                                    </Segment>
                                    :
                                    <Comment.Text>{comment.content}</Comment.Text>
                                }

                                <Comment.Actions>
                                    {comment.user.id === user?.id && editingCommentId !== comment.id &&
                                    <Comment.Action
                                        onClick={() => setEditingCommentId(comment.id)}
                                    >Izmeni</Comment.Action>}
                                    {comment.user.id === user?.id &&
                                    <Comment.Action onClick={() => openModal(
                                        <Segment basic clearing>
                                            <Header textAlign='center' as='h2'>
                                                Brisanje komentara
                                                <Icon color='red' name='warning circle'/>
                                            </Header>
                                            <p style={{textAlign: 'center'}}>
                                                Da li zaista želiš da obrišeš komentar <strong>{stringPreview(comment.content, 200)} ?</strong>
                                            </p>
                                            <Button color='red' floated='right' onClick={(event: any) => {
                                                event.target.className += ' loading'
                                                deleteComment(comment.id)
                                                    .then(() => closeModal())
                                            }}>
                                                <Icon name='checkmark'/> Da
                                            </Button>
                                            <Button onClick={() => closeModal()} floated='right'>
                                                <Icon name='remove'/> Ne
                                            </Button>
                                        </Segment>, 'small'
                                    )} > 
                                    Obriši
                                    </Comment.Action>}

                                </Comment.Actions>
                            </Comment.Content>
                        </Comment>
                    ))}

                    <FinalForm
                        onSubmit={addComment}
                        render={({handleSubmit, submitting, form}) => (
                            <Form onSubmit={() => handleSubmit()!.then(() => form.reset())}>
                                <Field
                                    name='content'
                                    component={TextAreaInput}
                                    rows={2}
                                    placeholder='Dodaj komentar'
                                />
                                <Button
                                    disabled={hubConnectionStatusColor !== 'green'}
                                    loading={submitting}
                                    content='Dodaj komentar'
                                    labelPosition='left'
                                    icon='edit'
                                    primary
                                />
                            </Form>
                        )}
                    />

                </Comment.Group>
            </Segment>
        </div>
    );
};

export default observer(ClientChat);