import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import BillsList from "./list/BillsList";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../app/stores/rootStore";
import {Button, Grid, Icon, Segment, Tab} from "semantic-ui-react";
import BillsInstallmentsList from "./list/BillsInstallmentsList";
import moment from "moment";
import autoTable from "jspdf-autotable";
import {BillType, IBill} from "../../app/models/bills";
import {formatDateOrDefault} from "../../app/common/util/date";
import {formatRsd} from "../../app/common/util/format";
import {IBillInstallment} from "../../app/models/billInstallments";
import {PdfService} from "../../app/common/pdf/PdfService";
import {useNavbarSelectedItem} from "../nav/utils/index.js";
import BreadcrumbNavigation from "../nav/BreadcrumbNavigation";
import {DatePicker} from "antd";

interface IProps {
}

const DEFAULT_ACTIVE_TAB_INDEX = 0

const BillsDashboard: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const {
        loading: loadingBills,
        clearPredicate,
        setPredicate,
        loadBills
    } = rootStore.billsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {loading: loadingBillsInstallments} = rootStore.billsInstallmentsStore
    const {SHIT_getFilteredRowsById, removeFromRegistry} = rootStore.tablesStore

    const [activeTabIndex, setActiveTabIndex] = useState(DEFAULT_ACTIVE_TAB_INDEX)
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    useNavbarSelectedItem('billsDashboard')

    const panes = [
        {
            menuItem: 'Računi',
            render: () => <Tab.Pane loading={loadingBills}><BillsList smallCreator trackingId='billsList' /></Tab.Pane>
        },
        {
            menuItem: 'Rate',
            render: () => <Tab.Pane loading={loadingBillsInstallments}>
                <BillsInstallmentsList
                    trackingId='billInstallmentsList' startDate={startDate}
                    endDate={endDate}/>
            </Tab.Pane>
        },
    ]

    useEffect(() => () => {
        removeFromRegistry('billsList')
        removeFromRegistry('billInstallmentsList')

    }, [
        removeFromRegistry
    ])

    useEffect(() => {
        clearPredicate()
        // setPredicate('byTraffic', currentTraffic!.id.toString())

        setPredicate('startDate', startDate)
        setPredicate('endDate', endDate)

        loadBills()
    }, [
        clearPredicate,
        setPredicate,
        loadBills,
        startDate,
        endDate
    ])

    const exportPdf = () => {
        const pdfService = new PdfService('landscape')
        const doc = pdfService.doc

        const filteredRows = activeTabIndex === 0 ? SHIT_getFilteredRowsById('billsList') ?? [] :
            activeTabIndex === 1 ? SHIT_getFilteredRowsById('billInstallmentsList') ?? [] :
                []

        const head = activeTabIndex === 0 ? ['#', 'Šifra', 'Tip', 'Klijent', 'Vrednost', 'Popust', 'Napomena', 'Plaćeno', 'Datum', 'Dospeće'] :
            activeTabIndex === 1 ? ['#', 'Šifra', 'Klijent', 'Redni broj', 'Vrednost', 'Datum', 'Napomena', 'Plaćeno'] :
                []

        const billTyper = (bill: IBill) => {
            let string = ''
            if (bill.type === BillType.Specification) {
                string += 'Specifikacija'
            } else if (bill.type === BillType.Invoice) {
                string += 'Faktura'
            }

            if (bill.isGratis) {
                string += ' GRATIS'
            }

            return string
        }

        const billPaider = (bill: IBill) => {
            const paidCount = bill.billInstallments.reduce((total, current) => total + (current.paid ? 1 : 0), 0)

            return `${paidCount} / ${bill.installmentsCount}`
        }

        const body = activeTabIndex === 0 ?
            filteredRows.map((bill: IBill, index) => ([
                index + 1,
                bill.idString,
                billTyper(bill),
                bill.client.name,
                formatRsd(bill.value),
                `${bill.discountRate}%`,
                bill.note,
                billPaider(bill),
                moment(bill.date).format('l'),
                moment(bill.payday).format('l')
            ])) :
            activeTabIndex === 1 ?
                filteredRows.map((billInstallment: IBillInstallment, index) => ([
                    index + 1,
                    billInstallment.billIdString,
                    billInstallment.client!.name,
                    `${billInstallment.ordinal} / ${billInstallment.installmentsCount}`,
                    formatRsd(billInstallment.value),
                    moment(billInstallment.date).format('l'),
                    billInstallment.note,
                    billInstallment.paid ? 'Da' : 'Ne'
                ])) :
                []

        doc.text(`Tabela računa iz prometa: ${currentTraffic?.name} za period ${formatDateOrDefault(startDate, 'start')} - ${formatDateOrDefault(endDate, 'end')}`, 25, 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 75,
            head: [
                head
            ],
            body
        })


        doc.save("Računi.pdf")
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Računi',
                            active: true
                        }
                    ]}
                />
            </Grid.Column>
            <Grid.Column width={16}> {/*Little hack here*/}
                <Segment.Group>
                    <Segment attached='top' color='blue' clearing>

                        <DatePicker.RangePicker
                            // disabled={loadingBills}
                            placeholder={['Početni datum', 'Krajni datum']}
                            format='DD.MM.YYYY.'
                            onChange={(date, dateString) => {
                                setStartDate(date && date[0] ? moment(date[0]).format('YYYY-MM-DD') : '')
                                setEndDate(date && date[1] ? moment(date[1]).format('YYYY-MM-DD') : '')

                            }}
                            value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                        />

                        {/*<Input*/}
                        {/*    label={{basic: true, content: 'Od'}}*/}
                        {/*    type='date'*/}
                        {/*    value={startDate}*/}
                        {/*    onChange={((event, data) => setStartDate(data.value))}*/}
                        {/*/>*/}
                        {/*<Input*/}
                        {/*    label={{basic: true, content: 'Do'}}*/}
                        {/*    type='date'*/}
                        {/*    value={endDate}*/}
                        {/*    onChange={((event, data) => setEndDate(data.value))}*/}
                        {/*/>*/}
                        <Button
                            disabled={loadingBills || loadingBillsInstallments}
                            icon
                            labelPosition='left'
                            floated='right'
                            color='teal'
                            onClick={() => exportPdf()}>
                            <Icon name='file pdf'/>
                            PDF
                        </Button>
                    </Segment>
                    <Segment attached='bottom'>
                        <Tab
                            defaultActiveIndex={DEFAULT_ACTIVE_TAB_INDEX}
                            panes={panes}
                            onTabChange={((event, data) => setActiveTabIndex(data.activeIndex as number))}/>
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid>
    )
}

export default observer(BillsDashboard)
