import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Grid, Icon, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../app/stores/rootStore";
import ProfitabilityDashboardIncomeSection, {
    profitabilityIncomeGroupByBrandListTableTrackingId,
    profitabilityIncomeGroupByClientCategoryTableTrackingId
} from "./income/ProfitabilityDashboardIncomeSection";
import ProfitabilityDashboardExpenseSection, {
    PROFITABILITY_EXPENSE_TRACKING_ID
} from "./expense/ProfitabilityDashboardExpenseSection";
import ProfitabilityDiagrams from "./diagrams/ProfitabilityDiagrams";
import ProfitabilityFilters from "./filters/ProfitabilityFilters";
import moment from "moment";
import {PdfService} from "../../../app/common/pdf/PdfService";
import {formatDateOrDefault} from "../../../app/common/util/date";
import autoTable from "jspdf-autotable";
import {formatRsd} from "../../../app/common/util/format";
import {IBillOrderAnalyzeEntity} from "../../../app/models/billOrders";
import {IExpenseEntity} from "../../../app/models/expenses";
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import ProfitabilityPeriods from "./periods/ProfitabilityPeriods";
import styled from "styled-components";

interface IProps {
}

const ProfitabilityDashboard: FunctionComponent<IProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const {
        loadAll,
        allLoading,
        clearAllPredicates,
        setStartDateFilter,
        setEndDateFilter,
        startDateFilter,
        endDateFilter,
        incomeView,
        showDiagramsExtensions, setShowDiagramExtensions,
    } = rootStore.profitabilityStore
    const {SHIT_getFilteredRowsById} = rootStore.tablesStore

    useNavbarSelectedItem('profitability')

    const [showPrimaryView, setShowPrimaryView] = useState(false)
    const [showPeriodsView, setShowPeriodsView] = useState(true)


    useEffect(() => {
            clearAllPredicates()
            setStartDateFilter(moment().startOf('year').format('YYYY-MM-DD'))
            setEndDateFilter(moment().endOf('year').format('YYYY-MM-DD'))
            loadAll()
        },
        [
            loadAll,
            clearAllPredicates,
            setEndDateFilter,
            setStartDateFilter,
        ])

    const exportPdf = () => {
        const doc = new PdfService("portrait").doc

        doc.text(`Profitabilnost za period ${formatDateOrDefault(startDateFilter, 'start')} - ${formatDateOrDefault(endDateFilter, 'end')}`, 25, 50)

        const incomeHead = () => {
            let headArray = ['#']

            if (incomeView === 'brand') {
                headArray = [...headArray, 'Brend']
            } else if (incomeView === 'clientCategory') {
                headArray = [...headArray, 'Kategorija klijenta']
            }

            headArray = [...headArray, 'Nabavna cena u RSD', 'Prodajna', 'RUC', 'Koeficijent RUC-a', 'Marza']

            return [headArray]
        }

        const incomeBody = () => {
            if (incomeView === 'brand') {
                return SHIT_getFilteredRowsById(profitabilityIncomeGroupByBrandListTableTrackingId)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.brand?.name!,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            } else if (incomeView === 'clientCategory') {
                return SHIT_getFilteredRowsById(profitabilityIncomeGroupByClientCategoryTableTrackingId)!
                    .map((analyzeEntity: IBillOrderAnalyzeEntity, index) => ([
                        index + 1,
                        analyzeEntity.clientCategory,
                        formatRsd(analyzeEntity.summaryBasePriceInRsd),
                        formatRsd(analyzeEntity.summaryPriceWithDiscountWithBillDiscount),
                        formatRsd(analyzeEntity.differenceInPrice),
                        (analyzeEntity.differenceInPrice / analyzeEntity.summaryPriceWithDiscountWithBillDiscount).toFixed(2),
                        (analyzeEntity.summaryPriceWithDiscountWithBillDiscount / analyzeEntity.summaryBasePriceInRsd).toFixed(2)
                    ]))
            }
        }

        const incomeSummaryValue = incomeView === 'brand' ?
            SHIT_getFilteredRowsById(profitabilityIncomeGroupByBrandListTableTrackingId)!.reduce((total: IExpenseEntity, current: IBillOrderAnalyzeEntity) => ({cost: total.cost + current.differenceInPrice}), {
                name: 'Prihodi',
                color: 'teal',
                cost: 0
            } as IExpenseEntity).cost :
            incomeView === 'clientCategory' ?
                SHIT_getFilteredRowsById(profitabilityIncomeGroupByClientCategoryTableTrackingId)!.reduce((total: IExpenseEntity, current: IBillOrderAnalyzeEntity) => ({cost: total.cost + current.differenceInPrice}), {
                    name: 'Prihodi',
                    color: 'teal',
                    cost: 0
                } as IExpenseEntity).cost :
                []
        const expenseSummaryValue = SHIT_getFilteredRowsById(PROFITABILITY_EXPENSE_TRACKING_ID)!.reduce((total: IExpenseEntity, current: IExpenseEntity) => ({cost: total.cost + current.cost}), {
            name: 'Rashodi',
            color: 'red',
            cost: 0
        } as IExpenseEntity).cost

        doc.text(`Profitabilnost: ${formatRsd(incomeSummaryValue - expenseSummaryValue)}`, 30, 75)
        doc.text(`Suma prihoda: ${formatRsd(incomeSummaryValue)}`, 30, 90)
        doc.text(`Suma rashoda: ${formatRsd(expenseSummaryValue)}`, 30, 105)

        doc.text('Prihodi', 30, 125)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: 140,
            head: incomeHead(),
            body: incomeBody(),

        })

        let finalY = (doc as any).lastAutoTable.finalY;
        doc.text('Rashodi', 30, finalY + 50)
        autoTable(doc, {
            styles: {
                font: 'DVSans'
            },
            startY: finalY + 60,
            head: [
                ['#', 'Naziv', 'Iznos']
            ],
            body: SHIT_getFilteredRowsById(PROFITABILITY_EXPENSE_TRACKING_ID)!
                .map((expenseEntity: IExpenseEntity, index) => ([
                    index + 1,
                    expenseEntity.name,
                    formatRsd(expenseEntity.cost)
                ]))

        })

        doc.save("Profitabilnost.pdf")
    }


    return (<Grid>
        <Grid.Column width={16}>
            <BreadcrumbNavigation
                items={[
                    {
                        text: 'Profitabilnost',
                        active: true
                    }
                ]}
            />
        </Grid.Column>
        <Grid.Column width={16}>
            {/*<Segment color='blue' loading={allLoading}>*/}
            <Segment.Group>
                <Grid>
                    <Grid.Row style={{padding: 0}} as={Segment} attached='top'>
                        <Grid.Column width={16} style={{padding: 0}}>
                            <Flex>
                                <FiltersWrapper>
                                    <ProfitabilityFilters/>    
                                </FiltersWrapper>
                                

                                <ControlsWrapper>
                                    <CenteredCheckbox
                                        label='Primarno'
                                        checked={showPrimaryView}
                                        onChange={(event: any, data: any) => setShowPrimaryView(data.checked!)}
                                    />
                                    <CenteredCheckbox
                                        label='Periodi'
                                        checked={showPeriodsView}
                                        onChange={(event: any, data: any) => setShowPeriodsView(data.checked!)}
                                    />
                                    <Button
                                        icon
                                        labelPosition='left'
                                        color='teal'
                                        onClick={() => exportPdf()}>
                                        <Icon name='file pdf'/>
                                        PDF
                                    </Button>
                                </ControlsWrapper>
                            </Flex>

                        </Grid.Column>
                    </Grid.Row>

                    {showPrimaryView && <>
                        <Grid.Row as={Segment} attached loading={allLoading}>
                            <Grid.Column width={16}>
                                <Button icon labelPosition='left'
                                        positive={showDiagramsExtensions}
                                        onClick={() => setShowDiagramExtensions(!showDiagramsExtensions)}
                                >
                                    <Icon name='chart pie'/>
                                    Ektenzije
                                </Button>
                                <Grid centered>
                                    <Grid.Column width={14}>
                                        <ProfitabilityDiagrams/>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row as={Segment} attached loading={allLoading}>
                            {!allLoading && // ovo mora da bi rerenderovao tabele odnosno da bi 
                                // resetovao filtera da ne bi pravilo gresku kako je neki filter aktivan a ne filtrira
                                <>
                                    <Grid.Column computer={10} tablet={16} mobile={16}>
                                        <ProfitabilityDashboardIncomeSection/>
                                    </Grid.Column>

                                    <Grid.Column computer={6} tablet={16} mobile={16}>
                                        <ProfitabilityDashboardExpenseSection/>
                                    </Grid.Column>
                                </>}
                        </Grid.Row>
                    </>}

                    {showPeriodsView &&
                        <Grid.Row as={Segment} attached='bottom' loading={allLoading}>
                            <Grid.Column width={16}>
                                <ProfitabilityPeriods/>
                            </Grid.Column>
                        </Grid.Row>}
                </Grid>
            </Segment.Group>
        </Grid.Column>
    </Grid>)
}

export default observer(ProfitabilityDashboard)

const Flex = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FiltersWrapper = styled.div`
  flex-grow: 0;
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-content: center;
  margin-left: auto;
  gap: 10px;
  align-self: center;
`;

const CenteredCheckbox = styled(Checkbox)`
  align-self: center;
`;
