import React, { FunctionComponent } from 'react';
import {Header, Icon, Label} from "semantic-ui-react";
import {ITraffic} from "../../../app/models/traffics";

interface IProps {
  traffic: ITraffic
}

const TrafficBasicCell: FunctionComponent<IProps> = ({traffic}) => {
  
  return (<Header>
    {traffic.name}
    <Header.Subheader>
      {traffic.isExpense &&
        <Label style={{marginLeft: 0}} icon='dollar sign' color={'orange'} >
          <Icon name='dollar sign' /> Trošak
        </Label>}
      {traffic.isDefault &&
      <Label style={{marginLeft: 0}} icon='dollar sign' color={'blue'} >
        Podrazumevani
      </Label>}
    </Header.Subheader>
  </Header>)
}

export default TrafficBasicCell;
