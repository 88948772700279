import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {Field, Form as FinalForm} from "react-final-form";
import {Button, Divider, Form, Header} from "semantic-ui-react";
import {FORM_ERROR} from "final-form";
import TextInput from "../../../app/common/form/TextInput";
import ErrorMessage from "../../../app/common/form/ErrorMessage";
import {
    ClientTourFormValues, clientToursStatusDropdownOptions,
    getClientTourStatusColorName,
    IClientTour,
    IClientTourFormValues,
} from "../../../app/models/clientTours";
import {v4 as uuid} from "uuid";
import {combineValidators, isRequired} from "revalidate";
import {RootStoreContext} from "../../../app/stores/rootStore";
import moment from "moment";
import {DatePicker} from "antd";
import {observer} from "mobx-react-lite";
import {DATE_TIME_SERIALIZABLE_FORMAT_STRING} from "../../../app/common/util/date";
import SelectStringInput from "../../../app/common/form/SelectStringInput";
import SelectNumberInput from "../../../app/common/form/SelectNumberInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";


interface IProps {
    clientTour?: IClientTour,
    loading?: boolean,
    initialStartDateTime?: Date
    initialEndDateTime?: Date,
    onCancelCallback?: () => void,
    onSubmitSuccess?: () => void,
    editMode?: boolean
}

const validate = combineValidators({
    startDateTime: isRequired({message: 'Početni datum je obavezan'}),
    endDateTime: isRequired({message: 'Krajnji datum je obavezan'}),
    title: isRequired({message: 'Naslov je obavezan'}),
})

const ClientTourForm: FunctionComponent<IProps> = ({
                                                       onCancelCallback,
                                                       onSubmitSuccess,
                                                       clientTour: clientTourFromProps,
                                                       initialEndDateTime,
                                                       loading,
                                                       editMode,
                                                       initialStartDateTime
                                                   }) => {
    const rootStore = useContext(RootStoreContext)
    const {createClientTour, editClientTour, submitting} = rootStore.clientToursStore
    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore

    const [clientTour, setClientTour] = useState<IClientTourFormValues>(new ClientTourFormValues(clientTourFromProps))
    // const [addTourMode, setAddTourMode] = useState(false)
    // const [editingTourId, setEditingTourId] = useState('')

    useEffect(() => {
        loadClients(true)

        if (!editMode && initialStartDateTime && initialEndDateTime) {

            setClientTour(prevState => ({
                ...prevState,
                startDateTime: moment(initialStartDateTime).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING),
                endDateTime: moment(initialEndDateTime).format(DATE_TIME_SERIALIZABLE_FORMAT_STRING)
            }))
        }

    }, [
        editMode,
        initialStartDateTime,
        initialEndDateTime,
        loadClients
    ])


    const handleFinalFormSubmit = (values: any) => {
        const clientTourFormValues: IClientTourFormValues = {
            ...values
        }

        if (clientTourFormValues.id) {
            const editedClientTour = {
                ...clientTourFormValues
            }


            return editClientTour(editedClientTour)
                .then(() => {
                    if (onSubmitSuccess) {
                        onSubmitSuccess()
                    }
                })
        } else {
            const newClientTour = {
                ...clientTourFormValues,
                id: uuid()
            }

            return createClientTour(newClientTour)
                .then(() => {
                    if (onSubmitSuccess) {
                        onSubmitSuccess()
                    }
                })
        }
    }

    return <>
        <FinalForm
            onSubmit={(values: IClientTourFormValues) => handleFinalFormSubmit(values).catch(error => ({
                    [FORM_ERROR]: error
                })
            )}
            validate={validate}
            initialValues={clientTour}
            render={({
                         values,
                         handleSubmit,
                         invalid,
                         pristine,
                         submitError,
                         dirtySinceLastSubmit,
                         form: {
                             mutators: {push, pop, update},
                             change
                         },
                     }) => (
                <Form onSubmit={handleSubmit}
                      loading={loading || loadingClients}>

                    {/*     Hidden fields   */}
                    <Field
                        name='startDateTime'
                        component='input'
                        hidden
                    />
                    <Field
                        name='endDateTime'
                        component='input'
                        hidden
                    />
                    {/*     End of Hidden fields   */}

                    <Form.Field>
                        <label>Naslov</label>
                        <Field
                            name='title'
                            value={clientTour.title}
                            placeholder='Naziv'
                            component={TextInput}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Klijent</label>
                        <Field
                            search
                            name='clientId'
                            placeholder='Klijent'
                            component={SelectStringInput}
                            options={clients.map(client => ({
                                key: client.id,
                                value: client.id,
                                text: client.name
                            }))}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Datum i vreme</label>
                        <Form.Field>
                            <DatePicker.RangePicker
                                getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode as HTMLElement
                                }}
                                showTime
                                style={{border: 0, paddingLeft: 0}}
                                placeholder={['Početni datum', 'Krajni datum']}
                                onChange={(date, dateString) => {
                                    let start = ''
                                    let end = ''

                                    if (date && date[0]) {
                                        start = date[0].format(DATE_TIME_SERIALIZABLE_FORMAT_STRING)
                                    }

                                    if (date && date[1]) {
                                        end = date[1].format(DATE_TIME_SERIALIZABLE_FORMAT_STRING)
                                    }

                                    change('startDateTime', start)
                                    change('endDateTime', end)
                                }}
                                value={[values.startDateTime ? moment(values.startDateTime) : null,
                                    values.endDateTime ? moment(values.endDateTime) : null]}
                            />
                        </Form.Field>

                    </Form.Field>

                    <Form.Field>
                        <label>Lokacija</label>
                        <Field
                            name='location'
                            value={values.location}
                            component={TextInput}
                            placeholder='Lokacija'
                            onChange={(event: any, data: any) => change('location', data.value)}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Status</label>
                        <Field
                            name='status'
                            component={SelectNumberInput}
                            as={Header}
                            size='tiny'
                            color={getClientTourStatusColorName(values.status)}
                            options={clientToursStatusDropdownOptions}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Napomena</label>
                        <Field
                            name='note'
                            component={TextAreaInput}
                            value={clientTour.note}
                            placeholder='Unesi napomenu...'
                        />
                    </Form.Field>

                    {(submitError && !dirtySinceLastSubmit) &&
                    <ErrorMessage error={submitError}/>}
                    <Divider hidden/>
                    <Button
                        loading={submitting}
                        disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                        floated='right'
                        color='blue'
                        // type='submit'
                        content={(!clientTour.id) ? 'Dodaj' : 'Izmeni'}
                    />
                    
                    <Button
                        onClick={() => {
                            if (onCancelCallback) {
                                onCancelCallback()
                            }
                        }}

                        disabled={loading /* || loadingClients */}
                        floated='right'
                        type='button'
                        content='Odustani'/>
                </Form>
            )}
        />
    </>
}

ClientTourForm.defaultProps = {
    editMode: false,
    loading: false
}

export default observer(ClientTourForm)
